import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {IApplication} from 'src/app/shared/models/application';
import {IProject} from 'src/app/shared/models/project';
import {UtilsService} from 'src/app/shared/services/utils.service';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApplicationProjectService {

  options = {
    headers: new HttpHeaders({'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'})
  };

  constructor(private http: HttpClient,
              private utilsService: UtilsService) {
  }

  getProjects(): Observable<IProject[]> {
    return this.http.get<IProject[]>(environment.backendApiUrl + "/projects", this.options).pipe(catchError(this.utilsService.handleError));
  }

  getProjectsUnlinked(): Observable<IProject[]> {
    return this.http.get<IProject[]>(environment.backendApiUrl + "/projects/unlinked", this.options).pipe(catchError(this.utilsService.handleError));
  }

  createProject(p: IProject): Observable<IProject> {
    return this.http.post<IProject>(environment.backendApiUrl + "/projects", p, this.options).pipe(catchError(this.utilsService.handleError));
  }

  updateProject(id: string, p: IProject): Observable<IProject> {
    return this.http.put<IProject>(environment.backendApiUrl + "/projects/" + id, p, this.options).pipe(catchError(this.utilsService.handleError));
  }

  deleteProject(p: IProject): Observable<any> {
    return this.http.delete<IProject>(environment.backendApiUrl + "/projects/" + p.id, this.options).pipe(catchError(this.utilsService.handleError));
  }

  getApplications(): Observable<IApplication[]> {
    return this.http.get<IApplication[]>(environment.backendApiUrl + "/applications", this.options).pipe(catchError(this.utilsService.handleError));
  }

  getApplicationsNames(): Observable<string[]> {
    return this.http.get<string[]>(environment.backendApiUrl + "/applications/names", this.options).pipe(catchError(this.utilsService.handleError));
  }

  getApplicationsByDsi(dsi: string): Observable<IApplication[]> {
    return this.http.get<IApplication[]>(environment.backendApiUrl + "/applications-by-dsi/" + dsi, this.options);
  }

  createApplication(p: IApplication): Observable<IApplication> {
    return this.http.post<IApplication>(environment.backendApiUrl + "/applications", p, this.options).pipe(catchError(this.utilsService.handleError));
  }

  updateApplication(id: string, p: IApplication): Observable<IApplication> {
    return this.http.put<IApplication>(environment.backendApiUrl + "/applications/" + id, p, this.options).pipe(catchError(this.utilsService.handleError));
  }

  deleteApplication(p: IApplication): Observable<any> {
    return this.http.delete<IApplication>(environment.backendApiUrl + "/applications/" + p.id, this.options).pipe(catchError(this.utilsService.handleError));
  }

  exportProjectData(): Observable<any> {
    return this.http.get(environment.backendApiUrl + "/project/export/", {responseType: 'blob'}).pipe(catchError(this.utilsService.handleError));
  }

  exportApplicationData(): Observable<any> {
    return this.http.get(environment.backendApiUrl + "/applications/export/", {responseType: 'blob'}).pipe(catchError(this.utilsService.handleError));
  }

}
