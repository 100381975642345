import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-calender-modal-confirmation',
  templateUrl: './calender-modal-confirmation.component.html'
})
export class CalenderModalConfirmationComponent implements OnInit {

  message = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit(): void {
    this.message = "Voulez-vous vraiment supprimer le calendrier pour le mois de <b> " + this.data.month + " " + this.data.year + "</b> ?";
  }
}

