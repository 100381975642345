import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatConfirmDialogComponent} from '../mat-confirm-dialog/mat-confirm-dialog.component';

@Component({
  selector: 'app-info-dialog-container',
  templateUrl: './info-dialog-container.component.html',
})
export class InfoDialogContainerComponent {


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<MatConfirmDialogComponent>) {
  }

  closeDialog() {
    this.dialogRef.close(false);
  }


}
