import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Injectable} from "@angular/core";
import * as Papa from "papaparse";
import {Observable} from "rxjs";
import {catchError} from "rxjs/operators";
import {UtilsService} from "src/app/shared/services/utils.service";

import {environment} from "src/environments/environment";
import {EcheancesService} from "../../pack-generation/calculate-echeances/services/echeances.service";
import {RepositoryManagerComponent} from "./repository-manager.component";


@Injectable({
  providedIn: 'root'
})
export class DirectoryService {


  private headers: Array<string>;

  constructor(private http: HttpClient,
              private utilsService: UtilsService,
              private _es: EcheancesService) {
    this.headers = [];//Used in transformHeader: gets filled with existing headers
  }

  options = {
    headers: new HttpHeaders({'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'})
  };

  fileOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'})
  };

  getAllFiles(): Observable<any[][]> {
    return this.http.get<any[]>(environment.backendApiUrl + "/dir/test", this.options).pipe(catchError(this.utilsService.handleError));
  }

  removeFile(file: string[]): Observable<any[][]> {
    this.http.post<any[]>(environment.backendApiUrl + "/dir/remove", file + "(UNPARSED)", this.options).pipe(catchError(this.utilsService.handleError));
    return this.http.post<any[]>(environment.backendApiUrl + "/dir/remove", file, this.options).pipe(catchError(this.utilsService.handleError));
  }

  postFileToBack(isImport: boolean, isAlert: boolean, file: File, cp: RepositoryManagerComponent) {
    if (isImport) {
      this.headers = [];
      let generatedJSON: any;
      this._es.getAltFields().subscribe({
        next: data => {
          const altFields: string[][] = data;
          const csv = Papa.parse(file, {

            download: true,
            header: true,
            dynamicTyping: true,
            encoding: "utf-8",
            transformHeader: header => {
              let ord = 0;
              let result: string = header;

              altFields.forEach(e => {
                if (e.includes(header)) {
                  result = e[0];
                  console.log(header + "->" + result);
                }
              });

              while (this.headers.includes(result)) { //If header name is already used
                result = header + "_" + ord++;//Transform into <HEADER>_<N+1>
              }
              this.headers.push(result);//Stocks the header's name for future duplicate checks
              return result; //Applies new header
            },
            complete: result => {
              generatedJSON = result.data.filter(a => this.filterRow(a));
              this.http.post<any[]>(environment.backendApiUrl + "/dir/test?name=" + file.name + "(UNPARSED)", file, this.options).pipe(catchError(this.utilsService.handleError)).subscribe(data => {
                this.http.post<any[]>(environment.backendApiUrl + "/dir/test?name=" + file.name, generatedJSON, this.options).pipe(catchError(this.utilsService.handleError)).subscribe(data => {
                  cp.data = this.trimData(data);
                });
              })

            }
          });
        },
        error: error => {
          console.log(error);
        },
        complete: () => {

        }
      });

    }
    if (isAlert) {
      this.http.post<any[]>(environment.backendApiUrl + "/dir/alert?name=" + file.name + "(UNPARSED)", file, this.options).pipe(catchError(this.utilsService.handleError)).subscribe(data => {
        this.http.post<any[]>(environment.backendApiUrl + "/dir/alert?name=" + file.name, file, this.options).pipe(catchError(this.utilsService.handleError)).subscribe(data => {
          cp.data = this.trimData(data);
        });
      })
    }
  }

  compareDates(a: any[], b: any[]): number {
    if (a[3] == b[3]) return 0;
    if (Date.parse(a[3]) < Date.parse(b[3])) return 1;
    return -1;
  }

  trimData(data: any[]): any[] {
    return data.sort(this.compareDates);

  }

  getTimer(): Observable<number[]> {
    return this.http.get<number[]>(environment.backendApiUrl + "/dir/timer", this.options).pipe(catchError(this.utilsService.handleError));
  }

  postTimer(h: number, m: number, enable: boolean): Observable<number[]> {
    return this.http.get<number[]>(environment.backendApiUrl + "/dir/timer?h=" + h + "&m=" + m + "&enable=" + enable + "&offset=" + new Date().getTimezoneOffset(), this.options).pipe(catchError(this.utilsService.handleError));
  }


  private STATE_HEADER = "Etat";
  private SERVICE_TYPE_HEADER = "Champs personnalisés (Type de service - CDS Prod train)";
  private DEFAULT_CORRECT = "CORRECTION-DEFAUT";
  private ESTIMATED_QUOTE = "DEVIS-ESTIMATIF"

  private DEMAND_TYPE_HEADER = "Type de ticket";
  private DEMAND = "Demande";

  filterRow(row: any): boolean {
    //Rule: Demand state cannot be null nor abandonned
    if (row[this.STATE_HEADER] == null) {
      return false;
    }


    //Rule: Service type must be either "CORRECTION-DEFAUT" OR "DEVIS-ESTIMATIF"
    if (row[this.SERVICE_TYPE_HEADER] != null &&
      (row[this.SERVICE_TYPE_HEADER] == this.DEFAULT_CORRECT
        || row[this.SERVICE_TYPE_HEADER] == this.ESTIMATED_QUOTE)) {
      return false;
    }

    //Rule: Demand type must be of type "Demand"
    if (row[this.DEMAND_TYPE_HEADER] == null
      || row[this.DEMAND_TYPE_HEADER] != this.DEMAND) {
      return false;
    }
    return true;
  }

  createFile(fileData: any, name: string, isImport: boolean, isAlert: boolean): Observable<any[][]> {
    this.http.post<any[]>(environment.backendApiUrl + "/dir/test?import=" + isImport + "&alert=" + isAlert + "&name=" + name, fileData, this.options).pipe(catchError(this.utilsService.handleError));
    return this.http.post<any[]>(environment.backendApiUrl + "/dir/test?import=" + isImport + "&alert=" + isAlert + "&name=" + name, fileData, this.options).pipe(catchError(this.utilsService.handleError));
  }

  importFile(fileData: any, name: string): Observable<any[][]> {
    return this.http.post<any[]>(environment.backendApiUrl + "/dir/file-create?name=" + name, fileData, this.options).pipe(catchError(this.utilsService.handleError));
  }

  importConf(fileData: any): Observable<any[][]> {
    return this.http.post<any[]>(environment.backendApiUrl + "/dir/file-conf", fileData, this.options).pipe(catchError(this.utilsService.handleError));
  }

  readFile(name: string): Observable<string> {
    return this.http.get(environment.backendApiUrl + "/dir/file-read?name=/Guides/" + name, {responseType: 'text'}).pipe(catchError(this.utilsService.handleError));
  }

  readFileContents(name: string): Observable<string> {
    return this.http.get(environment.backendApiUrl + "/dir/csv-read?name=" + name, {responseType: 'text'}).pipe(catchError(this.utilsService.handleError));
  }

  readFileFromRepo(name: string, repo: string, type: string): Observable<string> {
    let reslink = "";
    switch (type) {
      case "Import":
        reslink += "/Import Jira ";
        break;
      case "Alert":
        reslink += "/Alertes Jira ";
        break;
    }
    reslink += repo + "/" + name;

    return this.http.get(environment.backendApiUrl + "/dir/csv-read?name=" + reslink, {responseType: 'text'}).pipe(catchError(this.utilsService.handleError));
  }
}
