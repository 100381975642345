import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IAlert } from 'src/app/shared/models/alert';
import { IDemand } from 'src/app/shared/models/demand';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  options = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' })
  };
  constructor(private http: HttpClient,
    private utilsService:UtilsService) { }

    getDemandAlerts(demand: IDemand){
      return this.http.get<IAlert[]>(environment.backendApiUrl + "/alerts/"+ demand.demandNumber, this.options);
    }

    getAlerts(apps : string[]){
      return this.http.get<IAlert[]>(environment.backendApiUrl + "/alerts/apps?apps=" + apps, this.options);
    }
}
