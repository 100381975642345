import {Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router, ROUTES} from '@angular/router';
import {GuideComponent} from 'src/app/guide/guide.component';
import {AuthenticationService} from 'src/app/shared/services/authentication.service';
import {ThemeService} from 'src/app/shared/services/theme.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.less']
})
export class DashboardComponent {

  constructor(public router: Router,
              public authenticationService: AuthenticationService,
              public dialog: MatDialog,
              public theme: ThemeService) {
  }

  get ROUTES() {
    return ROUTES;
  }


  openGuide(s: string) {
    this.dialog.open(GuideComponent, {
      data: s
    })
  }
}
