import {Component, EventEmitter, Output, ViewChild} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import * as fileSaver from 'file-saver';
import {AuthenticationService} from 'src/app/shared/services/authentication.service';
import {JiraImportService} from '../../import-data/jira-import/services/jira-import.service';
import {EcheanceDetailsComponent} from './echeance-details/echeance-details.component';
import {EcheancesListComponent} from './echeances-list/echeances-list.component';
import {EcheancesService} from './services/echeances.service';
import {registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {ApplicationSettingsService} from '../../application-settings/application-settings.service';
import {ThemeService} from 'src/app/shared/services/theme.service';

registerLocaleData(localeFr);

@Component({
  selector: 'app-calculate-echeances',
  templateUrl: './calculate-echeances.component.html',
  styleUrls: ['./calculate-echeances.component.less']
})
export class CalculateEcheancesComponent {

  @ViewChild(EcheancesListComponent)
  echeancesListComponent!: EcheancesListComponent;

  @ViewChild(EcheanceDetailsComponent)
  echeanceDetailsComponent!: EcheanceDetailsComponent;

  areCalculationsDone = false;

  @Output()
  taskComplete = new EventEmitter<boolean>();

  public enableValidation = false;

  public enableRejection = false;


  public year = "";

  spinner = false;

  exportSpinner = false;

  test = "";

  constructor(public echeancesService: EcheancesService,
              private _snackBar: MatSnackBar,
              private jiraImportService: JiraImportService,
              public authenticationService: AuthenticationService,
              public theme: ThemeService,
              private _as: ApplicationSettingsService) {
  }


  //Exports a file
  downloadFile(evt: string): void {
    this.spinner = true;
    const apps = this.echeancesListComponent.applications;
    const appNames: string[] = [];
    apps.forEach(element => {
      appNames.push(element.name + '');
    });

    this.jiraImportService.getTimedPack(evt + "", appNames).subscribe({
      next: data => {
        const blob: any = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        window.URL.createObjectURL(blob);
        fileSaver.saveAs(blob, "Données Collectées " + evt + " - " + this._as.applicationSettings.CDS_name + " - " + new Date().toLocaleDateString().replace('_', '-') + " " + new Date().toLocaleTimeString().replace('_', '-'));
      },
      error: err => {
        console.log(err);
      },
      complete: () => {
        this.spinner = false;
      }
    });
  }

  //Function called when calculations are complete, allows table display
  completeTask(event: any) {
    this.echeancesListComponent.getFilteredAndPagedDemandsAndTotals();
  }
}
