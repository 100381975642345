import { NgModule } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon'
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatStepperModule } from '@angular/material/stepper';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatCardModule} from '@angular/material/card';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatRippleModule} from '@angular/material/core';
import { HeaderBarComponent } from './header-bar/header-bar.component';
import { FooterBarComponent } from './footer-bar/footer-bar.component';
import { MenuBarComponent } from './menu-bar/menu-bar.component';
import { ImportFileComponent } from './import-file/import-file.component';
import { DialogFormComponent } from './dialog-form/dialog-form.component';
import { NotificationSnackBarComponent } from './notification-snack-bar/notification-snack-bar.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { UtilsService } from './services/utils.service';
import { SimplePromptComponent } from './simple-prompt/simple-prompt.component';
import { FileInputComponent } from './file-input/file-input.component';

@NgModule({
    imports: [
        MatToolbarModule,
        MatTableModule,
        MatFormFieldModule,
        MatIconModule,
        MatButtonModule,
        MatInputModule,
        MatSnackBarModule,
        MatTabsModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        MatAutocompleteModule,
        MatDatepickerModule,
        MatStepperModule,
        MatPaginatorModule,
        MatCardModule,
        MatExpansionModule,
        MatRippleModule,
        MatSortModule,
        MatDialogModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        HttpClientModule,
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,

    ],
    declarations:[
        DialogFormComponent,
        FooterBarComponent,
        HeaderBarComponent,
        ImportFileComponent,
        FileInputComponent,
        MenuBarComponent,
        NotificationSnackBarComponent,
        SimplePromptComponent,

    ],
    exports:[
        DialogFormComponent,
        FooterBarComponent,
        HeaderBarComponent,
        ImportFileComponent,
        FileInputComponent,
        MenuBarComponent,
        NotificationSnackBarComponent,
        SimplePromptComponent,
        MatToolbarModule,
        MatTableModule,
        MatFormFieldModule,
        MatIconModule,
        MatButtonModule,
        MatInputModule,
        MatSnackBarModule,
        MatTabsModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        MatAutocompleteModule,
        MatDatepickerModule,
        MatStepperModule,
        MatPaginatorModule,
        MatCardModule,
        MatExpansionModule,
        MatRippleModule,
        MatSortModule,
        MatDialogModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        HttpClientModule,
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
    ],
    providers:[
        UtilsService,
        { provide: MAT_DIALOG_DATA, useValue: {} },
        { provide: MatDialogRef, useValue: {} }
    ]
})
export class SharedModule {
}
