<div class="purge-modal">
  <h2 mat-dialog-title>Purge des demandes</h2>
  <mat-dialog-content class="mat-typography">
    <p>Merci de sélectionner le ou les années à purger:</p>

    <mat-form-field>
      <mat-label>Années</mat-label>
      <mat-select multiple [(ngModel)]="data.years">
        <mat-option *ngFor="let year of yearsToDisplay" [value]="year">{{ year }}</mat-option>
      </mat-select>
    </mat-form-field>
    <br/>
    <p>Purge des données manuelles ?
      <mat-slide-toggle hideIcon [(ngModel)]="data.manualData" class="slide-manual-data"></mat-slide-toggle>
    </p>


  </mat-dialog-content>
  <mat-dialog-actions class="alRight">
    <button mat-button mat-dialog-close>Abandonner</button>
    <button mat-button (click)="confirmData()" color="warn"> Valider</button>
  </mat-dialog-actions>
</div>
