import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {INetting} from 'src/app/shared/models/netting-account';
import {UtilsService} from 'src/app/shared/services/utils.service';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class NettingService {


    options = {
        headers: new HttpHeaders({'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'})
    };


    constructor(private http: HttpClient,
                private utilsService: UtilsService) {
    }

    getNettings(): Observable<INetting[]> {
        return this.http.get<INetting[]>(environment.backendApiUrl + "/nettingswithschemas", this.options).pipe(catchError(this.utilsService.handleError));
    }

    getNettingAccountHasProject(): Observable<INetting[]> {
        return this.http.get<INetting[]>(environment.backendApiUrl + "/nettingAccounts/projects", this.options).pipe(catchError(this.utilsService.handleError));
    }

    createNettingAccount(n: INetting): Observable<INetting> {
        if (n.schema_name == '') {
            n.schema_name = "null";
        }
        return this.http.post<INetting>(environment.backendApiUrl + "/nettings/" + n.schema_name, n, this.options).pipe(catchError(this.utilsService.handleError));
    }

    updateNettingAccount(id: string, n: INetting): Observable<INetting> {
        return this.http.put<INetting>(environment.backendApiUrl + "/nettings/" + id + "/" + n.schema_name, n, this.options).pipe(catchError(this.utilsService.handleError));
    }

    deleteNettingAccount(n: INetting): Observable<any> {
        return this.http.delete<INetting>(environment.backendApiUrl + "/nettings/" + n.id, this.options).pipe(catchError(this.utilsService.handleError));
    }

    getNettingNames(): Observable<string[]> {
        return this.http.get<string[]>(environment.backendApiUrl + "/nettings/names", this.options).pipe(catchError(this.utilsService.handleError));
    }

    exportData(): Observable<any> {
        return this.http.get(environment.backendApiUrl + "/nettings/export", {responseType: 'blob'}).pipe(catchError(this.utilsService.handleError));
    }


}
