import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Constants } from 'src/app/shared/constants/Constants';
import { ApplicationSettings } from 'src/app/shared/models/application-settings';
import { ApplicationSettingsEntry } from 'src/app/shared/models/application-settings-entry';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApplicationSettingsService {
  options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    }),
  };

  public applicationSettings: ApplicationSettings = new ApplicationSettings();

  public constructor(
    private http: HttpClient,
    private utilsService: UtilsService,
  ) {}

  public getApplicationSettings() {
    return this.applicationSettings;
  }

  public readAndSaveApplicationSettings(resolve?: any) {
    this.readApplicationSettings().subscribe(
      (applicationSettingsEntries: any) => {
        this.saveApplicationSettings(applicationSettingsEntries);
        if (resolve) {
          resolve();
        }
      },
    );
  }

  public readApplicationSettings(): Observable<any> {
    return this.http
      .get<any>(environment.backendApiUrl + Constants.ApiPaths.SETTINGS)
      .pipe(catchError(this.utilsService.handleError));
  }

  public saveApplicationSettings(applicationSettingsEntries: any) {
    if (applicationSettingsEntries) {
      Object.keys(this.applicationSettings).forEach((key) => {
        if (applicationSettingsEntries[key].parameterType != 'string') {
          (this.applicationSettings as any)[key] = JSON.parse(
            applicationSettingsEntries[key].parameterValue,
          );
        } else {
          (this.applicationSettings as any)[key] =
            applicationSettingsEntries[key].parameterValue;
        }
      });
    }
  }

  public updateApplicationSettings(): Observable<any> {
    const applicationSettingsArray: ApplicationSettingsEntry[] = [];
    Object.keys(this.applicationSettings).forEach((key) => {
      const applicationSettingsEntry: ApplicationSettingsEntry =
        new ApplicationSettingsEntry();
      applicationSettingsEntry.parameterKey = key;
      applicationSettingsEntry.parameterValue =
        (this.applicationSettings as any)[key] + '';
      applicationSettingsEntry.parameterType = typeof (
        this.applicationSettings as any
      )[key];
      applicationSettingsArray.push(applicationSettingsEntry);
    });
    return this.http
      .put<any>(
        environment.backendApiUrl + Constants.ApiPaths.SETTINGS,
        applicationSettingsArray,
      )
      .pipe(catchError(this.utilsService.handleError));
  }

  public startPack(month: string, year: string): Observable<any> {
    const obj: string = year + 'a' + month;
    return this.http.post<any>(
      environment.backendApiUrl + '/settings/newpack/',
      obj,
      this.options,
    );
  }

  public mergePacks(): Observable<any> {
    return this.http
      .get<any>(
        environment.backendApiUrl + '/calendriers/fusion-pack',
        this.options,
      )
      .pipe(catchError(this.utilsService.handleError));
  }
}
