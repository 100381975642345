<div>
    <h2 mat-dialog-title class="modalFusionTitle"> <mat-icon *ngIf="!canFusion()" color="error">warning</mat-icon> Fusion des packs </h2>
    <mat-dialog-content class="mat-typography">
        <p *ngIf="canFusion(); else elseBlock">Voulez-vous vraiment fusionner les packs ?</p>
        <ng-template #elseBlock>
            <p> Dernier mois de l'année non atteint. <br />
                Etes-vous sur de vouloir fusionner les packs ?</p>
        </ng-template>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Annuler</button>
        <button mat-button [mat-dialog-close]="data" [color]="canFusion()? 'primary':'error'">Fusionner</button>
    </mat-dialog-actions>
</div>