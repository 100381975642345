<div class="card">

  <!-- Calendrier Form Component -->
  <app-calendrier-form [calendrierForm]="calendrierSelected" (updateEvent)="updateList($event)"
    (clearEvent)="clearCalendrierFormSelected()" (updateYear)="updateListByYear($event)"></app-calendrier-form>

  <div class="data-header" style="justify-content: flex-end; margin-top: 30px">
    <button mat-raised-button (click)="exportData()" class="indigo"
      [style.background-color]="theme.getColor('Main Dark')">
      <mat-icon>get_app</mat-icon>
      Export Excel
    </button>
  </div>

  <mat-table #table class="mat-elevation-z8 table-container" [dataSource]="calendrier" matSort>

    <!-- month Column -->
    <ng-container matColumnDef="mois">
      <mat-header-cell *matHeaderCellDef> Mois-Année</mat-header-cell>
      <mat-cell *matCellDef="let element"><strong style="color: brown;"> {{ monthNames[element.month - 1] + '-' +
          element.year }} </strong></mat-cell>
    </ng-container>
    ->


    <!-- Initialisation Column -->
    <ng-container matColumnDef="initialisation">
      <mat-header-cell *matHeaderCellDef> Initialisation</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.initialisation | date: 'dd/MM/yyyy' }}</mat-cell>
    </ng-container>

    <!-- Ouverture Column -->
    <ng-container matColumnDef="ouverture">
      <mat-header-cell *matHeaderCellDef> Ouverture</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.ouverture | date: 'dd/MM/yyyy' }}</mat-cell>
    </ng-container>

    <!-- du Column -->
    <ng-container matColumnDef="valid_debut">
      <mat-header-cell *matHeaderCellDef> Début validation</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.valid_debut | date: 'dd/MM/yyyy' }}</mat-cell>
    </ng-container>

    <!-- au Column -->
    <ng-container matColumnDef="valid_fin">
      <mat-header-cell *matHeaderCellDef> Fin validation</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.valid_fin | date: 'dd/MM/yyyy' }}</mat-cell>
    </ng-container>

    <!-- Cloture Column -->
    <ng-container matColumnDef="cloture">
      <mat-header-cell *matHeaderCellDef> Cloture</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.cloture | date: 'dd/MM/yyyy' }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef class="stick-right Xthin" id="actions">Actions</mat-header-cell>
      <mat-cell *matCellDef="let element" class="stick-right thin">
        <div class="action-button">
          <button mat-icon-button aria-label="Edition du mois" (click)="onEditRowPencel(element)"
            [disabled]="!authenticationService.isUserAdmin()">
            <mat-icon>mode_edit</mat-icon>
          </button>
          <button mat-icon-button aria-label="Suppression du mois" (click)="onDeleteRow(element)"
            [disabled]="!authenticationService.isUserAdmin()">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </mat-cell>
    </ng-container>


    <mat-header-row *matHeaderRowDef="displayedColumns ; sticky: true;"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns; " (click)="selectedCalendrierForm(row)"></mat-row>
  </mat-table>

</div>