import {Component, Input} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {JiraErrorComponent} from '../jira-error/jira-error.component';

@Component({
  selector: 'app-jira-error-list',
  templateUrl: './jira-error-list.component.html',
  styleUrls: ['./jira-error-list.component.less']
})
export class JiraErrorListComponent {

  @Input() errors!: any;

  errorData!: MatTableDataSource<any>;
  displayedColumns: string[] = ["demand", "errors"];


  constructor(private dialog: MatDialog) {
    this.errorData = new MatTableDataSource();
    this.errorData.data = this.errors;
  }


  //Opens a MatDialog displaying all warnings and errors
  openErrorDetails(error: any) {
    this.dialog.open
    (JiraErrorComponent, {
      minWidth: '50vw',
      maxWidth: '65vw',
      data: {data: error}
    }).afterClosed().subscribe({
      next: (response: any) => {
        console.log(response);
      },
      error: (error: any) => {
        console.error(error);
      },
      complete: () => {
        console.log('Dialog closed');
      }
    });
  }
}
