<div class="card">
    <app-netting-schema-form [netting_schema]=netting_schemaSelected (updateEvent)="updateList($event)"
        (clearEvent)="clearSelected()" [ngStyle]="authenticationService.isUserAdmin()? {}: {'display':'none'}">
    </app-netting-schema-form>

    <div class="data-header">
        <mat-form-field>
            <mat-label>Filtre</mat-label>
            <input matInput (keyup)="filterList($event)" #input>
        </mat-form-field>
        <button mat-raised-button [ngStyle]="authenticationService.isUserAdmin()? {}: {'display':'none'}"
            (click)="exportData()" class="indigo" [style.background-color]="theme.getColor('Main Dark')">
            <mat-icon>get_app</mat-icon>
            Export Excel
        </button>
    </div>

    <mat-table class="mat-elevation-z4" [dataSource]="netting_schemas" matSort>
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header id="name">Nom</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="line">
            <mat-header-cell *matHeaderCellDef mat-sort-header id="line">Ligne</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.line }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="nature">
            <mat-header-cell *matHeaderCellDef mat-sort-header id="nature">Nature Imputation</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.nature }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="na_name">
            <mat-header-cell *matHeaderCellDef mat-sort-header id="na_name">Compte Imputé</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.na_name }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="line_ref">
            <mat-header-cell *matHeaderCellDef id="line_ref">Ligne de référence</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.line_ref }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="taux1">
            <mat-header-cell *matHeaderCellDef id="taux1">1er taux</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.taux1 }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="taux2">
            <mat-header-cell *matHeaderCellDef id="taux2">2nd taux</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.taux2 }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef id="actions">Actions</mat-header-cell>
            <mat-cell *matCellDef="let element" class="actions-td">
                <div>
                    <button mat-icon-button aria-label="delete" (click)="onEditRowPencel(element)"
                        [disabled]="!authenticationService.isUserAdmin()">
                        <mat-icon>mode_edit</mat-icon>
                    </button>
                    <button mat-icon-button aria-label="delete" (click)="onDeleteRow(element)"
                        [disabled]="!authenticationService.isUserAdmin()">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; let i = index; columns: displayedColumns;"
            [ngStyle]="isFirstOfGroup(i) ? {'background-color': getBackgroundColor(), 'color': 'white'} : {}"
            (click)="onEditRow(row)">
        </mat-row> </mat-table>

</div>