import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IProject } from 'src/app/shared/models/project';
import { ApplicationProjectService } from '../services/application-project.service';
import { ProjectListComponent } from '../project-list/project-list.component';
import { INetting } from 'src/app/shared/models/netting-account';
import { UsersService } from "../../user/services/users.service";
import { SnackbarService } from '../../../shared/services/snackbar.service';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.less']
})
export class ProjectComponent {

  formGroup: UntypedFormGroup;
  asEdited = false;
  users: string[];
  filteredOptions!: string[];

  @Input()
  project!: IProject;

  @Output()
  updateEvent = new EventEmitter<IProject>();

  @Output()
  clearEvent = new EventEmitter<any>();

  @Input()
  nettingsNames: any[] = [];

  @Input()
  nettingAccounts!: INetting[];

  btnStatus = false;
  beb = false;

  constructor(private _fb: UntypedFormBuilder, private _aps: ApplicationProjectService, private snackbarService: SnackbarService,
    private pl: ProjectListComponent,
    usersService: UsersService,
  ) {
    this.formGroup = this._fb.group({
      name: ['', Validators.required],
      nettingAccounts: [''],
      beb: false,
      user: ['', Validators.required],
      key_jira: ['']

    });

    this.users = usersService.getAllNames();
    this.filteredOptions = this.users;
  }

  openErrorSnackBar(message: string) {
    this.snackbarService.openSnackBar(message, 'error-snack-bar');
  }

  openSuccessSnackBar(message: string) {
    this.snackbarService.openSnackBar(message);
  }


  updateFormGroup() {
    const nettingAccounts: INetting[] = this.project.nettingAccounts as INetting[];
    let nettingsNames: string[] = [];
    if (nettingAccounts) {
      nettingsNames = nettingAccounts.map<string>(netting => netting.name as string);
    }

    const pUpdate = {
      name: this.project.name,
      nettingAccounts: nettingsNames,
      beb: this.project.beb,
      user: !this.project.user ? null : this.project.user.identifier,
      key_jira: this.project.key_jira

    }
    this.formGroup.setValue(pUpdate);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.asEdited = false;

    if (this.project == null) {
      this.formGroup.reset()
    } else if (this.project.check && this.project.name != '') {
      this.updateFormGroup()
      this.asEdited = true;
      this.btnStatus = false;

    } else if (!this.project.check && this.project.name != '') {
      this.updateFormGroup()
      this.asEdited = false;
      this.btnStatus = true;

    }

  }

  onSubmit(): void {

    //Get user.identifier form user field
    const userValue = this.formGroup.value.user;
    this.formGroup.value.user = userValue.split("(")[userValue.split("(").length - 1].replace(")", "");


    this.formGroup.value.user = {
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      identifier: this.formGroup.value.user
    }

    const selectedNettings: INetting[] = [];

    if (this.formGroup.value.nettingAccounts != null) {
      this.formGroup.value.nettingAccounts.forEach((netting: INetting) => {
        const name = netting + ""
        selectedNettings.push(this.nettingsName(name, this.nettingAccounts));
      })
    }

    this.formGroup.value.nettingAccounts = selectedNettings;

    this.formGroup.value.beb = this.beb

    if (!this.asEdited && !this.btnStatus) {

      this._aps.createProject(this.formGroup.value).subscribe({
        next: data => {
          this.updateEvent.emit(data);
          this.formGroup.reset();
          this.beb = false;
          this.openSuccessSnackBar("Le projet a été créé avec succès")
        },
        error: err => {
          console.log(err)
          this.openErrorSnackBar("Le nom de projet et le responsable sont obligatoires")
        },
        complete: () => {
        }
      });
    } else if (!this.asEdited && this.btnStatus) {
      this._aps.updateProject(this.project.id ?? '', this.formGroup.value).subscribe({
        next: data => {
          this.updateEvent.emit(data);
          this.formGroup.reset();
          this.asEdited = false;
          this.btnStatus = false
          this.beb = false;
          this.openSuccessSnackBar("Le projet a été modifié avec succès")
        },
        error: err => {
          console.log(err)
          this.openErrorSnackBar("Le nom de projet et le responsable sont obligatoires")
        },
        complete: () => {
        }
      });
    }


  }

  /*
   * Initialize orderform component
   */
  clear(): void {
    this.formGroup.reset();
    this.asEdited = false;
    this.btnStatus = false
    this.clearEvent.emit();
  }


  is_edit(asEdited: boolean) {

    asEdited = !asEdited
    this.asEdited = asEdited
    this.btnStatus = true
  }

  /*
   * Delete project form
   */
  deleteProjectForm() {
    if (this.asEdited) {
      this._aps.deleteProject(this.project).subscribe({
        next: data => {
          this.clear();
          this.updateEvent.emit(data);
          this.openSuccessSnackBar("Le projet a été supprimé avec succès")
        },
        error: err => {
          console.log(err)
          this.openErrorSnackBar("Erreur lors de la suppression du projet")
        },
        complete: () => {
        }
      });
    }
  }

  /*
   * Pop Confirmation for deleting project
   */
  confirmDelete() {
    this.pl.onDeleteRow(this.project);
  }

  /*
   * Netting accounts name
   */

  public nettingsName(netting: string, nettingAccounts: INetting[]): INetting {
    let res: INetting = nettingAccounts[0];
    nettingAccounts.forEach((n: INetting) => {
      if (netting == n.name) {
        res = n;
      }
    })
    return res;
  }

  onchangeBeb(beb: boolean) {
    this.beb = beb
  }

  /*
  * Filters contact identifiers from users
  */
  public filter(value: string) {
    if (value) {
      const filterValue = value.toLowerCase();
      this.filteredOptions = this.users.filter(option => option.toLowerCase().includes(filterValue));
    }
  }
}
