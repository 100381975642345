<nav [style.background-color]="theme.getColor('Main Dark')" mat-tab-nav-bar class="collected-data"
  *ngIf="router.url.includes('data/')" [tabPanel]="tabPanel">
  <a mat-tab-link [ngClass]="{'current' : router.url.includes('pack-generation')}"
    routerLink="/core/data/pack-generation"> Données collectées </a>
  <div class="flex-grow"></div>
  <a (click)="openGuide('dataGuide')">
    <div class="material-icons help-icon">help_outline</div>
  </a>
</nav>
<mat-tab-nav-panel #tabPanel>
  <!-- Contenu des onglets ici -->
</mat-tab-nav-panel>