import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {IDemand} from 'src/app/shared/models/demand';
import {IPack} from 'src/app/shared/models/pack';
import {UtilsService} from 'src/app/shared/services/utils.service';
import {environment} from 'src/environments/environment';
import {Constants} from 'src/app/shared/constants/Constants';
import * as moment from 'moment';
import {EcheanceStatus} from 'src/app/shared/models/echeance-status';
import {UserProfile} from 'src/app/shared/models/user-profile';
import {AuthenticationService} from 'src/app/shared/services/authentication.service';
import {IComment} from 'src/app/shared/models/comment';
import {IDsi} from 'src/app/shared/models/dsi';
import {IManualData} from 'src/app/shared/models/manual-data';
import {NettingService} from 'src/app/core/netting-account/netting.service';
import {IAlert} from 'src/app/shared/models/alert';

@Injectable({
    providedIn: 'root'
})
export class EcheancesService {

    private billingMonth = 0;

    private billingYear = 0;

    constructor(private http: HttpClient,
                private utilsService: UtilsService,
                public authenticationService: AuthenticationService,
                public nettingService: NettingService) {

        this.getLatestPack().subscribe(value => {
            this.billingMonth = value.packMonth;
            this.billingYear = value.packYear;
        });
    }

    public getBillingMonth() {
        return this.billingMonth;
    }

    public getBillingYear() {
        return this.billingYear;
    }

    getLatestPack(): Observable<IPack> {
        return this.http.get<IPack>(environment.backendApiUrl + "/pack/latest").pipe(catchError(this.utilsService.handleError));
    }

    getDemands(): Observable<IDemand[]> {
        const optionsDemands = {
            headers: new HttpHeaders({'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'})
        };
        return this.http.get<IDemand[]>(environment.backendApiUrl + "/demands", optionsDemands).pipe(catchError(this.utilsService.handleError));
    }

    getDemand(number: string): Observable<IDemand> {
        const optionsDemands = {
            headers: new HttpHeaders({'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'})
        };
        return this.http.get<IDemand>(environment.backendApiUrl + "/demand/" + number, optionsDemands).pipe(catchError(this.utilsService.handleError));
    }

    updateDemandRef(number: string, ref: string): Observable<IDemand> {
        const optionsDemands = {
            headers: new HttpHeaders({'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'})
        };
        return this.http.post<IDemand>(environment.backendApiUrl + "/demand/ref?demand=" + number + "&ref=" + ref, optionsDemands).pipe(catchError(this.utilsService.handleError));
    }

    getPaginatedDemands(page: number, size: number, direction: string, filter: string, reffilter: string, galfilter: string, desfilter: string, years: number[], applicationsNames: string[], prestationNames: string[], mode: string, user: string, demandStatus: string[], serviceFilter: string[]): Observable<{ demands: IDemand[], total: number }> {
        if (filter == "") filter = "null";
        if (reffilter == "") reffilter = "null";
        if (direction == "") direction = "null";
        if (galfilter == "") galfilter = "null";
        if (desfilter == "") desfilter = "null";
        const optionsDemands = {
            headers: new HttpHeaders({'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'})
        };
        return this.http
            .post<{ demands: IDemand[], total: number }>(environment.backendApiUrl + "/demands" + "?page=" + page + "&size=" + size + "&direction=" + direction + "&filter=" + filter + "&reffilter=" + reffilter + "&galfilter=" + galfilter + "&desfilter=" + desfilter.toUpperCase() + "&year=" + years + "&mode=" + mode + "&user=" + user + "&demandStatus=" + demandStatus + "&serviceStatus=" + serviceFilter, applicationsNames.concat(prestationNames), optionsDemands)
            .pipe(catchError(this.utilsService.handleError));
    }

    getDueTotal(filter: string, reffilter: string, galfilter: string, desfilter: string, years: number[], applicationsNames: string[], prestationNames: string[], mode: string, user: string, demandStatus: string[],serviceFilter: string[]): Observable<number[]> {
        if (filter == "") filter = "null";
        if (reffilter == "") reffilter = "null";
        if (galfilter == "") galfilter = "null";

        if (desfilter == "") desfilter = "null";
        else desfilter = desfilter.toUpperCase();
        const optionsDemands = {
            headers: new HttpHeaders({'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'})
        };
        return this.http
            .post<number[]>(environment.backendApiUrl + "/dues/duetotal" + "?filter=" + filter + "&reffilter=" + reffilter + "&galfilter=" + galfilter + "&desfilter=" + desfilter.toUpperCase() + "&year=" + years + "&mode=" + mode + "&user=" + user + "&demandStatus=" + demandStatus + "&serviceStatus=" + serviceFilter, applicationsNames.concat(prestationNames), optionsDemands)
            .pipe(catchError(this.utilsService.handleError));
    }

    getCalculDues(): Observable<any> {
        return this.http.get(environment.backendApiUrl + "/jobLauncher", {
            headers: new HttpHeaders({'Access-Control-Allow-Origin': '*'}),
            responseType: 'text',
        }).pipe(catchError(this.utilsService.handleError));
    }

    getCalculDue(number: string): Observable<any> {
        return this.http.get(environment.backendApiUrl + "/jobLauncher/" + number, {
            headers: new HttpHeaders({'Access-Control-Allow-Origin': '*'}),
            responseType: 'text',
        }).pipe(catchError(this.utilsService.handleError));
    }

    submitComment(comment: IComment, demandeNumber: string): Observable<any> {
        return this.http.post(environment.backendApiUrl + "/comment/" + demandeNumber, comment)
            .pipe(catchError(this.utilsService.handleError));
    }

    public getComments(demandeNumber: string): Observable<any> {
        return this.http.get(environment.backendApiUrl + "/comment/demand/" + demandeNumber).pipe(catchError(this.utilsService.handleError));
    }

    public rejectDemands(demandsNumbers: string[], comment: string): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set(Constants.HttpHeaders.CONTENT_TYPE, Constants.HttpHeaders.APP_JSON);
        httpHeaders.set(Constants.HttpHeaders.ACCEPT, Constants.HttpHeaders.APP_JSON);

        const body = {
            demandsNumber: demandsNumbers,
            comment: comment
        }
        return this.http.put(environment.backendApiUrl + "/demands/reject",
            body,
            {headers: httpHeaders}
        ).pipe(catchError(this.utilsService.handleError));
    }

    public validateDemands(demandsNumbers: string[]): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set(Constants.HttpHeaders.CONTENT_TYPE, Constants.HttpHeaders.APP_JSON);
        httpHeaders.set(Constants.HttpHeaders.ACCEPT, Constants.HttpHeaders.APP_JSON);
        return this.http.put(environment.backendApiUrl + "/demands/validate",
            demandsNumbers,
            {headers: httpHeaders}
        ).pipe(catchError(this.utilsService.handleError));
    }

    public changeDemandEcheanceStatus(demandNumber: string, status: EcheanceStatus): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set(Constants.HttpHeaders.CONTENT_TYPE, Constants.HttpHeaders.APP_JSON);
        httpHeaders.set(Constants.HttpHeaders.ACCEPT, Constants.HttpHeaders.APP_JSON);
        return this.http.put(environment.backendApiUrl + "/demands/" + demandNumber + "/status",
            [status],
            {headers: httpHeaders}
        ).pipe(catchError(this.utilsService.handleError));
    }

    /**
     * Vérifier si la demande peut être validé/rejetté par l'utilisateur en se basant sur :
     * - le profil utilisateur
     * - le status de l'échéance correspondant au mois de facturation
     */
    checkIfDemandValidableARRejectable(demand: IDemand) {
        try {
            if (!demand.echeances) {
                return;
            }

            demand.echeances.forEach(echeance => {
                const echeanceDate = moment(echeance.date, Constants.Patterns.LOCAL_DATE_TIME_FORMAT);
                if (
                    echeanceDate.month() + 1 == this.getBillingMonth()
                    && echeanceDate.year() == this.getBillingYear()

                ) {
                    demand.validable =
                        echeance.status
                        && echeance.status != EcheanceStatus.VALIDE_SNCF
                        &&
                        (
                            (
                                echeance.status == EcheanceStatus.EN_ATTENTE_VALIDATION_CDS
                                && this.authenticationService.getUser()
                                && (this.authenticationService.getUser().profile == UserProfile.ADMIN
                                    || this.authenticationService.getUser().profile == UserProfile.CP_CDS
                                    || this.authenticationService.getUser().profile == UserProfile.MANAGER_CDS)
                            )
                            ||
                            (
                                (echeance.status.includes(EcheanceStatus.EN_ATTENTE_VALIDATION_SNCF)
                                    || echeance.status.includes(EcheanceStatus.REFUSE))
                                && this.authenticationService.getUser()
                                && (this.authenticationService.getUser().profile == UserProfile.CP_SNCF
                                    || this.authenticationService.getUser().profile == UserProfile.SERVICE_MANAGER)
                            )
                        );

                    demand.rejectable =
                        echeance.status
                        && echeance.status != EcheanceStatus.REFUSE
                        && (
                            (
                                echeance.status.includes(EcheanceStatus.EN_ATTENTE_VALIDATION_SNCF)
                                || echeance.status.includes(EcheanceStatus.VALIDE_SNCF))
                            && this.authenticationService.getUser()
                            && (this.authenticationService.getUser().profile == UserProfile.CP_SNCF
                                || this.authenticationService.getUser().profile == UserProfile.SERVICE_MANAGER)
                        );
                    demand.selected = false;
                }
            })
        } catch (breaked: any) {
            // echeance elligible trouvée, interrompre la boucle
          console.log(breaked);
        }

    }

    getDemandManualData(demand: IDemand) {
        const optionsDemands = {
            headers: new HttpHeaders({'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'})
        };
        return this.http.get<IManualData>(environment.backendApiUrl + "/manual-data/" + demand.demandNumber, optionsDemands);
    }

    getDemandAlerts(demand: IDemand) {
        return this.http.get<IAlert[]>(environment.backendApiUrl + "/alerts/" + demand.demandNumber, this.options);
    }


    options = {
        headers: new HttpHeaders({'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'})
    };

    postDemandManualData(manualData: IManualData): Observable<IManualData[]> {
        //console.log(manualData)
        return this.http.post<IManualData[]>(environment.backendApiUrl + "/manual-data/", manualData, this.options);
    }

    public startPack(obj: string): Observable<any> {
        return this.http.post<any>(environment.backendApiUrl + "/newpack", obj, this.options);
    }

    getDsi(): Observable<IDsi[]> {
        return this.http.get<IDsi[]>(environment.backendApiUrl + "/dsi");
    }

    public getYearsForPurgeDemands(): Observable<any> {
        return this.http.get<any>(environment.backendApiUrl + "/demands/getYears", this.options)
            .pipe(catchError(this.utilsService.handleError));
    }

    public purgeDemands(years: number[], includeManualData: boolean): Observable<any> {
        return this.http.delete<any>(environment.backendApiUrl + '/demands?years=' + years + '&includeManualData=' + includeManualData, this.options)
            .pipe(catchError(this.utilsService.handleError));
    }

    public getPrestations(): Observable<any> {
        return this.http.get<IDsi[]>(environment.backendApiUrl + "/prestations");
    }

    public getAltFields(): Observable<any> {
        return this.http.get<string[][]>(environment.backendApiUrl + "/alt-fields");
    }


    public validatePendingDemands(): Observable<any> {
        return this.http.get<any>(environment.backendApiUrl + "/validate-pending", this.options);
    }

    public postponeDemands(): Observable<any> {
        return this.http.get<any>(environment.backendApiUrl + "/postpone", this.options);
    }

    public deleteDemand(d: IDemand): Observable<any> {
        return this.http.delete<IDemand>(environment.backendApiUrl + "/demands/" + d.demandNumber, this.options).pipe(catchError(this.utilsService.handleError));
    }

    public getAllManualData(): Observable<IManualData[]> {
        return this.http.get<IManualData[]>(environment.backendApiUrl + "/manual-data");
    }

    public initNetting(): Observable<string> {
        return this.http.get<string>(environment.backendApiUrl + "/nettings/init", this.options).pipe(catchError(this.utilsService.handleError));
    }

    exportData(): Observable<any> {
        return this.http.get(environment.backendApiUrl + "/comment/export", {responseType: 'blob'}).pipe(catchError(this.utilsService.handleError));
    }

    public getDTWithOvershootOfMoreFivePercent():Observable<string[]>{
      return this.http.get<string[]>(environment.backendApiUrl + "/ordersForms/dtWithRatioGreaterThanFivePercent", this.options).pipe(catchError(this.utilsService.handleError));
    }

    public getAllServices():Observable<string[]>{
        return this.http.get<string[]>(environment.backendApiUrl + "/services", this.options).pipe(catchError(this.utilsService.handleError));
      }
    
}
