import {Component, Inject} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {INetting} from '../../models/netting-account';
import {ThemeService} from '../../services/theme.service';
import {DialogFormNettingService} from '../dialog-form-netting.service';

@Component({
  selector: 'app-dialog-form-netting',
  templateUrl: './dialog-form-netting.component.html',
  styleUrls: ['./dialog-form-netting.component.less']
})
export class DialogFormNettingComponent {

  public formGroup!: UntypedFormGroup;
  nettingsAccounts: INetting[] = [];
  nettingsNames: string[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public theme: ThemeService, private _fb: UntypedFormBuilder, public dialogRef: MatDialogRef<number>, private _np: DialogFormNettingService,
              private _snackBar: MatSnackBar) {
    this.formGroup = this._fb.group({
      ndemand: '',
      nettingsNames: ''
    });

    this.formGroup.setValue({ndemand: '', nettingsNames: data.netting});

    //Creates nettingAccountsList
    this.nettingsAccounts = [];
    this.nettingsNames.push("aucun");

    this._np.getNettingAccountsFromDemand(this.data).subscribe({
      next: value => {
        this.nettingsAccounts = value;
        this.nettingsAccounts.forEach((n: INetting) => {
          this.nettingsNames.push(n.name + "");
        });
      },
      error: err => {
        console.log(err);
        this.openSnackBar(err);
      },
      complete: () => {

      }
    });

  }

  /*
  * Submit form dialog
  */
  closeDialog(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (this.nettingsAccounts.length == 0 || this.formGroup.value.nettingsNames == "aucun") {
      this.formGroup.value.nettingsNames = null;
      this.formGroup.value.ndemand = this.data.demandNumber;
    } else {
      const name = this.formGroup.value.nettingsNames;
      let res = this.nettingsAccounts[0];

      this.nettingsAccounts.forEach((n: INetting) => {
        if (name == n.name) {
          res = n;
        }
      })

      this.formGroup.value.nettingsNames = res;
      this.formGroup.value.ndemand = this.data.demandNumber;
    }

    this._np.createNettingPack(this.formGroup.value).subscribe({
      next: data => {
        this.close(true);
      },
      error: err => {
        console.log(err);
        this.openSnackBar(err);
      },
      complete: () => {
      }
    });
  }


  /*
  * Close form dialog
  */

  close(succes: boolean) {
    this.dialogRef.close(succes);
  }

  /*
  * Show error in a notification bar
  */
  openSnackBar(err: string) {
    this._snackBar.open('ERREUR', err, {
      duration: 6000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
    });
  }
}


