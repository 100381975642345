import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ICalendrier, IMonthsClos} from 'src/app/shared/models/calendrier';
import {UtilsService} from 'src/app/shared/services/utils.service';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CalendrierService {

  options = {
    headers: new HttpHeaders({'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'})
  };

  constructor(private http: HttpClient,
              private utilsService: UtilsService) {
  }

  updateCalendrier(id: string, p: ICalendrier): Observable<ICalendrier> {
    return this.http.put<ICalendrier>(environment.backendApiUrl + "/calendriers/" + id, p, this.options).pipe(catchError(this.utilsService.handleError));
  }

  createCalendrier(c: ICalendrier): Observable<ICalendrier> {
    return this.http.post<ICalendrier>(environment.backendApiUrl + "/calendriers", c, this.options).pipe(catchError(this.utilsService.handleError));
  }

  getCalendrier() {
    return this.http.get<ICalendrier[]>(environment.backendApiUrl + "/calendriers", this.options).pipe(catchError(this.utilsService.handleError));
  }


  getCalendriersByYear(year: number) {
    return this.http.get<ICalendrier[]>(environment.backendApiUrl + "/calendriers/" + year, this.options).pipe(catchError(this.utilsService.handleError));
  }

  exportData(): Observable<any> {
    return this.http.get(environment.backendApiUrl + "/calendriers/export/", {responseType: 'blob'}).pipe(catchError(this.utilsService.handleError));
  }

  getMonthsClos(year: number): Observable<IMonthsClos[]> {
    return this.http.get<IMonthsClos[]>(environment.backendApiUrl + "/calendriers/ended_months/" + year, this.options).pipe(catchError(this.utilsService.handleError));
  }

  deleteCalendrier(id: string): Observable<ICalendrier> {
    return this.http.delete<ICalendrier>(environment.backendApiUrl + "/calendriers/" + id, this.options).pipe(catchError(this.utilsService.handleError));
  }
}
