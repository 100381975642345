import { Component, EventEmitter, Input, Output, SimpleChanges, } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ExcludedDemandsType, IExcluded_demand, } from 'src/app/shared/models/excluded-demand';

import { DialogService } from 'src/app/shared/services/dialog.service';
import { ExcludedDemandService } from '../excluded-demand.service';
import { SnackbarService } from '../../../shared/services/snackbar.service';

@Component({
  selector: 'app-excluded-demand-form',
  templateUrl: './excluded-demand-form.component.html',
  styleUrls: ['./excluded-demand-form.component.less'],
})
export class ExcludedDemandFormComponent {
  formGroup: UntypedFormGroup;
  asEdited = false;
  asValid = false;

  @Input()
  excluded_demand!: IExcluded_demand;

  @Output()
  updateEvent = new EventEmitter<IExcluded_demand>();

  constructor(
    private _fb: UntypedFormBuilder,
    private snackbarService: SnackbarService,
    private _exd: ExcludedDemandService,
    private dialogService: DialogService,
  ) {
    this.formGroup = this._fb.group({
      id: [''],
      exd_number: [''],
      exd_type: ['', Validators.required],
    });
  }

  openErrorSnackBar(message: string) {
    this.snackbarService.openSnackBar(message, 'error-snack-bar');
  }

  openSuccessSnackBar(message: string) {
    this.snackbarService.openSnackBar(message);
  }


  get ExcludedDemandsType() {
    return ExcludedDemandsType;
  }

  get Object() {
    return Object;
  }

  /*
   * onSubmit form
   */
  onSubmit(): void {
    this._exd.createExcludedDemands(this.formGroup.value).subscribe({
      next: (res) => {
        this.updateEvent.emit(res);
        this.formGroup.reset();
        this.openSuccessSnackBar('Exclusion créée avec succès');
      },
      error: (err) => {
        console.log(err);
        this.openErrorSnackBar(
          'Une exclusion avec ce numéro est deja présente',
        );
      },
      complete: () => {
      }
    });
  }

  /*
   * onUpdate form
   */
  onUpdate(): void {
    this._exd
      .updateExcludedDemands(
        this.excluded_demand.id ?? '',
        this.formGroup.value,
      )
      .subscribe({
        next: (res) => {
          this.updateEvent.emit(res);
          this.formGroup.reset();
          this.openSuccessSnackBar('Exclusion mise à jour avec succès');
        },
        error: (err) => {
          console.log(err);
          this.openErrorSnackBar(
            "Une erreur est survenue lors de la mise à jour de l'exclusion",
          );
        },
        complete: () => {
        },
      });
  }

  /*
   * Onchnages domain form object
   */
  ngOnChanges(changes: SimpleChanges): void {
    this.asEdited = false;

    if (this.excluded_demand?.id != null) {
      this.asEdited = true;
    }

    if (this.excluded_demand?.isValid) {
      this.asValid = true;
      this.asEdited = false;
    }

    if (this.excluded_demand != null && !this.excluded_demand.isValid) {
      this.asValid = false;
    }

    if (this.excluded_demand == null) {
      this.clear();
      return;
    }

    this.updateExdForm();
  }

  updateExdForm() {
    const pUpdate = {
      id: this.excluded_demand.id,
      exd_number: this.excluded_demand.exd_number,
      exd_type: this.excluded_demand.exd_type,
    };
    this.formGroup.setValue(pUpdate);
  }

  onEdit() {
    this.asEdited = false;
    this.asValid = true;
  }

  /*
   * Initialize exdForm component
   */
  clear(): void {
    this.formGroup.reset();
    this.asEdited = false;
    this.asValid = false;
    this.updateEvent.emit();
  }

  /*
   * delete exd
   */
  onDelete() {
    this.dialogService
      .openConfirmDialog(this.excluded_demand.exd_number)
      .afterClosed()
      .subscribe({
        next: (res) => {
          if (res) {
            this._exd.deleteExcludedDemands(this.excluded_demand).subscribe({
              next: (response) => {
                this.updateEvent.emit(response);
                this.formGroup.reset();
                this.openSuccessSnackBar('Exclusion supprimée avec succès');
              },
              error: (err) => {
                console.error(err);
                this.openErrorSnackBar(
                  'Une erreur est survenue lors de la suppression de l\'exclusion'
                );
              },
              complete: () => {
              },
            });
          }
        },
        error: (err) => {
          console.error(err);
          this.openErrorSnackBar('L\'exclusion n\'a pas pu être supprimée');
        },
        complete: () => {
        },
      });
  }
}
