import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {GuideComponent} from 'src/app/guide/guide.component';
import {ROUTES} from 'src/app/shared/constants/routes';
import {AuthenticationService} from 'src/app/shared/services/authentication.service';
import {ThemeService} from 'src/app/shared/services/theme.service';

@Component({
  selector: 'app-facturation',
  templateUrl: './facturation.component.html',
  styleUrls: ['./facturation.component.less']
})
export class FacturationComponent {

  constructor(public router: Router,
              public dialog: MatDialog,
              public theme: ThemeService,
              public authenticationService: AuthenticationService) {
  }

  get ROUTES() {
    return ROUTES;
  }


  openGuide(s: string) {
    this.dialog.open(GuideComponent, {
      data: s
    })
  }
}
