import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {IOrderForm} from 'src/app/shared/models/order-form';
import {OrderFormService} from '../services/order-form.service';
import {UsersService} from '../../user/services/users.service'
import {IUser} from 'src/app/shared/models/user';
import {AuthenticationService} from 'src/app/shared/services/authentication.service';
import {DialogService} from 'src/app/shared/services/dialog.service';
import * as fileSaver from 'file-saver';
import {ApplicationSettingsService} from '../../application-settings/application-settings.service';
import {ThemeService} from 'src/app/shared/services/theme.service';
import { SnackbarService } from '../../../shared/services/snackbar.service';

@Component({
  selector: 'app-order-form-list',
  templateUrl: './order-form-list.component.html',
  styleUrls: ['./order-form-list.component.less']
})
export class OrderFormListComponent implements OnInit, AfterViewInit {


  displayedColumns: string[] = ['number', 'description', 'dsi', 'dssi', 'user', 'year', 'lot', 'cost', 'actions'];
  ordersForms: MatTableDataSource<IOrderForm>;
  users: Array<IUser>;
  orderFormSelected!: IOrderForm;

  @ViewChild(MatSort)
  sort!: MatSort;

  totalOrders?: number;

  constructor(private _ofs: OrderFormService, private _as: UsersService, public authenticationService: AuthenticationService,
              private dialogService: DialogService,
              public theme: ThemeService,
              private applicationSettingsService: ApplicationSettingsService,
              private snackbarService: SnackbarService,) {
    this.ordersForms = new MatTableDataSource();
    this.users = [];
    this.loadContacts();
  }

  spinner = false;

  openErrorSnackBar(message: string) {
    this.snackbarService.openSnackBar(message, 'error-snack-bar');
  }

  openSuccessSnackBar(message: string) {
    this.snackbarService.openSnackBar(message);
  }
 
  exportData() {
    this._ofs.exportData().subscribe({
      next: data => {
        if (data) {
          const blob: any = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
          window.URL.createObjectURL(blob);
          fileSaver.saveAs(blob, "Bons de commande - " + this.applicationSettingsService.applicationSettings.CDS_name + " - " + new Date().toLocaleDateString().replace('_', '-') + " " + new Date().toLocaleTimeString().replace('_', '-'));
        } else {
          this.openErrorSnackBar("Aucun bons de commande a exporter");
        }
      },
      error: err => {
        console.log(err);
        this.openErrorSnackBar("Une erreur est survenue lors de l'export des bons de commande");
      },
      complete: () => {
      }
    });
  }


  loadContacts() {
    this._as.getAllContacts().subscribe({
      next: value => {
        this.users = value;
      },
      error: err => {
        console.log(err);
      },
      complete: () => {
      }
    });
  }

  ngAfterViewInit() {
    this.ordersForms.sort = this.sort
  }

  /*
   * init order-form-list data
   */
  ngOnInit(): void {
    this.spinner = true;
    this.getOrdersFormsServices();
  }

  /*
   * methode event for update order-form-list data
   */
  updateOrderFormList(data: IOrderForm): void {
    this.getOrdersFormsServices();
  }


  /*
   * Methode for select data
   */
  selectedOrderForm(data: IOrderForm): void {
    data.check = true
    this.orderFormSelected = data;
  }

  selectedOrderFormEditBtn(data: IOrderForm): void {
    data.check = false
    this.orderFormSelected = data;
  }

  removeOrderForm(data: IOrderForm): void {

    this.dialogService.openConfirmDialog(data.number)
      .afterClosed().subscribe({
      next: res => {
        if (res) {
          this._ofs.deleteOrderForm(data).subscribe({
            next: data => {
              this.getOrdersFormsServices();
              this.clearOrderFormSelected()
              this.openSuccessSnackBar("Le bon de commande a été supprimé avec succès");
            },
            error: err => {
              console.log(err);
              this.openErrorSnackBar("Une erreur est survenue lors de la suppression du bon de commande");
            },
            complete: () => {
            }
          });
        }
      },
      error: err => {
        console.log(err);
      },
      complete: () => {
      }
    });

  }

  /*
   * filter order-form-data list by number
   */
  orderFormFilterByNumber(event: Event) {
    let total = 0;
    const filterValue = (event.target as HTMLInputElement).value;

    this.ordersForms.filterPredicate = (data: IOrderForm, filter: string) => {
      return data.number.toString().includes(filter);
    };

    this.ordersForms.filter = filterValue.trim().toLowerCase();

    this.ordersForms.filteredData.forEach(order => {
      total += order.cost;
    });

    this.totalOrders = total;
  }

  /**
   * Filter order-form-data list by year
   */
  orderFormFilterByYear(event: Event) {
    let total = 0;
    const filterValue = (event.target as HTMLInputElement).value;

    this.ordersForms.filterPredicate = (data: IOrderForm, filter: string) => {
      return data.referenceYear.toString().includes(filter);
    };

    this.ordersForms.filter = filterValue.trim().toLowerCase();

    this.ordersForms.filteredData.forEach(order => {
      total += order.cost;
    });

    this.totalOrders = total;
  }

  /*
   * method event for initialise selected order
   */
  clearOrderFormSelected(): void {
    this.initOrderForm();
  }

  /*
   * get list of order forms with the services order
   */
  getOrdersFormsServices(): void {
    let totalCost = 0;
    this._ofs.getOrdersForms().subscribe({
      next: data => {
        data.forEach((d: IOrderForm) => {
          if (d.cost != null) {
            const cost = d.cost
            totalCost += cost
          }
        })

        this.totalOrders = parseFloat((totalCost).toFixed(2))
        this.ordersForms.data = data;
        this.spinner = false;
      },
      error: err => {
        console.log(err);
        this.spinner = false;
      },
      complete: () => {
      }
    });
  }

  /*
   * initialize orderform property
   */
  initOrderForm(): void {
    this.orderFormSelected = {
      id: '',
      user: {},
      cost: 0,
      referenceYear: 0,
      dsi: '',
      dssi: '',
      lot: '',
      number: '',
      description: '',
      montantInitial: 0,
      montantAvenant1: 0,
      montantAvenant2: 0,
      montantAvenant3: 0
    }
  }
}
