<div class="comment-box">
  <div class="comments" #commentsDiv>
    <ng-container *ngFor="let comment of demand.comments; let last = last">
      <div class="comment-section" [ngClass]="getCommentWrapperClass(comment)">
        <div class="commenter">
          {{ comment.submissionDateTime | date: 'dd/MM/yyyy H:mm' }}
          - {{ comment.user ? comment.user.firstName + ' ' + comment.user.lastName : '' }}
        </div>
        <div class="comment" [style.background-color]="theme.getColor('Main Dark')"
          [ngClass]="getCommentClass(comment)">
          <span>{{ comment.text }}</span>
        </div>
      </div>
      {{ last ? scrollToTheEnd() : '' }}
    </ng-container>
  </div>
  <mat-divider></mat-divider>
  <div id="comment-input-div">
    <mat-form-field id="comment-input-field">
      <input matInput [(ngModel)]="tappedComment" placeholder="Ajouter un commentaire">
      @if (tappedComment) {
      <button id="submit-comment" matSuffix mat-mini-fab color="primary" aria-label="Sent" (click)="submitComment()">
        <mat-icon>send</mat-icon>
      </button>
      }
    </mat-form-field>
  </div>
</div>