import {Component, Inject} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ThemeService} from '../services/theme.service';

@Component({
  selector: 'app-dialog-form',
  templateUrl: './dialog-form.component.html'
})
export class DialogFormComponent {

  public formGroup: UntypedFormGroup;

  public formFields: any[] = [
    {
      type: 'text',
      name: 'firstName',
      label: 'Prénom',
      value: '',
      pattern: '[a-zA-Z- ]*',
      required: true,
    },
    {
      type: 'text',
      name: 'lastName',
      label: 'Nom',
      value: 'test',
      pattern: '[a-zA-Z- ]*',
      required: true,
    },
    {
      type: 'text',
      name: 'email',
      label: 'Adresse Email',
      value: '',
      pattern: '.*',
      required: true,
    }
  ]

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: any | undefined, public theme: ThemeService, public dialogRef: MatDialogRef<number>) {
    this.formGroup = new UntypedFormGroup({});
  }

  ngAfterViewInit(): void {
    this.formGroup = new UntypedFormGroup({
      formFields: new UntypedFormControl(JSON.stringify(this.formFields))
    });
  }
submit() {
    console.log(this.formGroup.value);
}

  delete() {
    this.dialogRef.close('delete');
  }

  close() {
    this.dialogRef.close();
  }
}
