import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {IOrderForm} from 'src/app/shared/models/order-form';
import {OrderFormService} from '../services/order-form.service';
import {UsersService} from '../../user/services/users.service';
import {DialogService} from 'src/app/shared/services/dialog.service';
import { SnackbarService } from '../../../shared/services/snackbar.service';

@Component({
  selector: 'app-order-form',
  templateUrl: './order-form.component.html',
  styleUrls: ['./order-form.component.less']


})

export class OrderFormComponent implements OnInit {

  formGroup: UntypedFormGroup;
  asEdited = false;
  users: string[];
  filteredOptions!: string[];
  btnStatus = false;

  @Input()
  nOrders!: any;


  @Output()
  updateEvent = new EventEmitter<any>();

  @Output()
  clearEvent = new EventEmitter<any>();

  @Input()
  orderForm!: IOrderForm;


  constructor(private _fb: UntypedFormBuilder, private _ofs: OrderFormService, private usersService: UsersService, private snackbarService: SnackbarService,
              private dialogService: DialogService,
  ) {

    this.formGroup = this._fb.group({
      number: ['', Validators.required],
      cost: [''],
      user: ['', Validators.required],
      dsi: [''],
      dssi: [''],
      lot: [''],
      referenceYear: ['', [Validators.required, Validators.pattern(/^\d{4}$/)]],
      description: [''],
      montantInitial: ['', Validators.required],
      montantAvenant1: [''],
      montantAvenant2: [''],
      montantAvenant3: [''],
    });

    this.users = usersService.getAllNames();
    this.filteredOptions = this.users;

  }


  ngOnInit(): void {
    this.formGroup.get('cost')?.disable()
  }


  ngOnChanges(changes: SimpleChanges): void {
    this.asEdited = false;

    if (!this.orderForm || !this.orderForm.hasOwnProperty('check')) {
      this.formGroup.reset();
      this.asEdited = false;
      this.btnStatus = false;
      this.updateEvent.emit();
      return;
    }

    if (!this.orderForm.check && this.orderForm.id == '') {
      this.formGroup.reset();
      this.asEdited = false;
      this.btnStatus = false;
      this.updateEvent.emit();
    } else if (this.orderForm.check) {
      this.updateFormGroup();
      this.asEdited = true;
      this.btnStatus = false;

    } else if (!this.orderForm.check) {
      this.updateFormGroup();
      this.asEdited = false;
      this.btnStatus = true;
    }
  }

  /*
   * Initialize orderform component
   */
  clear(): void {
    this.formGroup.reset();
    this.asEdited = false;
    this.btnStatus = false
    this.clearEvent.emit();
  }

  /*
   * Update formgroup with orderform input
   */
  updateFormGroup() {
    const formUpdate = {
      number: this.orderForm.number,
      cost: this.orderForm.cost,
      user: !this.orderForm.user ? null : this.orderForm.user.identifier,
      dsi: this.orderForm.dsi,
      dssi: this.orderForm.dssi,
      lot: this.orderForm.lot,
      referenceYear: this.orderForm.referenceYear,
      description: this.orderForm.description,
      montantInitial: !this.orderForm.montantInitial ? this.orderForm.cost : this.orderForm.montantInitial,
      montantAvenant1: this.orderForm.montantAvenant1,
      montantAvenant2: this.orderForm.montantAvenant2,
      montantAvenant3: this.orderForm.montantAvenant3
    }

    this.formGroup.setValue(formUpdate);
  }

  is_edit(asEdited: boolean) {
    asEdited = !asEdited
    this.asEdited = asEdited
    this.btnStatus = true
  }


  /*
   * Delete order form
   */
  deleteOrderForm() {
    if (this.asEdited) {
      this._ofs.deleteOrderForm(this.orderForm).subscribe({
        next: data => {
          this.clear();
          this.updateEvent.emit(data);
          this.openSuccessSnackBar('Le bon de commande a été supprimé avec succès')
        },
        error: err => {
          console.log(err)
          this.openErrorSnackBar('Une erreur est survenue lors de la suppression du bon de commande')
        },
        complete: () => {
        }
      });
    }


  }

  /*
   * Create or modify an order form object
   */
  onSubmit(): void {

    //Get user.identifier form user field
    const userValue = this.formGroup.value.user;
    this.formGroup.value.user = userValue.split("(")[userValue.split("(").length - 1].replace(")", "");

    if (!this.asEdited && this.btnStatus) {
      this.formGroup.value.user = {
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        identifier: this.formGroup.value.user
      }

      if (this.formGroup.value.montantAvenant1 == null) this.formGroup.value.montantAvenant1 = 0
      if (this.formGroup.value.montantAvenant2 == null) this.formGroup.value.montantAvenant2 = 0
      if (this.formGroup.value.montantAvenant3 == null) this.formGroup.value.montantAvenant3 = 0

      this._ofs.updateOrderForm(this.orderForm.id ?? '', this.formGroup.value).subscribe({
        next: data => {
          this.updateEvent.emit(data);
          this.formGroup.reset();
          this.asEdited = false;
          this.btnStatus = false
          this.openSuccessSnackBar('Le bon de commande a été modifié avec succès')
        },
        error: err => {
          console.log(err)
          this.openErrorSnackBar('Une erreur est survenue lors de la modification du bon de commande')
        },
        complete: () => {
        }
      });
    } else if (!this.asEdited && !this.btnStatus) {
      this.formGroup.value.user = {
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        identifier: this.formGroup.value.user
      }
      this._ofs.createOrderForm(this.formGroup.value).subscribe({
        next: data => {
          this.updateEvent.emit(data);
          this.formGroup.reset();
          this.openSuccessSnackBar('Le bon de commande a été créé avec succès')
        },
        error: err => {
          console.log(err)
          this.openErrorSnackBar('Une erreur est survenue lors de la création du bon de commande')
        },
        complete: () => {
        }
      });
    }
  }


  openErrorSnackBar(message: string) {
    this.snackbarService.openSnackBar(message, 'error-snack-bar');
  }

  openSuccessSnackBar(message: string) {
    this.snackbarService.openSnackBar(message);
  }
 

  /*
  * Filters contact identifiers from users
  */
  public filter(value: string) {
    if (value) {
      const filterValue = value.toLowerCase();
      this.filteredOptions = this.users.filter(option => option.toLowerCase().includes(filterValue));
    }
  }

  // popUp confirmation
  confirmDelete() {
    this.dialogService.openConfirmDialog(this.orderForm.number)
      .afterClosed().subscribe({
      next: res => {
        this.deleteOrderForm();
      },
      error: err => {
        console.log(err)
      },
      complete: () => {
      }
    });
  }


}
