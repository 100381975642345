<div class="loginPage">
  <div class="HomeContainer">
    <div class="welcomeScreen">
      <div class="logoLogin">
        <div class="login-wrap" [ngStyle]="{'background-color': '#3f51b5'}" >
          <svg xmlns="http://www.w3.org/2000/svg" height="70" viewBox="0 -960 960 960" width="70" style="fill: white">
            <path
              d="M120-160v-112q0-34 17.5-62.5T184-378q62-31 126-46.5T440-440q20 0 40 1.5t40 4.5q-4 58 21 109.5t73 84.5v80H120ZM760-40l-60-60v-186q-44-13-72-49.5T600-420q0-58 41-99t99-41q58 0 99 41t41 99q0 45-25.5 80T790-290l50 50-60 60 60 60-80 80ZM440-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm300 80q17 0 28.5-11.5T780-440q0-17-11.5-28.5T740-480q-17 0-28.5 11.5T700-440q0 17 11.5 28.5T740-400Z"/>
          </svg>
        </div>
      </div>
      <div class="messageLogin">
        <h1>Bienvenue sur Packgemini</h1>
      </div>
    </div>
    <div class="loginScreen">
      <mat-vertical-stepper [linear]="true" #stepper >
        <mat-step>
          <form [formGroup]="identifierFormGroup">
            <ng-template matStepLabel>Votre identifiant</ng-template>
            <mat-form-field>
              <mat-label>Identifiant</mat-label>
              <input matInput placeholder="code CP" formControlName="identifier" required>
            </mat-form-field>
            <div>
              <button mat-button (click)="allowAccess()" [disabled]="identifierFormGroup.invalid || canAccess">
                Verification
              </button>
              <button mat-button matStepperNext *ngIf="canAccess">Suivant</button>
            </div>
          </form>
        </mat-step>
        <mat-step *ngIf="canAccess">
          <ng-template matStepLabel>Choix du rôle</ng-template>
            <mat-form-field color="accent">
              <mat-label>Profil</mat-label>
              <mat-select [(ngModel)]="profile">
                <mat-option *ngFor="let profileValue of Object.values(UserProfile)" [value]="profileValue">
                  {{ profileValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          <div>
            <button mat-button matStepperPrevious >Précédent</button>
            <button mat-button matStepperNext >Suivant</button>
          </div>
        </mat-step>
        <mat-step *ngIf="canAccess">
          <ng-template matStepLabel>Les applications rattachées</ng-template>
          <mat-form-field color="accent">
            <mat-label>Applications</mat-label>
            <mat-select multiple [disabled]="profile!=UserProfile.CP_SNCF && profile!=UserProfile.CP_CDS"
                        [(ngModel)]="selectedApplicationsNames">
              <mat-option *ngFor="let name of applicationsNames" [value]="name">
                {{ name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div>
            <button mat-button matStepperPrevious>Précédent</button>
            <button mat-button matStepperNext
                    [disabled]="(profile==UserProfile.CP_SNCF || profile==UserProfile.CP_CDS) && !selectedApplicationsNames.length">
              Suivant
            </button>
          </div>
        </mat-step>
        <mat-step *ngIf="canAccess">
          <ng-template matStepLabel>Terminer</ng-template>
          <div style="margin-top: 10px;">
            <button mat-button matStepperPrevious>Précédent</button>
            <button mat-raised-button class="blue login-btn" color="primary" type="submit"
                    [disabled]="(profile==UserProfile.CP_SNCF || profile==UserProfile.CP_CDS) && !selectedApplicationsNames.length"
                    (click)="validateAccess()">Valider
            </button>
          </div>
        </mat-step>
      </mat-vertical-stepper>
    </div>
  </div>
</div>
