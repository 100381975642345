import {Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {GuideComponent} from 'src/app/guide/guide.component';
import {ThemeService} from 'src/app/shared/services/theme.service';

@Component({
  selector: 'app-collected-data',
  templateUrl: './collected-data.component.html',
  styleUrls: ['./collected-data.component.less']
})
export class CollectedDataComponent {

  constructor(public router: Router,
              public theme: ThemeService,
              public dialog: MatDialog) {
  }

  openGuide(s: string) {
    this.dialog.open(GuideComponent, {
      data: s
    })
  }
}
