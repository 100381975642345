<nav [style.background-color]="theme.getColor('Main Dark')" mat-tab-nav-bar class="administration"
  *ngIf="router.url.includes('/dashboard')" [tabPanel]="tabPanel">
  <div class="flex-grow"></div>
  <a (click)="openGuide('commandGuide')" *ngIf="router.url.includes('/orders')">
    <div class="material-icons help-icon">help_outline</div>
  </a>
  <a (click)="openGuide('alertGuide')" *ngIf="router.url.includes('/alerts')">
    <div class="material-icons help-icon">help_outline</div>
  </a>
  <a (click)="openGuide('appsGuide')" *ngIf="router.url.includes('/apps')">
    <div class="material-icons help-icon">help_outline</div>
  </a>
</nav>


<mat-tab-nav-panel #tabPanel>
  <!-- Contenu des onglets ici -->
</mat-tab-nav-panel>