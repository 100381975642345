<div class="card">
  <app-application [ngStyle]="authenticationService.isUserAdmin()? {}: {'display':'none'}"
    [application]="applicationSelected" [dsis]="dsis" [dsisNames]="dsisNames" (updateEvent)="updateList($event)"
    (clearEvent)="clearSelected()"></app-application>
  <div class="data-header">
    <mat-form-field class="applicationFilter">
      <mat-label>Filtre</mat-label>
      <input matInput (keyup)="filterList($event)">
    </mat-form-field>
    <div class="exportBtn">
      <button mat-raised-button (click)="exportData()" class="indigo"
        [style.background-color]="theme.getColor('Main Dark')">
        <mat-icon>get_app</mat-icon>
        Export Excel
      </button>
    </div>
  </div>
  <mat-table #table class="mat-elevation-z8 table-container" [dataSource]="applications" matSort>

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header id="name"> Nom</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.name }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="label">
      <mat-header-cell *matHeaderCellDef mat-sort-header id="label"> Libellé</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.label }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="critical">
      <mat-header-cell *matHeaderCellDef mat-sort-header id="criticity"> Criticité</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.critical }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="project">
      <mat-header-cell *matHeaderCellDef mat-sort-header id="project"> Projet</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.project.name }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="dsis">
      <mat-header-cell *matHeaderCellDef mat-sort-header id="dsis"> Domaine</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <ng-container *ngFor="let dsi of element.dsis">{{ dsi.name }},</ng-container>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef id="actions"> Actions</mat-header-cell>
      <mat-cell *matCellDef="let element" class="actions-td">

        <div>
          <button mat-icon-button aria-label="edit" (click)="onEditRowPencel(element)"
            [disabled]="!authenticationService.isUserAdmin()">
            <mat-icon>mode_edit</mat-icon>
          </button>
          <button mat-icon-button aria-label="delete" (click)="onDeleteRow(element)"
            [disabled]="!authenticationService.isUserAdmin()">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
    <mat-row class="focusable" *matRowDef="let row; columns: displayedColumns;" (click)="onEditRow(row)"></mat-row>
  </mat-table>
</div>