import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {INetting} from 'src/app/shared/models/netting-account';
import {IProject} from 'src/app/shared/models/project';
import {AuthenticationService} from 'src/app/shared/services/authentication.service';
import {DialogService} from 'src/app/shared/services/dialog.service';
import {NettingService} from '../../netting-account/netting.service';
import {ApplicationProjectService} from '../services/application-project.service';
import * as fileSaver from 'file-saver';
import {ApplicationSettingsService} from '../../application-settings/application-settings.service';
import {ThemeService} from 'src/app/shared/services/theme.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.less'],
})
export class ProjectListComponent implements OnInit, AfterViewInit {
  projects!: MatTableDataSource<IProject>;
  projectSelected!: IProject;
  @ViewChild(MatSort)
  sort!: MatSort;
  displayedColumns: string[] = [
    'name',
    'nettings',
    'user',
    'key_jira',
    'beb',
    'actions',
  ];

  nettingAccounts: INetting[] = [];
  nettingsNames: string[] = [];
  projectUnlinked: string[] = [];

  constructor(
    private _aps: ApplicationProjectService,
    public authenticationService: AuthenticationService,
    public _nettingService: NettingService,
    public theme: ThemeService,
    private dialogService: DialogService,
    private applicationSettingsService: ApplicationSettingsService,
    private snackbarService: SnackbarService,
  ) {
    this.projects = new MatTableDataSource();
  }

  openErrorSnackBar(message: string) {
    this.snackbarService.openSnackBar(message, 'error-snack-bar');
  }

  openSuccessSnackBar(message: string) {
    this.snackbarService.openSnackBar(message);
  }
 

  ngOnInit(): void {
    this.getProjectsService();

    this._nettingService.getNettings().subscribe({
      next: (data) => {
        if (data) {
          this.nettingAccounts = data;
          this.nettingsNames = data.map(
            (netting: INetting) => netting.name as string,
          );
          this.nettingsNames.sort((a, b) => a.localeCompare(b));
        }
      },
      error: (err) => {
        console.log(err);
      },
      complete: () => {
        if (this.nettingAccounts.length === 0) {
          this.openErrorSnackBar('Aucun compte de netting trouvé');
        }
      }
    });
  }

  ngAfterViewInit() {
    this.projects.sort = this.sort;
  }

  onEditRow(row: IProject): void {
    this.projectSelected = row;
    this.projectSelected.check = true;
  }

  onEditRowPencel(row: IProject): void {
    this.projectSelected = row;
    this.projectSelected.check = false;
  }

  onDeleteRow(row: IProject): void {
    if (!this.projectUnlinked.includes(row.name + '')) {
      this.dialogService.openInfoDialog(row.name);
    } else {
      this.dialogService
        .openConfirmDialog(row.name)
        .afterClosed()
        .subscribe({
          next: (res) => {
            if (res) {
              this._aps.deleteProject(row).subscribe({
                next: (res) => {
                  this.updateList(res);
                  this.projectSelected = res;
                  this.openSuccessSnackBar(
                    'Le projet a été supprimé avec succès',
                  );
                },
                error: (err) => {
                  console.log(err);
                },
                complete: () => {
                  this.clearSelected();
                },
              });
            }
          },
          error: (err) => {
            console.log(err);
            this.openErrorSnackBar(
              'Une erreur est survenue lors de la suppression du projet',
            );
          },
          complete: () => {
          }
        });
    }
  }

  /*
   * methode event for update order-form-list data
   */
  updateList(data: IProject): void {
    this.getProjectsService();
  }

  /*
   * filter order-form-data list
   */
  filterList(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.projects.filter = filterValue.trim().toLowerCase();
  }

  clearSelected() {
    this.projectSelected = {name: '', check: false};
  }

  getProjectsService(): void {
    this._aps.getProjects().subscribe({
      next: (data) => {
        this.projects.data = data;
      },
      error: (err) => {
        console.log(err);
      },
      complete: () => {
      }
    });
    this._aps.getProjectsUnlinked().subscribe({
      next: (data) => {
        this.projectUnlinked = data.map((p: IProject) => p.name as string);
      },
      error: (err) => {
        console.log(err);
      },
      complete: () => {
      }
    });
  }

  exportData() {
    this._aps.exportProjectData().subscribe({
      next: (data) => {
        if (data) {
          const blob: any = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          window.URL.createObjectURL(blob);
          fileSaver.saveAs(
            blob,
            'Projets - ' +
            this.applicationSettingsService.applicationSettings.CDS_name +
            ' - ' +
            new Date().toLocaleDateString().replace('_', '-') +
            ' ' +
            new Date().toLocaleTimeString().replace('_', '-'),
          );
          this.openSuccessSnackBar('Les projets ont été exportés avec succès');
        } else {
          this.openErrorSnackBar('Aucun projet a exporter');
        }
      },
      error: (err) => {
        console.log(err);
        this.openErrorSnackBar("Une erreur est survenue lors de l'export des projets");
      },
      complete: () => {
      }
    });
  }
}
