import {Component} from '@angular/core';
import {ThemeService} from '../services/theme.service';

@Component({
    selector: 'app-footer-bar',
    templateUrl: './footer-bar.component.html',
    styleUrls: ['./footer-bar.component.less']
})
export class FooterBarComponent {

    constructor(public theme: ThemeService) {
    }

}
