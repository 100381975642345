import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ThemeService} from '../services/theme.service';

@Component({
    selector: 'app-import-file',
    templateUrl: './import-file.component.html',
    styleUrls: ['./import-file.component.less']
})
export class ImportFileComponent {

    formGroup: UntypedFormGroup;
    fileName !: string;
    file!: File;

    @Input()
    label = "Importer un fichier";

    @Input()
    placeholder = "Format incorrect";

    @Input()
    hint = "Obligatoire";

    @Input()
    canSubmit!: boolean;

    @Output()
    uploadFileEvent = new EventEmitter<any>();

    constructor(public theme: ThemeService, private _fb: UntypedFormBuilder) {
        this.formGroup = new UntypedFormGroup({
            file: new UntypedFormControl(null, [Validators.required]),
        });
    }

    onInput(event: any): void {
        if (event.target.files.length > 0) {
            this.file = event.target.files[0];
            this.fileName = this.file.name;
        }
    }

    onSubmit(): void {
        this.uploadFileEvent.emit(this.file);
    }
}
