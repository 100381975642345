import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http'
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {environment} from '../../../../environments/environment'
import {IOrderForm} from 'src/app/shared/models/order-form';
import {UtilsService} from 'src/app/shared/services/utils.service';

@Injectable({
  providedIn: 'root'
})
export class OrderFormService {

  options = {
    headers: new HttpHeaders({'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'})
  };

  constructor(private http: HttpClient,
              private utilsService: UtilsService) {
  }

  getOrdersForms(): Observable<IOrderForm[]> {
    return this.http.get<IOrderForm[]>(environment.backendApiUrl + "/ordersForms", this.options).pipe(catchError(this.utilsService.handleError));
  }

  createOrderForm(orderForm: IOrderForm): Observable<IOrderForm> {
    return this.http.post<IOrderForm>(environment.backendApiUrl + "/ordersForms", orderForm, this.options).pipe(catchError(this.utilsService.handleError));
  }

  updateOrderForm(id: string, orderForm: IOrderForm): Observable<IOrderForm> {
    return this.http.put<IOrderForm>(environment.backendApiUrl + "/ordersForms/" + id, orderForm).pipe(catchError(this.utilsService.handleError));
  }

  deleteOrderForm(orderForm: IOrderForm): Observable<IOrderForm> {
    return this.http.delete<IOrderForm>(environment.backendApiUrl + "/ordersForms/" + orderForm.id, this.options).pipe(catchError(this.utilsService.handleError));
  }

  exportData(): Observable<any> {
    return this.http.get(environment.backendApiUrl + "/orderForm/export/", {responseType: 'blob'}).pipe(catchError(this.utilsService.handleError));
  }

  exportDataDetails(data: any [][], year: string, month: string): Observable<any> {
    return this.http.post(environment.backendApiUrl + "/orderFormDetails/export?year=" + year + '&month=' + month, data,
      {responseType: 'blob'}).pipe(catchError(this.utilsService.handleError));
  }

  getOrderFormDetails(year: string, apps: string[], ref: string): Observable<any[][]> {
    return this.http.get<any[][]>(environment.backendApiUrl + "/orderForm/details/?year=" + year + '&apps=' + apps + "&ref=" + ref, this.options).pipe(catchError(this.utilsService.handleError));
  }
}
