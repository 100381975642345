import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {IApplication} from 'src/app/shared/models/application';
import {UserProfile} from 'src/app/shared/models/user-profile';
import {AuthenticationService} from 'src/app/shared/services/authentication.service';

@Component({
    selector: 'app-service-manager',
    templateUrl: './service-manager.component.html',
    styleUrls: ['./service-manager.component.less']
})
export class ServiceManagerComponent {


    selectedDsi = "";

    userApplications: IApplication[] = [];

    constructor(public router: Router,
                private authenticationService: AuthenticationService) {
        this.selectedDsi = "";
        if (this.authenticationService.getUser() &&
            (this.authenticationService.getUser().profile == UserProfile.CP_SNCF
                || this.authenticationService.getUser().profile == UserProfile.CP_CDS)
            && authenticationService.getUser().applications) {
            this.userApplications = this.authenticationService.getUser().applications;
        }
    }

    onDsiChange(dsi: string) {
        this.selectedDsi = dsi;
    }
}
