import {Component, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {IApplication} from 'src/app/shared/models/application';
import {IDsi} from 'src/app/shared/models/dsi';
import {AuthenticationService} from 'src/app/shared/services/authentication.service';
import {DialogService} from 'src/app/shared/services/dialog.service';
import {EcheancesService} from '../../pack-generation/calculate-echeances/services/echeances.service';
import {ApplicationProjectService} from '../services/application-project.service';
import * as fileSaver from 'file-saver';
import {ApplicationSettingsService} from '../../application-settings/application-settings.service';
import {ThemeService} from 'src/app/shared/services/theme.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import { SnackbarService } from '../../../shared/services/snackbar.service';

@Component({
  selector: 'app-application-list',
  templateUrl: './application-list.component.html',
  styleUrls: ['./application-list.component.less']
})
export class ApplicationListComponent implements OnInit {
  applications!: MatTableDataSource<IApplication>;
  applicationSelected!: IApplication;
  @ViewChild(MatSort)
  sort!: MatSort;
  displayedColumns: string[] = [
    'name',
    'critical',
    'label',
    'project',
    'dsis',
    'actions',
  ];

  dsis: IDsi[] = [];
  dsisNames: string[] = [];

  constructor(
    private _aps: ApplicationProjectService,
    public authenticationService: AuthenticationService,
    public echeancesService: EcheancesService,
    private dialogService: DialogService,
    public theme: ThemeService,
    private applicationSettingsService: ApplicationSettingsService,
    private snackbarService: SnackbarService,
  ) {
    this.applications = new MatTableDataSource();
  }

  openErrorSnackBar(message: string) {
    this.snackbarService.openSnackBar(message, 'error-snack-bar');
  }

  openSuccessSnackBar(message: string) {
    this.snackbarService.openSnackBar(message);
  }

  ngOnInit(): void {
    this.getApplicationsService();
    this.echeancesService.getDsi().subscribe({
      next: (data) => {
        if (data) {
          this.dsis = data;
          this.dsisNames = data.map((dsi: IDsi) => dsi.name as string);
        }
      },
      error: (err) => {
        console.log(err);
      },
      complete: () => {
      }
    });
  }

  ngAfterViewInit() {
    this.applications.sort = this.sort;
  }

  onEditRow(row: IApplication): void {
    this.applicationSelected = row;
    this.applicationSelected.check = true;
  }

  onEditRowPencel(row: IApplication): void {
    this.applicationSelected = row;
    this.applicationSelected.check = false;
  }

  onDeleteRow(row: IApplication): void {
    this.dialogService
      .openConfirmDialog(row.name)
      .afterClosed()
      .subscribe({
        next: (res) => {
          if (res) {
            this._aps.deleteApplication(row).subscribe({
              next: (data) => {
                this.getApplicationsService();
                this.clearSelected();
                this.openSuccessSnackBar('Application supprimée avec succès');
              },
              error: (err) => {
                console.log(err);
                this.openErrorSnackBar(
                  "Erreur lors de la suppression de l'application",
                );
              },
              complete: () => {
              }
            });
          }
        },
        error: (err) => {
          console.log(err);
        },
        complete: () => {
        }
      });
  }

  /*
   * methode event for update order-form-list data
   */
  updateList(data: IApplication): void {
    this.getApplicationsService();
  }

  /*
   * filter order-form-data list
   */
  filterList(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.applications.filter = filterValue.trim().toLowerCase();
  }

  clearSelected(): void {
    this.applicationSelected = {
      name: '',
      critical: '',
      project: {},
      check: false,
    };
  }

  getApplicationsService(): void {
    this._aps.getApplications().subscribe({
      next: (data) => {
        this.applications.data = data;
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  exportData() {
    this._aps.exportApplicationData().subscribe({
      next: (data) => {
        if (data) {
          const blob: any = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          window.URL.createObjectURL(blob);
          fileSaver.saveAs(
            blob,
            'Applications - ' +
            this.applicationSettingsService.applicationSettings.CDS_name +
            ' - ' +
            new Date().toLocaleDateString().replace('_', '-') +
            ' ' +
            new Date().toLocaleTimeString().replace('_', '-'),
          );
          this.openSuccessSnackBar('Applications exportées avec succès');
        } else {
          this.openErrorSnackBar('Aucunes applications à exporter');
        }
      },
      error: (err) => {
        console.log(err);
        this.openErrorSnackBar("Erreur lors de l'exportation des applications");
      },
      complete: () => {
      }
    });
  }
}
