import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {ApplicationSettingsService} from 'src/app/core/application-settings/application-settings.service';
import {CalendrierService} from 'src/app/core/calendrier-pack/calendrier.service';
import {EcheancesService} from 'src/app/core/pack-generation/calculate-echeances/services/echeances.service';
import {ROUTES} from '../constants/routes';
import {ApplicationSettings} from '../models/application-settings';
import {ICalendrier} from '../models/calendrier';

import {AuthenticationService} from '../services/authentication.service';
import {ConfigService} from '../services/config.service';
import {ThemeService} from '../services/theme.service';

@Component({
  selector: 'app-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.less']
})
export class HeaderBarComponent {
  applicationSettings: ApplicationSettings = new ApplicationSettings;
  public synchMonthYear!: ICalendrier;
  formattedDate: any;

  myDateOuverture: any;
  myDateValid_deb: any;
  myDateValid_fin: any;
  myDateCloture: any;

  stateMessage!: string;
  stateColor!: string;

  debugModeActivated = false;

  constructor(public authenticationService: AuthenticationService,
              public configService: ConfigService,
              private es: EcheancesService,
              public router: Router,
              public theme: ThemeService,
              public applicationSettingsService: ApplicationSettingsService,
              public calendrierService: CalendrierService) {

    this.applicationSettings = this.applicationSettingsService.getApplicationSettings()
    this.SynchroAppSettings_Calendrier();
    this.debugModeActivated = this.authenticationService.getDebugMode();
  }

  checkIndicator() {

    const now: Date = new Date(Date.now());
    now.setHours(12);
    now.setMinutes(30);
    const calendarOpening = new Date(Date.parse(this.synchMonthYear.ouverture + ""));
    const calendarStart = new Date(Date.parse(this.synchMonthYear.valid_debut + ""));
    const calendarEnd = new Date(Date.parse(this.synchMonthYear.valid_fin + ""));

    if (this.applicationSettings.Operations_in_progress) {
      this.stateMessage = " Fermé provisoirement – Opérations en cours";
      this.stateColor = 'orange';
    } else if (this.applicationSettings.Closure_in_progress) {
      this.stateMessage = " Fermé – Clôture Pack en cours";
      this.stateColor = 'red';
    } else if (!this.applicationSettings.enableCollectedDataTab || (this.applicationSettings.enableCollectedDataTab && now.setHours(0, 0, 0, 0) < calendarOpening.setHours(0, 0, 0, 0))) {
      this.stateMessage = "Fermé – Ouverture prévue le ";
      this.stateColor = 'orange2'
    } else if (this.applicationSettings.enableCollectedDataTab && (!this.applicationSettings.Enable_SNCF_Validation || now.setHours(0, 0, 0, 0) < calendarStart.setHours(0, 0, 0, 0))) {
      this.stateMessage = "Ouvert CDS - Validation Opérationnelle SNCF à partir du ";
      this.stateColor = 'greenTransparent'
    } else if (this.applicationSettings.enableCollectedDataTab && this.applicationSettings.Enable_SNCF_Validation && now.setHours(0, 0, 0, 0) >= calendarStart.setHours(0, 0, 0, 0) && now.setHours(0, 0, 0, 0) <= calendarEnd.setHours(0, 0, 0, 0)) {
      this.stateMessage = " Ouvert SNCF - Validation Opérationnelle jusqu’au ";
      this.stateColor = 'green'
    } else if (this.applicationSettings.enableCollectedDataTab && this.applicationSettings.Enable_SNCF_Validation && now.setHours(0, 0, 0, 0) > calendarEnd.setHours(0, 0, 0, 0)) {
      this.stateMessage = "Ouvert - Validation Opérationnelle achevée le ";
      this.stateColor = 'orange3'
    } else {
      this.stateMessage = "Mois du Pack à initialiser";
      this.stateColor = 'white'
    }
  }


  disconnecUser() {
    if (this.configService.isAuthEnabled()) {
      this.authenticationService.logOut();
    } else {
      sessionStorage.removeItem('user');
      this.router.navigateByUrl(`/${ROUTES.NO_AUTH_ACCESS}`);
    }
    localStorage.removeItem('debugMode');
  }

  SynchroAppSettings_Calendrier(): any {
    const months = ['janv', 'févr', 'mars', 'avr', 'mai', 'juin', 'juil', 'août', 'sept', 'oct', 'nov', 'déc']


    if (this.applicationSettings.Pack_current_month != 0 && this.applicationSettings.Pack_current_year != 0) {
      this.calendrierService.getCalendrier().subscribe({
          next: data => {
            data.forEach(cal => {
              if (cal.month == this.applicationSettings.Pack_current_month && cal.year == this.applicationSettings.Pack_current_year) {

                this.synchMonthYear = cal;

                if (this.synchMonthYear.month != null && this.synchMonthYear.year != null) {

                  this.myDateOuverture = this.getJsonDate(this.synchMonthYear.ouverture).day + "-" + months[this.getJsonDate(this.synchMonthYear.ouverture).month.replace(/(^|-)0+/g, "$1") - 1] + "-" + this.getJsonDate(this.synchMonthYear.ouverture).year
                  this.myDateValid_deb = this.getJsonDate(this.synchMonthYear.valid_debut).day + "-" + months[this.getJsonDate(this.synchMonthYear.valid_debut).month.replace(/(^|-)0+/g, "$1") - 1] + "-" + this.getJsonDate(this.synchMonthYear.valid_debut).year
                  this.myDateValid_fin = this.getJsonDate(this.synchMonthYear.valid_fin).day + "-" + months[this.getJsonDate(this.synchMonthYear.valid_fin).month.replace(/(^|-)0+/g, "$1") - 1] + "-" + this.getJsonDate(this.synchMonthYear.valid_fin).year

                  if (this.synchMonthYear.cloture != null) {
                    this.myDateCloture = this.getJsonDate(this.synchMonthYear.cloture).day + "-" + months[this.getJsonDate(this.synchMonthYear.cloture).month.replace(/(^|-)0+/g, "$1") - 1] + "-" + this.getJsonDate(this.synchMonthYear.cloture).year
                  }


                  this.formattedDate = months[this.synchMonthYear.month - 1] + "-" + this.synchMonthYear.year

                }
                this.checkIndicator();

                if (this.myDateCloture != null) {
                  this.stateMessage = "Clos le ";
                  this.stateColor = 'black'
                }

              }
            })
          },
          error: err => console.error(err),
          complete: () => {}
        }
      )
    }

  }

  getJsonDate(text: any) {
    const {0: year, 1: month, 2: day} = text.split("-");
    return {day, month, year};
  }
}
