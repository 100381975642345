import {Component, Inject} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {IAlert} from '../models/alert';

@Component({
  selector: 'app-dialog-form-alert',
  templateUrl: './dialog-form-alert.component.html',
  styleUrls: ['./dialog-form-alert.component.less']
})
export class DialogFormAlertComponent {

  DISPLAYED_COLUMNS :string[] = ['severity', 'comment'];

  constructor(@Inject(MAT_DIALOG_DATA) public data:IAlert[], private _fb: UntypedFormBuilder, public dialogRef: MatDialogRef<number>) {}


}
