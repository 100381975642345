<div class="sub-stepper">
  <div>
    <button mat-raised-button class="indigo" [style.background-color]="theme.getColor('Main Dark')"
            (click)="calculDues()">Calculer échéances
    </button>
    <mat-spinner *ngIf="spinner && !isDues"></mat-spinner>
  </div>
  <div *ngIf="isDues">
    <button mat-raised-button class="blue" [style.background-color]="theme.getColor('Main Light')"
            (click)="downloadFile()">Générer le pack
    </button>
    <mat-spinner *ngIf="spinner"></mat-spinner>
  </div>
</div>
