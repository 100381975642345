<div class="navAndDemandContainer" [ngClass]="{ hiddenDetails: rowindex == 0 && isLoading }">

  <div class="detailDemandDialog">
    <div class="white-bg" [ngClass]="{ transparent: isLoading }" *ngIf="demand">
      <div class="caracteristics">
        <h2 class="title">➡️ CARACTERISTIQUES</h2>
        <p *ngIf="!isEditing">
          <b>Référence:</b> {{ demand.reference }}
          <button mat-button color="primary" class="editButton" *ngIf="authenticationService.isUserAdmin()"
            (click)="toggleOrderForm()">
            editer
          </button>
        </p>
        <p *ngIf="isEditing">
          <b>Référence:</b>
          <mat-form-field appearance="outline" color="accent" id="order-form" class="">
            <input matInput value="{{ demand.reference }}" (keyup)="onRefChange($event)" type="text" />
          </mat-form-field>
          <button mat-mini-fab color="primary" (click)="toggleOrderForm()">
            <mat-icon>done</mat-icon>
          </button>
        </p>
        <p *ngIf="demand.jiraReference != null">
          <b>Référence JIRA:</b> {{ demand.jiraReference }}
        </p>
        <p><b>Application:</b> {{ demand.applicationName!.split("_")[0] }}</p>
        <p><b>Responsable:</b> {{ getResponsableName() }}</p>

        <p><b>Service:</b> {{ demand.serviceType }}</p>
        <p><b>Type de prestation:</b> {{ demand.prestationType }}</p>
        <p *ngIf="demand.project != null">
          <b>Projet: </b>{{ demand.project.name }}
        </p>

        <p><b>Charge Totale: </b>{{ demand.load | number: "1.1-2" : "fr-FR" }}j</p>
        <p><b>Montant Total: </b>{{ demand.cost | number: "1.1-2" : "fr-FR" }}€</p>
        <p>
          <b>Montant Réduit: </b>{{
          demand.cost - formGroup.value.reduction | number: "1.1-2" : "fr-FR"
          }}€
        </p>

        <p>
          <b>Statut de la demande: </b><span id="state" ngClass="{{ statusColor }}">{{ demand.state }}</span>
        </p>

        <p>
          <b>Projet GALILEI: </b> {{ demand.galileiProjectCode }} -
          {{ demand.galileiProjectDescription }}
        </p>
        <p>
          <b>Activité GALILEI: </b> {{ demand.galileiProjectActivityCode }} -
          {{ demand.galileiProjectActivityDescription }}
        </p>

        <p>
          <b>Netting:</b> {{ nettingName }}
          <button mat-button color="primary" class="editButton" (click)="dialogNetting()"
            *ngIf="authenticationService.isUserAdmin()">
            editer
          </button>
        </p>
        <p>
          <b>Demande créée le: </b>{{ demand.creationDateTime | date: "dd/MM/yyyy" }}
        </p>

        <h2 class="title">✅ DATES REALISEES</h2>
        <p>
          <b>Go réalisation: </b><span [ngClass]="{
          crossed:
            formGroup.value.realFirstDate != null &&
            isValid(formGroup.value.realFirstDate)
        }">{{ demand.realizationDateTime | date: "dd/MM/yyyy" }}</span>
        </p>
        <p>
          <b>Livraison: </b><span [ngClass]="{
          crossed:
            formGroup.value.realSecondDate != null &&
            isValid(formGroup.value.realSecondDate)
        }">
            {{ demand.deliveryDate | date: "dd/MM/yyyy" }}</span>
        </p>
        <p>
          <b>Clôture: </b><span [ngClass]="{
          crossed:
            formGroup.value.realThirdDate != null &&
            isValid(formGroup.value.realThirdDate)
        }">
            {{ demand.acceptanceValidationDeliveryDate | date: "dd/MM/yyyy" }}</span>
        </p>

        <h2 class="title">➡️ DATES PREVISIONNELLES</h2>
        <p>
          <b>Emission devis:</b>
          <span [ngClass]="{
          crossed:
            formGroup.value.estimateFirstDate != null &&
            isValid(formGroup.value.estimateFirstDate)
        }">
            {{ demand.estimateQuoteDate | date: "dd/MM/yyyy" }}</span>
        </p>
        <p>
          <b>Sortie Usine: </b><span>{{ demand.estimateExistFactoryDate | date: "dd/MM/yyyy" }}</span>
        </p>
        <p>
          <b>Livraison Revue: </b><span [ngClass]="{
          crossed:
            formGroup.value.estimateSecondDate != null &&
            isValid(formGroup.value.estimateSecondDate)
        }">{{ demand.revisedDeliveryDate| date: "dd/MM/yyyy" }}</span>
        </p>
        <p>
          <b>Livraison Production: </b><span [ngClass]="{
          crossed:
            formGroup.value.estimateThirdDate != null &&
            isValid(formGroup.value.estimateThirdDate)
        }">{{ demand.estimateProductionDelivryDate | date: "dd/MM/yyyy" }}</span>
        </p>

        <p class="sp">
          <b> 🧾 Mode de Facturation: </b><span [ngClass]="{
          crossed:
            formGroup.value.invoiceType != null &&
            formGroup.value.invoiceType !== '' &&
            formGroup.value.invoiceType !== 'Par défaut'
        }">{{ demand.facturationMode }}</span>
        </p>
      </div>
      <div class="dues">
        <div class="dues-header">
          <h2 class="dt-title">{{ demand.demandNumber }}</h2>
          <h1 class="alert-button" *ngIf="alertLevel != 0">
            <mat-icon class="alert alert{{ alertLevel }}" (click)="dialogAlert()">notifications</mat-icon>
          </h1>
          <h2 [style.background-color]="theme.getColor('Main Dark')" class="dt blue fully-centered">
            {{ demand.description }}
          </h2>
          <button id="comments-btn" mat-mini-fab color="primary" (click)="openDialog()">
            <span *ngIf="unreadCommentsNbr">{{ unreadCommentsNbr }}</span>
            <mat-icon>message</mat-icon>
          </button>
          <mat-form-field id="admin-status-select" appearance="outline" color="accent" [ngStyle]="
          authenticationService.isUserAdmin() ? {} : { display: 'none' }
        ">
            <mat-label>Statut</mat-label>
            <mat-select matInput [(ngModel)]="targetAdminEcheanceStatus">
              <mat-option *ngFor="let status of Object.values(EcheanceStatus)" [value]="status">
                {{ status }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button mat-raised-button class="blue" [style.background-color]="theme.getColor('Main Light')"
            id="admin-status-btn" [disabled]="!targetAdminEcheanceStatus" (click)="changeDemandEcheanceStatus()"
            [ngStyle]="
          authenticationService.isUserAdmin() ? {} : { display: 'none' }
        ">
            Changer
          </button>
          <button class="demands-action" title="Valider" id="validate-demand" mat-mini-fab color="primary"
            (click)="validateDemand()" [disabled]="!demand.validable || !validatable || !asyncLock">
            <mat-icon>check</mat-icon>
          </button>
          <button class="demands-action" title="Rejeter" id="reject-demand" mat-mini-fab color="primary"
            (click)="rejectDemand()" [disabled]="!demand.rejectable || !validatable || !asyncLock">
            <mat-icon>close</mat-icon>
          </button>
          <button mat-raised-button class="indigo return" [style.background-color]="theme.getColor('Main Dark')"
            type="reset" (click)="dialogRef.close()">
            Retour
          </button>
        </div>
        <div class="section2">
          <div class="gray-card" [ngClass]="{ larger: isEditingManualData }">
            <div class="dues-list">
              <div *ngIf="!demand.echeances![0]">Aucune échéance à afficher.</div>
              <div class=" due {{ getDateStatus(ech.date!) }}" *ngFor="let ech of demand.echeances">
                <h1 class="centered">
                  {{ getMonthOfDate(ech.date!) | uppercase }}
                  {{ getYearOfDate(ech.date!) }}
                  <mat-icon *ngIf="ech.status && ech.status != EcheanceStatus.DEFAULT" class="status-icon"
                    [title]="ech.status" [ngStyle]="{
                  color: Constants.ECHEANCES_STATUSES_ICONS.get(ech.status)!
                    .color
                }">
                    {{ Constants.ECHEANCES_STATUSES_ICONS.get(ech.status)!.name }}
                  </mat-icon>
                </h1>
                <span [ngClass]="{ red: hasUnknownCommand(demand) }">Charge: {{ ech.load | number: "1.1-2" : "fr-FR" }}
                  jours</span>
                <span [ngClass]="{ red: hasUnknownCommand(demand) }">Cout: {{ ech.cost | number: "1.1-2" : "fr-FR"
                  }}€</span>
              </div>
            </div>
          </div>
          <!--  bouton edition données manuelle -->
          <div class="btn-row" [ngClass]="{
          'btn-row-margin':
            !isEditingManualData && authenticationService.isUserAdmin()
        }" *ngIf="!isEditingManualData && authenticationService.isUserAdmin()">
            <button id="btnDeleteFirstDemand" *ngIf="canBeDeleted()" (click)="deleteSelf()" mat-fab class="btn delete">
              <mat-icon>delete</mat-icon>
            </button>
            <div></div>
            <button id="btnEditDemand" *ngIf="!isEditingManualData && authenticationService.isUserAdmin()"
              (click)="toggleEditing()" mat-fab class="btn edit">
              <mat-icon>edit</mat-icon>
            </button>
          </div>
          <!-- bouton suppression dt -->
          <button id="btnDeleteDemand" *ngIf="
          canBeDeleted() &&
          !(!isEditingManualData && authenticationService.isUserAdmin())
        " (click)="deleteSelf()" mat-fab class="btn delete-solo">
            <mat-icon>delete</mat-icon>
          </button>

          <form class="form" (keydown.enter)="$event.preventDefault()" *ngIf="isEditingManualData"
            [formGroup]="formGroup">
            <div class="manuelGrid">
              <div class="ValidationLine">
                <div class="ValidationAchieveDate">
                  <mat-form-field appearance="outline" color="accent">
                    <mat-label><b>Date validation Réalisée</b></mat-label>
                    <input [readonly]="!authenticationService.isUserAdmin()" [ngClass]="{
                    italic:
                      !isValid(formGroup.value.realFirstDate) ||
                      nulldate(formGroup.value.realFirstDate)
                  }" matInput formControlName="realFirstDate" type="date" /></mat-form-field>
                </div>
                <div class="ValidationForecastDate">
                  <mat-form-field appearance="outline" color="accent">
                    <mat-label><b>Date validation Prévisionnelle</b></mat-label>
                    <input [readonly]="!authenticationService.isUserAdmin()" [ngClass]="{
                    italic:
                      !isValid(formGroup.value.estimateFirstDate) ||
                      nulldate(formGroup.value.estimateFirstDate)
                  }" matInput formControlName="estimateFirstDate" type="date" /></mat-form-field>
                </div>
                <div class="DiscountInput">
                  <mat-form-field appearance="outline" color="accent" floatLabel="always" class="comm">
                    <mat-label><b>Réduction Applicable</b></mat-label>
                    <input [readonly]="!authenticationService.isUserAdmin()" matInput formControlName="reduction"
                      type="number" />
                  </mat-form-field>
                </div>
                <div class="FacturationInput">
                  <mat-form-field appearance="outline" color="accent" floatLabel="always" class="comm">
                    <mat-label><b>Mode Facturation</b></mat-label>
                    <mat-select formControlName="invoiceType">
                      <mat-option *ngFor="let listItem of listInvoice" [value]="listItem">
                        {{ listItem }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="SubmitArea" [ngStyle]="
                authenticationService.isUserAdmin() ? {} : { display: 'none' }
              ">
                  <button mat-raised-button class="blue btn" [style.background-color]="theme.getColor('Main Light')"
                    type="submit" (click)="confirmEditing()">
                    Valider
                  </button>
                </div>
              </div>
              <div class="DeliveryLine">
                <div class="DeliveryAchieveDate">
                  <mat-form-field appearance="outline" color="accent">
                    <mat-label><b>Date livraison Réalisée</b></mat-label>
                    <input [readonly]="!authenticationService.isUserAdmin()" [ngClass]="{
                    italic:
                      !isValid(formGroup.value.realSecondDate) ||
                      nulldate(formGroup.value.realSecondDate)
                  }" matInput formControlName="realSecondDate" type="date" /></mat-form-field>
                </div>
                <div class="DeliveryForecastDate">
                  <mat-form-field appearance="outline" color="accent">
                    <mat-label><b>Date livraison Revue</b></mat-label>
                    <input [readonly]="!authenticationService.isUserAdmin()" [ngClass]="{
                    italic:
                      !isValid(formGroup.value.estimateSecondDate) ||
                      nulldate(formGroup.value.estimateSecondDate)
                  }" matInput formControlName="estimateSecondDate" type="date" /></mat-form-field>
                </div>
                <div class="CommentInput">
                  <mat-form-field appearance="outline" color="accent" floatLabel="always" class="comm">
                    <mat-label><b>Commentaire</b></mat-label>
                    <textarea [readonly]="!authenticationService.isUserAdmin()" matInput formControlName="mDComment"
                      type="text"></textarea>
                  </mat-form-field>
                </div>
                <div class="CancelArea" [ngStyle]="
                authenticationService.isUserAdmin() ? {} : { display: 'none' }
              ">
                  <button mat-raised-button class="btn" (click)="cancelEditing()">
                    Annuler
                  </button>
                </div>
              </div>
              <div class="ClosureLine">
                <div class="ClosureAchieveDate">
                  <mat-form-field appearance="outline" color="accent">
                    <mat-label><b>Date clôture Réalisée</b></mat-label>
                    <input [readonly]="!authenticationService.isUserAdmin()" [ngClass]="{
                    italic:
                      !isValid(formGroup.value.realThirdDate) ||
                      nulldate(formGroup.value.realThirdDate)
                  }" matInput formControlName="realThirdDate" type="date" /></mat-form-field>
                </div>
                <div class="ClosureForecastDate">
                  <mat-form-field appearance="outline" color="accent">
                    <mat-label><b>Date clôture Prévisionnelle</b></mat-label>
                    <input [readonly]="!authenticationService.isUserAdmin()" [ngClass]="{
                    italic:
                      !isValid(formGroup.value.estimateThirdDate) ||
                      nulldate(formGroup.value.estimateThirdDate)
                  }" matInput formControlName="estimateThirdDate" type="date" /></mat-form-field>
                </div>
                <div class="EmptyThirdLineArea"></div>
              </div>
              <div class="InibJiraLine">
                * Saisir 01/01/0001 pour inhiber une date du Jira.
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!--   <mat-divider></mat-divider>
 -->
  <div class="demandNav">
    <div class="PreviousNav">
      <button *ngIf="true" (click)="prev()" mat-raised-button mat-flat-button [disabled]="rowmin == rowindex">
        <!-- <p class="material-icons nav-icon">navigate_before</p> -->
        Demande précédente
      </button>
    </div>
    <div class="NextNav">
      <button *ngIf="true" (click)="next()" mat-raised-button mat-flat-button [disabled]="rowmax == rowindex">
        <!--         <p class="material-icons nav-icon">navigate_next</p>-->
        Demande suivante
      </button>
    </div>

  </div>
</div>