import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {IExcluded_demand} from 'src/app/shared/models/excluded-demand';
import {UtilsService} from 'src/app/shared/services/utils.service';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExcludedDemandService {

  options = {
    headers: new HttpHeaders({'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'})
  };

  constructor(private http: HttpClient,
              private utilsService: UtilsService) {
  }

  getExcludedDemands(): Observable<IExcluded_demand[]> {
    return this.http.get<IExcluded_demand[]>(environment.backendApiUrl + "/excluded-demands", this.options).pipe(catchError(this.utilsService.handleError));
  }

  createExcludedDemands(exd: IExcluded_demand): Observable<IExcluded_demand> {
    return this.http.post<IExcluded_demand>(environment.backendApiUrl + "/excluded-demands", exd, this.options).pipe(catchError(this.utilsService.handleError));
  }

  updateExcludedDemands(id: string, exd: IExcluded_demand): Observable<IExcluded_demand> {
    return this.http.put<IExcluded_demand>(environment.backendApiUrl + "/excluded-demands/" + id, exd, this.options).pipe(catchError(this.utilsService.handleError));
  }

  deleteExcludedDemands(exd: IExcluded_demand): Observable<IExcluded_demand> {
    return this.http.delete<IExcluded_demand>(environment.backendApiUrl + "/excluded-demands/" + exd.id, this.options).pipe(catchError(this.utilsService.handleError));
  }
}
