<nav mat-tab-nav-bar class="administration" *ngIf="router.url.includes('service-management/')"
   [style.background-color]="theme.getColor('Main Dark')" [tabPanel]="tabPanel">
   <a mat-tab-link [ngClass]="{'current' : router.url.includes('service-management')}"
      routerLink="/core/service-management/projects" style="margin-left: 5%;"> Domaines </a>
   <a mat-tab-link [ngClass]="{'current' : router.url.includes('service-management')}"
      routerLink="/core/service-management/projects" style="margin-left: 35%;"> Applications </a>
   <div class="flex-grow"></div>
   <a (click)="openGuide('dsiGuide')">
      <div class="material-icons help-icon">help_outline</div>
   </a>
</nav>

<mat-tab-nav-panel #tabPanel>
   <!-- Contenu des onglets ici -->
</mat-tab-nav-panel>