import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {IApplication} from 'src/app/shared/models/application';
import {IDsi} from 'src/app/shared/models/dsi';
import {IProject} from 'src/app/shared/models/project';
import {DialogService} from 'src/app/shared/services/dialog.service';
import {ApplicationProjectService} from '../services/application-project.service';
import { SnackbarService } from '../../../shared/services/snackbar.service';

@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.less']
})
export class ApplicationComponent implements OnInit {

  formGroup: UntypedFormGroup;
  asEdited = false;
  btnStatus = false;

  projects!: IProject[];

  @Input()
  application!: IApplication;

  @Input()
  dsis!: IDsi[];

  @Input()
  dsisNames: any[] = [];

  filteredOptions!: any;

  @Output()
  updateEvent = new EventEmitter<IApplication>();

  @Output()
  clearEvent = new EventEmitter<any>();

  constructor(private _fb: UntypedFormBuilder,
              private _aps: ApplicationProjectService,
              private snackbarService: SnackbarService,
              private dialogService: DialogService) {
    this.formGroup = this._fb.group({
      name: ['', Validators.required],
      critical: [''],
      project: ['', Validators.required],
      dsis: ['', Validators.required],
      id: ['']
    });
  }

  openErrorSnackBar(message: string) {
    this.snackbarService.openSnackBar(message, 'error-snack-bar');
  }

  openSuccessSnackBar(message: string) {
    this.snackbarService.openSnackBar(message);
  }


  ngOnInit(): void {
    this._aps.getProjects().subscribe({
      next: data => {
        this.projects = data;
        this.filteredOptions = data;
      },
      error: err => {
        console.log(err)
        this.openErrorSnackBar("Erreur lors de la récupération des projets")
      },
      complete: () => {
      }
    });

  }

  updateFormGroup() {
    const dsis: IDsi[] = this.application.dsis as IDsi[];
    let dsisNames: string[] = [];
    if (dsis) {
      dsisNames = dsis.map<string>(dsi => dsi.name as string);
    }

    const pUpdate = {
      id: this.application.id,
      name: this.application.name,
      critical: this.application.critical,
      project: this.application.project,
      dsis: dsisNames
    }
    this.formGroup.setValue(pUpdate);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.application && this.application.check !== undefined) {
      this.asEdited = false;
      if (!this.application.check && this.application.name == '') {
        this.formGroup.reset()
        this.asEdited = false;
        this.btnStatus = false;
        this.updateEvent.emit()
      } else if (!this.application.check && this.application.name != '') {
        this.updateFormGroup()
        this.asEdited = false;
        this.btnStatus = true;
      } else if (this.application.check && this.application.name != '') {
        this.updateFormGroup()
        this.asEdited = true;
        this.btnStatus = false;
      }
    }
  }

  onSubmit(): void {

    if (this.formGroup.value.dsis != '') {
      const selectedDsis: IDsi[] = [];
      this.formGroup.value.dsis.forEach((dsi: IDsi) => {
        const name = dsi + ""
        selectedDsis.push(this.dsiFromName(name, this.dsis));
      })
      this.formGroup.value.dsis = selectedDsis;
    }

    if (!this.asEdited && !this.btnStatus) {
      this._aps.createApplication(this.formGroup.value).subscribe({
        next: data => {
          this.updateEvent.emit(data);
          this.openSuccessSnackBar("L'application a été créée avec succès")
        },
        error: err => {
          console.log(err)
          this.openErrorSnackBar("Erreur lors de la création de l'application")
        },
        complete: () => {
          this.formGroup.reset();
        }
      });
    } else if (!this.asEdited && this.btnStatus) {
      const applicationId = this.application.id ?? ''
      this._aps.updateApplication(applicationId, this.formGroup.value).subscribe({
        next: data => {
          this.updateEvent.emit(data);
          this.openSuccessSnackBar("L'application a été mise à jour avec succès")
        },
        error: err => {
          console.log(err)
          this.openErrorSnackBar("Erreur lors de la mise à jour de l'application")
        },
        complete: () => {
          this.formGroup.reset();
          this.asEdited = false;
          this.btnStatus = false
        }
      });
    }
  }

  /*
   * Initialize orderform component
   */
  clear(): void {

    this.formGroup.reset();
    this.asEdited = false;
    this.btnStatus = false
    this.clearEvent.emit();
  }


  /*
  * Filters projects
  */
  public filter(value: string) {
    if (typeof value !== 'object') {
      const filterValue = value.toLowerCase();
      this.filteredOptions = this.projects.filter(projet => projet.name?.toLowerCase().includes(filterValue));
    }
  }

  public getProjectName(value: any) {
    if (value != null) {
      return value.name;
    }
    return null;
  }

  public dsiFromName(dsi: string, dsis: IDsi[]): IDsi {
    let res: IDsi = dsis[0];
    dsis.forEach((d: IDsi) => {
      if (dsi == d.name) {
        res = d;
      }
    })
    return res;
  }

  is_edit(asEdited: boolean) {
    asEdited = !asEdited
    this.asEdited = asEdited
    this.btnStatus = true
  }

  /*
 * Delete Application form
 */
  deleteApplicationForm() {
    if (this.asEdited) {
      this._aps.deleteApplication(this.application).subscribe({
        next: data => {
          this.clear();
          this.updateEvent.emit(data);
          this.openSuccessSnackBar("L'application a été supprimée avec succès")
        },
        error: err => {
          console.log(err)
          this.openErrorSnackBar("Erreur lors de la suppression de l'application")
        }
      });
    }
  }

  // popUp confirmation
  confirmDelete() {
    this.dialogService.openConfirmDialog(this.application.name)
      .afterClosed().subscribe({
      next: res => {
        if (res) {
          this.deleteApplicationForm();
        }
      },
      error: err => {
        console.log(err)
      },
      complete: () => {
      }
    });
  }
}

