<form class="form" [formGroup]="formGroup" (ngSubmit)="submit()">
  <div *ngFor="let form of formFields">
    <mat-form-field appearance="outline" color="accent">
      <mat-label>{{form['label']}}</mat-label>
      <input matInput required="{{form['required']}}" name="{{form['name']}}" type="{{form['type']}}"
             [pattern]="'[a-zA-Z\- ]*'">
    </mat-form-field>
  </div>

  <div class="row">
    <button mat-raised-button class="thirdrow indigo" [style.background-color]="theme.getColor('Main Dark')"
            (click)="close()"> Annuler
    </button>
    <button mat-raised-button class="thirdrow blue" [style.background-color]="theme.getColor('Main Light')"
            type="submit" [disabled]="!formGroup.valid"> Confirmer
    </button>
  </div>
</form>
