import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {ROUTES} from '../constants/routes';
import {AuthenticationService} from '../services/authentication.service';
import {ConfigService} from '../services/config.service';

@Injectable({
    providedIn: 'root'
})
export class UserAuthenticationCheckGuard  {


    constructor(private authenticationService: AuthenticationService, private configService: ConfigService,
                private router: Router) {
    }


    public canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {

        if (this.configService.isAuthEnabled()) {
            if (this.authenticationService.hasValidAccessToken()) {
                if (sessionStorage.getItem('user')) {
                    return true;
                } else {
                    return false;
                }
            } else {
                this.router.navigateByUrl(`/${ROUTES.CHECK_AUTH_PATH}`);
                return false;
            }
        } else {
            if (sessionStorage.getItem('user')) {
                return true;
            } else {
                this.router.navigateByUrl(`/${ROUTES.NO_AUTH_ACCESS}`);
                return false;
            }


        }

    }
}
