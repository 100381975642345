import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { catchError } from 'rxjs/operators';
import { SimplePromptComponent } from 'src/app/shared/simple-prompt/simple-prompt.component';
import { JiraImportService } from '../import-data/jira-import/services/jira-import.service';
import { EcheancesService } from '../pack-generation/calculate-echeances/services/echeances.service';
import { ApplicationSettingsService } from './application-settings.service';
import * as fileSaver from 'file-saver';
import { CalendrierService } from '../calendrier-pack/calendrier.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ApplicationSettings } from 'src/app/shared/models/application-settings';
import { ApplicationSettingsEntry } from 'src/app/shared/models/application-settings-entry';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { Constants } from 'src/app/shared/constants/Constants';
import { Observable } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ErrorPromptComponent } from 'src/app/shared/error-prompt/error-prompt.component';
import { RepositoryManagerComponent } from './repository-manager/repository-manager.component';
import { FileManagerComponent } from './file-manager/file-manager.component';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { PurgeModalComponent } from './purge-modal/purge-modal.component';
import { FusionPackModalComponent } from './fusion-pack-modal/fusion-pack-modal.component';
import { ConfigService } from 'src/app/shared/services/config.service';

@Component({
  selector: 'app-settings',
  templateUrl: './application-settings.component.html',
  styleUrls: ['./application-settings.component.less'],
})
export class ApplicationSettingsComponent {
  applicationSettings: ApplicationSettings;

  isDemarrer = false;
  isEditing = false;
  isEditingRad = false;
  isEditingDat = false;
  isEditingRfa = false;
  isEditingGouvSogeti = false;
  isEditingGouvCapgemini = false;

  enableCloture = false;
  enablePurge = false;
  enableImportPack = false;

  isCheckedOperation = false;
  isCheckedOpenPack = false;
  isCheckedClosePack = false;

  useDefault = false;
  useDefaultBoth = false;
  isChecked = false;

  currentDate: any;
  arrOfMonth: any[] = [];

  formGroup: UntypedFormGroup;

  operationPackState!: boolean;

  smallestMonthFromCalender!: any;
  biggestMonthFromCalender!: any;

  public spinner = false;
  public isJira = false;

  public exportYear = 0;

  public hasJiraImported = true;

  jiraFile!: File;
  packFile!: File;
  month = 0;
  public months: string[] = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ];
  year = '2020';
  // public years: string[] = [];
  public years: number[] = [];

  listTypes: string[] = [
    'Mois Echu (Défaut)',
    'M en M - Standard',
    'M en M - Variante 1',
    'M en M - Variante 2',
    'M en M - Variante 3',
  ];

  listTitles: string[] = [
    'Facturation mois échu et au réel uniquement',
    'Facturation mois M en M au réel et à défaut prévisionnel si sur le mois M',
    'Facturation mois M en M au réel uniquement et jusqu’au début du cycle de validation',
    'Facturation mois M en M au réel uniquement et jusqu’au début du cycle de validation + les jalons prévisionnels compris dans la période de validation',
    'Facturation mois M en M au prévisionnel uniquement jusqu’à la fin du cycle de validation',
  ];

  invoice = 'Défaut';

  purgeYears: number[] = [];
  manualData = false;

  confirmMerge = false;
  showRatesDates = false;

  constructor(
    private _fb: UntypedFormBuilder,
    public applicationSettingsService: ApplicationSettingsService,
    public echeancesService: EcheancesService,
    public jiraImportService: JiraImportService,
    private snackbarService: SnackbarService,
    public dialog: MatDialog,
    public theme: ThemeService,
    public calendrierService: CalendrierService,
    private http: HttpClient,
    private utilsService: UtilsService,
    private router: Router,
    public config: ConfigService
  ) {
    this.applicationSettings =
      this.applicationSettingsService.getApplicationSettings();

    this.formGroup = this._fb.group({
      year: '',
      month: '',
      cds: '',
      rad: '',
      dat: '',
      OuverturePrevue: '',
      initialisation: '',
      valid_deb: '',
      valid_fin: '',
      cloture: '',
      year_export: this.applicationSettings.Pack_current_year,
      mig: '',
      prev: '',
      invoice: {value: this.invoice, disabled: !this.isEditingInvoice},
      rfa: '',
      gouv_sogeti: '',
      gouv_capgemini: ''
    });

    this.invoice = this.formGroup.value.invoice;

    /*
     * Initialize Years Input
     */
    const currentYear: number = new Date().getFullYear();
    for (let i = currentYear - 10; i < currentYear + 10; i++) {
      this.years.push(i);
    }

    // Recherche dans calendrier du 1er mois (le plus petit) non clos et ayant la date initialisation atteinte (DT_INIT >= date du jour).
    this.currentDate = new Date().toISOString().slice(0, 10);
    this.calendrierService.getCalendrier().subscribe({
      next: (data) => {
        data.forEach((cal) => {
          if (cal.initialisation) {
            if (cal.cloture == null && cal.initialisation <= this.currentDate) {
              this.arrOfMonth.push(cal);
            }
          }
        });

        if (this.arrOfMonth.length != 0) {
          this.smallestMonthFromCalender = this.arrOfMonth.reduce(
            function (prev, curr) {
              return prev.month && prev.year < curr.month && curr.year
                ? prev
                : curr;
            },
          );
        }

        if (this.smallestMonthFromCalender != null) {
          const formUpdate = {
            year: this.smallestMonthFromCalender.year,
            month: this.smallestMonthFromCalender.month - 1,
            cds: this.applicationSettings.CDS_name,
            OuverturePrevue: this.smallestMonthFromCalender.ouverture,
            initialisation: this.smallestMonthFromCalender.initialisation,
            valid_deb: this.smallestMonthFromCalender.valid_debut,
            valid_fin: this.smallestMonthFromCalender.valid_fin,
            cloture: this.smallestMonthFromCalender.cloture,
            rad: this.applicationSettings.JIRA_Radical,
            dat: this.applicationSettings.JIRA_Extract_Date,
            mig: this.applicationSettings.migration_enabled,
            prev: this.applicationSettings.previsional_facturation_enabled,
            invoice: this.applicationSettings.invoice_mode,
            year_export: this.applicationSettings.Pack_current_year,
            rfa: this.applicationSettings.RFA_second_rate_date,
            gouv_sogeti: this.applicationSettings.Gouv_Sogeti_second_rate_date,
            gouv_capgemini: this.applicationSettings.Gouv_Capgemini_second_rate_date
          };
          this.formGroup.setValue(formUpdate);

          // Recherche dernier mois (le plus grand) clos (DT_CLOTURE n’est pas null).
        } else if (this.smallestMonthFromCalender == null) {
          data.forEach((cal) => {
            if (cal.cloture != null) {
              this.arrOfMonth.push(cal);
            }

            if (this.arrOfMonth.length != 0) {
              this.biggestMonthFromCalender = this.arrOfMonth.reduce(
                function (prev, curr) {
                  return prev.year > curr.year ? prev : curr;
                },
              );
            }

            // FOUNDED
            if (this.biggestMonthFromCalender != null) {
              const formUpdate = {
                year: this.biggestMonthFromCalender.year,
                month: this.biggestMonthFromCalender.month - 1,
                cds: this.applicationSettings.CDS_name,
                OuverturePrevue: this.biggestMonthFromCalender.ouverture,
                initialisation: this.biggestMonthFromCalender.initialisation,
                valid_deb: this.biggestMonthFromCalender.valid_debut,
                valid_fin: this.biggestMonthFromCalender.valid_fin,
                cloture: this.biggestMonthFromCalender.cloture,
                rad: this.applicationSettings.JIRA_Radical,
                dat: this.applicationSettings.JIRA_Extract_Date,
                mig: this.applicationSettings.migration_enabled,
                prev: this.applicationSettings.previsional_facturation_enabled,
                invoice: this.applicationSettings.invoice_mode,
                year_export: this.applicationSettings.Pack_current_year,
                rfa: this.applicationSettings.RFA_second_rate_date,
                gouv_sogeti: this.applicationSettings.Gouv_Sogeti_second_rate_date,
                gouv_capgemini: this.applicationSettings.Gouv_Capgemini_second_rate_date
              };
              this.formGroup.setValue(formUpdate);

              // NOT FOUNDED
            } else {
              const formUpdateClean = {
                year: '',
                month: '',
                cds: '',
                rad: '',
                dat: 'null',
                OuverturePrevue: null,
                initialisation: null,
                valid_deb: null,
                valid_fin: null,
                cloture: null,
                year_export: this.applicationSettings.Pack_current_year,
                mig: '',
                prev: '',
                invoice: 'Mois Echu (Défaut)',
                rfa: 'null',
                gouv_sogeti: 'null',
                gouv_capgemini: 'null'
              };

              this.invoice = this.formGroup.value.invoice;
              this.formGroup.setValue(formUpdateClean);
            }
          });
        }

        /**
         * Menu Options Avancées
         **/
        // Mois du Pack = vide, les blocs [Opérations], [Ouvrir le Pack] et [Clore le Pack] sont masqués
        if (
          this.formGroup.value.month == null &&
          this.formGroup.value.year == null
        ) {
          this.operationPackState = true;
        }

        this.checkOperations(this.formGroup);
        this.checkOpenPack(this.formGroup);
        this.checkClosurePack(this.formGroup);

        if (
          this.formGroup.value.month + 1 !=
          this.applicationSettings.Pack_current_month ||
          this.formGroup.value.year != this.applicationSettings.Pack_current_year
        ) {
          this.isDemarrer = true;
        } else {
          this.isDemarrer = false;
        }
      },
      error: (error) => {
        console.log(error);
      },
      complete: () => {

      }
    });
  }

  public updateApplicationSettings() {
    this.applicationSettingsService
      .updateApplicationSettings()
      .subscribe({
        next: (response: any) => {
          if (response) {
            this.openSuccessSnackBar(
              'Les paramètres de réglage ont été modifiés avec succés',
            );
          } else {
            this.openErrorSnackBar(
              "Les paramètres de réglage n'ont pas pu été modifiés",
            );
          }
        },
        error: (error) => {
          console.log(error);
        },
        complete: () => {

        }
      });
  }

  onChangeRate($event: MatSlideToggleChange) {
    this.showRatesDates = $event.checked;
  }
  
  onChangePurgeDemands($event: MatSlideToggleChange) {
    this.enablePurge = $event.checked;
  }

  public purgeDemands() {
    this.echeancesService
      .getYearsForPurgeDemands()
      .subscribe({
        next: (response: any) => {
          this.purgeYears = response;
          this.purgeDemandsModal();
        },
        error: (error) => {
          console.log('Erreur lors de la récupération des années pour la purge des demandes' + error);
        },
        complete: () => {
        }
      });
  }

  public purgeDemandsModal() {
    const dialogRef = this.dialog.open(PurgeModalComponent, {
      data: {
        years: this.purgeYears,
        manualData: this.manualData,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.spinner = true;
        this.echeancesService
          .purgeDemands(result.years, result.manualData)
          .subscribe({
            next: (response: any) => {
              this.openSuccessSnackBar(
                'La purge des demandes a été effectuée avec succés',
              );
              this.spinner = false;
            },
            error: (error) => {
              console.log(error);
              this.openErrorSnackBar(
                "La purge des demandes n'a pas été effectuée avec succés",
              );
              this.spinner = false;
            },
            complete: () => {
            }
          });
      }
    });
  }

  public validateDemands() {
    const dialogRef = this.dialog.open(SimplePromptComponent, {
      data: {
        message:
          'Voulez-vous vraiment valider toutes les échéances en attente SNCF ?',
      },
    });
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        this.spinner = true;
        this.echeancesService
          .validatePendingDemands()
          .subscribe({
            next: (response) => {
              this.spinner = false;
              this.openSuccessSnackBar(
                'La validation automatique des échéances confirmées a été réalisée avec succés',
              );
            },
            error: (error) => {
              console.log(error);
              if (error.status == 404) {
                this.spinner = false;
                this.openSuccessSnackBar(
                  "Aucune échéance à valider",
                );
              } else {
                this.spinner = false;
                this.openErrorSnackBar(
                  "La validation automatique des échéances a échoué",
                );
              }
            },
            complete: () => {
            }
          });

      },
      error: (error) => {
        console.log(error);
      },
      complete: () => {
      }
    });
  }

  public postponeDemands() {
    this.spinner = true;
    this.echeancesService.getDemands().subscribe({
      next: (data) => {
        this.spinner = false;
        let dueList = '';

        const dialogRef = this.dialog.open(SimplePromptComponent, {
          data: {
            message: 'Voulez-vous vraiment reporter les DT non validées ? <br>',
          },
        });
        dialogRef.afterClosed().subscribe({
          next: (result) => {
            if (result) {
              'a'.concat;
              if (data.length != 0) {
                for (const element of data) {
                  if (element?.demandNumber != null)
                    dueList += '<br>' + element.demandNumber;
                }

                const dialogRef = this.dialog.open(SimplePromptComponent, {
                  data: {
                    message:
                      'Les DT suivantes sont en attente validation SNCF. Confirmez vous le report de ces échéances ?' +
                      dueList,
                  },
                });
                dialogRef.afterClosed().subscribe({
                  next: (result) => {
                    if (result) {
                      this.spinner = true;
                      this.echeancesService
                        .postponeDemands()
                        .subscribe({
                          next: (response) => {
                            this.spinner = false;
                            this.openSuccessSnackBar(
                              'Le report automatique a été effectué avec succés',
                            );
                          },
                          error: (error) => {
                            console.log(error);
                            if (error.status == 404) {
                              this.spinner = false;
                              this.openSuccessSnackBar(
                                "Aucune échéance à reporter",
                              );
                            }
                          },
                          complete: () => {
                          }
                        });
                    } else {
                      this.spinner = false;
                    }
                  },
                  error: (error) => {
                    console.log(error);
                  },
                  complete: () => {

                  }
                });
              } else {
                this.spinner = true;
                this.echeancesService
                  .postponeDemands()
                  .subscribe({
                    next: (response: string) => {
                      this.spinner = false;
                      this.openSuccessSnackBar(
                        'Le report automatique a été effectué avec succés',
                      );
                    },
                    error: (error) => {
                      console.log(error);
                      if (error.status == 404) {
                        this.spinner = false;
                        this.openSuccessSnackBar(
                          "Aucune échéance à reporter",
                        );
                      }
                    },
                    complete: () => {

                    }
                  });
              }
            }
          },
          error: (error) => {
            console.log(error);
          },
          complete: () => {
          }
        });
      },
      error: (error) => {
        console.log(error);
        this.spinner = false;
        this.openErrorSnackBar("Le report automatique n'a pas fonctionné.");
      },
      complete: () => {
      }
    });
  }

  public initNetting() {
    const dialogRef = this.dialog.open(SimplePromptComponent, {
      data: {
        message:
          'Etes-vous sûr de vouloir associer automatiquement des comptes nettings aux demandes?',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.spinner = true;
        this.hasJiraImported = false;
        this.echeancesService.initNetting().subscribe({
          next: (data) => {
            this.spinner = false;
            this.openSuccessSnackBar("Le netting s'est correctement associé.");
          },
          error: (error) => {
            console.log(error);
            this.spinner = false;
            this.openErrorSnackBar('Le netting a rencontré une erreur.');
          },
        });
      }
    });
  }

  onChangeImportPack($event: MatSlideToggleChange) {
    this.enableImportPack = $event.checked;
  }

  public importPack() {
    const dialogRef = this.dialog.open(SimplePromptComponent, {
      data: {
        message:
          'Vous êtes sûr de vouloir importer le pack ' + this.packFile.name,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result) {
          if (this.packFile != null && this.year != null && this.month != null) {
            this.spinner = true;
            this.jiraImportService
              .postFilePack(this.packFile, this.year, this.month + 1)
              .subscribe({
                next: (data) => {
                  this.openSuccessSnackBar(
                    "Le fichier s'est correctement importé.",
                  );
                },
                error: (e) => {
                  console.log(e);
                  this.openErrorSnackBar(
                    "Le fichier ne s'est pas correctement importé.",
                  );
                },
                complete: () => {
                  this.spinner = false;
                }
              });
          }
        }
      },
      error: (error) => {
        console.log(error);
        
      },
      complete: () => {

      }
    });
  }

  public importJira() {
    if (
      this.applicationSettings.JIRA_Radical != null &&
      this.applicationSettings.JIRA_Radical.trim() != '' &&
      this.jiraFile.name.indexOf(
        this.applicationSettings.JIRA_Radical.trim(),
      ) == -1
    ) {
      this.dialog.open(ErrorPromptComponent, {
        data: {
          message:
            '<b>Import impossible:</b> Le nom de fichier ' +
            this.jiraFile.name +
            ' ne correspond pas au radical indiqué.',
        },
      });
    } else {
      const dialogRef = this.dialog.open(SimplePromptComponent, {
        data: {
          message:
            'Vous êtes sûr de vouloir importer le fichier JIRA  ' +
            this.jiraFile.name,
        },
      });
      dialogRef.afterClosed().subscribe({
        next: (result) => {
          if (result) {
            if (this.jiraFile != null) {

              const regex = /(\d{4}-\d{2}-\d{2}T\d{2}_\d{2}_\d{2})/; // AAAA-MM-JJTHH_MM_SS
              const fileName = this.jiraFile.name;
              const matches = RegExp(regex).exec(fileName);
              let jiraDate = '';
              if (matches && matches[1]) {
                const isoDate = matches[1];
                jiraDate = isoDate.replace(/_/g, ':'); // AAAA-MM-JJTHH:MM:SS
                this.formGroup.controls['dat'].setValue(jiraDate);
                this.hasJiraImported = true;
                this.isJira = true;
                this.jiraImportService.jiraErrors;
                this.jiraImportService.eraseErrors();
                this.jiraImportService.postFileToBack(this.jiraFile, this);
                this.onEditDat();
              } else {
                this.dialog.open(ErrorPromptComponent, {
                  data: {
                    message:
                      '<b>Import impossible :</b> <br/> <p> Le nom du fichier JIRA <i><b>' +
                      this.jiraFile.name +
                      '</b></i>  ne contient pas de date au format attendu : <b>AAAA-MM-JJTHH_MM_SS</b></p>',
                  },
                });
              }

            }
          }
        },
        error: (error) => {
          console.log(error);
        },
        complete: () => {
          this.spinner = false;
        }
      }
      );
    }
  }

  public calculate() {
    const dialogRef = this.dialog.open(SimplePromptComponent, {
      data: { message: 'Vous êtes sûr de vouloir calculer les écheances ' },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result) {
          const startDate = new Date();
          this.spinner = true;
          this.jiraImportService.getCalculDues().subscribe({
            next: (data) => {
              const endDate = new Date();
              const seconds = (endDate.getTime() - startDate.getTime()) / 1000;
              this.openSuccessSnackBar(
                'Le calcul des échéances a été effectué en ' +
                seconds +
                ' secondes.',
              );
            },
            error: (error) => {
              console.log(error);
              this.openErrorSnackBar(
                "Le calcul des échéances n'a pas fonctionné.",
              );
            },
            complete: () => {
              this.spinner = false;
            }
          });
        }
      },
      error: (error) => {
        console.log(error);
      },
      complete: () => {
      }
    });
  }

  public openDirectoryManager() {
    this.dialog.open(RepositoryManagerComponent, {});
  }

  public openFileManager() {
    this.dialog.open(FileManagerComponent, {});
  }

  public jiraSpin(): boolean {
    if (this.isJira && this.jiraImportService.jiraErrors[0])
      this.isJira = false;
    return this.isJira && !this.jiraImportService.jiraErrors[0];
  }

  public downloadFile(): void {
    if (this.formGroup.value.year_export == '') {
      return;
    } else {

      this.spinner = true;

      this.jiraImportService
        .getTimedPack(this.formGroup.value.year_export + '', [])
        .subscribe({
          next: (data) => {
            const blob: any = new Blob([data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            window.URL.createObjectURL(blob);
            fileSaver.saveAs(
              blob,
              'Données Collectées ' +
              this.formGroup.value.year_export +
              ' - ' +
              this.applicationSettings.CDS_name +
              ' - ' +
              new Date().toLocaleDateString().replace('_', '-') +
              ' ' +
              new Date().toLocaleTimeString().replace('_', '-'),
            );
            this.spinner = false;
            this.openSuccessSnackBar('Le fichier a été correctement exporté.');
          },
          error: (err) => {
            console.log(err);
            this.spinner = false;
            this.openErrorSnackBar("Une erreur s'est produite lors de l'export.");
          },
          complete: () => {
          }
        });
    }
  }

  openErrorSnackBar(message: string) {
    this.snackbarService.openSnackBar(message, 'error-snack-bar');
  }

  openSuccessSnackBar(message: string) {
    this.snackbarService.openSnackBar(message);
  }


  /**
   * onChange Toggle Operations
   * */
  onChangeOperations($event: MatSlideToggleChange) {
    this.applicationSettings.Operations_in_progress = $event.checked;

    this.checkOperations(this.formGroup);

    if (this.applicationSettings.Operations_in_progress) {
      this.applicationSettings.enableCollectedDataTab = false;
      this.applicationSettings.Closure_in_progress = false;
    }

    this.updateApplicationSetting(this.applicationSettings).subscribe({
      next: (res) => {
        if (res) {
          this.checkOpenPack(this.formGroup);
          this.checkClosurePack(this.formGroup);
          this.reloadComponent();
        }
      },
      error: (error) => {
        console.log(error);
      },
      complete: () => {
      }
    });
  }

  /**
   * onChange Toggle Open Pack
   * */
  onChangeOpenPack($event: MatSlideToggleChange) {
    this.applicationSettings.enableCollectedDataTab = $event.checked;

    this.checkOpenPack(this.formGroup);

    if (this.applicationSettings.enableCollectedDataTab) {
      this.applicationSettings.Operations_in_progress = false;
      this.applicationSettings.Closure_in_progress = false;

      if (
        this.formGroup.value.valid_deb <= this.currentDate &&
        this.currentDate <= this.formGroup.value.valid_fin
      ) {
        this.applicationSettings.Enable_SNCF_Validation = true;
      }
    } else this.applicationSettings.Enable_SNCF_Validation = false;

    this.updateApplicationSetting(this.applicationSettings).subscribe({
      next: (res) => {
        if (res) {
          this.checkOperations(this.formGroup);
          this.checkClosurePack(this.formGroup);
          this.reloadComponent();
        }
      },
      error: (error) => {
        console.log(error);
      },
      complete: () => {
      }
    });
  }

  /**
   * onChange Toggle Close Pack
   * */
  onChangeClosePack($event: MatSlideToggleChange) {
    this.applicationSettings.Closure_in_progress = $event.checked;

    this.checkClosurePack(this.formGroup);
    if (this.applicationSettings.Closure_in_progress) {
      this.applicationSettings.Operations_in_progress = false;
      this.applicationSettings.enableCollectedDataTab = false;
    }

    this.updateApplicationSetting(this.applicationSettings).subscribe({
      next: (res) => {
        if (res) {
          this.checkOperations(this.formGroup);
          this.checkOpenPack(this.formGroup);
          this.reloadComponent();
        }
      },
      error: (error) => {
        console.log(error);
      },
      complete: () => {

      }
    });
  }

  /**
   *
   * onChange display fusion pack
   */
  onChangeDisplayFusionPack($event: MatSlideToggleChange) {
    this.applicationSettings.display_fusion_pack = $event.checked;

    this.updateApplicationSetting(this.applicationSettings).subscribe({
      next: (res) => {
        if (res) {
          console.log(res);
          this.reloadComponent();
        }
      },
      error: (error) => {
        console.log(error);
      },
      complete: () => {
      }
    });
  }

  updateApplicationSetting(as: any): Observable<any> {
    const applicationSettingsArray: ApplicationSettingsEntry[] = [];
    Object.keys(as).forEach((key) => {
      const applicationSettingsEntry: ApplicationSettingsEntry =
        new ApplicationSettingsEntry();
      applicationSettingsEntry.parameterKey = key;
      applicationSettingsEntry.parameterValue = (as as any)[key] + '';
      applicationSettingsEntry.parameterType = typeof (as as any)[key];
      applicationSettingsArray.push(applicationSettingsEntry);
    });
    return this.http
      .put<any>(
        environment.backendApiUrl + Constants.ApiPaths.SETTINGS,
        applicationSettingsArray,
      )
      .pipe(catchError(this.utilsService.handleError));
  }

  onSubmit() {
  }

  onEditRFA() {
    this.applicationSettings.RFA_second_rate_date = this.formGroup.value.rfa;
    this.updateApplicationSetting(this.applicationSettings).subscribe({
      next: (res) => {
        if (res) {
          this.openSuccessSnackBar('La date de changement de taux RFA a été modifiée avec succés');
        } else {
          this.openErrorSnackBar("La date de changement de taux  RFA n'a pas été modifiée");
        }
      },
      error: (error) => {
        console.log(error);
      },
      complete: () => {
        this.isEditingRfa = false;
      }
    });
  }

  onEditGouvSogeti() {
    this.applicationSettings.Gouv_Sogeti_second_rate_date = this.formGroup.value.gouv_sogeti;
    this.updateApplicationSetting(this.applicationSettings).subscribe({
      next: (res) => {
        if (res) {
          this.openSuccessSnackBar('La date de changement de taux Gouv Sogeti a été modifiée avec succés');
        } else {
          this.openErrorSnackBar("La date de changement de taux Gouv Sogeti n'a pas été modifiée");
        }
      },
      error: (error) => {
        console.log(error);
      },
      complete: () => {
        this.isEditingGouvSogeti = false;
      }
    });
  }

  onEditGouvCapgemini() {
    this.applicationSettings.Gouv_Capgemini_second_rate_date = this.formGroup.value.gouv_capgemini;
    this.updateApplicationSetting(this.applicationSettings).subscribe({
      next: (res) => {
        if (res) {
          this.openSuccessSnackBar('La date de changement de taux Gouv Capgemini a été modifiée avec succés');
        } else {
          this.openErrorSnackBar("La date de changement de taux Gouv Capgemini n'a pas été modifiée");
        }
      },
      error: (error) => {
        console.log(error);
      },
      complete: () => {
        this.isEditingGouvCapgemini = false;
      }
    });
  }

  onEditCDS() {
    this.applicationSettings.CDS_name = this.formGroup.value.cds;

    this.updateApplicationSetting(this.applicationSettings).subscribe({
      next: (res) => {
        if (res) {
          this.openSuccessSnackBar('Le nom du CDS a été modifié avec succés');
          this.isEditing = false;
        } else {
          this.openErrorSnackBar("Le nom du CDS n'a pas été modifié");
        }
      },
      error: (error) => {
        console.log(error);
      },
      complete: () => {

      }
    });
  }

  enableEditCDS() {
    this.isEditing = true;
  }

  onEditMig() {
    this.applicationSettings.migration_enabled = this.formGroup.value.mig;
    this.updateApplicationSetting(this.applicationSettings).subscribe({
      next: (res) => {
        if (res) {
          if (this.formGroup.value.mig)
            this.openSuccessSnackBar('La migration a été activée avec succés');
          else
            this.openSuccessSnackBar('La migration a été désactivée avec succés');
        }
      },
      error: (error) => {
        console.log(error);
      },
      complete: () => {

      }
    });
  }

  onEditPrev() {
    this.applicationSettings.previsional_facturation_enabled =
      this.formGroup.value.prev;
    this.updateApplicationSetting(this.applicationSettings).subscribe((res) => {
      if (res) {
        if (this.formGroup.value.prev)
          this.openSuccessSnackBar(
            'La facturation prévisionnelle a été activée avec succés',
          );
        else
          this.openSuccessSnackBar(
            'La facturation prévisionnelle a été désactivée avec succés',
          );
      }
    });
  }

  isEditingInvoice = false;

  enableEditInvoice() {
    this.isEditingInvoice = true;
  }

  onEditInvoice() {
    this.isEditingInvoice = false;

    this.formGroup.value.invoice = this.invoice;
    this.applicationSettings.invoice_mode = this.formGroup.value.invoice;
    this.updateApplicationSetting(this.applicationSettings).subscribe({
      next: (res) => {
        if (res) {
          this.openSuccessSnackBar(
            'Le mode de facturation a été modifié avec succès',
          );
        }
      },
      error: (error) => {
        console.log(error);
      },
      complete: () => {

      }
    });
  }

  onEditRad() {
    this.applicationSettings.JIRA_Radical = this.formGroup.value.rad;
    this.updateApplicationSetting(this.applicationSettings).subscribe((res) => {
      if (res) {
        this.openSuccessSnackBar(
          'Le radical du fichier JIRA a été modifié avec succés',
        );
        this.isEditingRad = false;
      } else {
        this.openErrorSnackBar(
          "Le radical du fichier JIRA n'a pas pu été modifié",
        );
      }
    });
  }

  enableEditRad() {
    this.isEditingRad = true;
  }

  onEditDat() {
    this.applicationSettings.JIRA_Extract_Date = this.formGroup.value.dat;

    this.updateApplicationSetting(this.applicationSettings).subscribe({
      next: (res) => {
        if (res) {
          this.isEditingDat = false;
        } else {
          this.openErrorSnackBar(
            "La dernière date d'extraction du fichier JIRA n'a pas pu été modifiée",
          );
        }
      },
      error: (error) => {
        console.log(error);
      },
      complete: () => {
      }
    });
  }

  enableEditDat() {
    this.isEditingDat = true;
  }

  enableEditRfa() {
    this.isEditingRfa = true;
  }

  enableEditGouvSogeti() {
    this.isEditingGouvSogeti = true;
  }

  enableEditGouvCapgemini() {
    this.isEditingGouvCapgemini = true;
  }

  checkOperations(form: any) {
    if (!this.applicationSettings.Operations_in_progress) {
      this.isCheckedOperation = false;
    } else {
      if (form.value.cloture == null) {
        this.isCheckedOperation = true;
      } else {
        this.isCheckedOperation = false;
      }
    }
  }

  checkOpenPack(form: any) {
    if (this.applicationSettings.enableCollectedDataTab) {
      if (form.value.cloture == null) {
        this.isCheckedOpenPack = true;
        this.useDefaultBoth = true;
      } else {
        this.isCheckedOpenPack = false;
        this.useDefaultBoth = true;
      }
    } else {
      this.isCheckedOpenPack = false;
      this.useDefaultBoth = false;
    }
  }

  checkClosurePack(form: any) {
    if (!this.applicationSettings.Closure_in_progress) {
      this.isCheckedClosePack = false;
      this.isChecked = false;
    } else {
      this.isChecked = true;
      if (form.value.cloture == null) {
        this.isCheckedClosePack = true;
      }
    }
  }

  reloadComponent() {
    const currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }

  isUpdateMonthYear() {
    this.spinner = true;
    this.echeancesService
      .startPack(
        this.formGroup.value.year + 'a' + (this.formGroup.value.month + 1),
      )
      .subscribe({
        next: (value) => {
          this.applicationSettings.Pack_current_month =
            this.formGroup.value.month + 1;
          this.applicationSettings.Pack_current_year = this.formGroup.value.year;

          this.spinner = false;
          this.updateApplicationSetting(this.applicationSettings).subscribe({
            next: (res) => {
              if (res) {
                this.openSuccessSnackBar(
                  "Le Mois et l'année ont été modifiés avec succés",
                );
                this.isDemarrer = false;
                this.reloadComponent();
              }
            },
            error: (error) => {
              console.log(error);
              this.openErrorSnackBar("Le Mois et l'année n'ont pas pu être modifié");
            },
            complete: () => {
            }
          });
        },
        error: (error) => {
          console.log(error);
          this.spinner = false;
        },
        complete: () => {
        }
      });
  }

  onChangeEnableSNCF($event: MatSlideToggleChange) {
    this.applicationSettings.Enable_SNCF_Validation = $event.checked;
    this.updateApplicationSetting(this.applicationSettings).subscribe({
      next: (res) => {
        if (res) {
          this.reloadComponent();
        }
      },
      error: (error) => {
        console.log(error);
      },
      complete: () => {
      }
    });
  }

  onChangeEnablePreviousYear($event: MatSlideToggleChange) {
    this.applicationSettings.Enable_Previous_Year = $event.checked;

    this.updateApplicationSetting(this.applicationSettings).subscribe({
      next: (res) => {
        if (res) {
          console.log(res);
        }
      },
      error: (error) => {
        console.log(error);
      },
      complete: () => {
      }
    });
  }

  clotureValid() {
    if (
      this.formGroup.value.cloture >= this.formGroup.value.valid_fin ||
      this.formGroup.value.cloture == ''
    ) {
      this.calendrierService.getCalendrier().subscribe({
        next: (cal) => {
          cal.forEach((data) => {
            if (
              data.month == this.formGroup.value.month + 1 &&
              data.year == this.formGroup.value.year
            ) {
              data.cloture = this.formGroup.value.cloture;
              this.calendrierService
                .updateCalendrier(data.id!, data)
                .subscribe({
                  next: (r) => {
                    this.openSuccessSnackBar('Date cloture affectée avec succés');
                    this.reloadComponent();
                  },
                  error: (error) => {
                    console.log(error);
                    this.openErrorSnackBar('Date cloture non affectée');
                  },
                  complete: () => {
                  }
                });
            }
          });
        },
        error: (error) => {
          console.log(error);
        },
        complete: () => {
        }
      });
    }
  }

  onEnableCloture() {
    this.enableCloture = true;
  }

  public importAlert() {
    if (
      this.applicationSettings.JIRA_Radical != null &&
      this.applicationSettings.JIRA_Radical.trim() != '' &&
      this.jiraFile.name.indexOf(
        this.applicationSettings.JIRA_Radical.trim(),
      ) == -1
    ) {
      this.dialog.open(ErrorPromptComponent, {
        data: {
          message:
            '<b>Import impossible:</b> Le nom de fichier ' +
            this.jiraFile.name +
            ' ne correspond pas au radical indiqué.',
        },
      });
    } else {
      const startDate = new Date();
      this.spinner = true;
      this.jiraImportService.postFileAlert(this.jiraFile).subscribe({
        next: (data) => {
          const endDate = new Date();
          const seconds = (endDate.getTime() - startDate.getTime()) / 1000;
          this.spinner = false;
          this.openSuccessSnackBar(
            'Traitement des alertes a effectué avec succès en ' +
            seconds +
            ' secondes',
          );

          this.jiraImportService.getAlertCsvByUser('', '').subscribe({
            next: (res) => {
              const blob: any = new Blob([res], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              });
              window.URL.createObjectURL(blob);
              fileSaver.saveAs(
                blob,
                'Alerte Jira - ' +
                this.applicationSettings.CDS_name +
                ' - ' +
                new Date().toLocaleDateString(),
              );
            },
            error: (error) => {
              console.log(error);
              this.openErrorSnackBar('Erreur dans la génération du fichier excel')
            },
            complete: () => {
            }
          });
        },
        error: (data) => {
          this.spinner = false;
          this.openErrorSnackBar('Erreur dans le Traitement des alertes');
        },
        complete: () => {
        }
      });
    }
  }

  public fusionPackModal() {
    const dialogRef = this.dialog.open(FusionPackModalComponent, {
      data: {
        confirmMerge: this.confirmMerge,
      },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result) {
          this.spinner = true;
          if (result.confirmMerge) {
            this.applicationSettingsService.mergePacks().subscribe({
              next: () => {
                this.spinner = false;
                this.openSuccessSnackBar(
                  'La fusion des packs a été effectuée avec succés',
                );
              },
              error: (error) => {
                console.log(error);
                this.spinner = false;
                this.openErrorSnackBar('La fusion a échoué');
              },
              complete: () => {
              }
            });
          }
        }
      },
      error: (error) => {
        console.log(error);
      },
      complete: () => {
      }
    });
  }
}
