import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ICalendrier } from 'src/app/shared/models/calendrier';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { CalendrierService } from '../calendrier.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';

@Component({
  selector: 'app-calendrier-form',
  templateUrl: './calendrier-form.component.html',
  styleUrls: ['./calendrier-form.component.less'],
})
export class CalendrierFormComponent implements OnInit {
  currentYear: number = new Date().getFullYear();

  years: number[] = [];
  monthNames = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Aout',
    'Septembre',
    'Octobre',
    'Novembre',
    'Decembre',
  ];
  month = 0;
  months: string[] = [];
  formGroup: UntypedFormGroup;
  ErrorMessage = false;

  asEdited = false;
  btnStatus = false;

  @Output()
  updateEvent = new EventEmitter<ICalendrier>();

  @Output()
  updateYear = new EventEmitter<any>();

  @Output()
  clearEvent = new EventEmitter<any>();

  @Input()
  calendrierForm!: ICalendrier;

  constructor(
    private _fb: UntypedFormBuilder,
    private _aps: CalendrierService,
    private snackbarService: SnackbarService,
    public authentificationService: AuthenticationService,
  ) {
    this.formGroup = this._fb.group({
      year: [this.currentYear, Validators.required],
      month: ['', Validators.required],
      initialisation: ['', Validators.required],
      ouverture: ['', Validators.required],
      valid_debut: ['', Validators.required],
      valid_fin: ['', Validators.required],
      cloture: '',
    });
  }

  openErrorSnackBar(message: string) {
    this.snackbarService.openSnackBar(message, 'error-snack-bar');
  }

  openSuccessSnackBar(message: string) {
    this.snackbarService.openSnackBar(message);
  }


  ngOnInit(): void {
    /*
     * Initialize Years Input
     */
    const currentYear: number = new Date().getFullYear();
    for (let i = currentYear - 10; i < currentYear + 10; i++) {
      this.years.push(i);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.asEdited = false;
    if (this.calendrierForm !== null && this.calendrierForm !== undefined) {
      if (!this.calendrierForm.enableEdit && this.calendrierForm.month == null) {
        this.updateFormGroup();
        this.asEdited = false;
        this.btnStatus = false;
      } else if (
        this.calendrierForm.enableEdit &&
        this.calendrierForm.month != null
      ) {
        this.updateFormGroup();
        this.asEdited = true;
        this.btnStatus = false;
      } else if (
        !this.calendrierForm.enableEdit &&
        this.calendrierForm.month != null
      ) {
        this.updateFormGroup();
        this.asEdited = false;
        this.btnStatus = true;
      }
    }
  }

  /*
   * Update formgroup  input
   */
  updateFormGroup() {
    const formUpdate = {
      year: this.calendrierForm.year,
      month:
        this.calendrierForm.month && this.calendrierForm.month <= 12
          ? this.calendrierForm.month - 1
          : '',
      initialisation: this.calendrierForm.initialisation,
      ouverture: this.calendrierForm.ouverture,
      valid_debut: this.calendrierForm.valid_debut,
      valid_fin: this.calendrierForm.valid_fin,
      cloture: this.calendrierForm.cloture,
    };
    this.formGroup.setValue(formUpdate);
  }

  /*
   * Check dates  input
   */
  ValidDate(
    dInit: Date,
    dOuvert: Date,
    dValid_deb: Date,
    dValid_fin: Date,
    dCloture: Date,
  ): boolean {
    if (dCloture) {
      if (
        dInit < dOuvert &&
        dOuvert < dValid_deb &&
        dValid_deb < dValid_fin &&
        dValid_fin < dCloture
      ) {
        return true;
      }
    }

    if (!dCloture) {
      if (dInit < dOuvert && dOuvert < dValid_deb && dValid_deb < dValid_fin) {
        return true;
      }
    }
    return false;
  }

  onSubmit(): void {
    this.formGroup.value.month = this.formGroup.value.month + 1;

    const init = this.formGroup.value.initialisation;
    const ouvert = this.formGroup.value.ouverture;
    const valid_deb = this.formGroup.value.valid_debut;
    const valid_fin = this.formGroup.value.valid_fin;
    const cloture = this.formGroup.value.cloture;

    if (this.ValidDate(init, ouvert, valid_deb, valid_fin, cloture)) {
      if (!this.asEdited && !this.btnStatus) {
        this._aps.createCalendrier(this.formGroup.value).subscribe({
          next: (data) => {
            this.updateEvent.emit(data);
            this.formGroup.reset({
              year: this.formGroup.value.year,
            });
            this.openSuccessSnackBar('Calendrier créé avec succès');
          },
          error: (err) => {
            console.log(err);
            this.openErrorSnackBar('Erreur : année mois dupliqué');
          },
          complete: () => {
          }
        });
      } else if (!this.asEdited && this.btnStatus) {
        this._aps
          .updateCalendrier(this.calendrierForm.id ?? '', this.formGroup.value)
          .subscribe({
            next: (data) => {
              this.updateEvent.emit(data);
              this.formGroup.reset({
                year: this.formGroup.value.year,
              });
              this.asEdited = false;
              this.btnStatus = false;
              this.openSuccessSnackBar('Calendrier mis à jour avec succès');
            },
            error: (err) => {
              console.log(err);
              this.openErrorSnackBar('Erreur : année mois dupliqué');
            },
            complete: () => {
            }
          });
      }
      this.ErrorMessage = false;
    } else {
      this.ErrorMessage = true;
    }
  }

  /*
   * Initialize orderform component
   */
  clear(): void {
    this.formGroup.reset();
    this.asEdited = false;
    this.btnStatus = false;
    this.ErrorMessage = false;
    this.clearEvent.emit();
  }

  /*
   * Enabling Editing
   */

  is_edit(asEdited: boolean) {
    asEdited = !asEdited;
    this.asEdited = asEdited;
    this.btnStatus = true;
  }

  /*
   * Filters projects
   */
  public filter(value: number) {
    this.updateYear.emit({ year: 'year', payload: value });
  }
}
