<div class="card">
  <app-domaines *ngIf="authenticationService.isUserAdmin()" [dsi]="dsiSelected" (updateEvent)="updateDsiList()"
    (clearEvent)="initDsi()"></app-domaines>

  <div class="data-header">
    <mat-form-field class="domainFilter">
      <mat-label>Filtre</mat-label>
      <input matInput (keyup)="dsisFilter($event)">
    </mat-form-field>
    <div class="exportBtn">
      <button mat-raised-button class="indigo" [style.background-color]="theme.getColor('Main Dark')"
        (click)="exportData()">
        <mat-icon>get_app</mat-icon>
        Export Excel
      </button>
    </div>
  </div>

  <mat-table  #table class="mat-elevation-z8 table-container" *ngIf="dsi" [dataSource]="dsi" matSort>

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header id="name"> Nom</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.name }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef mat-sort-header id="type"> Type</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.type }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef id="actions" class="actions-td"> Actions</mat-header-cell>
      <mat-cell *matCellDef="let element" class="actions-td">
        <button mat-icon-button (click)="selectedDsiPencel(element)" [disabled]="!authenticationService.isUserAdmin()">
          <mat-icon>mode_edit</mat-icon>
        </button>
        <button mat-icon-button (click)="removeDsi(element)" [disabled]="!authenticationService.isUserAdmin()">
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
    <mat-row class="focusable" mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectedDsi(row)"></mat-row>
  </mat-table>

</div>