<div *ngIf="error">
  <h1>{{error["description"]}}</h1>
  <div *ngFor="let e of error['rulesWarnings']">
    -> {{e}}
  </div>
  <div *ngFor="let e of error['rulesErrors']">
    -> {{e}}
  </div>
</div>

