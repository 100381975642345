import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-jira-error',
  templateUrl: './jira-error.component.html',
})
export class JiraErrorComponent {

  error?: any;

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: any | undefined, public dialogRef: MatDialogRef<number>) {
    this.error = dialogData["data"];
  }

}
