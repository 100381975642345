<nav [style.background-color]="theme.getColor('Main Dark')" mat-tab-nav-bar class="administration"
   *ngIf="router.url.includes('admin/')" [tabPanel]="tabPanel">
   <a mat-tab-link [ngClass]="{'current' : router.url.includes('order-form-list')}"
      routerLink="/core/admin/order-form-list"> Bons de commandes </a>
   <a mat-tab-link [ngClass]="{'current' : router.url.includes('projects')}" routerLink="/core/admin/projects">
      Projets </a>
   <a mat-tab-link [ngClass]="{'current' : router.url.includes('domaines-list')}"
      routerLink="/core/admin/domaines-list">
      Domaines </a>
   <a mat-tab-link [ngClass]="{'current' : router.url.includes('netting-form-list')}" 
      [ngStyle]="!authenticationService.isServiceManager()? {}: {'display':'none'}"
      routerLink="/core/admin/netting-form-list"> Netting </a>
   <a mat-tab-link [ngClass]="{'current' : router.url.includes('applications')}" routerLink="/core/admin/applications">
      Applications </a>
   <a mat-tab-link [ngClass]="{'current' : router.url.includes('contacts')}" routerLink="/core/admin/contacts">
      Contacts </a>
   <a mat-tab-link [ngClass]="{'current' : router.url.includes('calendrier-form-list')}"
      routerLink="/core/admin/calendrier-form-list"> Calendrier </a>
   <a mat-tab-link [ngClass]="{'current' : router.url.includes('excluded-demand-list')}"
      routerLink="/core/admin/excluded-demand-list"> Exclusions JIRA </a>
   <a mat-tab-link [ngClass]="{'current' : router.url.includes('netting-schema-form-list')}"
      [ngStyle]="!authenticationService.isServiceManager()? {}: {'display':'none'}"
      routerLink="/core/admin/netting-schema-form-list"> Schéma Netting </a>
   <div class="flex-grow"></div>
   <a mat-tab-link [ngClass]="{'current' : router.url.includes('reglage')}"
      [ngStyle]="authenticationService.isUserAdmin()? {}: {'display':'none'}"
      [routerLink]="'/'+ROUTES.CORE_PATH+'/'+ROUTES.ADMIN_SETTINGS_PATH"> Options Avancées </a>
   <a (click)="openGuide('adminGuide')">
      <div class="material-icons help-icon">help_outline</div>
   </a>
</nav>

<mat-tab-nav-panel #tabPanel>
   <!-- Contenu des onglets ici -->
</mat-tab-nav-panel>