import { NodeCompatibleEventEmitter } from "rxjs/internal/observable/fromEvent";
import { IApplication } from "./application";
import { IDemand } from "./demand";

export interface IDsi{

  id?: string;
  name?: string;
  type?:DsiTypes;
  demands?: IDemand[];
  allDemands?: IDemand[];
  applications?: IApplication[];

  dueCount?: number;
  dueTotal?: number;
  dueConfirmed?: number;
  duePending?: number;
  dueCompleted?: number;
  dueCompleted2?: number;

  alreadyFactured?: number;
  alreadyFactured2?: number;

  dueStatusCount?: number[];
  dueStatusAmount?: number[];



  commandTotal?: number;
  engagedTotal?: number;
  availableTotal?: number;
  commandlessEngagedTotal?: number;

  lateFacturedTotal?: number;
  excessTotal?: number;

  toBeFactured?: number;
  factured?: number;

  previsionalPassed?: number;



  commandTotal2?: number;
  engagedTotal2?: number;
  availableTotal2?: number;
  commandlessEngagedTotal2?: number;

  lateFacturedTotal2?: number;
  excessTotal2?: number;

  toBeFactured2?: number;
  factured2?: number;

  previsionalPassed2?: number;
  check?:boolean;


}

export enum DsiTypes {
  DirectionMetier = "DirectionMetier",
  DSI = "DSI",
  DDSI = "DDSI",
  CDS = "CDS"
}

