import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ROUTES} from './shared/constants/routes';
import {AuthenticationService} from './shared/services/authentication.service';
import {ConfigService} from './shared/services/config.service';

@Component({
  selector: 'check-roles',
  template: 'Redirection vers la page d\'authentification en cours...',
})

export class CheckUserAuthenticationComponent implements OnInit {

  constructor(private authenticationService: AuthenticationService,
              private configService: ConfigService,
              private router: Router) {
  }

  ngOnInit(): void {

    if (this.configService.isAuthEnabled()) {
      this.authenticationService.isDoneLoading$
        .subscribe({
          next: () => {
            if (!this.authenticationService.hasValidAccessToken()) {
              this.authenticationService.refreshToken()
                .then(() => {
                  this.router.navigateByUrl(`/${ROUTES.CORE_PATH}`);
                })
                .catch(error => {
                  console.log(error);
                  this.authenticationService.logIn();
                });

            } else {
              this.router.navigateByUrl(`/${ROUTES.CORE_PATH}`);
            }
          },
          error: (err) => {
            console.log(err);
          },
          complete: () => {
          }
        });

    } else {
      this.router.navigateByUrl(`/${ROUTES.CORE_PATH}`);
    }
  }


}
