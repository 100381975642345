import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import {Component, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {IExcluded_demand} from 'src/app/shared/models/excluded-demand';
import {AuthenticationService} from 'src/app/shared/services/authentication.service';
import {DialogService} from 'src/app/shared/services/dialog.service';
import {ExcludedDemandService} from '../excluded-demand.service';

@Component({
  selector: 'app-excluded-demand-list',
  templateUrl: './excluded-demand-list.component.html',
  styleUrls: ['./excluded-demand-list.component.less']
})
export class ExcludedDemandListComponent implements OnInit {
  excluded_demand!: MatTableDataSource<IExcluded_demand>;
  excluded_demandSelected!: IExcluded_demand;

  @ViewChild(MatSort)
  sort!: MatSort;

  displayedColumns: string[] = ['numero', 'type', 'anneeBdc', 'actions'];

  constructor(
    private _exd: ExcludedDemandService,
    public authenticationService: AuthenticationService,
    private dialogService: DialogService,
    private snackbarService: SnackbarService,
  ) {
    this.excluded_demand = new MatTableDataSource();
  }

  openErrorSnackBar(message: string) {
    this.snackbarService.openSnackBar(message, 'error-snack-bar');
  }

  openSuccessSnackBar(message: string) {
    this.snackbarService.openSnackBar(message);
  }
 

  ngOnInit(): void {
    this.getExcludedDemandsService();
    this.excluded_demand.sort = this.sort;
  }

  ngAfterViewInit() {
    this.excluded_demand.sort = this.sort;
  }

  getExcludedDemandsService(): void {
    this._exd.getExcludedDemands().subscribe({
      next: (data) => {
        this.excluded_demand.data = data;
      },
      error: (err) => {
        console.log(err);
      },
      complete: () => {

      }
    });
  }

  selectedExcluded_demand(exd: IExcluded_demand): void {
    this.excluded_demandSelected = exd;
    this.excluded_demandSelected.isValid = false;
  }

  updateExdList(): void {
    this.getExcludedDemandsService();
  }

  onEdit(exd: IExcluded_demand): void {
    this.excluded_demandSelected = exd;
    this.excluded_demandSelected.isValid = true;
  }

  onDelete(exd: IExcluded_demand): void {
    this.dialogService
      .openConfirmDialog(exd.exd_number)
      .afterClosed()
      .subscribe({
        next: (res) => {
          if (res) {
            this._exd.deleteExcludedDemands(exd).subscribe((res) => {
              this.updateExdList();
              this.excluded_demandSelected = res;
              this.openSuccessSnackBar('Exclusion supprimée avec succès')
            });
          }
        },
        error: (err) => {
          console.log(err);
          this.openErrorSnackBar('L\'exclusion n\'a pas pu être supprimée');
        },
        complete: () => {
        },
      });
  }


  /**
   * Filter the list of excluded demands by year
   */
  excludedDemandFilterByYear(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();

    this.excluded_demand.filterPredicate = (data: IExcluded_demand, filter: string) => {
      const lowerCaseOfYear = data.of_year ? data.of_year.toString().toLowerCase() : '';
      return lowerCaseOfYear.includes(filter);
    };

    this.excluded_demand.filter = filterValue.trim().toLowerCase();
  }

  /**
   * Filter the list of excluded demands by number
   */
  excludedDemandFilterByNumber(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();

    this.excluded_demand.filterPredicate = (data: IExcluded_demand, filter: string) => {
      const lowerCaseExdNumber = data.exd_number ? data.exd_number.toString().toLowerCase() : '';
      return lowerCaseExdNumber.includes(filter);
    };

    this.excluded_demand.filter = filterValue.trim().toLowerCase();
  }
}
