<div class="button-top-row">
  <div class="year-button-container">
    <button mat-raised-button class="blue pack-btn" [style.background-color]="theme.getColor('Main Light')"
      (click)="swapYear(settingsYear-1)" [ngClass]="{'pack-btn-active':settingsYear-1==selectedPack}">
      <h2>
        Pack {{ settingsYear - 1 }}</h2>
    </button>
    <button mat-raised-button class="blue pack-btn" [style.background-color]="theme.getColor('Main Light')"
      (click)="swapYear(settingsYear)" [ngClass]="{'pack-btn-active':settingsYear==selectedPack}">
      <h2>
        Pack {{ settingsYear }}</h2>
    </button>
    <mat-form-field class="filter-input filter-btn ">
      <mat-label class="filter-input">Filtre Commande</mat-label>
      <input id="filterReference" class="filter-input" matInput (focusout)="resetThenLoad();" [(ngModel)]="refValue"
        #input>
    </mat-form-field>
  </div>

  <button mat-raised-button (click)="exportData()" class="indigo " id="exportButton"
    [style.background-color]="theme.getColor('Main Dark')">
    Exporter le résultat
  </button>

</div>

<mat-spinner *ngIf="spinner"></mat-spinner>

<div class="data" [ngClass]="{'spinner':spinner}">
  <div class="data-header">


    <div class="header-section header-month">
      <div class="radio-input">
        <h4>Mois d'arrêté:</h4>
        <mat-radio-group name="monthRadio" [disabled]="monthCurrent == monthLastClosed">
          <mat-radio-button (change)="monthSelected = $event.value; loadStatedAmounts()" [value]="1">Dernier clos
          </mat-radio-button>
          <mat-radio-button (change)="monthSelected = $event.value; loadStatedAmounts()" [value]="2" checked="true">En
            cours
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div>
        <h1 *ngIf="monthSelected == 1">Mois d'arrêté:<br /> <b>{{ months[monthLastClosed - 1] }} {{ yearLastClosed
            }}</b>
        </h1>
        <h1 *ngIf="monthSelected == 2">Mois d'arrêté: <br /><b>{{ months[monthCurrent - 1] }} {{ yearCurrent }}</b></h1>
      </div>
    </div>
    <div class="header-section">
      <button mat-raised-button class="indigo filter-btn" [style.background-color]="theme.getColor('Main Dark')"
        (click)="swapMode(1)" [ngClass]="{'filter-btn-active':selectedMode==1}">
        <h2>Engagé > Commandé</h2>
      </button>
      <button mat-raised-button class="indigo filter-btn" [style.background-color]="theme.getColor('Main Dark')"
        (click)="swapMode(2)" [ngClass]="{'filter-btn-active':selectedMode==2}">
        <h2>Constaté > Commandé</h2>
      </button>
    </div>
    <div class="header-section header-table">
      <table class="header-tab">
        <caption class="displayNone">recapitulatif</caption>
        <tr>
          <th>
            <h2><b>Pack {{ selectedPack }}</b></h2>
          </th>
          <th [style.color]="theme.getColor('List Alt Dark')"
            [style.background-color]="theme.getColor('List Alt Light')" class="htab htab-h">Engagé
          </th>
          <th [style.color]="theme.getColor('List Alt Dark')"
            [style.background-color]="theme.getColor('List Alt Light')" class="htab htab-h">Constaté
          </th>
        </tr>
        <tr>
          <td [style.color]="theme.getColor('List Alt Dark')"
            [style.background-color]="theme.getColor('List Alt Light')" class="htab htab-r">Montant total du CdS avec
            et sans commande
          </td>
          <td class="htab htab-amount">{{ totalEngaged + totalEngagedCommandless | number:'1.1-2':"fr-FR" }} €</td>
          <td class="htab htab-amount">{{ totalStated + totalStatedCommandless | number:'1.1-2':"fr-FR" }} €</td>
        </tr>
        <tr>
          <td [style.color]="theme.getColor('List Alt Dark')"
            [style.background-color]="theme.getColor('List Alt Light')" class="htab htab-r">Montant total avec
            commande uniquement
          </td>
          <td class="htab htab-amount">{{ totalEngaged | number:'1.1-2':"fr-FR" }} €</td>
          <td class="htab htab-amount">{{ totalStated | number:'1.1-2':"fr-FR" }} €</td>
        </tr>
        <tr>
          <td [style.color]="theme.getColor('List Alt Dark')"
            [style.background-color]="theme.getColor('List Alt Light')" class="htab htab-r">Montant total sans
            commande / inconnue
          </td>
          <td class="htab htab-amount">{{ totalEngagedCommandless | number:'1.1-2':"fr-FR" }} €</td>
          <td class="htab htab-amount">{{ totalStatedCommandless | number:'1.1-2':"fr-FR" }} €</td>
        </tr>
      </table>
    </div>

  </div>
  <div class="data-table">
    <mat-table [dataSource]="dataTable" matSort class="light table" id="orderTableDashboard">

      <ng-container matColumnDef="command" sticky>
        <th class="cell cell-head" [style.color]="theme.getColor('List Dark')"
          [style.background-color]="theme.getColor('List Light')" mat-header-cell *matHeaderCellDef><b>Commandes</b>
        </th>
        <td class="cell" mat-cell *matCellDef="let element">{{ element[0] }}</td>
      </ng-container>

      <ng-container matColumnDef="label" sticky>
        <th class="cell cell-head cell-large" [style.color]="theme.getColor('List Dark')"
          [style.background-color]="theme.getColor('List Light')" mat-header-cell *matHeaderCellDef><b>Libellé
            de commande</b></th>
        <td class="cell cell-large" mat-cell *matCellDef="let element">{{ element[1] }}</td>
      </ng-container>

      <ng-container matColumnDef="year">
        <th class="cell cell-head" [style.color]="theme.getColor('List Dark')"
          [style.background-color]="theme.getColor('List Light')" mat-header-cell *matHeaderCellDef>
          <b>Année de Commande</b>
        </th>
        <td class="cell" mat-cell *matCellDef="let element"><b>{{ element[40] }}</b></td>
      </ng-container>

      <ng-container matColumnDef="dsi">
        <th class="cell cell-head" [style.color]="theme.getColor('List Dark')"
          [style.background-color]="theme.getColor('List Light')" mat-header-cell *matHeaderCellDef>
          <b>DSI</b>
        </th>
        <td class="cell" mat-cell *matCellDef="let element">{{ element[2] }}</td>
      </ng-container>

      <ng-container matColumnDef="comAmount">
        <th class="cell cell-head" [style.color]="theme.getColor('List Dark')"
          [style.background-color]="theme.getColor('List Light')" mat-header-cell *matHeaderCellDef><b>Montant
            de la Commande</b></th>
        <td class="cell" mat-cell *matCellDef="let element">{{ element[3] | number:'1.1-2':"fr-FR" }} €</td>
      </ng-container>

      <ng-container matColumnDef="engAmount">
        <th class="cell cell-head" [style.color]="theme.getColor('List Dark')"
          [style.background-color]="theme.getColor('List Light')" mat-header-cell *matHeaderCellDef><b>Engagé
            (DT avec GO de réalisation)</b></th>
        <td class="cell" mat-cell *matCellDef="let element">{{ element[4] | number:'1.1-2':"fr-FR" }} €</td>
      </ng-container>

      <ng-container matColumnDef="toEngAmount">
        <th class="cell cell-head" [style.color]="theme.getColor('List Dark')"
          [style.background-color]="theme.getColor('List Light')" mat-header-cell *matHeaderCellDef><b>A
            engager (DT sans GO de réalisation)</b></th>
        <td class="cell" mat-cell *matCellDef="let element">{{ element[5] | number:'1.1-2':"fr-FR" }} €</td>
      </ng-container>

      <ng-container matColumnDef="comEngRatio">
        <th class="cell cell-head" [style.color]="theme.getColor('List Dark')"
          [style.background-color]="theme.getColor('List Light')" mat-header-cell *matHeaderCellDef><b>Montant
            total des DT/Commande</b></th>
        <td class="cell" mat-cell *matCellDef="let element">
          <span *ngIf="element[39] !== 'TOTAL'">
            <span *ngIf="element[3] == 0">n/a</span>
            <span *ngIf="element[3] != 0">{{ element[6] * 100 | number:'1.1-2':"fr-FR" }} %</span>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="constAmount">
        <th class="cell cell-head" [style.color]="theme.getColor('List Dark')"
          [style.background-color]="theme.getColor('List Light')" mat-header-cell *matHeaderCellDef><b>Facturé
            à mois d'arrêté</b></th>
        <td class="cell" mat-cell *matCellDef="let element">{{ element[7] | number:'1.1-2':"fr-FR" }} €</td>
      </ng-container>

      <ng-container matColumnDef="comConstRatio">
        <th class="cell cell-head" [style.color]="theme.getColor('List Dark')"
          [style.background-color]="theme.getColor('List Light')" mat-header-cell *matHeaderCellDef><b>Facturé
            à date/commande</b></th>
        <td class="cell" mat-cell *matCellDef="let element">
          <span *ngIf="element[39] !== 'TOTAL'">
            <span *ngIf="element[3] == 0">n/a</span>
            <span *ngIf="element[3] != 0">{{ element[8] * 100 | number:'1.1-2':"fr-FR" }} %</span>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="availAmount">
        <th class="cell cell-head" [style.color]="theme.getColor('List Dark')"
          [style.background-color]="theme.getColor('List Light')" mat-header-cell *matHeaderCellDef><b>Montant
            disponible</b></th>
        <td class="cell" mat-cell *matCellDef="let element">{{ element[9] | number:'1.1-2':"fr-FR" }} €</td>
      </ng-container>

      <ng-container matColumnDef="overAmount">
        <th class="cell cell-head" [style.color]="theme.getColor('List Dark')"
          [style.background-color]="theme.getColor('List Light')" mat-header-cell *matHeaderCellDef><b>Montant
            dépassement</b></th>
        <td class="cell" mat-cell *matCellDef="let element">{{ element[10] | number:'1.1-2':"fr-FR" }} €</td>
      </ng-container>

      <ng-container matColumnDef="factured1">
        <th class="cell cell-head" [style.color]="theme.getColor('List Dark')"
          [style.background-color]="theme.getColor('List Light')" mat-header-cell *matHeaderCellDef><b>A
            facturer {{ selectedPack + 1 }}</b></th>
        <td class="cell" mat-cell *matCellDef="let element">{{ element[11] | number:'1.1-2':"fr-FR" }} €</td>
      </ng-container>

      <ng-container matColumnDef="total1">
        <th class="cell cell-head" [style.color]="theme.getColor('List Dark')"
          [style.background-color]="theme.getColor('List Light')" mat-header-cell *matHeaderCellDef>
          <b>Total {{ selectedPack }}</b>
        </th>
        <td class="cell" mat-cell *matCellDef="let element"><b>{{ element[12] | number:'1.1-2':"fr-FR" }} €</b></td>
      </ng-container>

      <ng-container matColumnDef="total2">
        <th class="cell cell-head" [style.color]="theme.getColor('List Dark')"
          [style.background-color]="theme.getColor('List Light')" mat-header-cell *matHeaderCellDef>
          <b>Total {{ selectedPack + 1 }}</b>
        </th>
        <td class="cell" mat-cell *matCellDef="let element"><b>{{ element[13] | number:'1.1-2':"fr-FR" }} €</b></td>
      </ng-container>

      <ng-container matColumnDef="cumul">
        <th class="cell cell-head" [style.color]="theme.getColor('List Dark')"
          [style.background-color]="theme.getColor('List Light')" mat-header-cell *matHeaderCellDef><b>Cumul</b>
        </th>
        <td class="cell" mat-cell *matCellDef="let element"><b>{{ element[14] | number:'1.1-2':"fr-FR" }} €</b></td>
      </ng-container>

      <ng-container *ngFor="let i of [].constructor(24); let index = index" matColumnDef="{{'m'+(index+1)}}">
        <th class="cell cell-head" [style.color]="theme.getColor('List Dark')"
          [style.background-color]="theme.getColor('List Light')" mat-header-cell *matHeaderCellDef>
          <b>{{ months[index % 12] }} {{ yearFromIndex(index) }}</b>
        </th>
        <td class="cell" mat-cell *matCellDef="let element">{{ element[15 + index] | number:'1.1-2':"fr-FR" }} €</td>
      </ng-container>


      <tr mat-header-row class="head-row" *matHeaderRowDef="headers; sticky: true;"></tr>
      <tr mat-row class="row"
        [ngClass]="{'total':row[0] === '---', 'red':row[0] !== '---' && isRed(row), 'hide':!isActive(row)}"
        *matRowDef="let row;  columns: headers;"></tr>
    </mat-table>
  </div>
</div>