import {Component, ElementRef, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {ThemeService} from 'src/app/shared/services/theme.service';
import {EcheancesService} from '../services/echeances.service';


@Component({
  selector: 'comment-prompt',
  templateUrl: './comment-prompt.component.html',
  styleUrls: ['./comment-prompt.component.less']
})
export class CommentPromptComponent {

  public comment!: string;

  constructor(public router: Router, public theme: ThemeService) {
  }

}
