<form class="formExcludedDemand" [formGroup]="formGroup">
  <mat-form-field appearance="outline" color="accent" class="input-space">
    <mat-label>Numéro de Demande</mat-label>
    <input matInput required type="text" formControlName="exd_number" [readonly]="asEdited">
    <mat-hint>Obligatoire</mat-hint>
  </mat-form-field>

  <mat-form-field appearance="outline" color="accent" class="input-space">
    <mat-label>Type d’exclusion</mat-label>
    <mat-select required formControlName="exd_type" [disabled]="asEdited">
      <mat-option *ngFor="let exd_type of Object.values(ExcludedDemandsType)" [value]="exd_type">
        {{ exd_type }}
      </mat-option>
    </mat-select>
    <mat-hint>Obligatoire</mat-hint>
  </mat-form-field>

  <button mat-raised-button class="btn_created" [disabled]="!formGroup.valid" *ngIf="!asEdited && !asValid"
          color="primary" (click)="onSubmit()">Créer
  </button>
  <button mat-raised-button class="btn_annuler" color="accent" type="button" (click)="clear()">Annuler</button>
  <button mat-raised-button class="btn_valid" [disabled]="!formGroup.valid" *ngIf="asValid" (click)="onUpdate()">
    Valider
  </button>
  <button mat-raised-button class="btn_edit" *ngIf="asEdited" (click)="onEdit()">Editer</button>
  <button mat-raised-button class="btn_supp" [disabled]="!formGroup.valid" *ngIf="asEdited" color="warn"
          (click)="onDelete()">Supprimer
  </button>

</form>

