import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {IUser} from 'src/app/shared/models/user';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {UtilsService} from 'src/app/shared/services/utils.service';

@Injectable({
    providedIn: 'root'
})

export class UsersService {

    options = {
        headers: new HttpHeaders({'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'})
    };

    constructor(private http: HttpClient,
                private utilsService: UtilsService) {
    }


    getUserByIdentifier(identifier: string): Observable<IUser> {
        return this.http.get<IUser>(environment.backendApiUrl + "/user/" + identifier, this.options).pipe(catchError(this.utilsService.handleError));
    }

    getAllContacts(): Observable<IUser[]> {
        return this.http.get<IUser[]>(environment.backendApiUrl + "/users", this.options).pipe(catchError(this.utilsService.handleError));
    }

    postContact(p: IUser): Observable<IUser> {
        return this.http.post<IUser>(environment.backendApiUrl + "/users", p, this.options).pipe(catchError(this.utilsService.handleError));
    }

    putContact(p: IUser): Observable<IUser[]> {
        return this.http.put<IUser[]>(environment.backendApiUrl + "/users", p, this.options).pipe(catchError(this.utilsService.handleError));
    }

    deleteContact(p: IUser): Observable<any> {
        return this.http.delete<IUser[]>(environment.backendApiUrl + "/users/" + p.id, this.options).pipe(catchError(this.utilsService.handleError));
    }

    getAllNames(): string[] {
        const result: string[] = [];
        //For each user: add their full name to result
        this.getAllContacts().subscribe(element => {
            element.forEach(row => {
                result.push(row.firstName + " " + row.lastName + " (" + row.identifier + ")");
            });
        });
        return result;
    }

    exportData(): Observable<any> {
        return this.http.get(environment.backendApiUrl + "/user/export/", {responseType: 'blob'}).pipe(catchError(this.utilsService.handleError));
    }

}
