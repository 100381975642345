
  <form [formGroup]="formGroup" (ngSubmit)="onSubmit()"  class="formProject">

    <mat-form-field appearance="outline" color="accent">
      <mat-label>Nom</mat-label>
      <input matInput required formControlName="name" type="text" [readonly]="asEdited">
      <mat-hint>Obligatoire</mat-hint>
    </mat-form-field>

    <mat-form-field appearance="outline" color="accent" >
      <mat-label>Compte Netting</mat-label>
      <mat-select formControlName="nettingAccounts" multiple [disabled]="asEdited">
        <mat-option *ngFor="let name of nettingsNames" [value]="name">
          {{ name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" color="accent" >
      <mat-label>Responsable</mat-label>
      <input matInput formControlName="user" type="text" [matAutocomplete]="autoUsers" (ngModelChange)="filter($event)"
        [readonly]="asEdited">
      <mat-autocomplete #autoUsers="matAutocomplete">
        <mat-option *ngFor="let user of filteredOptions" [value]="user">
          {{ user }}
        </mat-option>
      </mat-autocomplete>
      <mat-hint>Obligatoire</mat-hint>
    </mat-form-field>

    <mat-form-field appearance="outline" color="accent" >
      <mat-label>Clé Jira</mat-label>
      <input #KeyJira matInput formControlName="key_jira" type="text" maxlength="20" [readonly]="asEdited">
      <mat-hint align="end">{{KeyJira.value.length}} / 20</mat-hint>
    </mat-form-field>

    <mat-checkbox  [checked]="formGroup.value.beb"
      (change)="onchangeBeb($event.checked)" [disabled]="asEdited">Projet géré de bout en bout
    </mat-checkbox>


    <button mat-raised-button class="btn_created" color="primary" type="submit" [disabled]="!formGroup.valid"
      *ngIf="!asEdited && !btnStatus">Créer
    </button>
    <button mat-raised-button class="btn_annuler" color="accent" type="button" (click)="clear()">Annuler</button>
    <button mat-raised-button class="btn_valid" type="submit" [disabled]="!formGroup.valid"
      *ngIf="!asEdited && btnStatus">Valider
    </button>
    <button mat-raised-button class="btn_edit" *ngIf="asEdited" (click)="is_edit(asEdited)">Editer</button>
    <button mat-raised-button class="btn_supp" color="warn" *ngIf="asEdited"
      (click)="confirmDelete()">Supprimer</button>

  </form>
