export const ROUTES = {
  CHECK_AUTH_PATH: 'checkauth',
  CORE_PATH: 'core',
  LOGIN_TEST: 'login/sncf/manage',
  NO_AUTH_ACCESS:'checknoauth',
  UNAUTHORIZED_PATH: '401',
  NOT_FOUND_PATH: '404',
  EMPTY_PATH: '',
  ELSE_PATH: '**',
  ADMIN_PATH:'admin',
  ADMIN_ORDER_FOFRM_LIST_PATH: 'admin/order-form-list',
  ADMIN_PROJECTS_PATH: 'admin/projects',
  ADMIN_DOMAINES_PATH: 'admin/domaines-list',
  ADMIN_APPS_PATH: 'admin/applications',
  ADMIN_CONTACTS_PATH: 'admin/contacts',
  ADMIN_CALENDRIER_PATH: 'admin/calendrier-form-list',
  ADMIN_EXCLUDED_DEMAND_PATH: 'admin/excluded-demand-list',
  ADMIN_NETTING_PATH: 'admin/netting-form-list',
  ADMIN_IMPORT_PATH: 'admin/import',
  ADMIN_NETTING_SCHEMA_PATH: 'admin/netting-schema-form-list',
  ADMIN_SETTINGS_PATH: 'admin/reglage',
  DATA_PACK_GEN_PATH: 'data/pack-generation',
  SM_PATH: 'service-management',
  SM_PROJECT_PATH: 'service-management/projects',
  DASHBOARD_PATH:'dashboard',
  DASHBOARD_MENU_PATH:'dashboard/menu',
  DASHBOARD_ALERT_PATH:'dashboard/alerts',
  DASHBOARD_ORDERS_PATH:'dashboard/orders',
  DASHBOARD_APPS_PATH:'dashboard/apps',
  DASHBOARD_NETTINGS_PATH:'facturation/facturation-vue_netting',
  DASHBOARD_POWER_BI_PATH:'dashboard/powerbi',
  FACTURATION_PATH: 'facturation',
  FACTURATION_LIST_PATH: 'facturation/facturation-list',
  FACTURATION_IMPORT_PATH: 'facturation/facturation-import',
  FACTURATION_NETTING_PATH: 'facturation/facturation-netting',
  FACTURATION_VUE_NETTING_PATH: 'facturation/facturation-vue_netting',
  DATA_PATH: 'data',
  HELP_PATH: 'guide',
};
