<div class="netting-regularisation">
  <form class="form">
    <mat-form-field appearance="outline" class="column_top">
      <mat-label>Année du PACK</mat-label>
      <mat-select [(ngModel)]="packYear" (selectionChange)="changePackYear($event.value)" name="packYear"
        [disabled]="isFormDisabled">
        <mat-option *ngFor="let year of packYears" [value]="year">{{ year }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Mois facturation</mat-label>
      <mat-select [(ngModel)]="monthSelected" (selectionChange)="changeMonth($event.value)" name="month"
        [disabled]="isFormDisabled">
        <mat-option *ngFor="let month of months" [value]="month">{{ month }}</mat-option>
      </mat-select>
    </mat-form-field>
    
    <mat-form-field appearance="outline">
      <mat-label>Année facturation</mat-label>
      <mat-select [(ngModel)]="yearSelected" (selectionChange)="changeFactYear($event.value)" name="year"
        [disabled]="isFormDisabled">
        <mat-option *ngFor="let factYear of facturationYears" [value]="factYear">{{ factYear }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-radio-group [(ngModel)]="vueSelected" (change)="changeVue($event.value)" class="column_top" name="radio"
      [disabled]="isFormDisabled">
      <mat-radio-button class="radio" *ngFor="let vue of vues" [value]="vue" color="primary">
        {{ vue }}
      </mat-radio-button>
    </mat-radio-group>

    <button mat-raised-button class="indigo input column_top" [style.background-color]="theme.getColor('Main Dark')"
      type="submit" (click)="suivantService()" [disabled]="isFormDisabled"> Suivant
    </button>
  </form>
  <hr class="hr">
</div>