import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {IConfiguration} from '../models/configuration.model';
import {ApplicationSettingsService} from 'src/app/core/application-settings/application-settings.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private config!: IConfiguration;

  private authorizedRoles!: string[];

  constructor(private http: HttpClient,
              private applicationSettingsService: ApplicationSettingsService) {
  }

  /**
   * Charger la configuration à chaud
   * @param url
   * @returns
   */
  public loadConfig(url: string): Promise<any> {

    // on bloque le chargement de l'application en attendant le chargement de la configuration
    return new Promise((resolve: any) => {
      this.http.get(url)
        .subscribe({
          next: (config: any) => {
            // charger la configuration à chaud
            this.setConfiguration(config);
            this.applicationSettingsService.readAndSaveApplicationSettings(resolve);
          },
          error: (error: any) => {
            console.error('Erreur lors du chargement de la configuration', error);
          },
          complete: () => {
          }
        });
    });
  }

  public getConfiguration(): IConfiguration {
    return this.config;
  }

  public setConfiguration(config: IConfiguration) {
    this.config = config;
  }

  public isAuthEnabled(): boolean {
    return this.getConfiguration().authEnable == "true";
  }

  public getEnvironnement(): string {
    return this.getConfiguration().environment;
  }

  public getAuthorizedRoles() {
    return this.authorizedRoles || this.getConfiguration().userRole.split(' ')
  }
}
