import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DomSanitizer} from '@angular/platform-browser';
import {DirectoryService} from '../core/application-settings/repository-manager/directory.service';

@Component({
  selector: 'app-guide',
  templateUrl: './guide.component.html',
  styleUrls: ['./guide.component.less']

})
export class GuideComponent {

  innerHtml = "...";
  done = false;
  html: any;

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: string,
              public ds: DirectoryService, private sanitizer: DomSanitizer,) {

    this.ds.readFile(dialogData).subscribe({
      next: data => {
        this.innerHtml = data;
        this.html = this.sanitizer.bypassSecurityTrustHtml(this.innerHtml);
      },
      error: err => {
        console.log(err);
        this.done = true;
      },
      complete: () => {
      }
    })
  }
}
