import {Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {GuideComponent} from 'src/app/guide/guide.component';
import {ROUTES} from 'src/app/shared/constants/routes';
import {AuthenticationService} from 'src/app/shared/services/authentication.service';
import {ConfigService} from 'src/app/shared/services/config.service';
import {ThemeService} from 'src/app/shared/services/theme.service';


@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.less']

})

export class AdministrationComponent {

  constructor(public router: Router,
              public dialog: MatDialog,
              public config: ConfigService,
              public authenticationService: AuthenticationService,
              public theme: ThemeService
  ) {

  }

  get ROUTES() {
    return ROUTES;
  }

  openGuide(s: string) {
    this.dialog.open(GuideComponent, {
      data: s
    })
  }


}
