import {Component, Input, OnInit} from '@angular/core';
import {Constants} from 'src/app/shared/constants/Constants';
import {EcheanceStatus} from 'src/app/shared/models/echeance-status';
import {IApplication} from 'src/app/shared/models/application';
import {registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {ApplicationSettingsService} from '../../application-settings/application-settings.service';
import {UserProfile} from 'src/app/shared/models/user-profile';
import {AuthenticationService} from 'src/app/shared/services/authentication.service';
import {ThemeService} from 'src/app/shared/services/theme.service';
import {AppDetails} from 'src/app/shared/models/dsiDetails';
import {DomainesService} from '../../domaines/domaines.service';

registerLocaleData(localeFr);

@Component({
  selector: 'app-application-management-list',
  templateUrl: './application-management-list.component.html',
  styleUrls: ['./application-management-list.component.less']
})
export class ApplicationManagementListComponent implements OnInit {


  @Input()
  selectedDsi = ""; // decorate the property with @Input()

  @Input()
  public userApplications: IApplication[] = [];

  value = 66;

  year: number;
  month: number;

  apps!: AppDetails[];
  isLoading = true;

  get Constants() {
    return Constants;
  }

  get EcheanceStatus() {
    return EcheanceStatus;
  }

  constructor(public _ds: DomainesService, public theme: ThemeService, public authenticationService: AuthenticationService, public _as: ApplicationSettingsService) {
    this.year = _as.getApplicationSettings().Pack_current_year;
    this.month = _as.getApplicationSettings().Pack_current_month;
  }

  ngOnInit() {
    this._ds.getAllAppsDetails().subscribe({
      next: data => this.apps = data,
      error: err => console.error(err),
      complete: () => this.isLoading = false
    });
  }


  enableCDSgraph() {
    return !(this.authenticationService.getUser().profile == UserProfile.CP_SNCF
      || this.authenticationService.getUser().profile == UserProfile.GUEST_SNCF
      || this.authenticationService.getUser().profile == UserProfile.SERVICE_MANAGER);

  }


  hasAlerts(element: AppDetails): boolean {
    return (
      (element.prev_retard_facturation != null && element.prev_retard_facturation != 0)
      || (element.curr_retard_facturation != null && element.curr_retard_facturation != 0)
    );
  }

}
