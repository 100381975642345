import {Component, OnInit} from '@angular/core';
import {ConfigService} from "./shared/services/config.service";
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {

  constructor(private titleService: Title,
              public configService: ConfigService,
  ) {
  }

  ngOnInit() {
    const title = this.configService.getConfiguration().CDS_Name;
    this.titleService.setTitle(title);
  }

}

