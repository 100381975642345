<ng-container>
  <app-header-bar></app-header-bar>
    <div class="global-template">
        <div class="unautho-container">
          <div class="unautho-message">
            <mat-icon>not_interested</mat-icon> Vous n'êtes pas habilité à utiliser cet outil.
          </div>
          <div class="unautho-card-container">
            <div class="unautho-card-cds">
              <mat-card class="example-card">
                <mat-card-header>
                  <mat-card-title>Acteur CDS</mat-card-title>
                </mat-card-header>
                <img mat-card-image src="https://www.sogeti.com/Static/img/sogeti-logo.svg" alt="Sogeti logo">
                <mat-card-content>
                  <p>
                    Merci de contacter votre PMO
                  </p>
                </mat-card-content>
              </mat-card>
            </div>
            <div class="unautho-card-sncf">
              <mat-card class="example-card">
                <mat-card-header>
                  <mat-card-title>Acteur SNCF</mat-card-title>
                </mat-card-header>
                <img mat-card-image src="https://www.sncf.com/themes/contrib/sncf_theme/images/logo-sncf.svg?v=1697327509" alt="SNCF logo">
                <mat-card-content>
                  <p>
                    Merci de contacter votre service manager
                  </p>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
        </div>
      </div>
  <app-footer-bar></app-footer-bar>
</ng-container>