import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ROUTES} from './shared/constants/routes';
import {AuthenticationService} from './shared/services/authentication.service';
import {ConfigService} from './shared/services/config.service';

@Component({
  selector: 'check-auth',
  template: 'Redirection en cours vers packgemini...',
})

/**
 * Ce composant est utilisé pour traiter l'appel retour de la FID après l'authentification de l'utilisateur pour vérifier son groupe (roles)
 */
export class CheckUserRolesComponent implements OnInit {

  constructor(private authenticationService: AuthenticationService,
              private configService: ConfigService,
              private router: Router) {
  }

  ngOnInit(): void {

    if (this.configService.isAuthEnabled()) {
      this.authenticationService.isDoneLoading$
        .subscribe({
          next: () => {
            if (!this.authenticationService.hasValidAccessToken()) {
              this.authenticationService.refreshToken()
                .then(() => {
                  this.authenticationService.checkUserAuthorization(this.authenticationService.getAccessToken());

                })
                .catch(error => {
                  console.log(error);
                  this.authenticationService.logIn();
                })
            } else {
              this.authenticationService.checkUserAuthorization(this.authenticationService.getAccessToken());
            }
          },
          error: (err) => {
            console.log(err);
          },
          complete: () => {
          }
        });
    } else {
      this.router.navigateByUrl(`/${ROUTES.CORE_PATH}`);
    }
  }
}
