import {Component} from '@angular/core';
import * as fileSaver from 'file-saver';
import {ThemeService} from 'src/app/shared/services/theme.service';
import {ApplicationSettingsService} from '../../application-settings/application-settings.service';
import {JiraImportService} from '../jira-import/services/jira-import.service';

@Component({
  selector: 'app-pack-export',
  templateUrl: './pack-export.component.html',
})
export class PackExportComponent {

  spinner = false;
  isDues = false;

  constructor(private jiraImportService: JiraImportService, public theme: ThemeService, private _as: ApplicationSettingsService) {
  }

  calculDues(): void {
    this.spinner = true;

    this.jiraImportService.getCalculDues().subscribe({
      next: data => {
        this.isDues = true;
      },
      error: err => {
        console.log(err);
        this.isDues = false;
      },
      complete: () => {
        this.spinner = false;
      }
    });
  }

  downloadFile(): void {
    this.spinner = true;
    this.jiraImportService.getPack().subscribe({
      next: data => {
        const blob: any = new Blob(["\ufeff", data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        window.URL.createObjectURL(blob);
        fileSaver.saveAs(blob, "Données Collectées " + this._as.applicationSettings.Pack_current_year + " - " + this._as.applicationSettings.CDS_name + " - " + new Date().toLocaleDateString().replace('_', '-') + " " + new Date().toLocaleTimeString().replace('_', '-'));
      },
      error: err => {
        console.log(err);
      },
      complete: () => {
        this.spinner = false;
      }
    });
  }

}
