export enum UserProfile{



    ADMIN= "Administrateur",

    CP_CDS= "Acteur CDS",

    CP_SNCF= "Acteur SNCF",

    SERVICE_MANAGER= "Service Manager",

    MANAGER_CDS= "Manager CDS",

    GUEST_CDS = "Invité CDS",

    GUEST_SNCF = "Invité SNCF",
}
