import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {AuthenticationService} from '../services/authentication.service';
import {environment} from 'src/environments/environment';
import {ConfigService} from '../services/config.service';

@Injectable()
export class DefaultHttpInterceptor implements HttpInterceptor {


    constructor(
        private authenticationService: AuthenticationService,
        private configService: ConfigService
    ) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let request = req.clone();

        // ######### ajout de l'accessToken aux requêtes vers allant vers le backend si celui-ci n'a pas expiré, sinon refaire un login
        if (req.url.includes(environment.backendApiUrl) && !req.url.includes('/settings/')) {

            const userIdentifier = this.authenticationService.getUser() ? this.authenticationService.getUser().identifier as string : '';
            request = request.clone({
                setHeaders: {
                    userIdentifier: userIdentifier
                }
            });

            if (this.configService.isAuthEnabled() && this.authenticationService.hasValidIdToken()) {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${this.authenticationService.getIdentityClaims()['id_token']}`
                    }
                });

            } else if (this.configService.isAuthEnabled()) {
                this.authenticationService.refreshToken()
                    .then(() => {
                        request = request.clone({
                            setHeaders: {
                                Authorization: `Bearer ${this.authenticationService.getIdentityClaims()['id_token']}`
                            }
                        });
                    })
                    .catch(error => {
                        console.error('erreur lors du rafraichissement de idToken', error);
                        this.authenticationService.logIn();
                    })
            }

        }
        // ############

        // ######### ajout de client_id et client_secret à l'appel au Refresh de l'accessToken
        if (request.url.includes('access_token') && this.configService.isAuthEnabled()) {
            const client_id = request.body.updates.find((update: any) => update.param == 'client_id').value;
            const client_secret = request.body.updates.find((update: any) => update.param == 'client_secret').value;
            if (client_id && client_secret) {
                const authorization = btoa(client_id + ':' + client_secret);
                request = req.clone({
                    setHeaders: {
                        'Authorization': 'Basic ' + authorization
                    }
                });
            }
            request.body.updates = req.body.updates.filter((update: any) => !['client_id', 'client_secret'].includes(update.param));
            // ############
        }

        return next.handle(request)
            .pipe(tap(() => {
                },
                (err: any) => {
                    console.error(err);
                }));
    }

}
