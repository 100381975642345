import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {IDemand} from '../models/demand';
import {INetting} from '../models/netting-account';
import {INettingPack} from '../models/netting-pack';
import {UtilsService} from '../services/utils.service';

@Injectable({
    providedIn: 'root'
})
export class DialogFormNettingService {

    options = {
        headers: new HttpHeaders({'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'})
    };

    constructor(private http: HttpClient,
                private utilsService: UtilsService) {
    }

    createNettingPack(np: INettingPack): Observable<INettingPack> {
        return this.http.post<INettingPack>(environment.backendApiUrl + "/nettingPack", np, this.options).pipe(catchError(this.utilsService.handleError));
    }

    getNettingAccountsFromDemand(d: IDemand): Observable<INetting[]> {
        return this.http.get<INetting[]>(environment.backendApiUrl + "/nettings/project/" + d.demandNumber, this.options)
            .pipe(catchError(this.utilsService.handleError));
    }

    getNettingFromDemand(d: IDemand): Observable<INetting> {
        return this.http.get<INetting>(environment.backendApiUrl + "/nettingDemand/" + d.demandNumber, this.options)
            .pipe(catchError(this.utilsService.handleError));
    }

    getNettingPacks(): Observable<INettingPack[]> {
        return this.http.get<INettingPack[]>(environment.backendApiUrl + "/nettingPack", this.options)
            .pipe(catchError(this.utilsService.handleError));
    }

}
