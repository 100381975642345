import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SimplePromptComponent} from '../simple-prompt/simple-prompt.component';

@Component({
    selector: 'app-error-prompt',
    templateUrl: './error-prompt.component.html',
    styleUrls: ['./error-prompt.component.less']
})
export class ErrorPromptComponent {

    public message = '';

    constructor(public dialogRef: MatDialogRef<SimplePromptComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.message = data.message;
    }

    onNoClick(): void {
        this.dialogRef.close();
        this.message = '';
    }

}
