import {Component, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {IDsi} from 'src/app/shared/models/dsi';
import {AuthenticationService} from 'src/app/shared/services/authentication.service';
import {DialogService} from 'src/app/shared/services/dialog.service';
import {DomainesService} from '../domaines/domaines.service';
import * as fileSaver from 'file-saver';
import {ApplicationSettingsService} from '../application-settings/application-settings.service';
import {ThemeService} from 'src/app/shared/services/theme.service';
import { SnackbarService } from '../../shared/services/snackbar.service';

@Component({
  selector: 'app-domaines-list',
  templateUrl: './domaines-list.component.html',
  styleUrls: ['./domaines-list.component.less']
})
export class DomainesListComponent {

  dsi!: MatTableDataSource<IDsi>
  dsiSelected!: IDsi;

  @ViewChild(MatSort)
  sort!: MatSort;

  displayedColumns: string[] = ["name", "type", "actions"];

  constructor(public domaineService: DomainesService,
              public authenticationService: AuthenticationService,
              private dialogService: DialogService,
              public theme: ThemeService,
              private applicationSettingsService: ApplicationSettingsService,
              private snackbarService: SnackbarService ,) {
    this.dsi = new MatTableDataSource();
    this.loadDsis();
  }

  openErrorSnackBar(message: string) {
    this.snackbarService.openSnackBar(message, 'error-snack-bar');
  }

  openSuccessSnackBar(message: string) {
    this.snackbarService.openSnackBar(message);
  }
 


  loadDsis() {
    this.domaineService.getDsis().subscribe(value => this.dsi.data = value);
  }

  selectedDsi(dsi: IDsi): void {
    this.dsiSelected = dsi;
    this.dsiSelected.check = true;
  }

  selectedDsiPencel(dsi: IDsi): void {
    this.dsiSelected = dsi;
    this.dsiSelected.check = false;
  }

  initDsi(): void {
    this.dsiSelected = {

      id: '',
      name: '',
      check: false
    }
  }

  ngAfterViewInit() {
    this.dsi.sort = this.sort;
  }

  updateDsiList(): void {
    this.loadDsis();
  }

  removeDsi(row: IDsi): void {
    this.dialogService.openConfirmDialog(row.name)
      .afterClosed().subscribe({
      next: res => {
        if (res) {
          this.domaineService.deleteDsi(row).subscribe({
            next: data => {
              this.loadDsis();
              this.initDsi()
              this.openSuccessSnackBar("Le domaine a été supprimé avec succès");
            },
            error: err => {
              console.log(err)
              this.openErrorSnackBar("Une erreur est survenue lors de la suppression du domaine");
            },
            complete: () => {

            }
          });
        }
      },
      error: err => {
        console.log(err)
      },
      complete: () => {
      }
    })
  }

  dsisFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dsi.filter = filterValue.trim().toLowerCase();
  }

  exportData() {
    this.domaineService.exportData().subscribe({
      next: data => {
        if (data) {
          const blob: any = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
          window.URL.createObjectURL(blob);
          fileSaver.saveAs(blob, "Domaine - " + this.applicationSettingsService.applicationSettings.CDS_name + " - " + new Date().toLocaleDateString().replace('_', '-') + " " + new Date().toLocaleTimeString().replace('_', '-'));
          this.openSuccessSnackBar("Les domaines ont été exportés avec succès");
        } else {
          this.openErrorSnackBar("Aucun domaine a exporter");
        }
      },
      error: err => {
        console.log(err)
        this.openErrorSnackBar("Une erreur est survenue lors de l'exportation des domaines");
      },
      complete: () => {
      }
    });
  }

}
