import {Component, EventEmitter, Output} from '@angular/core';
import {JiraImportService} from './services/jira-import.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-jira-import',
  templateUrl: './jira-import.component.html',
  styleUrls: ['./jira-import.component.less']
})
export class JiraImportComponent {
  public spinner = false;

  public alert!: string;

  @Output() taskComplete = new EventEmitter<boolean>();

  constructor(public jiraImportService: JiraImportService, private _snackBar: MatSnackBar) {
  }


  //Uploads currently selected file, and awaits a response
  uploadFile(event: any) {
    this.jiraImportService.eraseErrors();

    this.jiraImportService.ping().subscribe({
      next: value => {
        this.taskComplete.emit(true);
        this.spinner = true;
      },
      error: err => {
        if ((err).includes("parsing")) {
          this.taskComplete.emit(true);
          this.spinner = true;
        } else {
          this.openSnackBar("Erreur serveur: Merci de contacter un administrateur.");
          this.taskComplete.emit(true);
          this.spinner = true;
        }
      },
      complete: () => {
      }
    });
  }

  //Displays a message in a SnackBar
  openSnackBar(message: string) {
    this._snackBar.open(message, "", {
      duration: 6000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
    });
  }
}
