<form class="form" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <div class="input-top">
    <strong>Calendrier :&nbsp; </strong>
    <mat-form-field appearance="outline" color="accent">
      <mat-label>Année</mat-label>
      <mat-select formControlName="year" [disabled]="asEdited" (ngModelChange)="filter($event)">
        <mat-option *ngFor="let year of years" [value]="year">
          {{ year }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="calendar_form_container" *ngIf="authentificationService.isUserAdmin()">
    <div class="mois"><p><strong>Mois :</strong></p></div>
    <div class="initialisation"><p><strong>Initialisation :</strong></p></div>
    <div class="ouverture"><p><strong>Ouverture :</strong></p></div>
    <div class="periodes_validation_sncf"><p><strong>Période de validation SNCF :</strong></p></div>
    <div class="cloture"><p><strong>Cloture :</strong></p></div>
    <div class="mois_select">
      <mat-form-field appearance="outline" color="accent" class="input-space">

        <mat-label>Mois</mat-label>
        <mat-select formControlName="month" [disabled]="asEdited" [(ngModel)]="month">
          <mat-option *ngFor="let month of monthNames ; let index = index" [value]="index">
            {{ month }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="initialisation_input">
      <mat-form-field hintLabel="Obligatoire" appearance="outline" class="input-space">
        <input matInput formControlName="initialisation" type="date" [readonly]="asEdited">
        <mat-hint style="margin-left: 15px ;color: red; " *ngIf="ErrorMessage"><strong>Date invalide</strong></mat-hint>
      </mat-form-field>
    </div>
    <div class="ouverture_input">
      <mat-form-field hintLabel="Obligatoire" appearance="outline" class="input-space">
        <input matInput formControlName="ouverture" type="date" [readonly]="asEdited">
        <mat-hint style="margin-left: 15px ;color: red " *ngIf="ErrorMessage"><strong>Date invalide</strong></mat-hint>
      </mat-form-field>
    </div>
    <div class="debut_periode_validation">
      <mat-form-field hintLabel="Obligatoire" appearance="outline" class="input-space">
        <mat-label>Du</mat-label>
        <input matInput formControlName="valid_debut" type="date" [readonly]="asEdited">
        <mat-hint style="margin-left: 15px ;color: red " *ngIf="ErrorMessage"><strong>Date invalide</strong></mat-hint>

      </mat-form-field>
    </div>
    <div class="fin_periode_validation">
      <mat-form-field hintLabel="Obligatoire" appearance="outline" class="input-space">
        <mat-label>Au</mat-label>
        <input matInput formControlName="valid_fin" type="date" [readonly]="asEdited">
        <mat-hint style="margin-left: 15px ;color: red " *ngIf="ErrorMessage"><strong>Date invalide</strong></mat-hint>
      </mat-form-field>
    </div>
    <div class="cloture_input">
      <mat-form-field appearance="outline" class="input-space">
        <input matInput formControlName="cloture" type="date" [readonly]="asEdited">
        <mat-hint style=" margin-left: 15px; color: red " *ngIf="ErrorMessage"><strong>Date invalide</strong></mat-hint>
      </mat-form-field>
    </div>
    <div class="all_buttons">
      <button mat-raised-button class="btn_create" color="primary" type="submit" [disabled]="!formGroup.valid"
              *ngIf="!asEdited && !btnStatus">Créer
      </button>

      <button mat-raised-button class="btn_valid" type="submit" [disabled]="!formGroup.valid"
              *ngIf="!asEdited && btnStatus">Valider
      </button>

      <button mat-raised-button class="btn_edit" *ngIf="asEdited" (click)="is_edit(asEdited)">Editer</button>

      <button mat-raised-button class="btn_annuler" type="button" color="accent" (click)="clear()">Annuler</button>
    </div>
  </div>
</form>
