<div class="button-top-row">
  <div class="year-button-container">
    <button mat-raised-button (click)="swapYear(0)" class="indigo btn-align-text"
      [style.background-color]="theme.getColor('Main Dark')" [ngClass]="{'active':currentYearIndex == 0}">
      <h2>
        Pack {{ settingsYear - 1 }}</h2>
    </button>
    <button mat-raised-button (click)="swapYear(1)" class="indigo btn-align-text"
      [style.background-color]="theme.getColor('Main Dark')" [ngClass]="{'active':currentYearIndex == 1}">
      <h2>
        Pack {{ settingsYear }}</h2>
    </button>
  </div>
  <div class="choice-export-container">
    <mat-form-field style="width: 150px; " appearance="fill">
      <mat-select [(value)]="selected" (valueChange)="swapSelected()">
        <mat-option *ngFor="let element of DSI_LIST; let index = index" [ngClass]="{'hidden':!dsiVisible[index]}"
          value="{{element}}">{{ element }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-raised-button (click)="exportData()" class="indigo  btn-align-text"
      [style.background-color]="theme.getColor('Main Dark')">
      Exporter le résultat
    </button>
  </div>

</div>

<mat-spinner *ngIf="spinner"></mat-spinner>

<div class="data" [ngClass]="{'spinner': spinner}">
  <div class="data-table">
    <mat-table [dataSource]="data" matSort class="light table" id="appTableDashboard">

      <ng-container matColumnDef="domaine" sticky>
        <th class="cell cell-dsi cell-head" [style.color]="theme.getColor('List Dark')"
          [style.background-color]="theme.getColor('List Light')" mat-header-cell *matHeaderCellDef>
          <b>Domaine</b>
        </th>
        <td class="cell cell-dsi" mat-cell *matCellDef="let element"><span *ngIf="element[0] !== 'Cumul'">{{ element[0]
            }}</span></td>
      </ng-container>

      <ng-container matColumnDef="application" sticky>
        <th class="cell cell-app cell-head" [style.color]="theme.getColor('List Dark')"
          [style.background-color]="theme.getColor('List Light')" mat-header-cell *matHeaderCellDef>
          <b>Application</b>
        </th>
        <td class="cell cell-app" mat-cell *matCellDef="let element">{{ element[1] }}</td>
      </ng-container>


      <ng-container matColumnDef="montant-engagé">
        <th class="cell cell-head" [style.color]="theme.getColor('List Dark')"
          [style.background-color]="theme.getColor('List Light')" mat-header-cell *matHeaderCellDef>
          <b>Montant
            engagé</b>
        </th>
        <td class="cell" mat-cell *matCellDef="let element">
          <span *ngIf="element[3] != '0'">{{ element[3] | number:'1.1-2':"fr-FR" }} €</span>
          <span *ngIf="element[3] == '0'"> - </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="engagé-sans-commande">
        <th class="cell cell-head" [style.color]="theme.getColor('List Dark')"
          [style.background-color]="theme.getColor('List Light')" mat-header-cell *matHeaderCellDef><b>Engagé
            sans commande</b></th>
        <td class="cell" mat-cell *matCellDef="let element">
          <span *ngIf="element[4] != '0'">{{ element[4] | number:'1.1-2':"fr-FR" }} €</span>
          <span *ngIf="element[4] == '0'"> - </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="rate-validé">
        <th class="cell cell-head cell-head-fac" [style.color]="theme.getColor('List Alt Dark')"
          [style.background-color]="theme.getColor('List Alt Light')" mat-header-cell *matHeaderCellDef><b>%
            Jalons validés</b></th>
        <td class="cell cell-head-fac" mat-cell *matCellDef="let element">
          <span *ngIf="element[43] != '0'" [ngClass]="{'complete': element[2] == 100 ,
            'empty': element[2] == 0 ,
            'warning': element[2]  < 50 ,
            'default' : true }">{{ element[2] | number:'1.1-1':"fr-FR" }} %
          </span>
          <span *ngIf="element[43] == '0'"> -
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="facturé-fin-m-1">
        <th class="cell cell-head cell-head-fac" [style.color]="theme.getColor('List Alt Dark')"
          [style.background-color]="theme.getColor('List Alt Light')" mat-header-cell *matHeaderCellDef>
          <b>Facturé
            fin m-1</b>
        </th>
        <td class="cell cell-head-fac " mat-cell *matCellDef="let element">
          <span *ngIf="element[5] != '0'">{{ element[5] | number:'1.1-2':"fr-FR" }} €</span>
          <span *ngIf="element[5] == '0'"> - </span>
        </td>
      </ng-container>


      <ng-container matColumnDef="facturable-du-mois">
        <th class="cell cell-head cell-head-fac" [style.color]="theme.getColor('List Alt Dark')"
          [style.background-color]="theme.getColor('List Alt Light')" mat-header-cell *matHeaderCellDef>
          <b>Facturable
            du mois</b>
        </th>
        <td class="cell cell-head-fac" mat-cell *matCellDef="let element">
          <span *ngIf="element[6] != '0'">{{ element[6] | number:'1.1-2':"fr-FR" }} €</span>
          <span *ngIf="element[6] == '0'"> - </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="retard-de-facturation">
        <th class="cell cell-head cell-head-fac" [style.color]="theme.getColor('List Alt Dark')"
          [style.background-color]="theme.getColor('List Alt Light')" mat-header-cell *matHeaderCellDef>
          <b>Retard
            de facturation</b>
        </th>
        <td class="cell cell-head-fac" mat-cell *matCellDef="let element">
          <span *ngIf="element[7] != '0'">{{ element[7] | number:'1.1-2':"fr-FR" }} €</span>
          <span *ngIf="element[7] == '0'"> - </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="constaté-m">
        <th class="cell cell-head" [style.color]="theme.getColor('List Dark')"
          [style.background-color]="theme.getColor('List Light')" mat-header-cell *matHeaderCellDef>
          <b>Constaté
            M</b>
        </th>
        <td class="cell" mat-cell *matCellDef="let element">
          <span *ngIf="element[44] != '0'">{{ element[44] | number:'1.1-2':"fr-FR" }} €</span>
          <span *ngIf="element[44] == '0'"> - </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="constaté-fin-m">
        <th class="cell cell-head" [style.color]="theme.getColor('List Dark')"
          [style.background-color]="theme.getColor('List Light')" mat-header-cell *matHeaderCellDef>
          <b>Constaté
            fin M</b>
        </th>
        <td class="cell" mat-cell *matCellDef="let element">
          <span *ngIf="element[8] != '0'">{{ element[8] | number:'1.1-2':"fr-FR" }} €</span>
          <span *ngIf="element[8] == '0'"> - </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="rate-constaté">
        <th class="cell cell-head" [style.color]="theme.getColor('List Dark')"
          [style.background-color]="theme.getColor('List Light')" mat-header-cell *matHeaderCellDef><b>%
            Constaté / Engagé</b></th>
        <td class="cell" mat-cell *matCellDef="let element">
          <span *ngIf="element[3] != '0'">{{ element[9] | number:'1.1-1':"fr-FR" }} %</span>
          <span *ngIf="element[3] == '0'"> - </span>

        </td>
      </ng-container>

      <ng-container matColumnDef="aa">
        <th class="cell cell-head" [style.color]="theme.getColor('List Dark')"
          [style.background-color]="theme.getColor('List Light')" mat-header-cell *matHeaderCellDef><b>Total
            AA</b></th>
        <td class="cell" mat-cell *matCellDef="let element"><b>
            <span *ngIf="element[10] != '0'">{{ element[10] | number:'1.1-2':"fr-FR" }} €</span>
            <span *ngIf="element[10] == '0'"> - </span>
          </b>
        </td>
      </ng-container>

      <ng-container matColumnDef="aa+1">
        <th class="cell cell-head" [style.color]="theme.getColor('List Dark')"
          [style.background-color]="theme.getColor('List Light')" mat-header-cell *matHeaderCellDef><b>Total
            AA+1</b></th>
        <td class="cell" mat-cell *matCellDef="let element"><b>
            <span *ngIf="element[11] != '0'">{{ element[11] | number:'1.1-2':"fr-FR" }} €</span>
            <span *ngIf="element[11] == '0'"> - </span>
          </b>
        </td>
      </ng-container>

      <ng-container matColumnDef="cumul">
        <th class="cell cell-head" [style.color]="theme.getColor('List Dark')"
          [style.background-color]="theme.getColor('List Light')" mat-header-cell *matHeaderCellDef>
          <b>Cumul</b>
        </th>
        <td class="cell" mat-cell *matCellDef="let element"><b>
            <span *ngIf="element[12] != '0'">{{ element[12] | number:'1.1-2':"fr-FR" }} €</span>
            <span *ngIf="element[12] == '0'"> - </span>
          </b>
        </td>
      </ng-container>

      <ng-container matColumnDef="total-previous">
        <th class="cell cell-head" [style.color]="theme.getColor('List Dark')"
          [style.background-color]="theme.getColor('List Light')" mat-header-cell *matHeaderCellDef>
          <b>Total < {{ yearFromIndex(1) }}</b>
        </th>
        <td class="cell" mat-cell *matCellDef="let element"><b>
            <span *ngIf="element[45] != '0'">{{ element[45] | number:'1.1-2':"fr-FR" }} €</span>
            <span *ngIf="element[45] == '0'"> 0 €</span>
          </b>
        </td>
      </ng-container>

      <ng-container *ngFor="let i of [].constructor(24); let index = index" matColumnDef="{{'m'+(index+1)}}">
        <th class="cell cell-head" [style.color]="theme.getColor('List Dark')"
          [style.background-color]="theme.getColor('List Light')" mat-header-cell *matHeaderCellDef>
          <b>{{ months[index % 12] }} {{ yearFromIndex(index) }}</b>
        </th>
        <td class="cell cell-head" mat-cell *matCellDef="let element">
          <span *ngIf="element[13 + index] != '0'">{{ element[13 + index] | number:'1.1-2':"fr-FR" }} €</span>
          <span *ngIf="element[13 + index] == '0'"> - </span>
        </td>
      </ng-container>

      <tr mat-header-row class="head-row" *matHeaderRowDef="headers; sticky:true;"></tr>
      <tr mat-row class="row"
        [ngClass]="{'total':row[1] === 'TOTAL :', 'total-total':row[0] === 'Cumul', 'hidden':!isVisible(row)}"
        *matRowDef="let row;  columns: headers;"></tr>
    </mat-table>
  </div>
</div>