import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Constants } from 'src/app/shared/constants/Constants';
import { IComment } from 'src/app/shared/models/comment';
import { IDemand } from 'src/app/shared/models/demand';
import { User } from 'src/app/shared/models/user';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { EcheancesService } from '../services/echeances.service';
import { ThemeService } from 'src/app/shared/services/theme.service';


@Component({
  selector: 'comment-box',
  templateUrl: './comment-box.component.html',
  styleUrls: ['./comment-box.component.less']
})
export class CommentBoxComponent {

  public tappedComment = '';

  public demand!: IDemand;

  @ViewChild('commentsDiv')
  public commentsDiv!: ElementRef<HTMLInputElement>;

  constructor(public router: Router, public theme: ThemeService, private echeanceService: EcheancesService, public authenticationService: AuthenticationService) {
  }


  submitComment() {
    if (!this.tappedComment || !this.tappedComment.trim())
      return;

    const comment: IComment = {
      text: this.tappedComment,
      submissionDateTime: moment().format(Constants.Patterns.LOCAL_DATE_TIME_FORMAT)
    };

    this.echeanceService.submitComment(comment, this.demand.demandNumber as string).subscribe({
      next: response => {
        if (response != null) {
          if (!this.demand.comments) {
            this.demand.comments = [];
          }
          this.demand.comments.push(response);
        }
      },
      error: error => {
        console.error('Error while submitting comment', error);
      },
      complete: () => {
      }
    });

    this.tappedComment = '';
  }

  public scrollToTheEnd() {
    if (this.commentsDiv) {
      this.commentsDiv.nativeElement.scrollTop = this.commentsDiv.nativeElement.scrollHeight;
    }
  }

  public isCommenterDifferentUser(commenterUser: User) {
    return !commenterUser
      || !this.authenticationService.getUser()
      || (this.authenticationService.getUser().identifier != commenterUser.identifier);
  }

  public getCommentWrapperClass(comment: IComment): string {
    return this.isCommenterDifferentUser(comment.user as User) ? 'diff-user-saved-comment-wrapper' : 'saved-comment-wrapper';
  }

  public getCommentClass(comment: IComment): string {
    return this.isCommenterDifferentUser(comment.user as User) ? 'diff-user-saved-comment' : 'saved-comment';
  }

}
