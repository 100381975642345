<h2 mat-dialog-title>Netting: </h2>
<mat-dialog-content>
    Veuillez sélectionner le type de projet pour le netting :
    <form class="form" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
        <mat-form-field appearance="outline" color="accent">
            <mat-select #matAppsRef matInput formControlName="nettingsNames">
                <mat-option *ngFor="let name of nettingsNames" [value]="name">
                    {{name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <input hidden matInput required formControlName="ndemand" type="text">
        <button mat-raised-button color="warn" type="reset" (click)="closeDialog()">
            Annuler
        </button>
        <button mat-raised-button class="thirdrow blue" [style.background-color]="theme.getColor('Main Light')"
            type="submit" [disabled]="formGroup.value.nettingsNames === ''"> Valider
        </button>
    </form>
</mat-dialog-content>