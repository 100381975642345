<ng-container>
    <div class="container">
        <app-header-bar></app-header-bar>
        <app-menu-bar></app-menu-bar>
        <div class="main">
            <app-dashboard></app-dashboard>
            <app-administration></app-administration>
            <app-collected-data></app-collected-data>
            <app-application-domain></app-application-domain>
            <app-facturation></app-facturation>
            <app-default></app-default>
            <div class="pageContent">
                <router-outlet></router-outlet>
            </div>
        </div>
        <app-footer-bar></app-footer-bar>
    </div>
</ng-container>
