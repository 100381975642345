import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {PurgeModalComponent} from "../purge-modal/purge-modal.component";

@Component({
  selector: 'app-purge-modal-confirmation',
  templateUrl: './purge-modal-confirmation.component.html'
})
export class PurgeModalConfirmationComponent implements OnInit {

  message = '';
  isYearEmpty = false;

  constructor(
    public dialogRef: MatDialogRef<PurgeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

  }

  ngOnInit(): void {
    this.confirmPurge();
  }

  confirmPurge() {
    switch (this.data.years.length) {
      case 0:
        this.message = "Vous n'avez <strong>pas sélectionné d'année.</strong>";
        this.isYearEmpty = true;
        break;
      case 1:
        this.message =
          "Vous avez demandé à purger les demandes de l'année <strong>" +
          this.data.years[0] + '</strong>';
        break;
      default:
        this.message =
          "Vous avez demandé à purger les demandes des années <strong> " +
          this.data.years.join(", ") + " </strong>";
    }

    if (this.data.years.length > 0) {
      switch (this.data.manualData) {
        case true:
          this.message += ' y <strong> compris les données manuelles </strong>.';
          break;
        case false:
          this.message += ' en <strong> conservant les données manuelles </strong>';

      }
    }
  }

}
