<h2 mat-dialog-title>Detail des alertes</h2>
<mat-dialog-content>
  <mat-table [dataSource]="data" class="mat-elevation-z8" id="detailAlert">
    <ng-container matColumnDef="severity" >
      <mat-header-cell class="severity-row" *matHeaderCellDef>Niveau d'alerte</mat-header-cell>
      <mat-cell class="severity-row {{element.severity}}" *matCellDef="let element"><b>{{ element.severity
          }}</b></mat-cell>
    </ng-container>
    <ng-container matColumnDef="comment" >
      <mat-header-cell class="comment-row" *matHeaderCellDef>Commentaire</mat-header-cell>
      <mat-cell class="comment-row" mat-cell *matCellDef="let element">{{ element.comment }}</mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="DISPLAYED_COLUMNS ; "></mat-header-row>
    <mat-row *matRowDef="let row; columns: DISPLAYED_COLUMNS;"></mat-row>
  </mat-table>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-raised-button  type="reset" (click)="dialogRef.close()"> Retour
  </button>
</mat-dialog-actions>