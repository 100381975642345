import {Component, EventEmitter, Input, Output, SimpleChanges} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {DsiTypes, IDsi} from 'src/app/shared/models/dsi';

import {DialogService} from 'src/app/shared/services/dialog.service';
import {DomainesService} from './domaines.service';
import { SnackbarService } from '../../shared/services/snackbar.service';

@Component({
  selector: 'app-domaines',
  templateUrl: './domaines.component.html',
  styleUrls: ['./domaines.component.less']
})
export class DomainesComponent {

  formGroup: UntypedFormGroup;
  asEdited = false;
  btnStatus = false;


  @Output()
  updateEvent = new EventEmitter<IDsi>();

  @Output()
  clearEvent = new EventEmitter<any>();

  @Input()
  dsi!: IDsi;


  constructor(private _fb: UntypedFormBuilder, private _dsi: DomainesService, private snackbarService: SnackbarService,
              private dialogService: DialogService) {
    this.formGroup = this._fb.group({

      name: ['', Validators.required],
      type: ['', Validators.required],
    });
  }


  get DsiTypes() {
    return DsiTypes;
  }

  get Object() {
    return Object;
  }

  /*
   * Create or modify a domain form object
   */

  onSubmit(): void {

    if (!this.asEdited && !this.btnStatus) {
      this._dsi.createDsi(this.formGroup.value).subscribe({
        next: data => {
          this.updateEvent.emit(data);
          this.formGroup.reset();
          this.openSuccessSnackBar("Domaine créé avec succès")
        },
        error: err => {
          console.log(err)
          this.openErrorSnackBar("Erreur lors de la création du domaine")
        },
        complete: () => {

        }
      });
    } else if (!this.asEdited && this.btnStatus) {
      this._dsi.updateDsi(this.dsi.id ?? '', this.formGroup.value).subscribe({
        next: data => {
          this.updateEvent.emit(data);
          this.formGroup.reset();
          this.asEdited = false;
          this.btnStatus = false
          this.openSuccessSnackBar("Domaine modifié avec succès")
        },
        error: err => {
          console.log(err)
          this.openErrorSnackBar("Erreur lors de la modification du domaine")
        },
        complete: () => {

        }
      });
    }
  }

  /*
   * Initialize dsiForm component
   */
  clear(): void {
    this.formGroup.reset();
    this.asEdited = false;
    this.btnStatus = false;
    this.updateEvent.emit();
  }


  openErrorSnackBar(message: string) {
    this.snackbarService.openSnackBar(message, 'error-snack-bar');
  }

  openSuccessSnackBar(message: string) {
    this.snackbarService.openSnackBar(message);
  }
 


  updateDsiForm() {
    const pUpdate = {

      name: this.dsi.name,
      type: this.dsi.type,
    }
    this.formGroup.setValue(pUpdate);
  }

  /*
   * Onchnages domain form object
   */
  ngOnChanges(changes: SimpleChanges): void {
    this.asEdited = false;

    if (changes.dsi && changes.dsi.currentValue) {
      const dsi = changes.dsi.currentValue;

      if (!dsi.check && dsi.name == '') {
        this.clear();
        this.asEdited = false;
        this.btnStatus = false;
      } else if (dsi.check && dsi.name != '') {
        this.updateDsiForm();
        this.asEdited = true;
        this.btnStatus = false;
      } else if (!dsi.check && dsi.name != '') {
        this.updateDsiForm();
        this.asEdited = false;
        this.btnStatus = true;
      }
    }
  }

  /*
   * Confirmation dialog delete dsi form
   */
  confirmDelete() {
    this.dialogService.openConfirmDialog(this.dsi.name)
      .afterClosed().subscribe({
      next: res => {
        this.deleteDsi();
      },
      error: err => {
        console.log(err)
      },
      complete: () => {
      }
    });
  }

  /*
   * Delete dsi form
   */
  deleteDsi() {

    if (this.asEdited) {
      this._dsi.deleteDsi(this.dsi).subscribe({
        next: data => {
          this.clear();
          this.updateEvent.emit(data);
          this.openSuccessSnackBar("Domaine supprimé avec succès")
        },
        error: err => {
          console.log(err)
          this.openErrorSnackBar("Erreur lors de la suppression du domaine")
        },
        complete: () => {
        }
      });
    }
  }

  is_edit(asEdited: boolean) {

    asEdited = !asEdited
    this.asEdited = asEdited
    this.btnStatus = true
  }


}
