<div class="card">
  <app-facturation-netting_regularisation (emitYearSelected)="updateYearSelected($event)"
    (emitPackYearSelected)="updatePackYearSelected($event)" (emitMonthSelected)="updateMonthSelected($event)"
    (emitVueSelected)="updateVueSelected($event)" (emitNetRegisShown)="updateNetRegisShown($event)"
    [isFormDisabled]="isFormDisabled">
  </app-facturation-netting_regularisation>

  <div *ngIf="NetRegisShown" id="nettingWorkspace">

    <div id="pageSection-leftSection" class="column">
      <div class="tableCA">
        <mat-table class="light mat-elevation-z4" [dataSource]="CA_facture" id="matTableCA">
          <!-- Colonne pour CA facturé -->
          <ng-container matColumnDef="CA_facture">
            <mat-cell *matCellDef="let element">
              <div class="ca-facture-container">
                <span>CA facturé ➡️ </span>
                <mat-form-field>
                  <input 
                    matInput 
                    class="input" 
                    name="CA" 
                    type="number" 
                    [(ngModel)]="element.montant"
                    [readonly]="tableReadOnly" 
                    (change)="changeCA(element.montant)" 
                    ngDefaultControl
                  >
                  <span matSuffix>€</span>
                </mat-form-field>
              </div>
            </mat-cell>
          </ng-container>
        
          <!-- Ligne de données -->
          <mat-row *matRowDef="let row; columns: displayedColumns_CA;"></mat-row>
        </mat-table>
      </div>
      
        <fieldset class="Ecart bloc" id="ecart">
          <legend>Ecart
            <span *ngIf="ecart === 0" class="checkmark" style="color: green;">&#10004;</span>
            <span *ngIf="ecart !== 0" class="cross" style="color: red;">&#10006;</span>
          </legend>
          <label> {{ ecart }} €</label>
        </fieldset>
        <fieldset class="Schema_manquant bloc" *ngIf="displaySchemaManquant">
          <legend class="displayNone">schema manquant</legend>
          <label> <b>⚠️ Schéma de netting manquant</b></label>
        </fieldset>
      
      <mat-divider></mat-divider>
    
      <div class="CAbySchema">
        <p><strong> ➡️ CA facturé par schéma</strong></p>
        <mat-table class="light mat-elevation-z4" [dataSource]="CA_facture_schema" matSort id="CASchemaTable">
          <ng-container matColumnDef="schema_name">
            <mat-header-cell *matHeaderCellDef> Schéma</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.schema_name }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef> <strong>Total</strong> </mat-footer-cell>
          </ng-container>
          <ng-container matColumnDef="montant">
            <mat-header-cell *matHeaderCellDef> Montant</mat-header-cell>
            <mat-cell *matCellDef="let element"> <strong>{{ element.montant | currency:'EUR':'symbol':'1.2-2'  }} </strong>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef [class.erreurComparaison]="compareCaSchema()"> <strong>{{ totalMontantSchema | currency:'EUR':'symbol':'1.2-2' }} </strong>
            </mat-footer-cell>

          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns_CA_by_schema ; sticky: true;"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns_CA_by_schema; "></mat-row>
          <mat-footer-row *matFooterRowDef="displayedColumns_CA_by_schema"></mat-footer-row>
        </mat-table>
      </div>


    </div>

    <div id="pageSection-centerSection" class="column">
      <mat-table class="light mat-elevation-z4" [dataSource]="nettingSource" id="CaCompteNetting">
        <ng-container matColumnDef="code_netting">
          <mat-header-cell *matHeaderCellDef> Code netting</mat-header-cell>
          <mat-cell *matCellDef="let element" [class.Ligne_rouge]="LineIsRed(element.schema_id)"
            [class.Ligne_orange]="LineIsOrange(element.name)"> {{ element.name }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="montant">
          <mat-header-cell *matHeaderCellDef> Montant</mat-header-cell>
          <mat-cell *matCellDef="let element" [class.Ligne_rouge]="LineIsRed(element.schema_id)"
            [class.Ligne_orange]="LineIsOrange(element.name)">
            <mat-form-field>
              <input matInput class="input" name="montant" type="number" [(ngModel)]="element.montant"
                (change)="changeMontant(element)" ngDefaultControl>
              <span matSuffix>€</span>
            </mat-form-field>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns_netting ; sticky: true;"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns_netting; "></mat-row>
      </mat-table>
    </div>

    <div id="pageSection-rightSection" class="column">
      <fieldset class="Sequence bloc" id="sequence">
        <legend>Sequence départ</legend>
        <input type="number" required min="1" [(ngModel)]="sequenceSelected" (ngModelChange)="changeSequence($event)"
          [disabled]="sequenceDisabled" name="sequence">
      </fieldset>
      <div id="btn-gen-netting">
        <button mat-raised-button class="indigo input bloc"
          [style.background-color]="isGenererDisabled ? 'btn-disable' : theme.getColor('Main Dark')"
          [style.opacity]="isGenererDisabled ? '0.5' : '1'" type="submit" id="generer_netting"
          [disabled]="isGenererDisabled" (click)="genererService()">
          Générer Netting
        </button>
        <button mat-raised-button class="indigo input bloc" [style.background-color]="theme.getColor('Main Dark')"
          type="submit" id="abandon" (click)="abandonService()"> Abandon
        </button>
      </div>
    </div>
  </div>


  <div *ngIf="NetGenisShown">
    <div>
      <button mat-raised-button class="indigo input bloc" [style.background-color]="theme.getColor('Main Dark')"
        type="submit" id="export" (click)="exportService()">
        <mat-icon>get_app</mat-icon>
        Export Excel
      </button>
      <button mat-raised-button class="indigo input bloc right" [style.background-color]="theme.getColor('Main Dark')"
        type="submit" id="retour" (click)="retourService()"> Retour
      </button>
    </div>

    <div>
      <mat-table class="light mat-elevation-z4" [dataSource]="nettingCalculatedSource" id="MatTableNettingGenerated">
        <ng-container *ngFor="let dispCol of displayedColumnsGen" matColumnDef="{{dispCol.key}}">
          <mat-header-cell *matHeaderCellDef>{{ dispCol.header }}</mat-header-cell>
          <mat-cell *matCellDef="let element" [class.Cell_rouge]="CellIsRed(element[dispCol.key], dispCol.header)">
            <ng-container *ngIf="isMonthColumn(dispCol.header); else defaultCell">
              {{ element[dispCol.key] | currency: "EUR" : "symbol" : "1.2-2" }}
            </ng-container>
            <ng-template #defaultCell>
              {{ element[dispCol.key] }}
            </ng-template>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumnsGenKeys;"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumnsGenKeys"
          [ngStyle]="{'background-color': getRowBackgroundColor(row.sequence)}"></mat-row>
      </mat-table>
    </div>
  </div>

</div>