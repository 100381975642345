<h2 mat-dialog-title class="rejectTitle">Merci de préciser le motif du rejet :</h2>
<mat-dialog-content id="prompt">
  <mat-form-field id="areaContainer">
    <textarea id="area" matInput [(ngModel)]="comment">
      </textarea>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions id="buttonArea" align="end">
  <button mat-button class="indigo" [style.background-color]="theme.getColor('Main Dark')" mat-dialog-close>Annuler le
    rejet
  </button>
  <button mat-button class="blue" [style.background-color]="theme.getColor('Main Light')" [disabled]="!comment"
    [mat-dialog-close]="comment">Rejeter
  </button>
</mat-dialog-actions>