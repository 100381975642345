import { EcheanceStatus } from "./echeance-status";

export class Echeance{
  cost = 0;
  delayedCost = 0;
  load?: number;
  date?: Date;
  projected?: boolean;
  status?:EcheanceStatus;
}
