<form class="formApplicationAccount" [formGroup]="formGroup" (ngSubmit)="onSubmit()">

  <mat-form-field appearance="outline" color="accent" >
    <mat-label>Nom</mat-label>
    <input matInput required formControlName="name" type="text" [readonly]="asEdited">
    <mat-hint>Obligatoire</mat-hint>
  </mat-form-field>
  <mat-form-field appearance="outline" color="accent">
    <mat-label>Criticité</mat-label>
    <input matInput formControlName="critical" type="text" [readonly]="asEdited">
  </mat-form-field>
  <mat-form-field appearance="outline" color="accent" class="input-large">
    <mat-label>Projet</mat-label>
    <input matInput required formControlName="project" type="text" [matAutocomplete]="autoProjets"
      (ngModelChange)="filter($event)" [readonly]="asEdited">
    <mat-autocomplete #autoProjets="matAutocomplete" [displayWith]="getProjectName">
      <mat-option *ngFor="let projet of filteredOptions" [value]="projet">{{ projet.name }}</mat-option>
    </mat-autocomplete>
    <mat-hint>Obligatoire</mat-hint>
  </mat-form-field>
  <mat-form-field appearance="outline" color="accent" class="input-large">
    <mat-label>Domaine</mat-label>
    <mat-select required formControlName="dsis" multiple [disabled]="asEdited">
      <mat-option *ngFor="let name of dsisNames" [value]="name">
        {{ name }}
      </mat-option>
    </mat-select>
    <mat-hint>Obligatoire</mat-hint>
  </mat-form-field>

  <button mat-raised-button class="btn_created" color="primary" type="submit" [disabled]="!formGroup.valid"
    *ngIf="!asEdited && !btnStatus">Créer
  </button>
  <button mat-raised-button class="btn_annuler" type="button" color="accent" (click)="clear()">Annuler</button>
  <button mat-raised-button class="btn_valid" type="submit" [disabled]="!formGroup.valid"
    *ngIf="!asEdited && btnStatus">Valider
  </button>
  <button mat-raised-button class="btn_edit" *ngIf="asEdited" (click)="is_edit(asEdited)">Editer</button>
  <button mat-raised-button class="btn_supp" color="warn" *ngIf="asEdited" (click)="confirmDelete()">Supprimer</button>
</form>