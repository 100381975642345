import {Component, Inject, OnInit} from '@angular/core';
import {ApplicationSettingsComponent} from '../application-settings.component';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {PurgeModalConfirmationComponent} from "../purge-modal-confirmation/purge-modal-confirmation.component";

@Component({
  selector: 'app-purge-modal',
  templateUrl: './purge-modal.component.html',
  styleUrls: ['./purge-modal.component.less'],
})
export class PurgeModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ApplicationSettingsComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

  }

  yearsToDisplay = [];

  ngOnInit(): void {
    this.yearsToDisplay = this.data.years;
    this.data.years = []
  }

  confirmData() {

    const dialogRef = this.dialog.open(PurgeModalConfirmationComponent, {
      data: {
        years: this.data.years,
        manualData: this.data.manualData
      }
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        this.dialogRef.close(result);
      },
      error: err => {
        console.log(err);
      },
      complete: () => {
      }
    })
  }
}
