import {Component, EventEmitter, Output} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {JiraImportService} from '../jira-import/services/jira-import.service';

@Component({
  selector: 'app-pack-import',
  templateUrl: './pack-import.component.html',
  styleUrls: ['./pack-import.component.less']
})
export class PackImportComponent {
  public spinner = false;
  public years: string[] = [];
  public yearSelected !: string;
  public monthSelected !: any;
  public months: string[] = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];

  @Output() taskComplete = new EventEmitter<boolean>();

  constructor(public jiraImportService: JiraImportService, private _snackBar: MatSnackBar) {
    this.createYears();
  }


  private createYears(): void {
    const date = new Date();
    let year = date.getUTCFullYear() - 1;
    for (let i = 0; i < 6; i++) {
      this.years.push(year.toString());
      year++;
    }
  }

  changeYear(year: string): void {
    this.yearSelected = year;
  }

  changeMonth(month: number): void {
    this.monthSelected = month + 1;
  }

  uploadPackFile(file: any): void {
    if (!this.yearSelected) {
      this.openSnackBar("Opération impossible, veuillez choisir une année.")
      return;
    }
    this.spinner = true;
    this.jiraImportService.postFilePack(file, this.yearSelected, this.monthSelected).subscribe({
      next: data => {
        this.openSnackBar("Import du pack terminé.");
        this.taskComplete.emit(true);
      },
      error: err => {
        console.log(err);
        this.openSnackBar("Erreur lors de l'import du pack");
        this.taskComplete.emit(true);
      },
      complete: () => {
        this.spinner = false;
      }
    });
  }

  //Displays a message in a SnackBar
  openSnackBar(message: string) {
    this._snackBar.open(message, "", {
      duration: 6000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
    });
  }

}
