<div class="confirm-dialog-container">
  <h2 mat-dialog-title>
    Etes-vous sûr de vouloir supprimer :
  </h2>
  <mat-dialog-content class="content-container">
    <span class="content-span full-width">{{ data.message }}</span>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-raised-button id="non-button" (click)="closeDialog()" [mat-dialog-close]="false">Non</button>
    <button mat-raised-button id="oui-button" [mat-dialog-close]="true">Oui</button>
  </mat-dialog-actions>
</div>