import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {MatDialog} from '@angular/material/dialog';
import {GuideComponent} from 'src/app/guide/guide.component';
import {ThemeService} from 'src/app/shared/services/theme.service';

registerLocaleData(localeFr);

@Component({
    selector: 'app-application-domain',
    templateUrl: './application-domain.component.html',
    styleUrls: ['./application-domain.component.less']
})
export class ApplicationDomainComponent {

    constructor(public router: Router,
                public dialog: MatDialog,
                public theme: ThemeService) {
    }

    openGuide(s: string) {
        this.dialog.open(GuideComponent, {
            data: s
        })
    }

}
