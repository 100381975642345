<form class="form" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <div class="group">
        <mat-form-field appearance="outline" color="primary" class="input">
            <mat-label>{{label}}</mat-label>
            <input matInput focused="true" placeholder="{{placeholder}}" (click)="fileInput.click()"
                   value="{{fileName}}">
            <input hidden type="file" #fileInput (change)="onInput($event)" formControlName="file">
            <mat-hint>{{hint}}</mat-hint>
        </mat-form-field>
        <button mat-raised-button class="indigo input" [style.background-color]="theme.getColor('Main Dark')"
                type="submit"
                [disabled]="!(formGroup.valid && (canSubmit == null || canSubmit))">Importer
        </button>
    </div>
</form>
