import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild, } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IDemand } from 'src/app/shared/models/demand';
import { Echeance } from 'src/app/shared/models/echeance';
import * as moment from 'moment';
import { EcheancesService } from '../services/echeances.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EcheanceDetailsComponent } from '../echeance-details/echeance-details.component';
import { IPack } from 'src/app/shared/models/pack';
import { UserProfile } from 'src/app/shared/models/user-profile';
import { CommentPromptComponent } from '../dialog-box/comment-prompt.component';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { IApplication } from 'src/app/shared/models/application';
import { ApplicationProjectService } from 'src/app/core/application-project/services/application-project.service';
import { Constants } from 'src/app/shared/constants/Constants';
import { EcheanceStatus } from 'src/app/shared/models/echeance-status';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { IPrestation } from 'src/app/shared/models/prestation';
import { INettingPack } from 'src/app/shared/models/netting-pack';
import { NettingService } from 'src/app/core/netting-account/netting.service';
import { INetting } from 'src/app/shared/models/netting-account';
import { IManualData } from 'src/app/shared/models/manual-data';
import { DialogFormNettingService } from 'src/app/shared/dialog-form-netting/dialog-form-netting.service';
import { OrderFormService } from 'src/app/core/orders-forms/services/order-form.service';
import { UntypedFormControl } from '@angular/forms';
import { ApplicationSettingsService } from 'src/app/core/application-settings/application-settings.service';
import { CalendrierService } from 'src/app/core/calendrier-pack/calendrier.service';
import { ICalendrier } from 'src/app/shared/models/calendrier';
import { ApplicationSettings } from 'src/app/shared/models/application-settings';
import { DsiTypes, IDsi } from 'src/app/shared/models/dsi';
import { IUser } from 'src/app/shared/models/user';
import { UsersService } from 'src/app/core/user/services/users.service';
import { DomainesService } from 'src/app/core/domaines/domaines.service';
import { ThemeService } from 'src/app/shared/services/theme.service';
import * as fileSaver from 'file-saver';
import { JiraImportService } from 'src/app/core/import-data/jira-import/services/jira-import.service';
import { SnackbarService } from '../../../../shared/services/snackbar.service';

registerLocaleData(localeFr);

@Component({
  selector: 'app-echeances-list',
  templateUrl: './echeances-list.component.html',
  styleUrls: ['./echeances-list.component.less'],
})
export class EcheancesListComponent implements AfterViewInit {
  nettingAccounts: INetting[] = [];
  nettingsNames: string[] = [];

  MONTHS: string[] = [
    'janvier',
    'février',
    'mars',
    'avril',
    'mai',
    'juin',
    'juillet',
    'août',
    'septembre',
    'octobre',
    'novembre',
    'décembre',
  ];

  DISPLAYED_COLUMNS: string[] = [
    'checkboxColumn',
    'manualData',
    'orderNumber',
    'demandNumber',
    'demandDescription',
    'applicationName',
    'serviceType',
    'prestationType',
    'toBeFactured',
    'engaged',
    'status',
    'galLabel',
    'summary',
    'm1',
    'm2',
    'm3',
    'm4',
    'm5',
    'm6',
    'm7',
    'm8',
    'm9',
    'm10',
    'm11',
    'm12',
    'm13',
    'm14',
    'm15',
    'm16',
    'm17',
    'm18',
    'm19',
    'm20',
    'm21',
    'm22',
    'm23',
    'm24',
    'm25',
    'm26',
    'm27',
    'm28',
    'm29',
    'm30',
    'm31',
    'm32',
    'm33',
    'm34',
    'm35',
    'm36',
  ];

  dsis: IDsi[] = [];
  users: IUser[] = [];
  perimeterControl = new UntypedFormControl('');

  spinner = false;

  @ViewChild(MatSort)
  sort!: MatSort;

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  @Input()
  duesEvent!: boolean;

  @Input()
  refreshTable!: null;

  @Output()
  updateValidationCapability = new EventEmitter<boolean>();

  @Output()
  updateRejectionCapability = new EventEmitter<boolean>();

  @Output()
  setYear = new EventEmitter<string>();

  @Input()
  public enableValidation!: boolean;

  @Input()
  public enableRejection!: boolean;

  @Input()
  public areCalculationsDone!: boolean;

  @Input()
  public exportSpinner!: boolean;


  @Output()
  export = new EventEmitter<string>();

  //Valeurs de filtres
  public formValue = '';
  public filter = '';
  public filterValue = '';
  public reffilter = '';
  public reffilterValue = '';
  public galfilter = '';
  public galfilterValue = '';
  public desFilter = '';
  public desfilterValue = '';
  public searchIndex = 'search';
  public displayYears: number[] = [];

  public displayDemandsStatus = new UntypedFormControl();
  public serviceFilter: string[] = [];
  public displayServiceFilter = new UntypedFormControl();

  //Année courante
  public year: number = new Date().getFullYear();

  //Année/Mois de facturation
  public settingYear: boolean;
  public currMonthIndex = 0;

  //Objet mat-table
  public dataSource!: MatTableDataSource<IDemand>;

  //Demandes à afficher
  public demands: Map<string, IDemand> = new Map();

  //Objets mémoire
  public prestations: IPrestation[];
  public applications: IApplication[] = [];
  public orderForms: string[] = [];
  public currPack!: IPack;
  public DtOvershootMoreFivePercent: string[] = [];
  public applicationSettings: ApplicationSettings;
  public manualDataList: string[] = [];
  public manualData!: IManualData[];
  public netting!: INettingPack[];

  //Variables logiques (blocks, spinners)
  public totalCost: number[] = [];
  public lockValidation = false;
  public isAlreadyValidating = false;
  public isClosed = true;
  public asyncLock = true;
  public isTableLoading = false;

  //Si en train de tout sélectionner
  public selectAll = false;

  //Constantes, enums
  demandStatus: string[] = [
    'Evalué',
    'Chiffré',
    'Approuvé',
    'Engagé',
    'Analysé',
    'En attente',
    'Implémenté',
    'Testé',
    'Abandonné',
    'Fermé',
    'Livré',
    'Réceptionné',
    'Accepté',
    'Mise en production',
    'Nouveau',
    'Ouvert',
    'Import Pack'
  ];

  //Année d'export
  public exportYear = 2021;

  //Affichage
  public isAppNamesNavVisible = true;
  public buttonTextFilter = 'Cacher';
  public resultsLength = 0;

  get Constants() {
    return Constants;
  }
  get Array() {
    return Array;
  }
  get EcheanceStatus() {
    return EcheanceStatus;
  }

  debugModeActivated = false;

  constructor(
    public echeancesService: EcheancesService,
    private cdref: ChangeDetectorRef,
    private applicationProjectService: ApplicationProjectService,
    private dialog: MatDialog,
    private snackbarService: SnackbarService,
    public authenticationService: AuthenticationService,
    private _nettingService: NettingService,
    private _dfns: DialogFormNettingService,
    private _ofs: OrderFormService,
    public _as: ApplicationSettingsService,
    private _cs: CalendrierService,
    private _us: UsersService,
    public theme: ThemeService,
    private _ds: DomainesService,
    private _es: EcheancesService,
    private jiraImportService: JiraImportService,
  ) {

    this.debugModeActivated = this.authenticationService.getDebugMode();

    this.year = this._as.getApplicationSettings().Pack_current_year;

    for (let i = 0; i < 36; i++) {
      this.totalCost[i] = 0;
    }

    this.prestations = [];
    this.applicationSettings = _as.getApplicationSettings();
    this.settingYear = this.applicationSettings.Enable_Previous_Year;

    if (!this.applicationSettings.display_fusion_pack && this.settingYear) this.displayYears.push(this.year - 1);
    this.displayYears.push(this.year);

    this.echeancesService.getLatestPack().subscribe({
      next: value => {
        this.currPack = new IPack();
        this.currPack.packMonth = value.packMonth;
        this.currPack.packYear = value.packYear;
        this.exportYear = value.packYear;
        this.currPack.packCommandYear = value.packCommandYear;
        this.currMonthIndex = this.currPack.packMonth + ((this.currPack.packYear - this.getYear()) * 12);

        this.year = this.applicationSettings.Pack_current_year;
        this.initMiscData();
        this.updatePerimeterOptions();
        this.getApplicationsNamesThenRelatedDemands();
      },
      error: err => {
        console.log(err);
      },
      complete: () => {
        this.initValidationCalendarWorkflow();
      }
    });

  }

  ngAfterViewInit() {
    this.echeancesService.getPrestations().subscribe({
      next: value => {
        for (const element of value) {
          const prestation: IPrestation = { name: element, selected: false };
          this.prestations.push(prestation);
        }
      },
      error: err => {
        console.log(err);
      },
      complete: () => {
      }
    });

    this.cdref.detectChanges();
  }

  paginatorNumberFromProfile() {
    if (this.authenticationService.getUser().profile == UserProfile.ADMIN) {
      return 100;
    }
    else {
      return 25;
    }
  }


  /**
  * Initialisation: Rafraichit les données manuelles en mémoire.
  */
  updateManualData() {
    this.manualDataList = [];
    this.echeancesService.getAllManualData().subscribe({
      next: value => {
        this.manualData = value;
        //Temporary solution: defines demands with manual data.
        for (const element of this.manualData)
          this.manualDataList.push(element.demand);
      },
      error: err => {
        console.log(err);
      },
      complete: () => {
      }
    });
  }

  /**
  * Initialisation: Initialise les données mémoires non vitales au premier appel (services, dépassements, nettings, prestation).
  */
  initMiscData() {
    this._nettingService.getNettingAccountHasProject().subscribe({
      next: (data) => {
        if (data) {
          this.nettingAccounts = data;
          this.nettingsNames = data.map(
            (netting: INetting) => netting.name as string,
          );
        }
      },
      error: (err) => {
        console.log(err);
      },
      complete: () => {
      }
    });

    this._ofs.getOrdersForms().subscribe({
      next: (value) => {
        for (const element of value) this.orderForms.push(element.number);
      },
      error: (err) => {
        console.log(err);
      },
      complete: () => {
      }
    }
    );

    //All DT with overshoot of more than 5%
    this.echeancesService
      .getDTWithOvershootOfMoreFivePercent()
      .subscribe({
        next: data => {
          this.DtOvershootMoreFivePercent = data;
        },
        error: err => {
          console.log(err);
        },
        complete: () => {
        }
      });


    this.echeancesService.getAllServices()
      .subscribe({
        next: data => {
          this.serviceFilter = data.sort();
        },
        error: err => {
          console.log(err);
        },
        complete: () => {
        }
      });
  }

  /**
 * Initialisation: Rassemble les filtres, récupère une liste de demande et s'il est en première page de tableau, recalcule les totaux.
 */
  getFilteredAndPagedDemandsAndTotals() {
    let years: number[] = [];
    if (this.displayYears[0] == null) years.push(this.year);
    else years = years.concat(this.displayYears);

    let demandStatus: string[] = [];
    if (
      this.displayDemandsStatus.value == null ||
      this.displayDemandsStatus.value.length == 0
    )
      demandStatus = [];
    else demandStatus = this.displayDemandsStatus.value;

    let statusFilter: string[] = [];
    if (
      this.displayServiceFilter.value == null ||
      this.displayServiceFilter.value.length == 0)
      statusFilter = []
    else {
      statusFilter = this.displayServiceFilter.value
    }

    this.setYear.emit(this.getYear() + '');
    const applicationsNames = this.getSelectedApplicationsNames();
    const prestationNames = this.getSelectedPrestationTypes();

    this.isTableLoading = true;
    this.demands.clear();

    this.echeancesService.getPaginatedDemands(
      this.paginator.pageIndex,
      this.paginator.pageSize,
      this.sort.direction,
      this.filter.toUpperCase(),
      this.reffilter.toUpperCase(),
      this.galfilterValue.toUpperCase(),
      this.desfilterValue,
      years,
      applicationsNames,
      prestationNames,
      this.searchIndex,
      this.authenticationService.getUser().profile + '',
      demandStatus,
      statusFilter
    ).subscribe({
      next: (data: { demands: IDemand[]; total: number }) => {
        this.isTableLoading = false;
        if (data) {
          if (this.debugModeActivated) {
            console.log("[DT] Toutes les demandes avec application des filtres")
            console.log(data);
          }
          data.demands.forEach((demand) => {
            this.echeancesService.checkIfDemandValidableARRejectable(demand);
            this.demands.set(demand.demandNumber, demand);
          });
          this.resultsLength = data.total;
        }
      },
      error: err => {
        console.log(err);
      },
      complete: () => { }
    });

    this.updateManualData();
    this.updateNettingData();
    if (this.paginator.pageIndex == 0) {
      this.totalCost = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0,
      ];
      this.echeancesService
        .getDueTotal(
          this.filter.toUpperCase(),
          this.reffilter.toUpperCase(),
          this.galfilterValue.toUpperCase(),
          this.desfilterValue,
          years,
          applicationsNames,
          prestationNames,
          this.searchIndex,
          this.authenticationService.getUser().profile + '',
          demandStatus,
          statusFilter
        )
        .subscribe({
          next: value => {
            this.totalCost = value;
          },
          error: err => {
            console.log(err);
          },
          complete: () => {
          }
        });
    }
  }
  //Variante simplifiée ignorant les filtres (pour le premier appel).
  getInitialDemandsAndTotals() {
    let years: number[] = [];
    if (this.displayYears[0] == null) years.push(this.year);
    else years = years.concat(this.displayYears);
    this.setYear.emit(this.getYear() + '');

    const applicationsNames = this.getSelectedApplicationsNames();
    this.isTableLoading = true;
    this.echeancesService.getPaginatedDemands(
      0, 100,
      'null', 'null', 'null', 'null', 'null',
      years, applicationsNames,
      [],
      this.searchIndex, this.authenticationService.getUser().profile + '',
      [], []
    ).subscribe({
      next: (data: { demands: IDemand[]; total: number }) => {
        this.isTableLoading = false;
        if (data) {
          if (this.debugModeActivated) {
            console.log("[DT] Toutes les demandes lors du premier appel sans application des filtres")
            console.log(data);
          }
          data.demands.forEach((demand) => {
            this.echeancesService.checkIfDemandValidableARRejectable(demand);
            this.demands.set(demand.demandNumber, demand);
          });
          this.resultsLength = data.total;
        }
        //Initialisations supplémentaires.
        this.initValidationCalendarWorkflow();
      },
      error: err => {
        console.log(err);
      },
      complete: () => { }
    });

    this.updateManualData();
    this.updateNettingData();
    if (this.paginator.pageIndex == 0) {
      this.totalCost = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0,
      ];
      this.echeancesService
        .getDueTotal(
          'null',
          'null',
          'null',
          'null',
          years,
          applicationsNames,
          [],
          this.searchIndex,
          this.authenticationService.getUser().profile + '',
          [],
          []
        )
        .subscribe({
          next: value => {
            this.totalCost = value;
          },
          error: err => {
            console.log(err);
          },
          complete: () => {
          }
        });
    }
  }

  /**
  * Initialisation: Récupère les affectations des nettings
  */
  updateNettingData() {
    this._dfns.getNettingPacks().subscribe({
      next: value => {
        this.netting = value;
      },
      error: err => {
        console.log(err);
      },
      complete: () => {

      }
    });
  }

  /**
  * Initialisation: Importe les données de calendrier et définit les autorisations de validation.
  */
  initValidationCalendarWorkflow() {
    this._cs.getCalendrier().subscribe({
      next: value => {
        const cals: ICalendrier[] = value;
        let cal: ICalendrier = cals[0];
        for (const element of cals) {
          if (
            element.month == this._as.applicationSettings.Pack_current_month &&
            element.year == this._as.applicationSettings.Pack_current_year
          )
            cal = element;
        }

        this.lockValidation = false;

        const dateComp: Date = new Date(Date.now());
        cal.valid_debut = new Date(Date.parse(cal.valid_debut + ''));
        cal.ouverture = new Date(Date.parse(cal.ouverture + ''));
        cal.valid_fin = new Date(Date.parse(cal.valid_fin + ''));
        cal.valid_fin.setHours(23);
        cal.valid_fin.setMinutes(59);
        cal.initialisation = new Date(Date.parse(cal.initialisation + ''));

        if (this.debugModeActivated) {
          console.log("******** Mode débogage actif ********");
          console.log("[VALIDATION] Calendrier : ", cal);
          console.log("[VALIDATION] Date du jour (dateComp):", dateComp);
          console.log("[VALIDATION] cal.valid_fin.getTime() < dateComp.getTime() || cal.valid_debut.getTime() > dateComp.getTime()", cal.valid_fin.getTime() < dateComp.getTime() || cal.valid_debut.getTime() > dateComp.getTime())
          console.log("[VALIDATION] cal.valid_fin.getTime() < dateComp.getTime() || cal.ouverture.getTime() > dateComp.getTime()", cal.valid_fin.getTime() < dateComp.getTime() || cal.ouverture.getTime() > dateComp.getTime())
        }
        this.isClosed = cal.cloture != null && cal.cloture != undefined;
        if (
          (this.authenticationService.getUser().profile == UserProfile.CP_SNCF ||
            this.authenticationService.getUser().profile ==
            UserProfile.SERVICE_MANAGER) &&
          (!this.applicationSettings.Enable_SNCF_Validation ||
            cal.valid_fin.getTime() < dateComp.getTime() ||
            cal.valid_debut.getTime() > dateComp.getTime())
        ) {
          this.lockValidation = true;
        }

        if (
          this.authenticationService.getUser().profile == UserProfile.GUEST_CDS ||
          this.authenticationService.getUser().profile == UserProfile.GUEST_SNCF
        )
          this.lockValidation = true;


        if (
          (this.authenticationService.getUser().profile == UserProfile.CP_CDS ||
            this.authenticationService.getUser().profile ==
            UserProfile.MANAGER_CDS) &&
          (cal.valid_fin.getTime() < dateComp.getTime() ||
            cal.ouverture.getTime() > dateComp.getTime())
        ) {
          this.lockValidation = true;
        }
      },
      error: err => {
        console.log(err);
      },
      complete: () => {
        if (this.debugModeActivated) {
          Promise.all([
            this.getLockValidationForProfile(UserProfile.CP_CDS),
            this.getLockValidationForProfile(UserProfile.CP_SNCF),
            this.getLockValidationForProfile(UserProfile.GUEST_SNCF),
            this.getLockValidationForProfile(UserProfile.GUEST_CDS),
            this.getLockValidationForProfile(UserProfile.MANAGER_CDS),
            this.getLockValidationForProfile(UserProfile.SERVICE_MANAGER),

          ]).then(results => {
            console.log("[VALIDATION] Etat de lockValidation pour le profil CDS :", results[0]);
            console.log("[VALIDATION] Etat de lockValidation pour le profil SNCF :", results[1]);
            console.log("[VALIDATION] Etat de lockValidation pour le profil GUEST_SNCF :", results[2]);
            console.log("[VALIDATION] Etat de lockValidation pour le profil GUEST_CDS :", results[3]);
            console.log("[VALIDATION] Etat de lockValidation pour le profil MANAGER_CDS :", results[4]);
            console.log("[VALIDATION] Etat de lockValidation pour le profil SERVICE_MANAGER :", results[5]);
            console.log("[VALIDATION] Etat de lockvalidation pour l'utilisateur actuel:", this.lockValidation)
          }).catch(err => {
            console.log(err);
          });
        }
      }
    }
    );

  }

  /**
   * Utiliser uniquement pour le debug quand on est connecter en admin
   */
  getLockValidationForProfile(profile: UserProfile): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this._cs.getCalendrier().subscribe({
        next: value => {
          const cals: ICalendrier[] = value;
          let cal: ICalendrier = cals[0];
          for (const element of cals) {
            if (
              element.month == this._as.applicationSettings.Pack_current_month &&
              element.year == this._as.applicationSettings.Pack_current_year
            )
              cal = element;
          }

          const dateComp: Date = new Date(Date.now());
          cal.valid_debut = new Date(Date.parse(cal.valid_debut + ''));
          cal.ouverture = new Date(Date.parse(cal.ouverture + ''));
          cal.valid_fin = new Date(Date.parse(cal.valid_fin + ''));
          cal.valid_fin.setHours(23);
          cal.valid_fin.setMinutes(59);
          cal.initialisation = new Date(Date.parse(cal.initialisation + ''));

          let lockValidation = false;

          if (
            (profile == UserProfile.CP_SNCF ||
              profile == UserProfile.SERVICE_MANAGER) &&
            (!this.applicationSettings.Enable_SNCF_Validation ||
              cal.valid_fin.getTime() < dateComp.getTime() ||
              cal.valid_debut.getTime() > dateComp.getTime())
          ) {
            lockValidation = true;
          }

          if (
            (profile == UserProfile.CP_CDS ||
              profile == UserProfile.MANAGER_CDS) &&
            (cal.valid_fin.getTime() < dateComp.getTime() ||
              cal.ouverture.getTime() > dateComp.getTime())
          ) {
            lockValidation = true;
          }

          if (
            profile == UserProfile.GUEST_CDS ||
            profile == UserProfile.GUEST_SNCF
          ) {
            lockValidation = true;
          }

          resolve(lockValidation);
        },
        error: err => {
          console.log(err);
          reject(new Error("Une erreur est survenue"));
        },
        complete: () => {
        }
      });
    });
  }


  /**
   * Initialisation: Récupérer la liste exhaustive des noms d'applications, puis les demandes relatives au applications sélectionnées
   */
  getApplicationsNamesThenRelatedDemands(): void {
    this.applicationProjectService.getApplicationsNames().subscribe({
      next: (applicationsNames) => {
        let filtredApplicationsNames: string[] = [];

        if (
          this.authenticationService.getUser() &&
          (this.authenticationService.getUser().profile ==
            UserProfile.CP_SNCF ||
            this.authenticationService.getUser().profile == UserProfile.CP_CDS)
        ) {
          filtredApplicationsNames = applicationsNames.filter((element) => {
            return (
              this.authenticationService.getUser().applications &&
              this.authenticationService
                .getUser()
                .applications.filter((e) => e.name === element).length > 0
            );
          });
        } else {
          filtredApplicationsNames = applicationsNames;
        }

        if (
          this.perimeterControl.value != undefined &&
          this.perimeterControl.value != '' &&
          this.perimeterControl.value != null &&
          this.perimeterControl.value.applications != null
        ) {
          const apps: IApplication[] = this.perimeterControl.value.applications;
          filtredApplicationsNames = applicationsNames.filter((element) => {
            return apps.filter((e) => e.name === element).length > 0;
          });
        }

        this.applications = filtredApplicationsNames.map<IApplication>(
          (element) => {
            return { name: element, selected: false };
          },
        );
        if (this.orderForms.length == 0 && this.nettingAccounts.length == 0) {
          this.getInitialDemandsAndTotals();
        }
        else {
          this.getFilteredAndPagedDemandsAndTotals();
        }

        this.paginator.pageIndex = 0;
      },
      error: (err) => {
        console.log(err);
      },
      complete: () => {
      }
    });
  }



  /**
   * Montants: Retourne la liste des échéances d'une demande à un mois donné.
   */
  getEcheancesOfMonth(demand: IDemand, month: number): Echeance[] {
    if (demand == null && month == null) {
      return [];
    } else {
      const year = Math.floor(this.getYear() + month / 12); //Calculates year from column
      month = month % 12; //Calculates month from column

      const echeances: Echeance[] = [];
      const dues = demand.echeances;
      if (dues != null) {
        dues.forEach((echeance) => {
          if (
            moment(echeance.date).year() == year &&
            moment(echeance.date).month() == month
          )
            echeances.push(echeance);
        });
      }
      return echeances;
    }
  }

  /**
   * Montants: Retourne une échéance virtuelle faisant la somme des échéances d'une demande à un mois donné
   */
  fusedEcheance(demand: IDemand, month: number): Echeance[] {
    const echeanceRet = new Echeance();
    echeanceRet.cost = 0;
    echeanceRet.load = 0;

    this.getEcheancesOfMonth(demand, month).forEach((echeance) => {
      echeanceRet.cost += echeance.cost;
      echeanceRet.load! += echeance.load!;
      echeanceRet.status = echeance.status;
    });

    const ret: Echeance[] = [];
    if (echeanceRet.cost != 0) ret.push(echeanceRet);
    return ret;
  }



  /**
  * Filtres: Détermine les noms des applications cochées
  */
  getSelectedApplicationsNames(): string[] {
    let applicationsNames: string[] = this.applications
      .filter((element) => {
        return element.selected;
      })
      .map((element) => {
        return element.name as string;
      });

    if (
      !applicationsNames.length &&
      this.authenticationService.getUser() &&
      (this.authenticationService.getUser().profile == UserProfile.CP_SNCF ||
        this.authenticationService.getUser().profile == UserProfile.CP_CDS ||
        this.perimeterControl.value != null)
    ) {
      // dans le cas d'un profil CP SNCF, et que aucune application n'a été sélectionné, on prend les applications associées au profil

      applicationsNames = this.applications.map(
        (element) => element.name as string,
      );
    }

    return applicationsNames;
  }

  /**
   * Filtres: Détermine les noms des prestations cochées
   */
  getSelectedPrestationTypes(): string[] {
    const presNames: string[] = this.prestations
      .filter((element) => {
        return element.selected;
      })
      .map((element) => {
        return '*' + element.name;
      });
    return presNames;
  }

  /**
   * Filtres: Events lors de la modification de la valeur du filtre, met à jour la valeur puis relance une recherche de demande
   */
  filterList(event: Event) {
    this.filter = (event.target as HTMLInputElement).value;
    if ((event as KeyboardEvent).key === "Enter" || (event.target as HTMLInputElement).value === "") {
      this.getFilteredAndPagedDemandsAndTotals();
      this.paginator.pageIndex = 0;
    }
  }
  reffilterList(event: Event) {
    this.reffilter = (event.target as HTMLInputElement).value;
    if ((event as KeyboardEvent).key === "Enter" || (event.target as HTMLInputElement).value === "") {
      this.getFilteredAndPagedDemandsAndTotals();
      this.paginator.pageIndex = 0;
    }
  }
  galfilterList(event: Event) {
    this.galfilter = (event.target as HTMLInputElement).value;
    if ((event as KeyboardEvent).key === "Enter" || (event.target as HTMLInputElement).value === "") {
      this.getFilteredAndPagedDemandsAndTotals();
      this.paginator.pageIndex = 0;
    }
  }
  desfilterList(event: Event) {
    this.desFilter = (event.target as HTMLInputElement).value;
    if ((event as KeyboardEvent).key === "Enter" || (event.target as HTMLInputElement).value === "") {
      this.getFilteredAndPagedDemandsAndTotals();
      this.paginator.pageIndex = 0;
    }
  }

  /**
  * Filtres: Change le mode de recherche. Appelé à 'search' lors de la réinitialisation des filtres
  */
  updateApplicationFilter(index: string) {
    if (index != '') this.searchIndex = index;
    this.paginator.pageIndex = 0;
    this.getFilteredAndPagedDemandsAndTotals();
  }

  /**
  * Filtres: Réinitialise tous les filtres
  */
  disableApplicationFilter() {
    this.filterValue = '';
    this.reffilterValue = '';
    this.filter = '';
    this.reffilter = '';
    this.galfilterValue = '';
    this.desFilter = '';
    this.desfilterValue = '';
    this.applications.forEach((element) => {
      element.selected = false;
    });
    this.prestations.forEach((element) => {
      element.selected = false;
    });
    this.updateApplicationFilter('');
  }

  /**
  * Filtres: Event lors d'une sélection d'un périmètre applicatif: redétermine les applications disponibles
  */
  onPerimeterChange() {

    if (this.perimeterControl.value == null)
      this.getApplicationsNamesThenRelatedDemands();
    else if (this.perimeterControl.value.type == null)
      this.getApplicationsNamesThenRelatedDemands();
    else
      this.applicationProjectService
        .getApplicationsByDsi(this.perimeterControl.value.id)
        .subscribe({
          next: data => {
            this.perimeterControl.value.applications = data;
            this.getApplicationsNamesThenRelatedDemands();
          },
          error: err => {
            console.log(err);
          },
          complete: () => {
          }
        });
  }

  /**
   * Filtres: Récupère l'année à résumer selon le contexte et le filtre
   */
  getYear(): number {
    if (this.displayYears[0] == null) return this.year;
    return this.displayYears[0];
  }



  /**
  * Périmètres: Initialise la sélection des périmètres
  */
  updatePerimeterOptions() {
    this._us.getAllContacts().subscribe({
      next: data => {
        data.forEach((user) => {
          if (user.applications!.length > 0)
            switch (
            this.getProfileProvenance(
              this.authenticationService.getUser().profile!,
            )
            ) {
              case 'neither':
                this.users.push(user);
                break;
              case 'CDS':
                break;
            }
        });
        this.users.sort((a, b) => a.lastName!.localeCompare(b.lastName!));
      },
      error: err => {
        console.log(err);
      },
      complete: () => {
      }
    });
    this._ds.getDsis().subscribe({
      next: data => {
        data.forEach((dsi) => {
          switch (
          this.getProfileProvenance(
            this.authenticationService.getUser().profile!,
          )
          ) {
            case 'neither':
            case 'CDS':
              this.dsis.push(dsi);
              break;
            case 'SNCF':
              if (
                dsi.type == DsiTypes.DirectionMetier ||
                dsi.type == DsiTypes.DSI ||
                dsi.type == DsiTypes.DDSI
              )
                this.dsis.push(dsi);
              break;
          }
        });
      },
      error: err => {
        console.log(err);
      },
      complete: () => {
      }
    });
  }

  /**
  * Périmètres: Retourne true si l'utilisateur est Invité CDS ou SNCF
  */
  isGuest(): boolean {
    switch (this.authenticationService.getUser().profile) {
      case UserProfile.GUEST_CDS:
      case UserProfile.GUEST_SNCF:
        return true;
      default:
        return false;
    }
  }

  /**
  * Périmètres: Retourne si l'utilisateur a un profil CDS ou SNCF
  */
  getProfileProvenance(profile: UserProfile): string {
    switch (profile) {
      case UserProfile.CP_CDS:
      case UserProfile.GUEST_CDS:
      case UserProfile.MANAGER_CDS:
        return 'CDS';

      case UserProfile.CP_SNCF:
      case UserProfile.GUEST_SNCF:
      case UserProfile.SERVICE_MANAGER:
        return 'SNCF';
    }
    return 'neither';
  }



  /**
  * Détails de demande:  Clic sur une des lignes de la liste des demandes: Ouvre la popup des détails de la demande
  */
  openDemandDetails(row: IDemand) {
    this.getYear();
    const dialogRef = this.dialog.open(EcheanceDetailsComponent, {
      data: {
        data: Array.from(this.demands.values()),
        index: this.getIndexFromDemand(row),
        validatable: !this.lockValidation,
        min: 0,
        max: Array.from(this.demands.values()).length - 1,
        month: this.currPack.packMonth,
        year: this.currPack.packYear
      }

    });
    dialogRef.componentInstance.refreshTable.subscribe({
      next: () => {
        this.getFilteredAndPagedDemandsAndTotals()
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
      }
    });
  }

  /**
  * Détails de demande: Détermine l'index de la ligne correspondant à une demande donnée
  */
  getIndexFromDemand(row: IDemand) {
    const arr = Array.from(this.demands.values());
    for (let i = 0; i < arr.length; i++)
      if (arr[i].demandNumber == row.demandNumber) return i;
    return -1;
  }



  /**
   * Validation de groupe: Valider les écheances des demandes séléctionnées
   */
  public validateDemands() {
    this.isAlreadyValidating = true;
    const selectedDemandsNumbers: string[] = [];
    Array.from(this.demands.values())
      .filter((demand) => {
        return demand.selected;
      })
      .forEach((demand: IDemand) => {
        selectedDemandsNumbers.push(demand.demandNumber);
      });
    if (!selectedDemandsNumbers.length) {
      this.isAlreadyValidating = false;
      return;
    }

    this.echeancesService.validateDemands(selectedDemandsNumbers).subscribe({
      next: (data) => {
        if (data?.error) {
          console.error(data.error);

          if (data.demandsNumbersInError) {
            this.openErrorSnackBar(
              Constants.ErrorMessages.DEMANDS_NOT_VALIDATED +
              data.demandsNumbersInError,
            );
          } else {
            this.openErrorSnackBar(Constants.ErrorMessages.SERVER_TECH_ERROR);
          }

          if (data.updatedDemands?.length) {
            this.synchronizeUpdatedDemandsStatuses(data);
            this.updateDemandsValidationAndRejectionCapabilities();
            this.isAlreadyValidating = false;
          }
        } else if (data.updatedDemands?.length) {
          this.openSuccessSnackBar(Constants.Messages.DEMANDS_VALIDATED);
          this.synchronizeUpdatedDemandsStatuses(data);
          this.updateDemandsValidationAndRejectionCapabilities();
          this.isAlreadyValidating = false;
        }
        this.asyncLock = true;
      },

      error: (err) => {
        console.error(err);
        this.isAlreadyValidating = false;
      },
      complete: () => {
      }
    });
  }

  /**
   * Validation de groupe: Refuser les écheances des demandes séléctionnées
   */
  public rejectDemands() {
    this.isAlreadyValidating = true;
    const selectedDemandsNumbers: string[] = [];
    Array.from(this.demands.values())
      .filter((demand) => {
        return demand.selected;
      })
      .forEach((demand: IDemand) => {
        selectedDemandsNumbers.push(demand.demandNumber);
      });

    if (!selectedDemandsNumbers.length) {
      this.isAlreadyValidating = false;
      return;
    }

    this.openCommentPrompt()
      .afterClosed()
      .subscribe({
        next: comment => {
          if (comment) {
            const reasonOfRejection = "Motif du rejet : " + comment;
            this.echeancesService
              .rejectDemands(selectedDemandsNumbers, reasonOfRejection)
              .subscribe({
                next: (data) => {
                  if (data?.error) {
                    console.error(data.error);

                    if (data.demandsNumbersInError) {
                      this.openErrorSnackBar(
                        Constants.ErrorMessages.DEMANDS_NOT_REJECTED +
                        data.demandsNumbersInError,
                      );
                    } else {
                      this.openErrorSnackBar(
                        Constants.ErrorMessages.SERVER_TECH_ERROR,
                      );
                    }

                    if (data.updatedDemands?.length) {
                      this.synchronizeUpdatedDemandsStatuses(data);
                      this.updateDemandsValidationAndRejectionCapabilities();
                      this.isAlreadyValidating = false;
                    }
                  } else if (data.updatedDemands?.length) {
                    this.openSuccessSnackBar(Constants.Messages.DEMANDS_REJECTED);
                    this.synchronizeUpdatedDemandsStatuses(data);
                    this.updateDemandsValidationAndRejectionCapabilities();
                    this.isAlreadyValidating = false;
                  }
                },
                error: (err) => {
                  console.error(err);
                  this.isAlreadyValidating = false;
                },
                complete: () => {
                }
              });
          }
        },
        error: (err) => {
          console.error(err);
        },
        complete: () => {
        }
      });
  }

  /**
  * Validation de groupe: Met à jour les échéances et la capacité à valider de toutes les demandes affectée par une validation/refus de groupe.
  */
  public synchronizeUpdatedDemandsStatuses(data: any) {
    data.updatedDemands.forEach((updatedDemand: IDemand) => {
      const foundDemand = this.demands.get(updatedDemand.demandNumber);
      if (foundDemand?.echeances && updatedDemand.echeances) {
        foundDemand.echeances = updatedDemand.echeances;
        this.echeancesService.checkIfDemandValidableARRejectable(foundDemand);
      }
    });
  }

  /**
  * Validation de groupe: Cocher toutes les demandes disponibles
  */
  public selectAllDemands() {
    Array.from(this.demands.values())
      .filter((demand) => {
        return (
          this.currentDue(demand).cost != 0 &&
          (this.canBeValidated(demand) || demand.rejectable)
        );
      })
      .forEach((demand) => {
        demand.selected = this.selectAll;
      });

    this.updateDemandsValidationAndRejectionCapabilities();
  }

  /**
  * Validation de groupe: Rafraichit les coches de chaque demande selon le statut d'échéance et le netting.
  */
  public updateDemandsValidationAndRejectionCapabilities() {
    const validableDemands = Array.from(this.demands.values()).filter(
      (demand) => {
        return this.canBeValidated(demand) && demand.selected;
      },
    );
    this.updateValidationCapability.emit(!!validableDemands.length);
    const rejectableDemands = Array.from(this.demands.values()).filter(
      (demand) => {
        return demand.rejectable && demand.selected;
      },
    );
    this.updateRejectionCapability.emit(!!rejectableDemands.length);
  }

  /**
  * Validation de groupe: Détermine si une demande peut être validée selon le contexte
  */
  public canBeValidated(d: IDemand): boolean {
    // demande non validable si serviceType = "A renseigner"
    if (d.serviceType == "A renseigner") return false;

    if (!d.validable) return false;

    if (
      !this.authenticationService.isUserAdmin() &&
      this.authenticationService.getUser().profile != UserProfile.CP_SNCF &&
      this.authenticationService.getUser().profile !=
      UserProfile.SERVICE_MANAGER
    ) {
      for (const element of this.netting) {
        if (element.ndemand == d.demandNumber) return true;
      }
      return false;
    }
    return true;
  }

  /**
  * Validation de groupe: Ouvre une fenêtre de commentaire lorsque refuse des demandes
  */
  openCommentPrompt(): MatDialogRef<CommentPromptComponent, any> {
    return this.dialog.open(CommentPromptComponent, {});
  }



  /**
  * Montants: Calcul du montant total Constaté fin M-1
  */
  public getEndMinusOne(): number {
    let res = 0;
    for (let i = 0; i < this.currMonthIndex - 1; i++)
      res += this.totalCost[i];
    return res;
  }

  /**
  * Montants: Calcul du montant total Constaté fin M
  */
  public getEnd(): number {
    let res = this.getEndMinusOne();
    res += this.totalCost[this.currMonthIndex - 1] + this.totalCost[37];
    return res;
  }

  /**
  * Montants: Détermine si l'index du mois correspond au mois en cours de facturation
  */
  isCurrMonth(index: number): boolean {
    if (
      this.currPack == null ||
      index % 12 != (this.currPack.packMonth - 1) % 12
    )
      return false;
    if (index < 12 * (this.currPack.packYear - this.getYear())) return false;
    if (index >= 12 * (1 + this.currPack.packYear - this.getYear()))
      return false;
    return true;
  }

  /**
  * Montants: Détermine si une demande est dans le cas d'un dépassement de commande (Cas déterminés à l'avance dans le onInit()).
  */
  isOvershoot(demand: IDemand): boolean {
    if (!demand.reference) {
      return false;
    }
    if (this.DtOvershootMoreFivePercent.includes(demand.reference)) {
      return true;
    }
    return false;
  }

  /**
  * Montants: Détermine à la volée les montants de la colonne "Total < XXXX"
  */
  historicSumm(demand: IDemand): number {
    let ret = 0;
    for (const ech of demand.echeances) {
      if (
        ech !== undefined &&
        ech.date !== undefined &&
        ech.date !== null &&
        !ech.projected &&
        Number((ech.date + '').split('-')[0]) < this.year
      )
        ret += ech.cost;
    }

    return ret;
  }

  /**
  * Montants: Variante de fusedEcheance prennant en compte les retard de facturation sur l'ensemble des jalons.
  */
  currentDue(d: IDemand): Echeance {
    const ech = new Echeance();
    ech.cost = 0;

    ech.load = 0;
    let res = 0;
    for (const element of d.echeances) {
      if (element.date) {
        const date: Date = new Date(element.date!);
        if (element.delayedCost != null) res += element.delayedCost;
        if (
          date.getFullYear() == this.currPack.packYear &&
          date.getMonth() == this.currPack.packMonth - 1
        ) {
          ech.cost += element.cost;
          ech.load += element.load!;
          ech.status = element.status;
          ech.date = element.date;
        }
      }
    }
    ech.cost += res;
    return ech;
  }



  /**
  * Exports: Lance l'export des commentaires
  */
  exportData() {
    this.spinner = true;
    this._es.exportData().subscribe({
      next: (data) => {
        if (data) {
          const blob: any = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          window.URL.createObjectURL(blob);
          fileSaver.saveAs(
            blob,
            'Export des commentaires - ' +
            new Date().toLocaleDateString().replace('_', '-'),
          );
          this.spinner = false;
        } else {
          this.spinner = false;
          this.openErrorSnackBar('Pas de commentaires à exporter');
        }
      },
      error: (err) => {
        console.error(err);
        this.spinner = false;
        this.openErrorSnackBar('L\'export des commentaires a échoué');
      },
      complete: () => {
      }
    });
  }


  /**
  * Exports: Exporte les données collectées sous la forme d'un fichier .csv
  */
  downloadFile() {
    this.spinner = true;
    const apps = this.applications;
    const appNames: string[] = [];
    apps.forEach((element) => {
      appNames.push(element.name + '');
    });

    this.jiraImportService
      .getTimedPack(this.exportYear + '', appNames)
      .subscribe({
        next: (data) => {
          const blob: any = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          window.URL.createObjectURL(blob);
          fileSaver.saveAs(
            blob,
            'Données Collectées ' +
            this.exportYear +
            ' - ' +
            this._as.applicationSettings.CDS_name +
            ' - ' +
            new Date().toLocaleDateString().replace('_', '-') +
            ' ' +
            new Date().toLocaleTimeString().replace('_', '-'),
          );
          this.spinner = false;
        },
        error: (err) => {
          console.log(err);
          this.spinner = false;
          this.openErrorSnackBar('L\'export du pack a échoué');
        },
        complete: () => {
        }
      });
  }


  /**
  * Affichage: Afficher/Cacher les filtres d'application/prestation.
  */
  toggleAppNamesNav() {
    this.isAppNamesNavVisible = !this.isAppNamesNavVisible;
    this.buttonTextFilter = this.isAppNamesNavVisible ? 'Masquer' : 'Afficher';
  }

  openErrorSnackBar(message: string) {
    this.snackbarService.openSnackBar(message, 'error-snack-bar');
  }

  openSuccessSnackBar(message: string) {
    this.snackbarService.openSnackBar(message);
  }


  /**
   * Affichage: Retourne la couleur (Classe CSS) correspondante au statut de demande.
   */
  statusColor(status: string): string {
    switch (status) {
      case 'Evalué':
      case 'Chiffré':
      case 'Approuvé':
      case 'Engagé':
      case 'Analysé':
      case 'En attente':
      case 'Implémenté':
      case 'Testé':
        return 'state-blue';
      case 'Abandonné':
        return 'state-red';
      case 'Fermé':
      case 'Livré':
      case 'Réceptionné':
      case 'Accepté':
      case 'Mise en production':
        return 'state-green';
      default:
        return 'status-grey';
    }
  }



  /**
  * Critères d'affichage: Affichage en orange des demandes qui n'ont pas de bons de commande valide, ou s'il n'est pas reconnu.
  */
  hasUnknownCommand(d: IDemand): boolean {
    if (
      d.reference == null ||
      d.reference == '' ||
      d.reference.toUpperCase().includes('DEROGATION') ||
      d.reference.toUpperCase().includes('SAUVEGARDE')
    )
      return true;
    if (this.orderForms.indexOf(d.reference) == -1) return true;
    return false;
  }

  /**
  * Critères d'affichage: Ajoute un '*' avant une demande qui possède des données manuelles en mémoire.
  */
  hasManualData(demand: IDemand): boolean {
    return this.manualDataList.findIndex((e) => e == demand.demandNumber) != -1;
  }

/**
 * Remise a zero des filtres
 */
  resetFilters() {
    // Réinitialisez les valeurs des filtres textuels
    this.filter = '';
    this.reffilter = '';
    this.galfilter = '';
    this.desFilter = '';

    // Réinitialisez les valeurs des contrôles de formulaire
    this.displayDemandsStatus.setValue([]);
    this.displayServiceFilter.setValue([]);

    // Mettez à jour la table avec les filtres réinitialisés
    this.getFilteredAndPagedDemandsAndTotals();
  }
}
