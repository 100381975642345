import { Injectable } from '@angular/core';
import { CustomSnackbarComponent } from '../custom-snackbar/custom-snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(private _snackBar: MatSnackBar) { }

  openSnackBar(message: string, panelClass: string = 'success-snack-bar') {
    this._snackBar.openFromComponent(CustomSnackbarComponent, {
      duration: 10000,
      data: { message: message },
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: panelClass
    });
  }
}
