import { Component, Inject, OnInit } from '@angular/core';
import { ApplicationSettings } from 'src/app/shared/models/application-settings';
import { ApplicationSettingsService } from '../application-settings.service';
import { ApplicationSettingsComponent } from '../application-settings.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-fusion-pack-modal',
  templateUrl: './fusion-pack-modal.component.html',
  styleUrls: ['./fusion-pack-modal.component.less'],
})
export class FusionPackModalComponent implements OnInit {
  applicationSettings: ApplicationSettings;

  constructor(
    public dialogRef: MatDialogRef<ApplicationSettingsComponent>,
    public applicationSettingsService: ApplicationSettingsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.applicationSettings =
      this.applicationSettingsService.getApplicationSettings();
  }

  ngOnInit(): void {
    this.packFusion();
  }

  public canFusion() {
    //Pack fusion can be done only if we are in december in normal workflow
    return this.applicationSettings.Pack_current_month == 12;
  }

  public packFusion() {
    this.data.confirmMerge = true;
  }
}
