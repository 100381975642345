import {Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {ApplicationSettingsService} from 'src/app/core/application-settings/application-settings.service';
import {DirectoryService} from 'src/app/core/application-settings/repository-manager/directory.service';
import {GuideComponent} from 'src/app/guide/guide.component';
import {UserProfile} from '../models/user-profile';
import {AuthenticationService} from '../services/authentication.service';
import {ConfigService} from '../services/config.service';
import {ThemeService} from '../services/theme.service';

@Component({
    selector: 'app-menu-bar',
    templateUrl: './menu-bar.component.html',
    styleUrls: ['./menu-bar.component.less']
})
export class MenuBarComponent {

      constructor(public router: Router,
                public authenticationService: AuthenticationService,
                private applicationSettingsService: ApplicationSettingsService,
                public config: ConfigService,
                public theme: ThemeService,
                public dialog: MatDialog) {
    }

    isAdministrationTabShowable() {
        return this.authenticationService.getUser().profile != UserProfile.CP_CDS
            && this.authenticationService.getUser().profile != UserProfile.CP_SNCF
            && this.authenticationService.getUser().profile != UserProfile.GUEST_SNCF
            && this.authenticationService.getUser().profile != UserProfile.GUEST_CDS;
    }

    isCollectedDataTabHidden() {
        return !this.applicationSettingsService.getApplicationSettings().enableCollectedDataTab &&
            this.authenticationService.getUser().profile != UserProfile.ADMIN;
    }

    isFacturationHidden() {
        return this.authenticationService.getUser().profile != UserProfile.ADMIN 
    }

    openGuide(s: string) {
        this.dialog.open(GuideComponent, {
            data: s
        })
    }


}
