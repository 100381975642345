<div class="mat-elevation-z4 table">
  <table class="light thin-table" *ngIf="errors[0]">
    <caption class="displayNone">
      Demande, avertissements et Erreurs
    </caption>
    <thead>
    <tr>
      <th> Demande</th>
      <th> Avertissements</th>
      <th> Erreurs</th>
    </tr>
    </thead>
    <tbody>
    <tr class="thin-tr" *ngFor="let error of errors">
      <td>{{error["description"]}}</td>
      <td (click)="openErrorDetails(error)">
        <div class="warn">{{error["rulesWarnings"].length}}<span class="material-icons">warning</span></div>
      </td>
      <td (click)="openErrorDetails(error)">
        <div class="error">{{error["rulesErrors"].length}}<span class="material-icons">warning</span></div>
      </td>
    </tr>
    </tbody>
  </table>
</div>
