<div class="card">

  <app-project [project]="projectSelected" [nettingAccounts]="nettingAccounts" [nettingsNames]="nettingsNames"
    (updateEvent)="updateList($event)" (clearEvent)="clearSelected()"
    [ngStyle]="authenticationService.isUserAdmin()? {}: {'display':'none'}"></app-project>

  <div class="data-header">
    <mat-form-field class="projectFilter">
      <mat-label>Filtre</mat-label>
      <input matInput (keyup)="filterList($event)">
    </mat-form-field>
    <div class="exportBtn">
    <button mat-raised-button (click)="exportData()" class="indigo"
      [style.background-color]="theme.getColor('Main Dark')">
      <mat-icon>get_app</mat-icon>
      Export Excel
    </button>
    </div>
  </div>

  <mat-table #table class="mat-elevation-z8 table-container" [dataSource]="projects" matSort>
    <ng-container matColumnDef="name" >
      <mat-header-cell *matHeaderCellDef  id="lastName" mat-sort-header>Nom</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="nettings">
      <mat-header-cell *matHeaderCellDef  id="nettings"> Compte Netting</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <ng-container *ngFor="let netting of element.nettingAccounts">{{netting.name}},</ng-container>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="user">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Responsable </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.user?.firstName}} {{element.user?.lastName}}</mat-cell>
      <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="key_jira" >
      <mat-header-cell *matHeaderCellDef  id="key_jira" mat-sort-header>Clé JIRA</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.key_jira }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="beb">
      <mat-header-cell *matHeaderCellDef mat-sort-header>BEB</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.beb == true ? 'BEB' : '' }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef class="stick-right " id="actions">Actions</mat-header-cell>
      <mat-cell *matCellDef="let element" class="stick-right">
        <div class="btn_actions">
          <button mat-icon-button aria-label="delete" (click)="onEditRowPencel(element)"
            [disabled]="!authenticationService.isUserAdmin()">
            <mat-icon>mode_edit</mat-icon>
          </button>
          <button mat-icon-button aria-label="delete" (click)="onDeleteRow(element)"
            [disabled]="!authenticationService.isUserAdmin()">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row  *matRowDef="let row; columns: displayedColumns;" (click)="onEditRow(row)"></mat-row>
  </mat-table>
</div>