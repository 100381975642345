import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { CalendrierService } from '../../../calendrier-pack/calendrier.service';
import { IMonthsClos } from 'src/app/shared/models/calendrier';
import { ThemeService } from "src/app/shared/services/theme.service";
import { ApplicationSettings } from "src/app/shared/models/application-settings";
import { ApplicationSettingsService } from "src/app/core/application-settings/application-settings.service";


@Component({
  selector: 'app-facturation-netting_regularisation',
  templateUrl: './facturation-netting_regularisation.component.html',
  styleUrls: ['./facturation-netting_regularisation.component.less']
})


export class FacturationNettingRegularisationComponentForm implements OnInit {

  public packYears: number[] = [];
  public currentYear: number = new Date().getFullYear();
  public yearSelected!: number;

  public packFusionned: boolean = false;

  @Output()
  emitYearSelected = new EventEmitter<number>();

  public months: string[] = [];
  public monthSelected!: string;

  @Output()
  emitMonthSelected = new EventEmitter<string>();

  @Output()
  emitPackYearSelected = new EventEmitter<number>();

  public lastMonthClos!: number;
  public firstMonthClos!: number;
  public monthsClosByYear: IMonthsClos[] = [];

  public vues: string[] = ['Netting mois', 'Regularisation'];
  public vueSelected!: string;

  @Output()
  emitVueSelected = new EventEmitter<string>();

  @Input()
  public isFormDisabled = false;

  @Output()
  emitNetRegisShown = new EventEmitter<boolean>();

  public packYear: number = this.currentYear;
  public facturationYears: number[] = [];

  constructor(public _calendrierService: CalendrierService, 
    public theme: ThemeService,
    private applicationSettingsService: ApplicationSettingsService,
  ) {

  }

  ngOnInit(): void {
    this.vueSelected = "Netting mois";
    this.emitVueSelected.emit(this.vueSelected);
    this.displayPackYear();
    this.packFusionned = this.isPackFusionned();
    this.displayYearFacturation();
    this.getMonthClosService();
  }

  displayPackYear(): void {
    this.packYears = [this.packYear-1, this.packYear];
    this.yearSelected = this.currentYear;
    this.emitYearSelected.emit(this.yearSelected);
  }

  displayMonth(): void {
    this.monthSelected = this.lastMonthClos.toString();
    if (this.monthSelected.length == 1) {
      this.monthSelected = "0" + this.monthSelected;
    }
    this.months = [];
    for (let i = this.firstMonthClos; i < (this.lastMonthClos + 1); i++) {
      let m: string = i.toString();
      if (m.length == 1) {
        m = "0" + m;
      }
      this.months.push(m);
    }
    this.emitMonthSelected.emit(this.monthSelected);
  }

  displayYearFacturation(): void {
    this.facturationYears = [];
    if (this.packFusionned) {
      this.facturationYears = [this.packYear];
    }
    else {
      this.facturationYears = [this.packYear , this.packYear +1];
    }
  }

  getMonthClosService(): void {
    this._calendrierService.getMonthsClos(this.packYear).subscribe({
      next: data => {
        if (data) {
          this.monthsClosByYear = data;
          this.lastMonthClos = this.monthsClosByYear[this.monthsClosByYear.length - 1].month;
          this.firstMonthClos = this.monthsClosByYear[0].month;
          this.displayMonth();
          this.yearSelected = this.packYear;
          this.emitYearSelected.emit(this.yearSelected);
          this.emitMonthSelected.emit(this.monthSelected);
          this.emitPackYearSelected.emit(this.packYear);
        }
      },
      error: err => {
        console.log(err)
      },
      complete: () => {
      }
    });
  }


  suivantService(): void {
    this.isFormDisabled = true;
    this.emitNetRegisShown.emit(true);
  }

  changePackYear(year: number): void {
    this.packYear = year;
    this.displayYearFacturation();
    this.getMonthClosService();
    this.emitPackYearSelected.emit(this.packYear);
  }

  changeFactYear(year: number): void {
    this.yearSelected = year;
    this.emitYearSelected.emit(this.yearSelected);
  }

  changeMonth(month: number): void {
    this.monthSelected = month.toString();
    if (this.monthSelected.length == 1) {
      this.monthSelected = "0" + this.monthSelected;
    }
    this.emitMonthSelected.emit(this.monthSelected);
  }

  changeVue(vue: string): void {
    this.vueSelected = vue;
    this.emitVueSelected.emit(this.vueSelected);
  }

  private isPackFusionned(): boolean {
    const applicationSettings: ApplicationSettings =
      this.applicationSettingsService.getApplicationSettings();
    return applicationSettings.display_fusion_pack;
  }
}
