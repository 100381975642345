import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { INettingSchema } from 'src/app/shared/models/netting-schema';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { NettingService } from '../../netting-account/netting.service';
import { NettingSchemaService } from '../nettingschema.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';

@Component({
  selector: 'app-netting-schema-form',
  templateUrl: './netting-schema-form.component.html',
  styleUrls: ['./netting-schema-form.component.less']
})
export class NettingSchemaFormComponent implements OnInit {

  formGroup: UntypedFormGroup;
  public natures: string[] = [];
  public compte_imputes: any[] = [];

  asEdited = false;
  btnStatus = false;

  @Input()
  netting_schema!: INettingSchema;

  @Input()
  nettingsSchemas: any[] = [];

  @Output()
  updateEvent = new EventEmitter<INettingSchema>();

  @Output()
  clearEvent = new EventEmitter<any>();

  constructor(
    private _fb: UntypedFormBuilder,
    private _ns: NettingService,
    private _nss: NettingSchemaService,
    private dialogService: DialogService,
    private snackbarService: SnackbarService
  ) {
    this.formGroup = this._fb.group({
      id: [''],
      name: ['', Validators.required],
      line: ['', Validators.required],
      nature: ['', Validators.required],
      na_name: [''],
      line_ref: ['', Validators.required],
      taux1: ['', Validators.required],
      taux2: [''],
      isEnabled: [''],
      compte_imputes_id: ['']
    }, { validators: this.referenceLineValidator() });
  }

  ngOnInit(): void {
    this.natures = ["GOUV-CAP", "GOUV-Sogeti", "Projet", "PROV-RFA"];
    this.getComptes();
  }

  getComptes(): void {
    this._ns.getNettingNames().subscribe({
      next: data => {
        if (data) {
          this.compte_imputes = [...data];
        }
      },
      error: err => {
        console.log(err);
      }
    });
  }

  openErrorSnackBar(message: string) {
    this.snackbarService.openSnackBar(message, 'error-snack-bar');
  }

  openSuccessSnackBar(message: string) {
    this.snackbarService.openSnackBar(message);
  }

  updateFormGroup() {
    const pUpdate = {
      id: this.netting_schema.id,
      name: this.netting_schema.name,
      line: this.netting_schema.line,
      nature: this.netting_schema.nature,
      na_name: this.netting_schema.na_name,
      line_ref: this.netting_schema.line_ref,
      taux1: this.netting_schema.taux1,
      taux2: this.netting_schema.taux2,
      isEnabled: this.netting_schema.isEnabled,
      compte_imputes_id: this.netting_schema.na_id
    };
    this.formGroup.setValue(pUpdate);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.netting_schema) return;

    this.asEdited = false;
    if (!this.netting_schema || !this.netting_schema.hasOwnProperty('line') || this.netting_schema.isEnabled && this.netting_schema.name === '') {
      this.updateFormGroup();
      this.asEdited = false;
      this.btnStatus = false;
    } else if (this.netting_schema.isEnabled && this.netting_schema.name != '') {
      this.updateFormGroup();
      this.asEdited = true;
      this.btnStatus = false;
    } else if (!this.netting_schema.isEnabled && this.netting_schema.name != '') {
      this.updateFormGroup();
      this.asEdited = false;
      this.btnStatus = true;
    }
  }

  onCompteChange(event: any): void {
    const selectedCompteName = event.value;
    if (!selectedCompteName) {
      this.formGroup.get('compte_imputes_id')?.setValue(null);
    } else {
      const selectedCompte = this.compte_imputes.find(compte => compte.na_name === selectedCompteName);
      if (selectedCompte) {
        this.formGroup.get('compte_imputes_id')?.setValue(selectedCompte.na_id);
      }
    }
  }

  onSubmit(): void {
    if (!this.asEdited && !this.btnStatus) {
      this._nss.createNettingSchema(this.formGroup.value).subscribe({
        next: data => {
          this.updateEvent.emit(data);
          this.openSuccessSnackBar("Schéma de netting créé avec succès");
        },
        error: err => {
          console.log(err);
          this.openErrorSnackBar("Erreur lors de la création du schéma de netting");
        },
        complete: () => {
          this.resetForm();
        }
      });
    } else if (!this.asEdited && this.btnStatus) {
      this._nss.updateNettingSchema(this.netting_schema.id ?? '', this.formGroup.value).subscribe({
        next: data => {
          this.updateEvent.emit(data);
          this.openSuccessSnackBar("Schéma de netting mis à jour avec succès");
        },
        error: err => {
          console.log(err);
          this.openErrorSnackBar("Erreur lors de la mise à jour du schéma de netting");
        },
        complete: () => {
          this.resetForm();
        }
      });
    }
  }

  clear(): void {
    this.resetForm();
    this.clearEvent.emit();
  }

  is_edit(asEdited: boolean) {
    this.asEdited = !asEdited;
    this.btnStatus = true;
  }

  deleteProjectForm() {
    if (this.asEdited) {
      this._nss.deleteNettingSchema(this.netting_schema).subscribe({
        next: data => {
          this.clear();
          this.updateEvent.emit(data);
        },
        error: err => {
          console.log(err);
        },
        complete: () => {
        }
      });
    }
  }

  confirmDelete() {
    this.dialogService.openConfirmDialog(this.netting_schema.name)
      .afterClosed().subscribe({
        next: res => {
          this.deleteProjectForm();
          this.openSuccessSnackBar("Schéma de netting supprimé avec succès");
        },
        error: err => {
          console.log(err);
          this.openErrorSnackBar("Erreur lors de la suppression du schéma de netting");
        },
        complete: () => {
          this.resetForm();
        }
      });
  }

  private resetForm() {
    this.formGroup.reset();
    this.asEdited = false;
    this.btnStatus = false;
  }

  // Validation personnalisée pour vérifier que la ligne de référence est valide
 referenceLineValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const line = control.get('line')?.value;
    const lineRef = control.get('line_ref')?.value;

    if (lineRef < 0 || lineRef > line) {
      return { invalidReferenceLine: true };
    }
    return null;
  };
}
}
