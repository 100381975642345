<mat-spinner class="loading-main" *ngIf="spinner"></mat-spinner>
<div *ngIf="!spinner">
  <div class="card" *ngIf="dsi != null">
    <mat-accordion class="example-headers-align">
      <div *ngFor="let element of dsi"
           [ngClass]="{'disabled' : element.curr_engage == null && userApplications.length > 0, 'transparent' : element.curr_engage == null}">
        <mat-expansion-panel hideToggle>
          <mat-expansion-panel-header (click)="toggleDsi(element)" class="project"
                                      [style.border]="'2px solid ' + theme.getColor('List Dark')"
                                      [style.background-color]="theme.getColor('List Light')"
                                      [ngClass]="{'empty-project': element.jalons == 0, 'red-project':hasAlerts(element)}">
            <mat-panel-title class="graph-title">
              <div class="domain_container">
                <div class="left_graph " *ngIf="enableCDSgraph()">
                  <mat-progress-spinner title="Avancement CDS" class="example-margin mat-progress-spinner"
                                        [ngClass]="{'complete-circle': element.jauge_gauche == 100 ,
                            'empty-circle': element.jauge_gauche == 101 ,
                            'warning-circle': element.jauge_gauche  < 50 ,
                            'default-circle' : true }" [value]=element.jauge_gauche%102 [diameter]="65">
                  </mat-progress-spinner>
                  <h1 class="inside-spinner" *ngIf="element.jauge_gauche != 100">
                    {{ element.jauge_gauche % 101 | number: '1.0-0':'fr-FR' }}<span
                    class="small-percent">%</span>
                  </h1>
                  <h1 class="inside-spinner" *ngIf="element.jauge_gauche == 100">
                                    <span class="material-icons">
                                        <b>done</b>
                                    </span>
                  </h1>
                </div>
                <div class="content">
                  <div class="title">
                    <h3 [style.color]="theme.getColor('List Dark')"><b>{{ element.name }}</b> -
                      (<b>{{ element.jalons }}</b>
                      Jalons - {{ element.cout | number: '1.0-0':'fr-FR' }} €)</h3>
                  </div>
                  <div class="all_status">
                    <div class="status_1 status" title="Informations manquantes"
                         [class]="element.jalons_informations_manquantes != 0 ? '': 'invisible'">
                      <mat-icon class="status-icon"
                                [ngStyle]="{color:Constants.ECHEANCES_STATUSES_ICONS.get(EcheanceStatus.INFORMATION_MANQUANTE)!.color}">
                        {{ Constants.ECHEANCES_STATUSES_ICONS.get(EcheanceStatus.INFORMATION_MANQUANTE)!.name }}
                      </mat-icon>
                      <div class="count">
                        <p class="amount-count">
                          <b>{{ element.jalons_informations_manquantes }}</b> jalons
                        </p>
                        <p class="amount-cost">{{
                            element.cout_informations_manquantes | number:
                              '1.0-0':'fr-FR'
                          }}
                          €</p>
                      </div>
                    </div>
                    <div class="status_2  status" title="En attente de confirmation CDS"
                         [class]="element.jalons_attente_CDS != 0 ? '': 'invisible'">
                      <mat-icon class="status-icon"
                                [ngStyle]="{color:Constants.ECHEANCES_STATUSES_ICONS.get(EcheanceStatus.EN_ATTENTE_VALIDATION_CDS)!.color}">
                        {{ Constants.ECHEANCES_STATUSES_ICONS.get(EcheanceStatus.EN_ATTENTE_VALIDATION_CDS)!.name }}
                      </mat-icon>
                      <div class="count">
                        <p class="amount-count"><b>{{ element.jalons_attente_CDS }}</b>
                          jalons</p>
                        <p class="amount-cost">{{
                            element.cout_attente_CDS | number:
                              '1.0-0':'fr-FR'
                          }}
                          €</p>
                      </div>
                    </div>
                    <div class="status_3 status" title="En attente de validation SNCF"
                         [class]="element.jalons_attente_SNCF != 0 ? '': 'invisible'">
                      <mat-icon class="status-icon"
                                [ngStyle]="{color:Constants.ECHEANCES_STATUSES_ICONS.get(EcheanceStatus.EN_ATTENTE_VALIDATION_SNCF)!.color}">
                        {{ Constants.ECHEANCES_STATUSES_ICONS.get(EcheanceStatus.EN_ATTENTE_VALIDATION_SNCF)!.name }}
                      </mat-icon>
                      <div class="count">
                        <p class="amount-count">
                          <b>{{ element.jalons_attente_SNCF }}</b> jalons
                        </p>
                        <p class="amount-cost">{{
                            element.cout_attente_SNCF | number:
                              '1.0-0':'fr-FR'
                          }}
                          €</p>
                      </div>
                    </div>
                    <div class="status_4 status " title="Validé SNCF"
                         [class]="element.jalons_valide != 0 ? '': 'invisible'">
                      <mat-icon class="status-icon"
                                [ngStyle]="{color:Constants.ECHEANCES_STATUSES_ICONS.get(EcheanceStatus.VALIDE_SNCF)!.color}">
                        {{ Constants.ECHEANCES_STATUSES_ICONS.get(EcheanceStatus.VALIDE_SNCF)!.name }}
                      </mat-icon>
                      <div class="count">
                        <p class="amount-count"><b>{{ element.jalons_valide }}</b>
                          jalons</p>
                        <p class="amount-cost">{{ element.cout_valide | number: '1.0-0':'fr-FR' }}
                          €</p>
                      </div>
                    </div>
                    <div class="status_5 status" title="Refusé SNCF"
                         [class]="element.jalons_refuse != 0 ? '': 'invisible'">
                      <mat-icon class="status-icon"
                                [ngStyle]="{color:Constants.ECHEANCES_STATUSES_ICONS.get(EcheanceStatus.REFUSE)!.color}">
                        {{ Constants.ECHEANCES_STATUSES_ICONS.get(EcheanceStatus.REFUSE)!.name }}
                      </mat-icon>
                      <div class="count">
                        <p class="amount-count"><b>{{ element.jalons_refuse }}</b>
                          jalons</p>
                        <p class="amount-cost">{{ element.cout_refuse | number: '1.0-0':'fr-FR' }}
                          €</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="right_graph " title="Avancement SNCF">
                  <mat-progress-spinner class="example-margin mat-progress-spinner" [ngClass]="{'complete-circle': element.jauge_droite == 100 ,
                            'empty-circle': element.jauge_droite == 101 ,
                            'warning-circle': element.jauge_droite  < 50 ,
                            'default-circle' : true }" [value]=element.jauge_droite%102 [diameter]="65">
                  </mat-progress-spinner>


                  <h1 class="inside-spinner" *ngIf="element.jauge_droite != 100">{{
                      element.jauge_droite %
                      101 | number: '1.0-0'
                    }}
                    <span class="small-percent">%</span>
                  </h1>

                  <h1 class="inside-spinner" *ngIf="element.jauge_droite == 100">
                                    <span class="material-icons">
                                        <b>done</b>
                                    </span>
                  </h1>
                </div>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="amount-all-years">
            <table class="amount-table" [ngClass]="{'empty-table': element.jalons == 0}">
              <caption class="displayNone">Montants</caption>
              <th class="displayNone">Montants</th>
              <tr>
                <td [ngClass]="{'hide':_as.getApplicationSettings().display_fusion_pack}"
                    class="amount-cell ac">
                  <h1><b>{{ year - 1 }}</b></h1>
                </td>
                <td class="label-cell">
                <td class="amount-cell ac">
                  <h1><b>{{ year }}</b></h1>
                </td>
              </tr>

              <tr>
                <td [ngClass]="{'hide':_as.getApplicationSettings().display_fusion_pack}"
                    class="amount-cell ar">{{ element.prev_commande | number:'1.1-2':'fr-FR' }} €
                </td>
                <td class="label-cell ac">Montant total commandé</td>
                <td class="amount-cell ar">{{ element.curr_commande | number:'1.1-2':'fr-FR' }} €</td>
              </tr>

              <tr>
                <td [ngClass]="{'hide':_as.getApplicationSettings().display_fusion_pack}"
                    class="amount-cell ar">{{ element.prev_engage | number:'1.1-2':'fr-FR' }} €
                </td>
                <td class="label-cell ac">Montant total engagé</td>
                <td class="amount-cell ar">{{ element.curr_engage | number:'1.1-2':'fr-FR' }} €</td>
              </tr>

              <tr>
                <td [ngClass]="{'hide':_as.getApplicationSettings().display_fusion_pack}"
                    class="amount-cell ar">{{ element.prev_disponible | number:'1.1-2':'fr-FR' }} €
                </td>
                <td class="label-cell ac">Montant total disponible</td>
                <td class="amount-cell ar">{{ element.curr_disponible | number:'1.1-2':'fr-FR' }} €</td>
              </tr>

              <tr>
                <td [ngClass]="{'hide':_as.getApplicationSettings().display_fusion_pack}"
                    class="amount-cell ar">{{ element.prev_engage_sans_commande | number:'1.1-2':'fr-FR' }}
                  €
                </td>
                <td class="label-cell ac ">Montant total engagé sans commande</td>
                <td class="amount-cell ar">{{ element.curr_engage_sans_commande | number:'1.1-2':'fr-FR' }}
                  €
                </td>
              </tr>

              <tr class="bar-below">
                <td [ngClass]="{'hide':_as.getApplicationSettings().display_fusion_pack}"
                    class="amount-cell ar bar-below">{{ element.prev_total_facture | number:'1.1-2':'fr-FR' }}
                  €
                </td>
                <td class="label-cell ac bar-below">Montant total facturé</td>
                <td class="amount-cell ar bar-below">{{ element.curr_total_facture | number:'1.1-2':'fr-FR' }}
                  €
                </td>
              </tr>


              <tr>
                <td class="amount-cell ar bar-above"
                    [ngClass]="{'hide':_as.getApplicationSettings().display_fusion_pack, 'r':element.prev_retard_facturation!=null && element.prev_retard_facturation!=0}">
                  {{ element.prev_retard_facturation | number:'1.1-2':'fr-FR' }}
                  €
                </td>
                <td class="label-cell ac bar-above "
                    [ngClass]="element.prev_retard_facturation!=null && element.prev_retard_facturation!=0 || element.curr_retard_facturation!=null &&  element.curr_retard_facturation!=0 ?'r':''">
                  Retard de facturation
                </td>
                <td class="amount-cell ar bar-above"
                    [ngClass]="element.curr_retard_facturation!=null &&  element.curr_retard_facturation!=0 ?'r':''">
                  {{ element.curr_retard_facturation | number:'1.1-2':'fr-FR' }}
                  €
                </td>
              </tr>

              <tr>
                <td class="amount-cell ar"
                    [ngClass]="{'hide':_as.getApplicationSettings().display_fusion_pack, 'r':element.prev_depassement!=null && element.prev_depassement!=0}">
                  {{ element.prev_depassement | number:'1.1-2':'fr-FR' }}
                  €
                </td>
                <td class="label-cell ac"
                    [ngClass]="element.prev_depassement!=null && element.prev_depassement!=0 || element.curr_depassement!=null && element.curr_depassement!=0 ?'r':''">
                  Dépassement
                </td>
                <td class="amount-cell ar"
                    [ngClass]="element.curr_depassement!=null && element.curr_depassement!=0 ?'r':''">
                  {{ element.curr_depassement | number:'1.1-2':'fr-FR' }}
                  €
                </td>
              </tr>

              <tr [ngClass]="{'bar-below': !_as.getApplicationSettings().display_fusion_pack}">
                <td
                  [ngClass]="{'hide': _as.getApplicationSettings().display_fusion_pack, 'r': element.prev_depassement_pr != null && element.prev_depassement_pr != 0}"
                  class="amount-cell ar bar-below">{{ element.prev_depassement_pr | number:'1.1-2':'fr-FR' }}
                  €
                </td>
                <td class="label-cell ac "
                    [ngClass]="{'bar-below': !_as.getApplicationSettings().display_fusion_pack, 'r': element.prev_depassement_pr != null && element.prev_depassement_pr != 0 || element.curr_depassement_pr != null && element.curr_depassement_pr != 0}">
                  Dépassement prévisionnel
                </td>
                <td class="amount-cell ar"
                    [ngClass]="{'bar-below': !_as.getApplicationSettings().display_fusion_pack, 'r': element.curr_depassement_pr != null && element.curr_depassement_pr != 0}">
                  {{ element.curr_depassement_pr | number:'1.1-2':'fr-FR' }}
                  €
                </td>
              </tr>

              <tr [ngClass]="{'hide': !_as.getApplicationSettings().display_fusion_pack}">
                <td
                  [ngClass]="{'hide':_as.getApplicationSettings().display_fusion_pack, 'r':element.prev_depassement_pr!=null && element.prev_depassement_pr!=0}"
                  class="amount-cell ar bar-below">{{
                    element.prev_depassement_pr |
                      number:'1.1-2':'fr-FR'
                  }}
                  €
                </td>
                <td class="label-cell ac bar-below "
                    [ngClass]="element.prev_depassement_pr!=null && element.prev_depassement_pr!=0 || element.prev_depassement_pr!=null && element.prev_depassement_pr!=0 ?'r':''">
                  Dépassement prévisionnel année précédente
                </td>
                <td class="amount-cell ar bar-below"
                    [ngClass]="element.prev_depassement_pr!=null && element.prev_depassement_pr!=0 ?'r':''">
                  {{ element.prev_depassement_pr | number:'1.1-2':'fr-FR' }}
                  €
                </td>
              </tr>

              <tr>
                <td [ngClass]="{'hide':_as.getApplicationSettings().display_fusion_pack}"
                    class="amount-cell ar bar-above">{{ element.prev_a_facturer | number:'1.1-2':'fr-FR' }}
                  €
                </td>
                <td class="label-cell ac bar-above">A facturer pour le mois</td>
                <td class="amount-cell ar bar-above">{{ element.curr_a_facturer | number:'1.1-2':'fr-FR' }}
                  €
                </td>
              </tr>

            </table>
          </div>
        </mat-expansion-panel>

      </div>
    </mat-accordion>
  </div>
</div>
