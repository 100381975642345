import {Component, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {INetting} from 'src/app/shared/models/netting-account';
import {AuthenticationService} from 'src/app/shared/services/authentication.service';
import {DialogService} from 'src/app/shared/services/dialog.service';
import {NettingService} from '../netting.service';
import * as fileSaver from 'file-saver';
import {ApplicationSettingsService} from '../../application-settings/application-settings.service';
import {ThemeService} from 'src/app/shared/services/theme.service';
import { SnackbarService } from '../../../shared/services/snackbar.service';

@Component({
  selector: 'app-netting-form-list',
  templateUrl: './netting-form-list.component.html',
  styleUrls: ['./netting-form-list.component.less'],
})
export class NettingFormListComponent implements OnInit {
  nettings!: MatTableDataSource<INetting>;
  nettingSelected!: INetting;
  @ViewChild(MatSort)
  sort!: MatSort;
  displayedColumns: string[] = [
    'name',
    'code',
    'intitule',
    'schema_name',
    'actions',
  ];

  constructor(
    private _na: NettingService,
    public authenticationService: AuthenticationService,
    private dialogService: DialogService,
    public theme: ThemeService,
    private applicationSettingsService: ApplicationSettingsService,
    private snackbarService: SnackbarService,
  ) {
    this.nettings = new MatTableDataSource();
  }

  openErrorSnackBar(message: string) {
    this.snackbarService.openSnackBar(message, 'error-snack-bar');
  }

  openSuccessSnackBar(message: string) {
    this.snackbarService.openSnackBar(message);
  }
 
  ngOnInit(): void {
    this.getNettingAccountService();
  }

  ngAfterViewInit() {
    this.nettings.sort = this.sort;
  }

  /*
   * get NettingsAcounts Service
   */
  getNettingAccountService(): void {
    this._na.getNettings().subscribe({
      next: (data) => {
        this.nettings.data = data;
      },
      error: (err) => {
        console.log(err);
      },
      complete: () => {
      }
    });
  }

  /*
   * methode event for update netting-form-list data
   */
  updateList(data: INetting): void {
    this.getNettingAccountService();
  }

  /*
   * methode event for clear netting-form-list data
   */
  clearSelected() {
    this.nettingSelected = {
      id: '',
      name: '',
      code: '',
      intitule: '',
      schema_name: '',
      isEnabled: false,
    };
  }

  /*
   * filter netting-form-data list
   */
  filterList(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.nettings.filter = filterValue.trim().toLowerCase();
  }

  /*
   * edit netting-form-data list using pencel icon
   */
  onEditRowPencel(row: INetting): void {
    this.nettingSelected = row;
    this.nettingSelected.isEnabled = false;
  }

  /*
   * edit netting-form-data list
   */
  onEditRow(row: INetting): void {
    this.nettingSelected = row;
    this.nettingSelected.isEnabled = true;
  }

  /*
   * delete netting-form-data list
   */
  onDeleteRow(row: INetting): void {
    this.dialogService
      .openConfirmDialog(row.name)
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this._na.deleteNettingAccount(row).subscribe({
            next: (data) => {
              this.getNettingAccountService();
              this.clearSelected();
              this.openSuccessSnackBar('Le netting a été supprimé avec succès');
            },
            error: (err) => {
              console.log(err);
              this.openErrorSnackBar(
                'Une erreur est survenue lors de la suppression du netting',
              );
            },
            complete: () => {
            }
          });
        }
      });
  }

  exportData() {
    this._na.exportData().subscribe({
      next: (data) => {
        if (data) {
          const blob: any = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          window.URL.createObjectURL(blob);
          fileSaver.saveAs(
            blob,
            'Netting - ' +
            this.applicationSettingsService.applicationSettings.CDS_name +
            ' - ' +
            new Date().toLocaleDateString().replace('_', '-') +
            ' ' +
            new Date().toLocaleTimeString().replace('_', '-'),
          );
          this.openSuccessSnackBar('Netting exporté avec succès');
        } else {
          this.openErrorSnackBar('Aucun netting a exporter');
        }
      },
      error: (err) => {
        console.log(err);
        this.openErrorSnackBar(
          "Une erreur est survenue lors de l'export du netting",
        );
      },
      complete: () => {
      }
    });
  }
}
