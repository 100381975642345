import { Injectable } from '@angular/core';
import { Constants } from '../constants/Constants';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  constructor(private configService:ConfigService) {
  }


  public getColor(color:string):string {
    const theme:string = this.configService.getEnvironnement();

    switch(color){
      //Basic Colors, replaces indigo and bue respectively
      case Constants.PackColor.MAIN_DARK:
        switch(theme){
          case Constants.ENVIRONMENT_PRD_NAME_KEY: return("rgb( 63, 88,156)");
          case Constants.ENVIRONMENT_REC_NAME_KEY: return("rgb( 42,122, 78)");
          case Constants.ENVIRONMENT_INT_NAME_KEY: return("rgb(171, 89, 54)");
        } break;
      case Constants.PackColor.MAIN_LIGHT:
        switch(theme){
          case Constants.ENVIRONMENT_PRD_NAME_KEY: return("rgb( 47,160,253)");
          case Constants.ENVIRONMENT_REC_NAME_KEY: return("rgb( 38,183,113)");
          case Constants.ENVIRONMENT_INT_NAME_KEY: return("rgb(235,148, 86)");
        } break;

      //Sidebar colors, darker tones
      case Constants.PackColor.SIDEBAR_DARK:
        switch(theme){
          case Constants.ENVIRONMENT_PRD_NAME_KEY: return("rgb( 47, 50, 76)");
          case Constants.ENVIRONMENT_REC_NAME_KEY: return("rgb( 41, 74, 56)");
          case Constants.ENVIRONMENT_INT_NAME_KEY: return("rgb( 99, 43, 35)");
        } break;
      case Constants.PackColor.SIDEBAR_LIGHT:
        switch(theme){
          case Constants.ENVIRONMENT_PRD_NAME_KEY: return("rgb( 75, 80,121)");
          case Constants.ENVIRONMENT_REC_NAME_KEY: return("rgb( 75, 80,121)");
          case Constants.ENVIRONMENT_INT_NAME_KEY: return("rgb(141, 71, 54)");
        } break;

      //Sidebar colors, darker tones
      case Constants.PackColor.LIST_DARK:
        switch(theme){
          case Constants.ENVIRONMENT_PRD_NAME_KEY: return("rgb( 63, 88,156)");
          case Constants.ENVIRONMENT_REC_NAME_KEY: return("rgb( 73,128, 91");
          case Constants.ENVIRONMENT_INT_NAME_KEY: return("rgb(171, 89, 54)");
        } break;
      case Constants.PackColor.LIST_LIGHT:
        switch(theme){
          case Constants.ENVIRONMENT_PRD_NAME_KEY: return("rgb(168,216,255)");
          case Constants.ENVIRONMENT_REC_NAME_KEY: return("rgb(183,235,186)");
          case Constants.ENVIRONMENT_INT_NAME_KEY: return("rgb(246,209,174)");
        } break;

      //Sidebar colors, darker tones
      case Constants.PackColor.LIST_ALT_DARK:
        switch(theme){
          case Constants.ENVIRONMENT_PRD_NAME_KEY: return("rgb( 38, 61,124)");
          case Constants.ENVIRONMENT_REC_NAME_KEY: return("rgb( 42,102, 66)");
          case Constants.ENVIRONMENT_INT_NAME_KEY: return("rgb(171, 54, 54)");
        } break;
      case Constants.PackColor.LIST_ALT_LIGHT:
        switch(theme){
          case Constants.ENVIRONMENT_PRD_NAME_KEY: return("rgb(166,190,255)");
          case Constants.ENVIRONMENT_REC_NAME_KEY: return("rgb(162,224,186)");
          case Constants.ENVIRONMENT_INT_NAME_KEY: return("rgb(252,185,168)");
        } break;
    }
    return "";
  }

}
