<mat-spinner *ngIf="spinner"></mat-spinner>
<div class="operation" [ngClass]="{'spinner': spinner}">
	<div id="leftSection-import">
		<div class="lb_name_size"> ⬇️ Importer les factures </div>
		<br>
		<app-file-input id="fileInput" [(content)]="invoiceFile"></app-file-input>
		<button [disabled]="!invoiceFile" mat-raised-button class="indigo mLeft"
			[style.background-color]="theme.getColor('Main Dark')" type="submit" (click)="uploadFile($event)"
			[disabled]="!invoiceFile" [ngClass]="{'disabled-button': !invoiceFile}">Importer</button>

		<div class="lb_name_size">⬆️ Exporter les factures </div>
		<br>

		<mat-form-field appearance="outline">
			<mat-label>Année</mat-label>
			<mat-select [(ngModel)]="yearSelected" (selectionChange)="changeYear($event.value)">
				<mat-option></mat-option>
				<mat-option *ngFor="let year of years" [value]="year">{{year}}</mat-option>
			</mat-select>
		</mat-form-field>

		<button mat-raised-button class="indigo input mLeft" [style.background-color]="theme.getColor('Main Dark')"
			type="submit" [disabled]="!canExport" (click)="exportInvoice(yearSelected)">Exporter</button>

		<div class="lb_name_size">💶 Totaux facturés </div>
		

		<!-- Totals Section -->
		<div class="totals-section">
			
			<table id="totalTable">
				<thead>
				  <tr>
					<th></th>
					<th></th>
				  </tr>
				</thead>
				<tbody>
				  <tr *ngFor="let packYear of getPackYears()" class="totTable">
					<td>
					  Total facturé pour le pack <strong>{{ packYear }}:</strong><br>
					  Nombre de factures pour le pack <strong>{{ packYear }}:</strong>
					</td>
					<td class="right-column">
					 <strong>▶️ {{ totalInvoicesByPackYear[packYear].total | number:'1.2-2':"fr-FR" }} €</strong><br>
					  <strong>▶️ {{ totalInvoicesByPackYear[packYear].count }}</strong>
					</td>
				  </tr>
				</tbody>
			  </table>
			  <br>
			
		</div>
	</div>

	<div id="rightSection-import">

		<mat-table class="light mat-elevation-z4" [dataSource]="invoiceSummary" matSort>

			<!-- month Column -->
			<ng-container matColumnDef="month">
				<mat-header-cell *matHeaderCellDef> Mois Pack </mat-header-cell>
				<mat-cell *matCellDef="let element"> <strong style="color: brown;">
						{{element.mois_facturation | date:'MMMM yyyy'}} </strong> </mat-cell>
			</ng-container>

			<!-- packYear column-->
			<ng-container matColumnDef="packYear">
				<mat-header-cell *matHeaderCellDef> Année Pack </mat-header-cell>
				<mat-cell *matCellDef="let element"> {{element. pack_year}} </mat-cell>
			</ng-container>

			<!-- totalCharged Column -->
			<ng-container matColumnDef="totalInvoice">
				<mat-header-cell *matHeaderCellDef> Total facturé </mat-header-cell>
				<mat-cell *matCellDef="let element"> {{element.total_mois | number:'1.1-2':"fr-FR"}} € </mat-cell>
			</ng-container>

			<!-- nbOfInvoices Column -->
			<ng-container matColumnDef="nbInvoices">
				<mat-header-cell *matHeaderCellDef> Nb de factures </mat-header-cell>
				<mat-cell *matCellDef="let element"> {{element.nbre_factures}} </mat-cell>
			</ng-container>

			<mat-header-row *matHeaderRowDef="displayedColumns ; sticky: true;"></mat-header-row>
			<mat-row *matRowDef="let row; columns: displayedColumns; " ></mat-row>

			<ng-template matNoDataRow>
				<div class="no-data-row">
				  ✅ Il n'y a aucunes factures importées pour l'année sélectionnée.
				</div>
			  </ng-template>

		</mat-table>
	</div>

</div>