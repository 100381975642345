<div class="sub-stepper">

  <app-import-file (uploadFileEvent)="uploadPackFile($event)"
                   [canSubmit]="yearSelected != null && monthSelected != null"></app-import-file>

  <mat-form-field appearance="outline">
    <mat-label>Année</mat-label>
    <mat-select required (selectionChange)="changeYear($event.value)">
      <mat-option></mat-option>
      <mat-option *ngFor="let year of years" [value]="year">{{year}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Mois</mat-label>
    <mat-select required (selectionChange)="changeMonth($event.value)">
      <mat-option></mat-option>
      <mat-option *ngFor="let month of months; let index = index" [value]="index">{{month}}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-spinner *ngIf="spinner"></mat-spinner>
</div>
