import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {IDsi} from 'src/app/shared/models/dsi';
import {AuthenticationService} from 'src/app/shared/services/authentication.service';
import {UtilsService} from 'src/app/shared/services/utils.service';
import {environment} from 'src/environments/environment';
import {AppDetails, DsiDetails} from 'src/app/shared/models/dsiDetails';

@Injectable({
  providedIn: 'root'
})
export class DomainesService {

  options = {
    headers: new HttpHeaders({'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'})
  };

  constructor(private http: HttpClient,
              private utilsService: UtilsService,
              private authService: AuthenticationService) {
  }

  getDsis(): Observable<IDsi[]> {
    return this.http.get<IDsi[]>(environment.backendApiUrl + "/dsi", this.options).pipe(catchError(this.utilsService.handleError));
  }


  getAllDsisDetails(): Observable<DsiDetails[]> {
    const userApplications = this.authService.getUser().applications;
    const appNames: string[] = [];
    userApplications.forEach(userApp => {
      appNames.push(userApp.name + '')
    });
    return this.http.get<DsiDetails[]>(environment.backendApiUrl + "/dsi/details_dsi?apps=" + appNames, this.options).pipe(catchError(this.utilsService.handleError));
  }

  getAllAppsDetails(): Observable<AppDetails[]> {
    const userApplications = this.authService.getUser().applications;
    const appNames: string[] = [];
    userApplications.forEach(userApp => {
      appNames.push(userApp.name + '')
    });
    return this.http.get<AppDetails[]>(environment.backendApiUrl + "/dsi/details_app?apps=" + appNames, this.options).pipe(catchError(this.utilsService.handleError));
  }

  getDsiDetails(year: any, type: any): Observable<string[][]> {
    const apps: string[] = [];
    this.authService.getUser().applications.forEach(element => {
      apps.push(element.name!);
    });
    if (apps.length == 0) apps.push('none');
    const user = "default";

    return this.http.get<string[][]>(environment.backendApiUrl + "/dsi/details?apps=" + apps + "&year=" + year + "&type=" + type, this.options).pipe(catchError(this.utilsService.handleError));
  }

  exportDataDetails(data: any [][], year: string): Observable<any> {
    return this.http.post(environment.backendApiUrl + "/dsi/details/export?year=" + year, data,
      {responseType: 'blob'}).pipe(catchError(this.utilsService.handleError));
  }

  deleteDsi(p: IDsi): Observable<any> {
    return this.http.delete<IDsi>(environment.backendApiUrl + "/dsi/" + p.id, this.options).pipe(catchError(this.utilsService.handleError));
  }


  updateDsi(id: string, p: IDsi): Observable<IDsi> {
    return this.http.put<IDsi>(environment.backendApiUrl + "/dsi/" + id, p, this.options).pipe(catchError(this.utilsService.handleError));
  }

  createDsi(p: IDsi): Observable<IDsi> {
    return this.http.post<IDsi>(environment.backendApiUrl + "/dsi", p, this.options).pipe(catchError(this.utilsService.handleError));
  }

  exportData(): Observable<any> {
    return this.http.get(environment.backendApiUrl + "/dsi/export/", {responseType: 'blob'}).pipe(catchError(this.utilsService.handleError));
  }

}
