import {Component, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {MenuBarComponent} from '../shared/menu-bar/menu-bar.component';

@Component({
    selector: 'core',
    templateUrl: './core.component.html',
    styleUrls: ['./core.component.less']
})

export class CoreComponent {

    @ViewChild(MenuBarComponent)
    public menuBarComponent!: MenuBarComponent;

    constructor(public router: Router) {
    }

}
