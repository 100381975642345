import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ICalendrier } from 'src/app/shared/models/calendrier';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { CalendrierService } from '../calendrier.service';
import * as fileSaver from 'file-saver';
import { ApplicationSettingsService } from '../../application-settings/application-settings.service';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { ApplicationSettings } from '../../../shared/models/application-settings';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ApplicationSettingsComponent } from '../../application-settings/application-settings.component';
import { CalenderModalConfirmationComponent } from '../calender-modal-confirmation/calender-modal-confirmation.component';
import { SnackbarService } from '../../../shared/services/snackbar.service';

@Component({
  selector: 'app-calendrier-form-list',
  templateUrl: './calendrier-form-list.component.html',
  styleUrls: ['./calendrier-form-list.component.less'],
})
export class CalendrierFormListComponent implements OnInit {
  currentYear: number = new Date().getFullYear();

  applicationSettings: ApplicationSettings = new ApplicationSettings();

  calendrier!: MatTableDataSource<ICalendrier>;
  YearInput!: number;
  calendrierSelected!: ICalendrier;

  public monthNames: string[] = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Aout',
    'Septembre',
    'Octobre',
    'Novembre',
    'Decembre',
  ];

  month!: string;

  @ViewChild(MatSort)
  sort!: MatSort;

  displayedColumns: string[] = [
    'mois',
    'initialisation',
    'ouverture',
    'valid_debut',
    'valid_fin',
    'cloture',
    'actions',
  ];

  constructor(
    public authenticationService: AuthenticationService,
    public theme: ThemeService,
    private applicationSettingsService: ApplicationSettingsService,
    public dialogRef: MatDialogRef<ApplicationSettingsComponent>,
    public dialog: MatDialog,
    public calendrierService: CalendrierService,
    private snackbarService: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.calendrier = new MatTableDataSource<ICalendrier>();
    this.applicationSettings =
      this.applicationSettingsService.getApplicationSettings();
  }

  ngOnInit(): void {
    this.getCalendrierServiceByYear(this.currentYear);
  }

  openErrorSnackBar(message: string) {
    this.snackbarService.openSnackBar(message, 'error-snack-bar');
  }

  openSuccessSnackBar(message: string) {
    this.snackbarService.openSnackBar(message);
  }



  onEditRowPencel(row: ICalendrier): void {
    this.calendrierSelected = row;
    this.calendrierSelected.enableEdit = false;
  }

  /*
   * Methode for select data
   */
  selectedCalendrierForm(data: ICalendrier): void {
    this.calendrierSelected = data;
    this.calendrierSelected.enableEdit = true;
  }

  /*
   * method event for initialise selected order
   */
  clearCalendrierFormSelected(): void {
    this.initCalendrierForm();
  }

  /*
   * initialize Calendrierform property
   */

  initCalendrierForm(): void {
    this.calendrierSelected = {
      year: 0,
      month: 0,
      enableEdit: false,
    };
  }

  /*
   * methode event for update calendrier-form-list data
   */
  updateList(data: ICalendrier): void {
    // utiliser uniquement pour rafraichir l'ecran apres l'utilisation du formulaire de creation modification du calendrier
  }

  /*
   * methode event for update calendrier-form-list data
   */
  updateListByYear(event: any): void {
    const year = event.payload;
    this.getCalendrierServiceByYear(year);
  }

  getCalendrierServiceByYear(year: number) {
    this.calendrierService.getCalendriersByYear(year).subscribe({
      next: (data) => {
        this.calendrier.data = data;
      },
      error: (err) => {
        console.log(err);
      },
      complete: () => {
      }
    });
  }

  exportData() {
    this.calendrierService.exportData().subscribe({
      next: (data) => {
        if (data) {
          const blob: any = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          window.URL.createObjectURL(blob);
          fileSaver.saveAs(
            blob,
            'Calendrier - ' +
            this.applicationSettingsService.applicationSettings.CDS_name +
            ' - ' +
            new Date().toLocaleDateString().replace('_', '-') +
            ' ' +
            new Date().toLocaleTimeString().replace('_', '-'),
          );
          this.openSuccessSnackBar(
            'Les calendriers ont été exportés avec succès',
          );
        } else {
          this.openSuccessSnackBar('Aucun calendrier à exporter');
        }
      },
      error: (err) => {
        console.log(err);
        this.openErrorSnackBar(
          "Une erreur est survenue lors de l'exportation des calendriers",
        );
      },
      complete: () => {

      }
    });
  }

  onDeleteRow(row: ICalendrier): void {
    if (
      row.month == this.applicationSettings.Pack_current_month &&
      row.year == this.applicationSettings.Pack_current_year
    ) {
      alert("Impossible de supprimer un calendrier en cours d'utilisation");
    } else {
      const dialogRef = this.dialog.open(CalenderModalConfirmationComponent, {
        data: {
          id: row.id,
          month: this.monthNames[row.month! - 1],
          year: row.year,
        },
      });
      dialogRef.afterClosed().subscribe({
        next: (result) => {
          if (result) {
            this.calendrierService
              .deleteCalendrier(row.id!)
              .subscribe({
                next: (data: any) => {
                  this.getCalendrierServiceByYear(this.currentYear);
                  this.openSuccessSnackBar(
                    'Le calendrier a été supprimé avec succès',
                  );
                },
                error: (err) => {
                  console.log(err);
                  this.openErrorSnackBar(
                    'Une erreur est survenue lors de la suppression du calendrier',
                  );
                },
                complete: () => {
                }
              });
          }
        },
        error: (err) => {
          console.log(err);
        },
        complete: () => {
        }
      });
    }
  }
}
