<div class="sub-stepper">
    <mat-spinner *ngIf="spinner"></mat-spinner>
    <app-echeances-list
            *ngIf="!spinner" [duesEvent]="areCalculationsDone"
            (updateValidationCapability)="enableValidation=$event"
            (updateRejectionCapability)="enableRejection=$event"
            (setYear)="year=$event"

            (export)="downloadFile($event)"

            [enableValidation]="enableValidation"
            [enableRejection]="enableRejection"

            [areCalculationsDone]="areCalculationsDone"
            [exportSpinner]="exportSpinner">

    </app-echeances-list>

    <app-echeance-details
            (updateValidationCapability)="enableValidation=$event"
            (updateRejectionCapability)="enableRejection=$event"
            (refreshTable)="completeTask($event)"></app-echeance-details>


   
</div>
