import { EcheanceStatus } from "../models/echeance-status";

export class Constants{

    public static readonly ECHEANCES_STATUSES_ICONS:Map<EcheanceStatus, {name:string, color:string}> = new Map([
        [EcheanceStatus.REFUSE, {name:'cancel_rounded', color:'red'}],
        [EcheanceStatus.VALIDE_SNCF, {name:'check_circle_rounded', color:'#00d01a'}],
        [EcheanceStatus.EN_ATTENTE_VALIDATION_CDS, {name:'help_outline', color:'#ffc522'}],
        [EcheanceStatus.EN_ATTENTE_VALIDATION_SNCF, {name:'access_time_outlined', color:'#00d01a'}],
        [EcheanceStatus.INFORMATION_MANQUANTE, {name:'warning', color:'red'}],
        [EcheanceStatus.DEFAULT, {name:'', color:'#ffffff'}],
        [EcheanceStatus.VALIDE_AUTO, {name:'check_circle_rounded', color:'#2f8ffd'}],
    ]);

	static Patterns= class {
        public static readonly   LOCAL_DATE_TIME_FORMAT:string = 'YYYY-MM-DDTHH:mm:ss';
    }


    static HttpHeaders = class {

        public static readonly   CONTENT_TYPE:string = 'Content-Type';
        public static readonly   APP_JSON:string = 'application/json';
        public static readonly   ACCEPT:string = 'Accept';
    }

    static ErrorMessages = class{
        public static readonly SERVER_TECH_ERROR = 'Erreur technique, reportez SVP le message d\'erreur apparu dans la console du navigateur';
        public static readonly DEMANDS_NOT_VALIDATED ='Les demandes suivantes n\'ont pas été validées : ';
        public static readonly DEMANDS_NOT_REJECTED ='Les demandes suivantes n\'ont pas été rejetées : ';
        public static readonly DEMAND_NOT_VALIDATED ='La demande n\'a pas été validée';
        public static readonly DEMAND_NOT_REJECTED ='La demande n\'a pas été rejetée';
        public static readonly ECHEANCE_STATUS_NOT_CHANGED ='Le statut de l\'échéance de la demande n\'a pas été modifié';


    }
    static Messages = class{
        public static readonly DEMANDS_REJECTED ='les demandes sélectionnées ont été rejetées avec succès';
        public static readonly DEMAND_REJECTED ='la demande a été rejetée avec succès';
        public static readonly DEMANDS_VALIDATED ='les demandes sélectionnées ont été validées avec succès';
        public static readonly ECHEANCE_STATUS_CHANGED ='Le statut de l\'échéance de la demande a été modifié avec susccès';
        public static readonly DEMAND_VALIDATED ='la demande a été validée avec succès';
    }
    static ApiPaths = class{
        public static readonly USERS ='/users';
        public static readonly USERS_BY_IDENTIFIER = '/users/identifier/';
        public static readonly SETTINGS = '/settings/';
    }

    static PackColor = class{
      public static readonly MAIN_LIGHT = "Main Light";
      public static readonly MAIN_DARK = "Main Dark";

      public static readonly SIDEBAR_LIGHT = "Sidebar Light";
      public static readonly SIDEBAR_DARK = "Sidebar Dark";

      public static readonly LIST_LIGHT = "List Light";
      public static readonly LIST_DARK = "List Dark";

      public static readonly LIST_ALT_LIGHT = "List Alt Light";
      public static readonly LIST_ALT_DARK = "List Alt Dark";
    }

    static readonly ENVIRONMENT_DEV_NAME_KEY = 'Developpement';
    static readonly ENVIRONMENT_INT_NAME_KEY = 'Integration' ;
    static readonly ENVIRONMENT_REC_NAME_KEY = 'Recette' ;
    static readonly ENVIRONMENT_PRD_NAME_KEY = 'Production' ;
    static readonly ENVIRONMENT_TN_NAME_KEY = 'Transilien' ;

    static readonly ENVIRONMENT_DEV_BACKGROUND_COLOR_KEY = 'rgb(235,148,86)' ;
    static readonly ENVIRONMENT_INT_BACKGROUND_COLOR_KEY = 'rgb(235,148,86)' ;
    static readonly ENVIRONMENT_REC_BACKGROUND_COLOR_KEY = 'rgb(235, 162, 36)' ;
    static readonly ENVIRONMENT_PRD_BACKGROUND_COLOR_KEY = 'rgb(47,160,253)';
    static readonly ENVIRONMENT_TN_BACKGROUND_COLOR_KEY = 'rgb(47,160,253)';
    }
