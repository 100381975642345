import {Component} from '@angular/core';
import * as fileSaver from 'file-saver';
import {UserProfile} from 'src/app/shared/models/user-profile';
import {AuthenticationService} from 'src/app/shared/services/authentication.service';
import {ThemeService} from 'src/app/shared/services/theme.service';
import {ApplicationSettingsService} from '../../application-settings/application-settings.service';
import {DomainesService} from '../../domaines/domaines.service';
import {EcheancesService} from '../../pack-generation/calculate-echeances/services/echeances.service';
import { SnackbarService } from '../../../shared/services/snackbar.service';

@Component({
  selector: 'app-dashboard-apps',
  templateUrl: './dashboard-apps.component.html',
  styleUrls: ['./dashboard-apps.component.less'],
})
export class DashboardAppsComponent {
  constructor(
    public as: ApplicationSettingsService,
    public ds: DomainesService,
    public es: EcheancesService,
    public theme: ThemeService,
    public auths: AuthenticationService,
    private snackbarService: SnackbarService,
  ) {
    this.settingsYear = this.as.getApplicationSettings().Pack_current_year;

    this.spinner = true;
    ds.getDsiDetails(
      this.settingsYear + this.currentYearIndex - 1,
      'CDS',
    ).subscribe({
      next: (data) => {
        this.dataB = this.dataB = data
          .sort((a, b) => {
            if (a[1] > b[1]) return 1;
            if (a[1] < b[1]) return -1;
            return 0;
          })
          .sort((a, b) => {
            if (a[0] > b[0]) return 1;
            if (a[0] < b[0]) return -1;
            return 0;
          });
        this.data = this.insertTotalRows(this.dataB);
        this.resetList();
        this.swapSelected();
      },
      error: (error) => {
        console.log(error);
        this.openErrorSnackBar('Erreur lors de la récupération des données');
      },
      complete: () => {
        this.spinner = false;
      }
    });
  }

  openErrorSnackBar(message: string) {
    this.snackbarService.openSnackBar(message, 'error-snack-bar');
  }

  openSuccessSnackBar(message: string) {
    this.snackbarService.openSnackBar(message);
  }
 

  currentYearIndex = 1;
  spinner = true;
  settingsYear = 0;

  data: string[][] = [];
  dataA: string[][] = [];
  dataB: string[][] = [];

  selected = 'CDS';
  dsiVisible = [true, true, true, true];
  DSI_LIST = ['CDS', 'DirectionMetier', 'DSI', 'DDSI'];

  months = [
    'janvier',
    'février',
    'mars',
    'avril',
    'mai',
    'juin',
    'juillet',
    'août',
    'septembre',
    'octobre',
    'novembre',
    'décembre',
  ];

  headers: string[] = [
    'domaine',
    'application',
    'montant-engagé',
    'engagé-sans-commande',
    'rate-validé',
    'facturé-fin-m-1',
    'facturable-du-mois',
    'retard-de-facturation',
    'constaté-m',
    'constaté-fin-m',
    'rate-constaté',
    'total-previous',
    'm1',
    'm2',
    'm3',
    'm4',
    'm5',
    'm6',
    'm7',
    'm8',
    'm9',
    'm10',
    'm11',
    'm12',
    'aa',
    'm13',
    'm14',
    'm15',
    'm16',
    'm17',
    'm18',
    'm19',
    'm20',
    'm21',
    'm22',
    'm23',
    'm24',
    'aa+1',
    'cumul',
  ];

  swapYear(i: number) {
    this.currentYearIndex = i;
    if (i == 1) {
      if (this.dataB.length == 0) this.initData(i);
      else this.data = this.insertTotalRows(this.dataB);
    } else {
      if (this.dataA.length == 0) this.initData(i);
      else this.data = this.insertTotalRows(this.dataA);
    }
  }

  swapSelected() {
    if (this.currentYearIndex == 0) {
      this.data = this.insertTotalRows(this.dataA);
    } else {
      this.data = this.insertTotalRows(this.dataB);
    }
  }

  initData(i: number) {
    if (i == 1) {
      this.spinner = true;
      this.ds
        .getDsiDetails(
          this.settingsYear + this.currentYearIndex - 1,
          this.selected,
        )
        .subscribe({
          next: (data) => {
            this.dataB = data
              .sort((a, b) => {
                if (a[1] > b[1]) return 1;
                if (a[1] < b[1]) return -1;
                return 0;
              })
              .sort((a, b) => {
                if (a[0] > b[0]) return 1;
                if (a[0] < b[0]) return -1;
                return 0;
              });
            this.data = this.insertTotalRows(this.dataB);
            this.swapSelected();
          },
          error: (error) => {
            console.log(error);
            this.openErrorSnackBar('Erreur lors de la récupération des données');
          },
          complete: () => {
            this.spinner = false;
          }
        });
    } else {
      this.spinner = true;
      this.ds
        .getDsiDetails(
          this.settingsYear + this.currentYearIndex - 1,
          this.selected,
        )
        .subscribe({
          next: (data) => {
            this.dataA = data
              .sort((a, b) => {
                if (a[1] > b[1]) return 1;
                if (a[1] < b[1]) return -1;
                return 0;
              })
              .sort((a, b) => {
                if (a[0] > b[0]) return 1;
                if (a[0] < b[0]) return -1;
                return 0;
              });
            this.data = this.insertTotalRows(this.dataA);
            this.swapSelected();
          },
          error: (error) => {
            console.log(error);
            this.openErrorSnackBar('Erreur lors de la récupération des données');
          },
          complete: () => {
            this.spinner = false;
          }
        });
    }
  }

  yearFromIndex(index: number) {
    if (index < 12) return this.settingsYear + this.currentYearIndex - 1;
    return this.settingsYear + 1 + this.currentYearIndex - 1;
  }

  pApps: string[] = [];

  isVisible(row: string[]) {
    if (row[1] == 'TOTAL :') return true;

    switch (this.auths.getUser().profile) {
      case UserProfile.CP_CDS:
      case UserProfile.CP_SNCF:
        if (this.pApps.length == 0) {
          this.auths.getUser().applications.forEach((element) => {
            this.pApps.push(element.name + '');
            this.pApps.push(element.label + '');
          });
        }
        if (this.pApps.indexOf(row[1]) == -1) return false;
    }

    return row[41] == this.selected;
  }

  resetList() {
    this.dsiVisible = [false, false, false, false];
    this.selected = '';

    let data = this.data;

    if (this.currentYearIndex == 0) data = this.dataA;
    if (this.currentYearIndex == 1) data = this.dataB;

    data.forEach((element) => {
      if (this.DSI_LIST.indexOf(element[41]) != -1) {
        if (
          this.auths.getUser().profile == UserProfile.CP_CDS ||
          this.auths.getUser().profile == UserProfile.CP_SNCF
        ) {
          if (this.pApps.length == 0) {
            this.auths.getUser().applications.forEach((element) => {
              this.pApps.push(element.name + '');
              this.pApps.push(element.label + '');
            });
          }
          this.dsiVisible[this.DSI_LIST.indexOf(element[41])] = true;
        } else this.dsiVisible[this.DSI_LIST.indexOf(element[41])] = true;
      }
    });

    if (
      this.auths.getUser().profile == UserProfile.GUEST_SNCF ||
      this.auths.getUser().profile == UserProfile.CP_SNCF ||
      this.auths.getUser().profile == UserProfile.SERVICE_MANAGER
    ) {
      this.dsiVisible[0] = false;
    }

    for (let i = 0; i < this.DSI_LIST.length && this.selected == ''; i++) {
      if (this.dsiVisible[i]) this.selected = this.DSI_LIST[i];
    }
  }

  insertTotalRows(data: string[][]): string[][] {
    const res: string[][] = [];

    let lastRow = '';
    let totals: string[] = [];

    const totalTotals: string[] = [];
    totalTotals.push('Cumul');
    totalTotals.push('TOTAL :');

    for (let i = 0; i < 46; i++) totalTotals.push('0');
    let count = 0;

    data.forEach((row) => {
      if (this.isVisible(row)) {
        //IF ROW IS IN CURRENT BLOC
        count++;
        if (lastRow == row[0]) {
          for (let i = 2; i < row.length; i++) {
            if (row[i] == '0.0') row[i] = '0';
            totals[i] = Number(row[i]) + Number(totals[i]) + '';
            totalTotals[i] = Number(row[i]) + Number(totalTotals[i]) + '';
          }
          res.push(row);
        }
        //IF ROW IS IN NEW BLOC
        else {
          //INSERT TOTAL ROW IF EXISTS

          if (lastRow != '') {
            if (Number(totals[43]) != 0)
              totals[2] =
                Number(100 * Number(totals[42])) / Number(totals[43]) + '';
            else totals[2] = 0 + '';

            if (Number(totals[3]) != 0)
              totals[9] =
                Number(100 * Number(totals[8])) / Number(totals[3]) + '';
            else totals[9] = 0 + '';

            res.push(totals);
          }
          //CREATE TOTAL ROW
          totals = [];
          for (let i = 0; i < row.length; i++) totals.push('');
          totals[0] = row[0];
          totals[1] = 'TOTAL :';

          //HANDLE NEW ROW
          count = 1;
          lastRow = row[0];
          for (let i = 2; i < row.length; i++) {
            totals[i] = Number(row[i]) + Number(totals[i]) + '';
            totalTotals[i] = Number(row[i]) + Number(totalTotals[i]) + '';
          }
          res.push(row);
        }
      }
    });

    if (Number(totals[43]) != 0)
      totals[2] = Number(100 * Number(totals[42])) / Number(totals[43]) + '';
    else totals[2] = 0 + '';
    if (Number(totals[3]) != 0)
      totals[9] = Number(100 * Number(totals[8])) / Number(totals[3]) + '';
    else totals[9] = 0 + '';

    res.push(totals);

    if (Number(totalTotals[43]) != 0)
      totalTotals[2] =
        Number(100 * Number(totalTotals[42])) / Number(totalTotals[43]) + '';
    else totalTotals[2] = 0 + '';

    if (Number(totalTotals[3]) != 0)
      totalTotals[9] =
        Number(100 * Number(totalTotals[8])) / Number(totalTotals[3]) + '';
    else totalTotals[9] = 0 + '';

    res.push(totalTotals);

    return res;
  }

  insertExportTotalRows(data: string[][]): string[][] {
    const res: string[][] = [];
    data.forEach((row) => {
      if (this.isVisible(row)) res.push(row);
    });

    return res;
  }

  exportData() {
    let exportYear: number = this.settingsYear;
    if (this.currentYearIndex == 0) exportYear = this.settingsYear - 1;

    if (this.currentYearIndex == 0) {
      this.ds
        .exportDataDetails(
          this.insertExportTotalRows(this.data),
          '' + (this.settingsYear - 1),
        )
        .subscribe({
          next: (data) => {
            if (data) {
              const blob: any = new Blob([data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              });
              fileSaver.saveAs(
                blob,
                "Suivi d'application " +
                exportYear +
                " - " +
                this.as.applicationSettings.CDS_name +
                ' - ' +
                new Date().toLocaleDateString().replace('_', '-') +
                ' ' +
                new Date().toLocaleTimeString().replace('_', '-'),
              );
            } else {
              this.openSuccessSnackBar('Aucune donnée à exporter');
            }
          },
          error: (error) => {
            console.log(error);
            this.openErrorSnackBar("Erreur lors de l'export des données");
          },
          complete: () => {

          }
        });
    } else {
      this.ds
        .exportDataDetails(
          this.insertExportTotalRows(this.data),
          '' + this.settingsYear,
        )
        .subscribe({
          next: (data) => {
            if (data) {
              const blob: any = new Blob([data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              });
              fileSaver.saveAs(
                blob,
                "Suivi d'application " +
                exportYear +
                " - " +
                this.as.applicationSettings.CDS_name +
                ' - ' +
                new Date().toLocaleDateString().replace('_', '-') +
                ' ' +
                new Date().toLocaleTimeString().replace('_', '-'),
              );
            } else {
              this.openSuccessSnackBar('Aucune donnée à exporter');
            }
          },
          error: (error) => {
            console.log(error);
            this.openErrorSnackBar("Erreur lors de l'export des données");
          },
          complete: () => {

          }
        });
    }
  }
}
