<mat-tab-group>
  <mat-tab>
    <ng-template mat-tab-label>
      Import du Pack
    </ng-template>
    <app-pack-import>Loading...</app-pack-import>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      Import Jira
    </ng-template>
    <app-jira-import>Loading...</app-jira-import>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      Calcul des Echéances
    </ng-template>
    <app-pack-export>Loading...</app-pack-export>
  </mat-tab>
</mat-tab-group>
