<div class="purge-modal-confirmation">
  <h2 mat-dialog-title>Confirmation</h2>
  <mat-dialog-content class="mat-typography">
    <p><span [innerHTML]="message"></span></p>

  </mat-dialog-content>
  <mat-dialog-actions style="display: flex; justify-content: flex-end;">
    <button mat-button mat-dialog-close>Annuler</button>
    <button mat-button [mat-dialog-close]="data" [disabled]="isYearEmpty" color="warn">Purger</button>
  </mat-dialog-actions>
</div>
