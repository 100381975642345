import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnauthorizedRoutingModule } from './unauthorized-routing.module';
import { UnauthorizedComponent } from './unauthorized.component';
import { SharedModule } from '../shared/shared-module';

@NgModule({
  imports: [
    UnauthorizedRoutingModule,
    SharedModule,
    CommonModule
  ],
  declarations: [UnauthorizedComponent]
})
export class UnauthorizedModule { }
