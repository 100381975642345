import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import * as fileSaver from 'file-saver';
import {ErrorPromptComponent} from 'src/app/shared/error-prompt/error-prompt.component';
import {ApplicationSettings} from 'src/app/shared/models/application-settings';
import {ThemeService} from 'src/app/shared/services/theme.service';
import {SimplePromptComponent} from 'src/app/shared/simple-prompt/simple-prompt.component';
import {ApplicationSettingsService} from '../application-settings.service';
import {DirectoryService} from './directory.service';

@Component({
  selector: 'app-repository-manager',
  templateUrl: './repository-manager.component.html',
  styleUrls: ['./repository-manager.component.less']
})
export class RepositoryManagerComponent implements OnInit {

  constructor(public ds: DirectoryService, public theme: ThemeService, public dialog: MatDialog, public applicationSettingsService: ApplicationSettingsService,) {
    this.applicationSettings = this.applicationSettingsService.getApplicationSettings();
  }


  applicationSettings: ApplicationSettings;

  data: any[][] = [];
  view = "Import"

  isEditingH = false;

  typeImport = true;
  typeAlert = false;

  timer = "00:00";
  slider = true;

  DISPLAYED_COLUMNS = ["name", "status", "creation-date", "actions"]

  ngOnInit(): void {
    this.ds.getAllFiles().subscribe({
      next: (data: any[][]) => {
        this.data = this.ds.trimData(data.sort(this.compareDates));
      },
      error: (error: any) => {
        console.log(error);
      },
      complete: () => {
      }
    });

    this.ds.getTimer().subscribe({
      next: (data: number[]) => {
        if (data[3] == 0) {
          const off = -1 * (new Date().getTimezoneOffset()) / 60;
          data[0] += (data[0] + off) % 24;
        }
        this.timer = (data[0] < 10 ? '0' : '') + data[0] + ':' + (data[1] < 10 ? '0' : '') + data[1];
        this.slider = data[2] == 1;
      },
      error: (error) => {
        console.log(error);
      },
      complete: () => {
      }
    });
  }

  compareDates(a: any[], b: any[]): number {
    if (a[3] == b[3]) return 0;
    if (Date.parse(a[3]) < Date.parse(b[3])) return 1;
    return -1;
  }

  enableEditH() {
    this.isEditingH = true;
  }

  toggleSlider() {
    this.onEditH();
  }

  onEditH() {
    this.isEditingH = false;
    const h: number = parseInt(this.timer.split(":")[0], 10);
    const m: number = parseInt(this.timer.split(":")[1], 10);

    this.ds.postTimer(h, m, this.slider).subscribe((data: number[]) => {
      if (data[3] == 0) {
        const off = -1 * (new Date().getTimezoneOffset()) / 60;
        data[0] += (data[0] + off) % 24;
      }
      this.timer = (data[0] < 10 ? '0' : '') + data[0] + ':' + (data[1] < 10 ? '0' : '') + data[1];
      this.slider = data[2] == 1;
    });
  }

  statusText(e: string) {
    switch (e) {
      case "Todo":
        return "En Attente";
      case "Done":
        return "Traité";
      case "Error":
        return "Erreur";
      default:
        return "none";
    }
  }

  removeFile(e: string[]) {
    const dialogRef = this.dialog.open(SimplePromptComponent, {
      data: {message: 'Voulez-vous vraiment supprimer le fichier ' + e[0] + ' ?'}
    });
    dialogRef.afterClosed().subscribe(
      {
        next: result => {
          if (result) {
            this.ds.removeFile(e).subscribe({
              next: (data: any[][]) => {
                this.data = data.sort(this.compareDates);
              },
              error: (error) => {
                console.log(error);
              },
              complete: () => {
              }
            });
          }
        },
        error: (error) => {
          console.log(error);
        },
        complete: () => {
        }
      })
  }

  downloadFile(e: string[]) {
    this.ds.readFileFromRepo(e[0] + "(UNPARSED)", e[2], this.view).subscribe({
      next: data => {
        const blob: any = new Blob(["\ufeff", data], {type: 'text/csv;charset=utf-8;'});
        window.URL.createObjectURL(blob);
        fileSaver.saveAs(blob, e[0]);
      },
      error: (error) => {
        console.log(error);
      },
      complete: () => {
      }
    })


  }

  uploadFile(e: File) {
    if (this.applicationSettings.JIRA_Radical != null
      && this.applicationSettings.JIRA_Radical.trim() != ""
      && e.name.indexOf(this.applicationSettings.JIRA_Radical.trim()) == -1) {
      this.dialog.open(ErrorPromptComponent, {
        data: {message: '<b>Import impossible :</b> Le nom de fichier ' + e.name + ' ne correspond pas au radical indiqué.'}
      });
    } else {
      this.ds.postFileToBack(this.typeImport, this.typeAlert, e, this);
    }
  }

}
