import {CommonModule} from "@angular/common";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {NgModule} from "@angular/core";
import {DefaultHttpInterceptor} from "../shared/interceptors/default-http-interceptors";
import {SharedModule} from "../shared/shared-module";
import {UserFormComponent} from "./user/user-form/user-form.component";
import {UsersComponent} from "./user/users/users.component";
import {AdministrationComponent} from "./administration/administration.component";
import {ApplicationListComponent} from "./application-project/application-list/application-list.component";
import {ApplicationComponent} from "./application-project/application/application.component";
import {ProjectListComponent} from "./application-project/project-list/project-list.component";
import {ProjectComponent} from "./application-project/project/project.component";
import {CollectedDataComponent} from "./collected-data/collected-data.component";
import {CoreRoutingModule} from "./core-routing.module";
import {CoreComponent} from "./core.component";
import {DefaultComponent} from "./default/default.component";
import {ImportDataComponent} from "./import-data/import-data.component";
import {JiraErrorListComponent} from "./import-data/jira-import/jira-error-list/jira-error-list.component";
import {JiraErrorComponent} from "./import-data/jira-import/jira-error/jira-error.component";
import {JiraImportComponent} from "./import-data/jira-import/jira-import.component";
import {PackExportComponent} from "./import-data/pack-export/pack-export.component";
import {PackImportComponent} from "./import-data/pack-import/pack-import.component";
import {NotFoundComponent} from "./not-found/not-found.component";
import {OrderFormListComponent} from "./orders-forms/order-form-list/order-form-list.component";
import {OrderFormComponent} from "./orders-forms/order-form/order-form.component";
import {CalculateEcheancesComponent} from "./pack-generation/calculate-echeances/calculate-echeances.component";
import {CommentPromptComponent} from "./pack-generation/calculate-echeances/dialog-box/comment-prompt.component";
import {CommentBoxComponent} from "./pack-generation/calculate-echeances/dialog-box/comment-box.component";
import {
  EcheanceDetailsComponent
} from "./pack-generation/calculate-echeances/echeance-details/echeance-details.component";
import {EcheancesListComponent} from "./pack-generation/calculate-echeances/echeances-list/echeances-list.component";
import {PackGenerationComponent} from "./pack-generation/pack-generation/pack-generation.component";
import {ApplicationDomainComponent} from "./service-manager/application-domain/application-domain.component";
import {ApplicationSettingsComponent} from './application-settings/application-settings.component';
import {DomainesComponent} from './domaines/domaines.component';
import {DomainesListComponent} from './domaines-list/domaines-list.component';
import {CalendrierFormComponent} from './calendrier-pack/calendrier-form/calendrier-form.component';
import {CalendrierFormListComponent} from './calendrier-pack/calendrier-form-list/calendrier-form-list.component';
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatNativeDateModule} from "@angular/material/core";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {NettingFormComponent} from './netting-account/netting-form/netting-form.component';
import {NettingFormListComponent} from './netting-account/netting-form-list/netting-form-list.component';
import {NettingSchemaFormComponent} from './netting-schema/netting-schema-form/netting-schema-form.component';
import {
  NettingSchemaFormListComponent
} from './netting-schema/netting-schema-form-list/netting-schema-form-list.component';
import {ExcludedDemandFormComponent} from './excluded-demand/excluded-demand-form/excluded-demand-form.component';
import {ExcludedDemandListComponent} from './excluded-demand/excluded-demand-list/excluded-demand-list.component';
import {DashboardComponent} from "./dashboard/dashboard.component";
import {DashboardMenuComponent} from "./dashboard/dashboard-menu/dashboard-menu.component";
import {DashboardAlertComponent} from "./dashboard/dashboard-alert/dashboard-alert.component";
import {DashboardOrderComponent} from "./dashboard/dashboard-order/dashboard-order.component";
import {DashboardAppsComponent} from "./dashboard/dashboard-apps/dashboard-apps.component";
import {RepositoryManagerComponent} from "./application-settings/repository-manager/repository-manager.component";
import {FileManagerComponent} from "./application-settings/file-manager/file-manager.component";
import {MatRadioModule} from '@angular/material/radio';
import {MatListModule} from '@angular/material/list';
import {FacturationComponent} from "./facturations/facturation/facturation.component";
import {FacturationListComponent} from "./facturations/facturation-list/facturation-list.component";
import {FacturationImportComponent} from "./facturations/facturation-import/facturation-import.component";
import {
  FacturationNettingRegularisationComponentForm
} from "./facturations/facturation-netting_regularisation/facturation-netting_regularisation-form/facturation-netting_regularisation.component";
import {
  FacturationNettingListComponent
} from "./facturations/facturation-netting_regularisation/facturation-netting-list/facturation-netting-list.component";
import {FacturationVueNettingComponent} from "./facturations/facturation-vue_netting/facturation-vue_netting.component";
import {MatGridListModule} from "@angular/material/grid-list";
import {
  PurgeModalConfirmationComponent
} from './application-settings/purge-modal-confirmation/purge-modal-confirmation.component';
import {
  CalenderModalConfirmationComponent
} from './calendrier-pack/calender-modal-confirmation/calender-modal-confirmation.component';
import { ClosingPackModalComponent } from './application-settings/closing-pack-modal/closing-pack-modal.component';
import { FusionPackModalComponent } from './application-settings/fusion-pack-modal/fusion-pack-modal.component';


@NgModule({
  imports: [
    SharedModule,
    CoreRoutingModule,
    CommonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatListModule,
    MatGridListModule
  ],
  exports: [],
  declarations: [
    CoreComponent,
    OrderFormComponent,
    OrderFormListComponent,
    AdministrationComponent,
    ProjectComponent,
    ApplicationComponent,
    ApplicationListComponent,
    ProjectListComponent,
    UsersComponent,
    UserFormComponent,
    JiraImportComponent,
    JiraErrorListComponent,
    JiraErrorComponent,
    PackGenerationComponent,
    PackImportComponent,
    CalculateEcheancesComponent,
    CollectedDataComponent,
    EcheancesListComponent,
    ImportDataComponent,
    PackExportComponent,
    EcheanceDetailsComponent,
    NotFoundComponent,
    DefaultComponent,
    CommentBoxComponent,
    CommentPromptComponent,
    ApplicationDomainComponent,
    ApplicationSettingsComponent,
    DomainesComponent,
    DomainesListComponent,
    CalendrierFormComponent,
    CalendrierFormListComponent,
    NettingFormComponent,
    NettingFormListComponent,
    NettingSchemaFormComponent,
    NettingSchemaFormListComponent,
    ExcludedDemandFormComponent,
    ExcludedDemandListComponent,
    DashboardComponent,
    DashboardMenuComponent,
    DashboardAlertComponent,
    DashboardOrderComponent,
    DashboardAppsComponent,
    RepositoryManagerComponent,
    FileManagerComponent,
    FacturationComponent,
    FacturationListComponent,
    FacturationImportComponent,
    FacturationNettingRegularisationComponentForm,
    FacturationNettingListComponent,
    FacturationVueNettingComponent,
    PurgeModalConfirmationComponent,
    CalenderModalConfirmationComponent,
    ClosingPackModalComponent,
    FusionPackModalComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DefaultHttpInterceptor,
      multi: true
    },
    MatDatepickerModule,
  ],
  bootstrap: [CoreComponent],

})
export class CoreModule {
}
