<form class="form" [formGroup]="formGroup" (ngSubmit)="onSubmit()" class="formDomain">
  <mat-form-field appearance="outline" color="accent" class="input-margin">
    <mat-label>Nom</mat-label>
    <input matInput required formControlName="name" type="text" [readonly]="asEdited">
    <mat-hint>Obligatoire</mat-hint>
  </mat-form-field>

  <mat-form-field appearance="outline" color="accent">
    <mat-label>Type</mat-label>
    <mat-select formControlName="type" [disabled]="asEdited">
      <mat-option *ngFor="let dsiType of Object.values(DsiTypes)" [value]="dsiType">
        {{dsiType}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <button mat-raised-button class="btn_created" color="primary" type="submit" [disabled]="!formGroup.valid"
    *ngIf="!asEdited && !btnStatus">Créer
  </button>
  <button mat-raised-button class="btn_annuler" type="button" color="accent" (click)="clear()">Annuler</button>
  <button mat-raised-button class="btn_valid" type="submit" [disabled]="!formGroup.valid"
    *ngIf="!asEdited && btnStatus">Valider
  </button>
  <button mat-raised-button class="btn_edit" *ngIf="asEdited" (click)="is_edit(asEdited)">Editer</button>
  <button mat-raised-button class="btn_supp" color="warn" *ngIf="asEdited" (click)="confirmDelete()">Supprimer</button>

</form>