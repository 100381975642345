import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NotFoundComponent} from './core/not-found/not-found.component';
import {ROUTES} from './shared/constants/routes';
import {UserAuthenticationCheckGuard} from './shared/guards/user-authentication-check.guard';
import {CheckUserRolesComponent} from './check-user-roles.component';
import {CheckUserAuthenticationComponent} from './check-user-authentication.component';
import {NoAuthenticationAccessComponent} from './no-authentication-access.component';

const routes: Routes = [


  {
    path: ROUTES.CORE_PATH,
    loadChildren: () => import('./core/core.module').then(module => module.CoreModule),
    canActivate: [UserAuthenticationCheckGuard]
  },
  {
    path: ROUTES.CHECK_AUTH_PATH,
    component:CheckUserAuthenticationComponent,
    pathMatch:'full',
  },
  {
    path: ROUTES.NO_AUTH_ACCESS,
    component:NoAuthenticationAccessComponent,
    pathMatch:'full',
  },
  {
    path: ROUTES.LOGIN_TEST,
    component:CheckUserRolesComponent,
    pathMatch:'full',
  },
  {
    path: ROUTES.EMPTY_PATH,
    redirectTo:ROUTES.CORE_PATH,
    pathMatch:'full',
  },
  {
    path: ROUTES.UNAUTHORIZED_PATH,
    loadChildren: () => import('./unauthorized/unauthorized.module').then(module => module.UnauthorizedModule),
  },
  {
    path: ROUTES.ELSE_PATH,
    component: NotFoundComponent
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {enableTracing:false})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
