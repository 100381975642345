export interface IExcluded_demand {
    id?:string;
    exd_number:string;
    exd_type?:ExcludedDemandsType;
    isValid?:boolean;
    of_year:string;
}

export enum ExcludedDemandsType {
    DEMANDE = "DEMANDE",
    ALERTE = "ALERTE"
}