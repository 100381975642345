import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { ApplicationSettingsService } from 'src/app/core/application-settings/application-settings.service';
import { ROUTES } from '../constants/routes';
import { UserProfile } from '../models/user-profile';

@Injectable({
    providedIn: 'root'
})
export class UserProfileCheckGuard {

    private readonly PATHS_ALOWED_PROFILES = new Map<string, string[]>([
        [ROUTES.ADMIN_ORDER_FOFRM_LIST_PATH, [UserProfile.ADMIN, UserProfile.SERVICE_MANAGER, UserProfile.MANAGER_CDS]],
        [ROUTES.ADMIN_CALENDRIER_PATH, [UserProfile.ADMIN, UserProfile.SERVICE_MANAGER, UserProfile.MANAGER_CDS]],
        [ROUTES.ADMIN_EXCLUDED_DEMAND_PATH, [UserProfile.ADMIN, UserProfile.SERVICE_MANAGER, UserProfile.MANAGER_CDS]],
        [ROUTES.ADMIN_NETTING_PATH, [UserProfile.ADMIN, UserProfile.MANAGER_CDS]],
        [ROUTES.ADMIN_PROJECTS_PATH, [UserProfile.ADMIN, UserProfile.SERVICE_MANAGER, UserProfile.MANAGER_CDS]],
        [ROUTES.ADMIN_DOMAINES_PATH, [UserProfile.ADMIN, UserProfile.SERVICE_MANAGER, UserProfile.MANAGER_CDS]],
        [ROUTES.ADMIN_APPS_PATH, [UserProfile.ADMIN, UserProfile.SERVICE_MANAGER, UserProfile.MANAGER_CDS]],
        [ROUTES.ADMIN_CONTACTS_PATH, [UserProfile.ADMIN, UserProfile.SERVICE_MANAGER, UserProfile.MANAGER_CDS]],
        [ROUTES.ADMIN_IMPORT_PATH, [UserProfile.ADMIN]],
        [ROUTES.ADMIN_NETTING_SCHEMA_PATH, [UserProfile.ADMIN,  UserProfile.MANAGER_CDS]],
        [ROUTES.DATA_PACK_GEN_PATH, [UserProfile.ADMIN, UserProfile.SERVICE_MANAGER, UserProfile.CP_CDS, UserProfile.CP_SNCF, UserProfile.MANAGER_CDS, UserProfile.GUEST_CDS, UserProfile.GUEST_SNCF]],
        [ROUTES.ADMIN_SETTINGS_PATH, [UserProfile.ADMIN]],
        [ROUTES.SM_PROJECT_PATH, [UserProfile.ADMIN, UserProfile.SERVICE_MANAGER, UserProfile.CP_CDS, UserProfile.CP_SNCF, UserProfile.MANAGER_CDS, UserProfile.GUEST_CDS, UserProfile.GUEST_SNCF]],
        [ROUTES.DASHBOARD_PATH, [UserProfile.ADMIN, UserProfile.SERVICE_MANAGER, UserProfile.CP_CDS, UserProfile.CP_SNCF, UserProfile.MANAGER_CDS, UserProfile.GUEST_CDS, UserProfile.GUEST_SNCF]],
        [ROUTES.DASHBOARD_ALERT_PATH, [UserProfile.ADMIN, UserProfile.SERVICE_MANAGER, UserProfile.CP_CDS, UserProfile.CP_SNCF, UserProfile.MANAGER_CDS, UserProfile.GUEST_CDS, UserProfile.GUEST_SNCF]],
        [ROUTES.DASHBOARD_MENU_PATH, [UserProfile.ADMIN, UserProfile.SERVICE_MANAGER, UserProfile.CP_CDS, UserProfile.CP_SNCF, UserProfile.MANAGER_CDS, UserProfile.GUEST_CDS, UserProfile.GUEST_SNCF]],
        [ROUTES.DASHBOARD_ORDERS_PATH, [UserProfile.ADMIN, UserProfile.SERVICE_MANAGER, UserProfile.CP_CDS, UserProfile.CP_SNCF, UserProfile.MANAGER_CDS, UserProfile.GUEST_CDS, UserProfile.GUEST_SNCF]],
        [ROUTES.DASHBOARD_APPS_PATH, [UserProfile.ADMIN, UserProfile.SERVICE_MANAGER, UserProfile.CP_CDS, UserProfile.CP_SNCF, UserProfile.MANAGER_CDS, UserProfile.GUEST_CDS, UserProfile.GUEST_SNCF]],
        [ROUTES.DASHBOARD_NETTINGS_PATH, [UserProfile.ADMIN, UserProfile.MANAGER_CDS, UserProfile.CP_CDS]],
        [ROUTES.DASHBOARD_POWER_BI_PATH, [UserProfile.ADMIN, UserProfile.SERVICE_MANAGER, UserProfile.CP_CDS, UserProfile.CP_SNCF, UserProfile.MANAGER_CDS, UserProfile.GUEST_CDS, UserProfile.GUEST_SNCF]],
        [ROUTES.FACTURATION_LIST_PATH, [UserProfile.ADMIN]],
        [ROUTES.FACTURATION_IMPORT_PATH, [UserProfile.ADMIN]],
        [ROUTES.FACTURATION_PATH, [UserProfile.ADMIN]],
        [ROUTES.FACTURATION_NETTING_PATH, [UserProfile.ADMIN]],
        [ROUTES.FACTURATION_VUE_NETTING_PATH, [UserProfile.ADMIN, UserProfile.MANAGER_CDS, UserProfile.CP_CDS]]
    ]);

    constructor(private router: Router,
        private applicationSettingsService: ApplicationSettingsService) {
    }


    public canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {

        const user = JSON.parse(sessionStorage.getItem('user') as string);
        const path: string = route.routeConfig!.path ?? '';
        const alowedProfiles = this.PATHS_ALOWED_PROFILES.get(path);
        // Règles générales des profils autorisées
        if (user?.profile && alowedProfiles && alowedProfiles.includes(user.profile)) {

            // Règles spéciales aux chemins
            if (path == ROUTES.DATA_PACK_GEN_PATH && user.profile != UserProfile.ADMIN && !this.applicationSettingsService.getApplicationSettings().enableCollectedDataTab) {
                this.router.navigateByUrl(`/${ROUTES.CORE_PATH}/${ROUTES.NOT_FOUND_PATH}`);
                return false;
            } else {
                return true;
            }
        } else {
            this.router.navigateByUrl(`/${ROUTES.CORE_PATH}/${ROUTES.NOT_FOUND_PATH}`);
            return false;
        }

    }
}
