<mat-spinner class="loading-main" *ngIf="spinner"></mat-spinner>

<div class="card" *ngIf="!spinner">

  <app-order-form [orderForm]="orderFormSelected" (updateEvent)="updateOrderFormList($event)"
    (clearEvent)="clearOrderFormSelected()"
    [ngStyle]="authenticationService.isUserAdmin()? {}: {'display':'none'}"></app-order-form>

  <div class="data-header">

    <mat-form-field class="yearFilter">
      <mat-label>Filtre année</mat-label>
      <input matInput (keyup)="orderFormFilterByYear($event)">
    </mat-form-field>
    <mat-form-field class="orderFilter">
      <mat-label>Filtre N° bon de commande</mat-label>
      <input matInput (keyup)="orderFormFilterByNumber($event)">
    </mat-form-field>
    <div class="exportBtn">
      <button mat-raised-button class="indigo btRight" [style.background-color]="theme.getColor('Main Dark')"
        (click)="exportData()">
        <mat-icon>get_app</mat-icon>
        Export Excel
      </button>
    </div>
  </div>

  <mat-table [dataSource]="ordersForms" #table class="light mat-elevation-z4" matSort>

    <ng-container matColumnDef="number">
      <mat-header-cell *matHeaderCellDef mat-sort-header> No. </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.number }} </mat-cell>
      <td class="total" mat-footer-cell *matFooterCellDef><strong> Total </strong></td>
    </ng-container>

    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.description }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="dsi">
      <mat-header-cell *matHeaderCellDef> DSI </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.dsi }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="dssi">
      <mat-header-cell *matHeaderCellDef> DDSI </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.dssi }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="user">
      <mat-header-cell *matHeaderCellDef> Responsable </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.user?.firstName }} {{ element.user?.lastName }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="year">
      <mat-header-cell *matHeaderCellDef> Année </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.referenceYear }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="lot">
      <mat-header-cell *matHeaderCellDef> lot </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.lot }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="cost">
      <mat-header-cell *matHeaderCellDef> Montant
        <div class="tOrders">{{ totalOrders | currency:'EUR':'symbol':'1.2-2' }}</div>
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.cost | currency:'EUR':'symbol':'1.2-2' }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
      <mat-cell *matCellDef="let element" class="alRight">
        <button mat-icon-button (click)="selectedOrderFormEditBtn(element)"
          [disabled]="!authenticationService.isUserAdmin()">
          <mat-icon>mode_edit</mat-icon>
        </button>
        <button mat-icon-button (click)="removeOrderForm(element)" [disabled]="!authenticationService.isUserAdmin()">
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns; " (click)="selectedOrderForm(row)"></mat-row>
  </mat-table>
</div>