import { Component, OnInit, ViewChild, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { INettingSchema } from 'src/app/shared/models/netting-schema';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { NettingService } from '../../netting-account/netting.service';
import { NettingSchemaService } from '../nettingschema.service';
import * as fileSaver from 'file-saver';
import { ApplicationSettingsService } from '../../application-settings/application-settings.service';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';

@Component({
  selector: 'app-netting-schema-form-list',
  templateUrl: './netting-schema-form-list.component.html',
  styleUrls: ['./netting-schema-form-list.component.less']
})
export class NettingSchemaFormListComponent implements OnInit, AfterViewInit {

  netting_schemas!: MatTableDataSource<INettingSchema>;
  netting_schemaSelected!: INettingSchema;
  @ViewChild(MatSort) sort!: MatSort;
  displayedColumns: string[] = ["name", "line", "nature", "na_name", "line_ref", "taux1","taux2", "actions"];

  constructor(
    private _ns: NettingService,
    private _nss: NettingSchemaService,
    public theme: ThemeService,
    public authenticationService: AuthenticationService,
    private dialogService: DialogService,
    private applicationSettingsService: ApplicationSettingsService,
    private snackbarService: SnackbarService
  ) {
    this.netting_schemas = new MatTableDataSource();
  }

  
  openErrorSnackBar(message: string) {
    this.snackbarService.openSnackBar(message, 'error-snack-bar');
  }

  openSuccessSnackBar(message: string) {
    this.snackbarService.openSnackBar(message);
  }

  ngOnInit(): void {
    this.getNettingSchemaService();
  }

  ngAfterViewInit() {
    this.netting_schemas.sort = this.sort;
  }

  getNettingSchemaService(): void {
    this._nss.getNettingSchemas().subscribe({
      next: data => {
        this.netting_schemas.data = data;
      },
      error: err => {
        console.log(err);
        this.openErrorSnackBar('Erreur lors de la récupération des schemas de netting');
      }
    });
  }

  updateList(data: INettingSchema): void {
    this.getNettingSchemaService();
  }

  clearSelected() {
    this.netting_schemaSelected = {
      id: '',
      name: '',
      line: undefined,
      nature: '',
      na_name: undefined,
      line_ref: undefined,
      taux1: undefined,
      taux2: undefined,
      isEnabled: false,
      na_id: '',
      compte_imputes_id: ''
    };
  }

  filterList(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.netting_schemas.filter = filterValue.trim().toLowerCase();
  }

  onEditRowPencel(row: INettingSchema): void {
    this.netting_schemaSelected = { ...row, isEnabled: false };
  }

  onEditRow(row: INettingSchema): void {
    this.netting_schemaSelected = { ...row, isEnabled: true };
  }

  onDeleteRow(row: INettingSchema): void {
    this.dialogService.openConfirmDialog(row.name)
      .afterClosed().subscribe(res => {
        if (res) {
          this._nss.deleteNettingSchema(row).subscribe({
            next: data => {
              this.getNettingSchemaService();
              this.openSuccessSnackBar('Schéma de netting supprimé avec succès');
            },
            error: err => {
              console.log(err);
              this.openErrorSnackBar('Erreur lors de la suppression du schéma de netting');
            },
            complete: () => {
              this.clearSelected();
            }
          });
        }
      });
  }

  exportData() {
    this._nss.exportDataNettingSchema().subscribe({
      next: data => {
        const blob: any = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        window.URL.createObjectURL(blob);
        fileSaver.saveAs(blob, `NettingShema - ${this.applicationSettingsService.applicationSettings.CDS_name} - ${new Date().toLocaleDateString().replace('_', '-')} ${new Date().toLocaleTimeString().replace('_', '-')}`);
        this.openSuccessSnackBar('Données exportées avec succès');
      },
      error: err => {
        console.log(err);
        if (err.status === 404) {
          this.openSuccessSnackBar('Aucun schema de netting a exporter');
        } else {
          this.openErrorSnackBar('Erreur lors de l\'export des schema de netting');
        }
      }
    });
  }

  isFirstOfGroup(index: number): boolean {
    if (index === 0) return true;
    const data = this.netting_schemas.data;
    return data[index].name !== data[index - 1].name;
  }

  getBackgroundColor(): string {
    return this.theme.getColor('Main Dark');
  }
}
