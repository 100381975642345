import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
})
export class FileInputComponent {

  formGroup: UntypedFormGroup;
  fileName !: string;

  @Output()
  file!: File;

  @Input()
  label = "Importer un fichier";

  @Input()
  placeholder = "";

  @Input()
  hint = "";

  @Input()
  canSubmit!: boolean;

  @Input()
  content: File;
  @Output()
  contentChange = new EventEmitter<File>();

  constructor(private _fb: UntypedFormBuilder) {
    this.formGroup = new UntypedFormGroup({
      file: new UntypedFormControl(null, [Validators.required]),
    });
    this.content = this.file;
  }


  onInput(event: any): void {
    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
      this.fileName = this.file.name;
      this.content = this.file;
      this.contentChange.emit(this.content);
    }
  }

}
