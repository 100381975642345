<form class="form" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <div class="group">
    <mat-form-field appearance="outline" color="accent">
      <mat-label>No ° </mat-label>
      <input
        matInput
        required
        formControlName="number"
        type="text"
        [readonly]="asEdited"
      />
      <mat-hint>Obligatoire</mat-hint>
    </mat-form-field>

    <mat-form-field appearance="outline" color="accent">
      <mat-label>DSI</mat-label>
      <input matInput formControlName="dsi" type="text" [readonly]="asEdited" />
    </mat-form-field>

    <mat-form-field appearance="outline" color="accent">
      <mat-label>DDSI</mat-label>
      <input
        matInput
        formControlName="dssi"
        type="text"
        [readonly]="asEdited"
      />
    </mat-form-field>

    <mat-form-field appearance="outline" color="accent">
      <mat-label>Responsable</mat-label>
      <input
        matInput
        formControlName="user"
        type="text"
        [matAutocomplete]="autoUsers"
        (ngModelChange)="filter($event)"
        [readonly]="asEdited"
      />
      <mat-autocomplete #autoUsers="matAutocomplete">
        <mat-option *ngFor="let user of filteredOptions" [value]="user">
          {{ user }}
        </mat-option>
      </mat-autocomplete>
      <mat-hint>Obligatoire</mat-hint>
    </mat-form-field>

    <mat-form-field appearance="outline" color="accent">
      <mat-label>Année du Pack</mat-label>
      <input
        matInput
        required
        formControlName="referenceYear"
        type="number"
        [readonly]="asEdited"
      />
      <mat-hint>Obligatoire</mat-hint>
    </mat-form-field>

    <mat-form-field appearance="outline" color="accent">
      <mat-label>Lot</mat-label>
      <input matInput formControlName="lot" type="text" [readonly]="asEdited" />
    </mat-form-field>

    <mat-form-field appearance="outline" color="accent">
      <mat-label>Montant</mat-label>
      <input
        matInput
        formControlName="cost"
        type="number"
        step="0.00"
        value="{{
          formGroup.get('montantInitial')?.value +
            +formGroup.get('montantAvenant1')?.value +
            formGroup.get('montantAvenant2')?.value +
            formGroup.get('montantAvenant3')?.value
        }}"
      />
    </mat-form-field>
  </div>

  <div class="group">
    <mat-form-field appearance="outline" color="accent" class="input-large">
      <mat-label>Description</mat-label>
      <input
        matInput
        formControlName="description"
        type="text"
        [readonly]="asEdited"
      />
    </mat-form-field>

    <mat-form-field appearance="outline" color="accent">
      <mat-label>Montant Initial</mat-label>
      <input
        matInput
        formControlName="montantInitial"
        type="number"
        step="0.00"
        [readonly]="asEdited"
      />
      <mat-hint>Obligatoire</mat-hint>
    </mat-form-field>

    <mat-form-field appearance="outline" color="accent">
      <mat-label>Montant Avenant 1</mat-label>
      <input
        matInput
        formControlName="montantAvenant1"
        type="number"
        step="0.00"
        [readonly]="asEdited"
      />
    </mat-form-field>

    <mat-form-field appearance="outline" color="accent">
      <mat-label>Montant Avenant 2</mat-label>
      <input
        matInput
        formControlName="montantAvenant2"
        type="number"
        step="0.00"
        [readonly]="asEdited"
      />
    </mat-form-field>

    <mat-form-field appearance="outline" color="accent">
      <mat-label>Montant Avenant 3</mat-label>
      <input
        matInput
        formControlName="montantAvenant3"
        type="number"
        step="0.00"
        [readonly]="asEdited"
      />
    </mat-form-field>
  </div>
  <button
    mat-raised-button
    class="btn"
    color="primary"
    type="submit"
    [disabled]="!formGroup.valid"
    *ngIf="!asEdited && !btnStatus"
  >
    Créer
  </button>
  <button mat-raised-button class="btn" color="accent" (click)="clear()">
    Annuler
  </button>
  <button
    mat-raised-button
    class="btn_valid btn"
    type="submit"
    [disabled]="!formGroup.valid"
    *ngIf="!asEdited && btnStatus"
  >
    Valider
  </button>
  <button
    mat-raised-button
    class="btn_edit btn"
    *ngIf="asEdited"
    (click)="is_edit(asEdited)"
  >
    Editer
  </button>
  <button
    mat-raised-button
    color="warn"
    (click)="confirmDelete()"
    *ngIf="asEdited"
  >
    Supprimer
  </button>
</form>
