export interface IAlert{

    id?:number;
    type?:AlertVue;
    application?:string;
    dnumber?:string;
    ddescription?:string;
    dusername?:string;
    severity?:AlertLevel;
    comment?:string;
    dstatus?:string;
    datetime?:Date;
    code?:string;
    projectCode?:string;
    serviceType?:string;
    reference?:string;
    dateTime?:string;
}

export enum AlertVue {
    DSI = "DSI",
    BEB = "BEB",
    CDS = "CDS",
    ADMIN = "ADMIN"
  }

  export enum AlertLevel {
    Bloquant = "Bloquant",
    Majeur = "Majeur",
    Avertissement = "Avertissement",
    Information = "Information"
  }

  export enum AlertCode {
    A01 = "A01 - Type de Demande",
    A02 = "A02 - Date Prévision Devis",
    A03 = "A03 - Date Prévision Livraison Sortie d'Usine",
    A04 = "A04 - Date Prévision Livraion Production",
    A05 = "A05 - Date Prévision Emission Devis",
    A06 = "A06 - Date Prévision Livraison Sortie d'Usine",
    A07 = "A07 - Date Prévision Livraison Production",
    A08 = "A08 - Service Manquant",
    A09 = "A09 - Date de GO de Réalisation",
    A10 = "A10 - Date Prévision Emission Devis",
    A12 = "A12 - Bon de Commande invalide ou manquant",
    A13 = "A13 - Date Prévision Sortie d'Usine",
    A14 = "A14 - Date Prévision Sortie d'Usine",
    A15 = "A15 - Date Prévision Livraison Production",
    A16 = "A16 - Date Prévision Livraison Sortie D'Usine < Go de Réalisation",
    A17 = "A17 - Charge non renseignée",
    A18 = "A18 - Charge non valide",
    A19 = "A19 - Montant non renseigné",
    A20 = "A20 - Montant non valide",
    A21 = "A21 - Charge > Montant",
    A22 = "A22 - Nouvelle Commande",
    A23 = "A23 - Nouveau Projet",
    A24 = "A24 - Nouveau Service",
    A25 = "A25 - Nouveau Chef de projet",
    A26 = "A26 - DT fermée avec montant non nul et l'absence de date de go réalisation",
    A27 = "A27 - Mauvais formatage de la charge estimée",
    A28 = "A28 - Mauvais formatage du montant validé"
  }
