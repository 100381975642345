import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, filter } from 'rxjs/operators';
import { Observable, ReplaySubject } from 'rxjs';
import { ConfigService } from './config.service';
import { AuthConfig, OAuthEvent, OAuthService, TokenResponse } from 'angular-oauth2-oidc';
import { ROUTES } from '../constants/routes';
import { UtilsService } from './utils.service';
import { environment } from 'src/environments/environment';
import { Constants } from '../constants/Constants';
import { User } from '../models/user';
import { UserProfile } from '../models/user-profile';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {


  private isDoneLoadingSubject$ = new ReplaySubject<boolean>();

  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

  private user!: User;

  public getUser() {
    return this.user;
  }

  public setUser(user: User) {
    this.user = user
    return this.user;
  }

  constructor(private oauthService: OAuthService,
    private http: HttpClient,
    private router: Router,
    private configService: ConfigService,
    private utilsService: UtilsService) {

    const user = JSON.parse(sessionStorage.getItem('user') as string);
    if (user) {
      this.user = user;
    }
  }

  /**
   * Configurer le service OAuth et lancer la première séquence de login
   * @param configService
   * @param oAuthService
   * @returns
   */
  setOAuthConfig(configService: ConfigService, oAuthService: OAuthService): Promise<any> {
    return new Promise((resolve: any) => {


      if (configService.isAuthEnabled()) {
        const authConfig: AuthConfig = {
          issuer: configService.getConfiguration().issuer,
          redirectUri: configService.getConfiguration().redirectUri,
          clientId: configService.getConfiguration().clientId,
          scope: configService.getConfiguration().scope,
          responseType: 'code',
          dummyClientSecret: configService.getConfiguration().client_secret,
          sessionChecksEnabled: false,
        };

        oAuthService.configure(authConfig);
        this.runInitialLoginSequence();
        this.oauthService.setupAutomaticSilentRefresh();
        this.oauthService.events
          .pipe(filter(e => ['token_received'].includes(e.type)))
          .subscribe(e => this.oauthService.loadUserProfile());

      }
      // on débloque
      resolve();
    });

  }

  /**
   * Lancer la séquence de login
   */
  public runInitialLoginSequence(): Promise<void> {

    return this.oauthService.loadDiscoveryDocument()
      .then(() => this.oauthService.tryLogin())
      .then(() => {
        this.isDoneLoadingSubject$.next(true);

        if (this.oauthService.state
          && this.oauthService.state !== 'undefined'
          && this.oauthService.state !== 'null') {
          this.router.navigateByUrl(`/${ROUTES.EMPTY_PATH}`);
        }
      })
      .catch(() => this.isDoneLoadingSubject$.next(true));
  }


  /**
   * enregistrer les données de l'utilisateur connecté dans la session
   */
  public saveSessionUser(response: any, user: User, roles: string[]) {  
    this.user = {
      lastName: user.lastName,
      firstName: user.firstName,
      identifier: user.identifier,
      profile: user.profile,
      applications: user.applications,
      roles: roles,
      nettingView: user.nettingView,
    };
    sessionStorage.setItem('user', JSON.stringify(this.user));
    localStorage.setItem('debugMode', 'false');
  }


  /**
   * Vérifier si l'utilisateur a tous les droits nécessaires pour accéder à l'appli
   * @param accessToken
   */
  public checkUserAuthorization(accessToken: string) {
    let hasRoles = false;

    this.getUserInfo(accessToken).subscribe({
      next: response => {
        this.getUserProfile(response.login || response.sub).subscribe({
          next: user => {
            if (user?.profile) {
              this.saveSessionUser(response, user, hasRoles ? response.roles : response.roles1);
              this.router.navigateByUrl(`/${ROUTES.CORE_PATH}`);
            } else {
              this.router.navigateByUrl(`/${ROUTES.UNAUTHORIZED_PATH}`);
            }
          },
          error: error => {
            console.error(error)
            window.alert("Vous n'êtes pas autorisé à accéder à cette application.");
            this.logOut();
          },
          complete: () => { }
        })

      },
      error: error => {
        console.error(error)
      },
      complete: () => { }
    })
  }

  /**
   * Vérifier si l'utilisateur est dans le bon groupe FID associé à Packgemini
   * @param receivedRole
   * @returns
   */
  private hasUserRightFIDRole(receivedRole: any) {
    if (receivedRole instanceof Array) {
      return !!receivedRole.filter(x => this.configService.getAuthorizedRoles().includes(x)).length;
    } else {
      return this.configService.getAuthorizedRoles().includes(receivedRole);
    }
  }

  /**
   * Récupérer le profil de l'utilisateur de la BDD
   */
  public getUserProfile(login: string): Observable<any> {

    return this.http.get<any>(environment.backendApiUrl + Constants.ApiPaths.USERS_BY_IDENTIFIER + login)
      .pipe(catchError(this.utilsService.handleError));
  }

  /**
   * Récupérer les informations utilsateur de la FID
   * @param accessToken
   */
  public getUserInfo(accessToken: string): Observable<any> {

    const httpHeaders = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
    return this.http.get<any>(this.configService.getConfiguration().userInfoEndPoint, { headers: httpHeaders })
      .pipe(catchError(this.utilsService.handleError));
  }

  /**
   *
   * @param targetUrl
   */
  public logIn(targetUrl?: string) {
    sessionStorage.removeItem('user');
    this.oauthService.initCodeFlow(encodeURIComponent(targetUrl ?? this.router.url));
  }

  public isUserNotServiceManager() {
    return this.user?.profile && this.user.profile != UserProfile.SERVICE_MANAGER;
  }

  public isServiceManager() {
    return this.user.profile == UserProfile.SERVICE_MANAGER;
  }

  public isUserAdmin() {
    return this.user && this.user.profile == UserProfile.ADMIN;
  }

  public isUserAdminOrServiceManager() {
    return this.user && (this.user.profile == UserProfile.ADMIN || this.user.profile == UserProfile.SERVICE_MANAGER || this.user.profile == UserProfile.MANAGER_CDS || this.user.profile == UserProfile.GUEST_CDS || this.user.profile == UserProfile.GUEST_SNCF);
  }

  public isUserAdminOrServiceManagerOrManagerCDS() {
    return this.user && (this.user.profile == UserProfile.ADMIN || this.user.profile == UserProfile.SERVICE_MANAGER || this.user.profile == UserProfile.MANAGER_CDS);
  }


  /**
   *
   */
  public logOut() {
    sessionStorage.removeItem('user');
    this.oauthService.logOut();
    sessionStorage.clear();
  }

  /**
   * Vérifier si l'access_token n'a pas expiré
   * @returns
   */
  public hasValidAccessToken() {
    return this.oauthService.hasValidAccessToken()
  }

  /**
   * Vérifier si l'id_token n'a pas expiré
   * @returns
   */
  public hasValidIdToken() {
    return this.oauthService.hasValidIdToken()
  }

  /**
   * Rafraichir l'access_token expiré
   * @returns
   */
  public refreshToken(): Promise<TokenResponse> {
    return this.oauthService.refreshToken()
  }

  /**
   * Rafraichir l'access_token expiré
   * @returns
   */
  public getAuthorizationHeader(): string {
    return this.oauthService.authorizationHeader()
  }

  public getIdToken(): string {
    return this.oauthService.getIdToken();
  }

  public getAccessToken() {
    return this.oauthService.getAccessToken();
  }

  public silentRefresh(): Promise<OAuthEvent> {
    return this.oauthService.silentRefresh();
  }

  public getIdentityClaims(): any {
    return this.oauthService.getIdentityClaims();
  }

  public getDebugMode() {
    return JSON.parse(localStorage.getItem('debugMode') as string);
  }
}
