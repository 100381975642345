export class ApplicationSettings {
    previsional_facturation_enabled = false;
    invoice_mode = "Défaut";
    CDS_name = 'CDS Transilien';
    Closure_in_progress = false;
    Enable_Previous_Year = false;
    Enable_SNCF_Validation = true;
    enableCollectedDataTab = true;
    JIRA_Extract_Date = '2022-06-29T15:53';
    JIRA_Radical = 'Extraction Pack BDC Transilien';
    migration_enabled = true;
    Operations_in_progress = false;
    Pack_current_month = 6;
    Pack_current_year = 2022;
    display_fusion_pack = false;
    RFA_second_rate_date = '2022-06-29T15:53';
    Gouv_Sogeti_second_rate_date = '2022-06-29T15:53';
    Gouv_Capgemini_second_rate_date = '2022-06-29T15:53';
}
