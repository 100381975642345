<div class="card">
  <app-excluded-demand-form *ngIf="authenticationService.isUserAdmin()" [excluded_demand]="excluded_demandSelected"
    (updateEvent)="updateExdList()"></app-excluded-demand-form>

  <div class="data-header">
    <mat-form-field class="yearFilter">
      <mat-label>Filtre année</mat-label>
      <input matInput (keyup)="excludedDemandFilterByYear($event)">
    </mat-form-field>
    <mat-form-field class="exd_numberFilter">
      <mat-label>Filtre N° de demande</mat-label>
      <input matInput (keyup)="excludedDemandFilterByNumber($event)">
    </mat-form-field>
  </div>

  <mat-table #table class="mat-elevation-z8 table-container" [dataSource]="excluded_demand" matSort>

    <ng-container matColumnDef="numero">
      <mat-header-cell *matHeaderCellDef mat-sort-header id="numero"> Numéro demande</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.exd_number}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef mat-sort-header id="type"> Type d'exclusion</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.exd_type}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="anneeBdc">
      <mat-header-cell *matHeaderCellDef mat-sort-header id="anneeBdc"> Année du bon de commande</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.of_year}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef id="actions" class="actions-td"> Actions</mat-header-cell>
      <mat-cell *matCellDef="let element" class="actions-td">
        <button mat-icon-button (click)="onEdit(element)" [disabled]="!authenticationService.isUserAdmin()">
          <mat-icon>mode_edit</mat-icon>
        </button>
        <button mat-icon-button (click)="onDelete(element)" [disabled]="!authenticationService.isUserAdmin()">
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectedExcluded_demand(row)"></mat-row>
  </mat-table>

</div>