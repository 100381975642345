<div class="alert-container" [ngClass]="{'spinner-active':spinner}">
  <div class="alert-header">

    <div class="alert-export-btn">
      <button mat-raised-button class="export-btn blue" [style.background-color]="theme.getColor('Main Light')"
        (click)="exportData()">Exporter les alertes
      </button>
    </div>
    <div class="alert-vues">
      <button mat-raised-button class="vue-btn indigo" [style.background-color]="theme.getColor('Main Dark')"
        (click)="swap(1)" [ngClass]="{'active':modes[1],'hidden':!isEnabled('DSI')}">DSI
      </button>
      <button mat-raised-button class="vue-btn indigo" [style.background-color]="theme.getColor('Main Dark')"
        (click)="swap(2)" [ngClass]="{'active':modes[2],'hidden':!isEnabled('BEB')}">BEB
      </button>
      <button mat-raised-button class="vue-btn indigo" [style.background-color]="theme.getColor('Main Dark')"
        (click)="swap(3)" [ngClass]="{'active':modes[3],'hidden':!isEnabled('CDS')}">CDS
      </button>
      <button mat-raised-button class="vue-btn indigo" [style.background-color]="theme.getColor('Main Dark')"
        (click)="swap(4)" [ngClass]="{'active':modes[4],'hidden':!isEnabled('PMO')}">PMO
      </button>
    </div>


  </div>
  <div class="alert-body">

    <div class="alert-filter">
      <div id="app-names-nav">
        <div id="app-names-table">
          <table class="table-filter">
            <caption class="displayNone">Liste des types d'alertes</caption>
            <th class="displayNone">Type d'alerte</th>
            <td [ngClass]="{'hidden': isAnyActive() != 0}" class="unselected-card-none">
              <i>Aucune alerte notifiée.</i>
            </td>
            <tr *ngFor="let code of codes; let i = index;">
              <td matRipple [ngClass]="{'hidden': codesCount[i] == null || codesCount[i] == 0}"
                [class]="(codesSelection[i] ? '' : 'un') + 'selected-card-' + codesCrit[i]"
                (click)="codesSelection[i] = !codesSelection[i]">
                <div class="code-row">
                  <div class="code">
                    <b>{{ code.split(' - ')[0] }} : </b>{{ code.split(' - ')[1] }}
                  </div>
                  <div class="code-count"><b>{{ codesCount[i] }}</b></div>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="alert-table">
      <div class="demandFilter">
        <mat-form-field appearance="outline">
          <mat-label>Filtrer par demande</mat-label>
          <input id="filterReference" matInput #input (keyup)="updateFilterList()" [(ngModel)]="demandFilter">
        </mat-form-field>
      </div>
      <mat-table [dataSource]="data" matSort class="light mat-elevation-z4 table" id="alertTable">

        <ng-container matColumnDef="severity">
          <mat-header-cell class="cell severity-row" *matHeaderCellDef>Sévérité</mat-header-cell>
          <mat-cell [class]="(isActive(element)&&isCodeActive(element)?'':'hide')"
            class="cell severity-row {{element.severity}}" mat-cell *matCellDef="let element">{{ element.severity }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="code">
          <mat-header-cell class="cell code-table-row" *matHeaderCellDef>Code</mat-header-cell>
          <mat-cell [class]="(isActive(element)&&isCodeActive(element)?'':'hide')" class="cell code-table-row"
            *matCellDef="let element"><b>{{ element.code.split(" - ")[0] }}</b></mat-cell>
        </ng-container>

        <ng-container matColumnDef="comment">
          <mat-header-cell class="cell comment-row" id="commentRowHeader" *matHeaderCellDef>Alerte</mat-header-cell>
          <mat-cell [class]="(isActive(element)&&isCodeActive(element)?'':'hide')" class="cell"
            *matCellDef="let element">{{ element.comment }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="dnumber">
          <mat-header-cell class="cell dnumber-row" *matHeaderCellDef>Clé de Ticket</mat-header-cell>
          <mat-cell [class]="(isActive(element)&&isCodeActive(element)?'':'hide')" class="cell dnumber-row"
            *matCellDef="let element"><b>{{ element.dnumber }}</b></mat-cell>
        </ng-container>

        <ng-container matColumnDef="ddescription">
          <mat-header-cell class="cell ddescription-row" id="descriptionRowHeader" *matHeaderCellDef>Résumé</mat-header-cell>
          <mat-cell [class]="(isActive(element)&&isCodeActive(element)?'':'hide')" class="cell"
            *matCellDef="let element">{{ element.ddescription }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="application">
          <mat-header-cell class="cell application-row" *matHeaderCellDef>Application</mat-header-cell>
          <mat-cell [class]="(isActive(element)&&isCodeActive(element)?'':'hide')" class="cell application-row"
            *matCellDef="let element">{{ element.application }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="dusername">
          <mat-header-cell class="cell dusername-row" *matHeaderCellDef>Responsable</mat-header-cell>
          <mat-cell [class]="(isActive(element)&&isCodeActive(element)?'':'hide')" class="cell dusername-row"
            *matCellDef="let element">{{ element.dusername }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="dstatus">
          <mat-header-cell class="cell dstatus-row" *matHeaderCellDef>Statut</mat-header-cell>
          <mat-cell [class]="(isActive(element)&&isCodeActive(element)?'':'hide')" class="cell dstatus-row"
            *matCellDef="let element"><span class="state" ngClass="{{statusColor(element)}}">{{ element.dstatus
              }}</span></mat-cell>
        </ng-container>

        <ng-container matColumnDef="projectCode">
          <mat-header-cell class="cell project-row" *matHeaderCellDef>Projet</mat-header-cell>
          <mat-cell [class]="(isActive(element)&&isCodeActive(element)?'':'hide')" class="cell project-row"
            *matCellDef="let element">{{ element.projectCode }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="serviceType">
          <mat-header-cell class="cell service-row" *matHeaderCellDef>Service</mat-header-cell>
          <mat-cell [class]="(isActive(element)&&isCodeActive(element)?'':'hide')" class="cell service-row"
            *matCellDef="let element">{{ element.serviceType }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="reference">
          <mat-header-cell class="cell ref-row" *matHeaderCellDef>Référence</mat-header-cell>
          <mat-cell [class]="(isActive(element)&&isCodeActive(element)?'':'hide')" class="cell ref-row"
            *matCellDef="let element">{{ element.reference }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="datetime">
          <mat-header-cell class="cell date-row" *matHeaderCellDef>Date de Génération</mat-header-cell>
          <mat-cell [class]="(isActive(element)&&isCodeActive(element)?'':'hide')" class="cell date-row"
            *matCellDef="let element">{{ element.datetime | date:'dd/MM/YYYY - HH:mm' }}
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="DISPLAYED_COLUMNS ; sticky: true;"></mat-header-row>
        <mat-row [class]="(isActive(row)&&isCodeActive(row)?'':'hide')"
          *matRowDef="let row; columns: DISPLAYED_COLUMNS;"></mat-row>

      </mat-table>
    </div>
  </div>

</div>