import { Component, OnInit } from '@angular/core';
import { IMonthsClos } from 'src/app/shared/models/calendrier';
import { CalendrierService } from '../../calendrier-pack/calendrier.service';
import { NettingSchemaService } from '../../netting-schema/nettingschema.service';
import { IFullNetting } from 'src/app/shared/models/calculated_netting';
import * as fileSaver from 'file-saver';
import { ApplicationSettingsService } from '../../application-settings/application-settings.service';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { ApplicationSettings } from 'src/app/shared/models/application-settings';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { UserProfile } from 'src/app/shared/models/user-profile';
import { Router } from '@angular/router';

@Component({
  selector: 'app-facturation-vue_netting',
  templateUrl: './facturation-vue_netting.component.html',
  styleUrls: ['./facturation-vue_netting.component.less'],
})
export class FacturationVueNettingComponent implements OnInit {
  public years: number[] = [];
  public packYears: number[] = [];
  public yearSelected!: number;
  public packSelected!: number;
  public lastMonthClos!: number;
  public firstMonthClos!: number;
  public monthsClosByYear: IMonthsClos[] = [];
  public monthSelected!: string;
  public currentYear: number = new Date().getFullYear();
  public schemas: string[] = [];
  public schemaSelected!: string;
  public displayedColumnsGenKeys!: string[];
  public displayedColumnsGen!: Array<any>;
  public months_list: string[] = [
    'janvier',
    'fevrier',
    'mars',
    'avril',
    'mai',
    'juin',
    'juillet',
    'aout',
    'septembre',
    'octobre',
    'novembre',
    'decembre',
  ];
  public nettingSource: IFullNetting[] = [];
  public currentYearPack!: number;

  constructor(
    public _calendrierService: CalendrierService,
    public theme: ThemeService,
    private readonly _nss: NettingSchemaService,
    private readonly _ass: ApplicationSettingsService,
    private readonly snackbarService: SnackbarService,
    private router : Router
  ) { }

  spinner = false;

  openErrorSnackBar(message: string) {
    this.snackbarService.openSnackBar(message, 'error-snack-bar');
  }

  openSuccessSnackBar(message: string) {
    this.snackbarService.openSnackBar(message);
  }

  exportNetting() {
    const headers: string[] = [];
    const dataTable: string[][] = [];

    this.spinner = true;

    this.displayedColumnsGen.forEach((col) => {
      headers.push(col.header);
    });
    

    this.nettingSource.forEach((row) => {
      const tempRow: string[] = [];

      for (const element of this.displayedColumnsGenKeys) {
        const key = element;  
        switch (key) {
          case 'projet':
            tempRow.push(row.projet + '');
            break;
          case 'nature':
            tempRow.push(row.nature + '');
            break;
          case 'intitule':
            tempRow.push(row.intitule + '');
            break;
          case 'janvier':
            tempRow.push(row.janvier + '');
            break;
          case 'fevrier':
            tempRow.push(row.fevrier + '');
            break;
          case 'mars':
            tempRow.push(row.mars + '');
            break;
          case 'avril':
            tempRow.push(row.avril + '');
            break;
          case 'mai':
            tempRow.push(row.mai + '');
            break;
          case 'juin':
            tempRow.push(row.juin + '');
            break;
          case 'juillet':
            tempRow.push(row.juillet + '');
            break;
          case 'aout':
            tempRow.push(row.aout + '');
            break;
          case 'septembre':
            tempRow.push(row.septembre + '');
            break;
          case 'octobre':
            tempRow.push(row.octobre + '');
            break;
          case 'novembre':
            tempRow.push(row.novembre + '');
            break;
          case 'decembre':
            tempRow.push(row.decembre + '');
            break;
          case 'total':
            const total = this.calculateTotal(row);
            tempRow.push(total.toString());
            break;
        }
      }

      dataTable.push(tempRow);
    });

    this._nss.exportNettingView(dataTable, headers).subscribe({
      next: (data) => {
        const blob: any = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        fileSaver.saveAs(
          blob,
          'Vue netting -' +
          this._ass.applicationSettings.CDS_name +
          ' - PACK ' + this.packSelected + ' - ' +
          new Date().toLocaleDateString(),
        );
        this.spinner = false
      },
      error: (err) => {
        console.log(err);
        this.openErrorSnackBar("Erreur lors de l'export");
        this.spinner = false;
      },
    });
  }

  public isPackFusioned(): boolean {
    const applicationSettings: ApplicationSettings =
      this._ass.getApplicationSettings();
    return applicationSettings.display_fusion_pack;
  }

  ngOnInit(): void {
     if (this.isSuiviNettingsHidden()) {
      this.router.navigate(['/']); 
    }
    this.displayYear();
    this.getMonthClosService();
    this.getSchemas();
    this.schemaSelected = 'CONSOLIDATION';
  }


  isYearDisabled(year: number): boolean {
    if (this.packSelected === this.currentYear - 1) {
      return year === this.currentYear + 1;
    } else if (this.packSelected === this.currentYear) {
      return year === this.currentYear - 1;
    }
    return false;
  }

  displayYear(): void {
    this.currentYearPack = this._ass.getApplicationSettings().Pack_current_year;
    this.years = [this.currentYear - 1, this.currentYear, this.currentYear + 1];
    this.packYears = [this.currentYear, this.currentYear - 1];
    this.yearSelected = this.currentYear;
    this.packSelected = this.currentYear;
  }

  getMonthClosService(): void {
    this._calendrierService.getMonthsClos(this.packSelected).subscribe({
      next: (data) => {
        if (data) {
          this.monthsClosByYear = data;
          this.firstMonthClos = this.monthsClosByYear[0].month;
          this.lastMonthClos =
            this.monthsClosByYear[this.monthsClosByYear.length - 1].month;
          this.month_to_string(this.lastMonthClos);
          this.updateTableColumns();
          this.getNetting();
        }
      },
      error: (err) => {
        console.log(err);
        this.openErrorSnackBar(
          'Erreur lors de la recuperation du dernier mois clos',
        );
      },
    });
  }

  month_to_string(month: number): void {
    this.monthSelected = month.toString();
    if (this.monthSelected.length == 1) {
      this.monthSelected = '0' + this.monthSelected;
    }
  }

  changeYear(year: number): void {
    if (this.isPackFusioned()) {
      this.yearSelected = this.packSelected;
    } else {
      this.yearSelected = year;
    }
    this.getMonthClosService();
    this.getNetting();
  }

  changePackYear(packYear: number): void {
    this.packSelected = packYear;
    if (this.isPackFusioned()) {
      this.yearSelected = this.packSelected;
    }
    this.getMonthClosService();
    this.getNetting();
  }

  getSchemas(): void {
    this._nss.getSchemasNames().subscribe({
      next: (data) => {
        if (data) {
          this.schemas = data;
          this.schemas.splice(0, 0, 'CONSOLIDATION');
          this.schemas = this.schemas.filter((schema) => !schema.includes('GLOBAL'));
        }
      },
      error: (err) => {
        console.log(err);
        this.openErrorSnackBar('Erreur lors de la recuperation des schemas');
      },
    });
  }

  changeSchema(schema: string): void {
    this.schemaSelected = schema;
    this.tableDefGenNet();
    this.getNetting();
  }

  getNetting(): void {
    this.spinner = true;
    this._nss
      .getFullNetting(
        this.packSelected.toString(),
        this.yearSelected.toString(),
        this.monthSelected,
        this.schemaSelected,
      )
      .subscribe({
        next: (data) => {
          if (data) {
            this.nettingSource = data;

          }
          this.spinner = false;
        },
        error: (err) => {
          console.log(err);
          this.openErrorSnackBar('Erreur lors de la recuperation des données');
          this.spinner = false;
        },
      });
  }

  updateTableColumns(): void {
    this.displayedColumnsGen = [
      {
        key: 'projet',
        header: 'Projet',
      },
      {
        key: 'nature',
        header: 'Nature imputation',
      },
      {
        key: 'intitule',
        header: 'Intitulé',
      },
    ];
    for (let i = this.firstMonthClos - 1; i < this.lastMonthClos; i++) {
      const header_month = this.months_list[i] + '-' + this.yearSelected;
      this.displayedColumnsGen.push({
        key: this.months_list[i],
        header: header_month,
      });
    }
    this.displayedColumnsGen.push({
      key: 'total',
      header: 'Total',
    });
    this.displayedColumnsGenKeys = this.displayedColumnsGen.map(
      (col) => col.key,
    );

  }

  calculateTotal(element: any): number {
    let total = 0;
    for (let i = this.firstMonthClos - 1; i < this.lastMonthClos; i++) {
      const monthKey = this.months_list[i];
      if (element[monthKey]) {
        total += parseFloat(element[monthKey]) || 0;
      }
    }
    return total;
  }


  tableDefGenNet(): void {
    this.updateTableColumns();
  }

  CellIsRed(element: any, header: string): boolean {
    const headerGen: string[] = ['Projet', 'Nature imputation', 'Intitulé'];
    if (!headerGen.includes(header)) {
      if (element < 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isCurrencyColumn(columnKey: string): boolean {
    const currencyColumns = ['janvier', 'fevrier', 'mars', 'avril', 'mai', 'juin', 'juillet', 'aout', 'septembre', 'octobre', 'novembre', 'decembre'];
    return currencyColumns.includes(columnKey);
  }

  isFacturationCDSLine(row: any): boolean {
    return row.projet === 'FACTURATION CDS';
  }

  isPrelevementLine(row: any): boolean {
    return row.projet === 'Prelevement' || row.projet === 'TOTAL';
  }

  removeBackground(row: any): boolean {
    if (row.projet === null && row.nature != null) {
      return true;
    }
    return false
  }

  isFirstOfGroup(index: number): boolean {
    if (index === 0) return true;
    const data = this.nettingSource;
    return data[index].projet !== data[index - 1].projet;
  }

  isSuiviNettingsHidden(): boolean {
    const user = JSON.parse(sessionStorage.getItem('user') ?? '{}');
    const nettingView = user.nettingView;

    return ! (user.profile === UserProfile.ADMIN || user.profile === UserProfile.MANAGER_CDS || (user.profile === UserProfile.CP_CDS && nettingView));
  }

}