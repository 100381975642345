<mat-toolbar class="header" [style.background-color]="debugModeActivated ? 'grey' : theme.getColor('Main Light')">
  <span [ngClass]="stateColor === 'orange' ? 'dotColorOrange' : stateColor === 'orange2' ? 'dotColorOrange2'
    : stateColor === 'orange3' ? 'dotColorOrange'
    : stateColor === 'red' ? 'dotColorRed'
    : stateColor === 'green' ? 'dotColorGreen'
    : stateColor === 'greenTransparent' ? 'dotColorTransparent'
    : stateColor === 'white' ? 'dot'
    : stateColor === 'black' ? 'dotColorBlack': ''"></span>
    <span>
      <span>
        <b> PackGemini {{configService.getConfiguration().version}}</b>
      </span>
        {{applicationSettings.CDS_name}} - {{formattedDate}}
        <span>
      </span>
        {{stateMessage}}
        <span>
        {{ stateColor === 'orange' ? '' : '' }}
            {{ stateColor === 'orange2' ? myDateOuverture : '' }}
            {{ stateColor === 'orange3' ? myDateValid_fin : '' }}
            {{ stateColor === 'greenTransparent' ? myDateValid_deb : ''}}
            {{ stateColor === 'green' ? myDateValid_fin : ''}}
            {{ stateColor === 'black' ? myDateCloture : ''}}
      </span>

    </span>
    <span *ngIf="debugModeActivated"> [DEBUG]</span>
    <span id="user-name">{{authenticationService.getUser() ? authenticationService.getUser().firstName + ' ' + authenticationService.getUser().lastName : ''}}
        <pre style="font-size: 12px;display: inline-block;">{{authenticationService.getUser() ? '(' + authenticationService.getUser().profile + ')' : ''}}</pre>
    </span>
    <button mat-mini-fab color="primary" id="disconnect" title="Se déconnecter" (click)="disconnecUser()"
            [style.background-color]="theme.getColor('Main Dark')"
            >
        <mat-icon>power_settings_new</mat-icon>
    </button>
</mat-toolbar>
