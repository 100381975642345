import {Component} from '@angular/core';

@Component({
  selector: 'app-pack-generation',
  templateUrl: './pack-generation.component.html',
  styleUrls: ['./pack-generation.component.less']
})
export class PackGenerationComponent {

  constructor() {
  }

  public spinner = false;

  public validators: boolean[] = [false, false, false]


}
