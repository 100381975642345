import { Component } from '@angular/core';
import { ConfigService } from "src/app/shared/services/config.service";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-dashboard-power-bi',
  templateUrl: './dashboard-power-bi.component.html',
  styleUrls: ['./dashboard-power-bi.component.less']
})
export class DashboardPowerBiComponent {
  powerBiUrl: SafeResourceUrl;

  constructor(
    public configService: ConfigService,
    private sanitizer: DomSanitizer
  ) {
    const url = this.configService.getConfiguration().PowerBiReportSrc;
    this.powerBiUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
