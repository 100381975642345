<div id="nettingAction">
  <form class="form" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <h2 mat-dialog-title>Netting {{ data.dNumber }}</h2>
    <mat-dialog-content>
      <mat-form-field appearance="outline" color="accent" style="width: 100%">
        <mat-label
          >Veuillez sélectionner le type de projet pour le netting :</mat-label
        >
        <mat-select formControlName="nettingsNames">
          <mat-option *ngFor="let name of nettingsNames" [value]="name">
            {{ name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <input hidden matInput required formControlName="ndemand" type="text" />
    </mat-dialog-content>

    <mat-dialog-actions align="center">
      <button
        mat-raised-button
        color="warn"
        type="reset"
        (click)="closeDialog()"
      >
        Annuler
      </button>
      <button
        mat-raised-button
        [style.background-color]="theme.getColor('Main Light')"
        class="blue"
        type="submit"
        [disabled]="formGroup.value.nettingsNames === ''"
        [ngClass]="{ btnDisabled: formGroup.value.nettingsNames === '' }"
      >
        Valider
      </button>
    </mat-dialog-actions>
  </form>
</div>
