import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { ROUTES } from '../shared/constants/routes';
import { UnauthorizedComponent } from './unauthorized.component';

const routes: Routes = [
  {
    path: ROUTES.EMPTY_PATH,
    component: UnauthorizedComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UnauthorizedRoutingModule { }
