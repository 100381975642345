import { formatDate, registerLocaleData } from '@angular/common';
import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { throwError } from 'rxjs';
import { Constants } from 'src/app/shared/constants/Constants';
import { IDemand } from 'src/app/shared/models/demand';
import { Echeance } from 'src/app/shared/models/echeance';
import { EcheanceStatus } from 'src/app/shared/models/echeance-status';
import { IManualData } from 'src/app/shared/models/manual-data';
import { IOrderForm } from 'src/app/shared/models/order-form';
import { IPack } from 'src/app/shared/models/pack';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { CommentBoxComponent } from '../dialog-box/comment-box.component';
import { CommentPromptComponent } from '../dialog-box/comment-prompt.component';
import { EcheancesService } from '../services/echeances.service';
import localeFr from '@angular/common/locales/fr';
import { SimplePromptComponent } from 'src/app/shared/simple-prompt/simple-prompt.component';
import {
  DialogFormNettingComponent
} from 'src/app/shared/dialog-form-netting/dialog-form-netting/dialog-form-netting.component';
import { DialogFormNettingService } from 'src/app/shared/dialog-form-netting/dialog-form-netting.service';
import { UserProfile } from 'src/app/shared/models/user-profile';
import { AlertLevel, AlertVue, IAlert } from 'src/app/shared/models/alert';
import { DialogFormAlertComponent } from 'src/app/shared/dialog-form-alert/dialog-form-alert.component';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { SnackbarService } from '../../../../shared/services/snackbar.service';

registerLocaleData(localeFr);

@Component({
  selector: 'app-echeance-details',
  templateUrl: './echeance-details.component.html',
  styleUrls: ['./echeance-details.component.less']
})
export class EcheanceDetailsComponent {

  listInvoice: string[] = [
    "Par défaut", "AGILE", "100% CLOTURE", "SADE", "MCO FORFAIT", "MCO DEMANDE", "TRANSITION"
  ];

  public formGroup: UntypedFormGroup;

  public isEditing = false;
  public isEditingManualData = false;

  public isLoading = true;

  public demand!: IDemand;
  public manualData!: IManualData;

  public currPack: IPack = new IPack();

  public targetAdminEcheanceStatus!: EcheanceStatus;

  public orderForms!: IOrderForm[];

  public isAlreadyValidating = false;

  @Output()
  updateValidationCapability = new EventEmitter<boolean>();

  @Output()
  updateRejectionCapability = new EventEmitter<boolean>();

  @Output()
  refreshTable: EventEmitter<boolean> = new EventEmitter();

  unreadCommentsNbr = 0;

  MONTHS: string[] = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'];
  statusColor = "";
  validatable = false;

  asyncLock = true;

  get EcheanceStatus() {
    return EcheanceStatus;
  }

  get Constants() {
    return Constants;
  }

  get Object() {
    return Object;
  }

  nettingName = "Aucun";

  rowindex = 0;
  rowmin = 0;
  rowmax = 0;

  alerts: IAlert[] = [];
  alertLevel = 0;

  debugModeActivated = false;

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: any,
    public dialogRef: MatDialogRef<EcheanceDetailsComponent>,
    private dialog: MatDialog,
    private snackbarService: SnackbarService,
    private echeancesService: EcheancesService,
    private formBuilder: UntypedFormBuilder,
    public authenticationService: AuthenticationService,
    public theme: ThemeService,
    public _ns: DialogFormNettingService) {
    this.formGroup = this.formBuilder.group({
      realFirstDate: "",
      realSecondDate: "",
      realThirdDate: "",

      estimateFirstDate: "",
      estimateSecondDate: "",
      estimateThirdDate: "",

      mDComment: "",
      invoiceType: "",

      reduction: 0
    });

    if (dialogData && dialogData.data) {
      this.rowindex = this.dialogData['index'];
      this.rowmin = this.dialogData['min'];
      this.rowmax = this.dialogData['max'];
      this.validatable = this.dialogData['validatable'];
      this.currPack.packMonth = this.dialogData['month'];
      this.currPack.packYear = this.dialogData['year'];
      this.initDialog();
    }

    this.debugModeActivated = this.authenticationService.getDebugMode();

  }

  /**
   * Initialisation: Charge les données additionnelles et les droits d'édition de la demande.
   */
  initDialog() {
    
    this.demand = this.dialogData["data"][this.rowindex];
    if (this.debugModeActivated) {
      console.log("******** Mode débogage actif ********");
      console.log("Contenu de la demande: ")
      console.log(this.demand);
      console.log("*************************************")
    }
    this.alerts = [];

    this.isEditingManualData = false;

    if (this.demand.realizationDateTime != null) this.demand.realizationDate = new Date(this.demand.realizationDateTime);

    this.formGroup = this.formBuilder.group({
      realFirstDate: "",
      realSecondDate: "",
      realThirdDate: "",

      estimateFirstDate: "",
      estimateSecondDate: "",
      estimateThirdDate: "",

      mDComment: "",
      invoiceType: "",

      reduction: 0
    });
    this.nettingName = "";

    if (!this.authenticationService.isUserAdmin()) this.formGroup.disable();


    if (this.demand?.demandNumber) {
      this.echeancesService.getDemandManualData(this.demand).subscribe({
        next: data => {
          this.isLoading = false;
          this.manualData = data;
          this.formGroup.setValue({
            realFirstDate: this.manualData.realFirstDate != null ? formatDate(this.manualData.realFirstDate + "", "yyyy-MM-dd", 'en') : null,
            realSecondDate: this.manualData.realSecondDate != null ? formatDate(this.manualData.realSecondDate + "", "yyyy-MM-dd", 'en') : null,
            realThirdDate: this.manualData.realThirdDate != null ? formatDate(this.manualData.realThirdDate + "", "yyyy-MM-dd", 'en') : null,

            estimateFirstDate: this.manualData.estimateFirstDate != null ? formatDate(this.manualData.estimateFirstDate + "", "yyyy-MM-dd", 'en') : null,
            estimateSecondDate: this.manualData.estimateSecondDate != null ? formatDate(this.manualData.estimateSecondDate + "", "yyyy-MM-dd", 'en') : null,
            estimateThirdDate: this.manualData.estimateThirdDate != null ? formatDate(this.manualData.estimateThirdDate + "", "yyyy-MM-dd", 'en') : null,

            mDComment: this.manualData.comment,
            invoiceType: this.manualData.invoiceType,

            reduction: this.manualData.reduction
          })
          this.isEditingManualData = this.manualData.demand != null;
          this.manualData.demand = this.demand.demandNumber;

        },
        error: err => {
          console.log(err);
        },
        complete: () => {
        }
      })
    }

    //Determine Netting name
    this._ns.getNettingFromDemand(this.demand).subscribe({
      next: value => {
        this.nettingName = (value != null ? value.name + "" : "Aucun");
      }, error: err => {
        console.error(err);
      },
      complete: () => {
      }
    });

    //Determine Alert level
    this.alertLevel = 0;

    this.echeancesService.getDemandAlerts(this.demand).subscribe({
      next: value => {
        this.alerts = value;
        this.handleAlerts();
      },
      error: err => {
        console.error(err);
      },
      complete: () => {
      }
    });


    //Sort dues in chronological order
    this.demand.echeances.sort(this.compareDues);
  }

  /**
   * Initialisation: Charge les alertes de la demand et détermine son degré de criticité.
   */
  handleAlerts() {
    const temp: IAlert[] = [];

    this.alerts.forEach(e => {
      switch (this.authenticationService.getUser().profile) {
        case UserProfile.CP_CDS:
        case UserProfile.GUEST_CDS:
          if (e.type == AlertVue.BEB || e.type == AlertVue.CDS || e.type == AlertVue.DSI) temp.push(e);
          break;

        case UserProfile.CP_SNCF:
        case UserProfile.GUEST_SNCF:
        case UserProfile.SERVICE_MANAGER:
          if (e.type == AlertVue.DSI) temp.push(e);
          break;

        default:
          temp.push(e);
          break;
      }
    });

    temp.forEach(e => {
      switch (e.severity) {
        case AlertLevel.Majeur:
        case AlertLevel.Bloquant:
          if (this.alertLevel < 3) this.alertLevel = 3;
          break;
        case AlertLevel.Avertissement:
          if (this.alertLevel < 2) this.alertLevel = 2;
          break;
        case AlertLevel.Information:
          if (this.alertLevel < 1) this.alertLevel = 1;
          break;
        default:
          break;
      }
    });
    this.alerts = temp;
  }

  /**
   * Initialisation: Détermine si des données manuelles non nulles existent.
   */
  isManualDataNull(): boolean {
    if (this.manualData.comment != null && this.manualData.comment != "") return true;
    if (this.manualData.invoiceType != null && this.manualData.invoiceType != "") return true;
    if (this.manualData.reduction != null && this.manualData.reduction != 0) return true;

    if (this.manualData.realFirstDate != null) return true;
    if (this.manualData.realSecondDate != null) return true;
    if (this.manualData.realThirdDate != null) return true;

    if (this.manualData.estimateFirstDate != null) return true;
    if (this.manualData.estimateSecondDate != null) return true;
    if (this.manualData.estimateThirdDate != null) return true;
    return false;

  }



  /**
   * Dialog: Recharge la dialog pour la demande suivante de la liste.
   */
  next() {
    this.rowindex++;
    this.isLoading = true;
    this.asyncLock = true;
    this.isAlreadyValidating = false;
    this.initDialog();
  }

  /**
   * Dialog: Recharge la dialog pour la demande précédente de la liste.
   */
  prev() {
    this.rowindex--;
    this.isLoading = true;
    this.asyncLock = true;
    this.isAlreadyValidating = false;
    this.initDialog();
  }

  /**
  * Dialog: Demande une confirmation de suppression, puis ferme la dialog et supprime la demande.
  */
  deleteSelf() {
    //Open dialog box
    const dialogRef = this.dialog.open(SimplePromptComponent, {
      data: { message: 'Voulez vous vraiment supprimer la demande ' + this.demand.demandNumber + ' ?' }
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result) {
          this.echeancesService.deleteDemand(this.demand).subscribe({
            next: response => {
              if (response == null) {
                this.openSuccessSnackBar("La demande a été supprimée avec succès.");
                this.dialogRef.close();
                this.refreshTable.emit(true);
              } else {
                this.openErrorSnackBar("ERREUR: La demande n'a pas pu être supprimée.");
              }
            },
            error: err => {
              console.error(err);
            },
            complete: () => {

            }
          });
        }
      },
      error: err => {
        console.error(err);
      },
      complete: () => {
      }
    })


  }



  /**
  * Edition de la demande: Changement manuel du compte netting (passe par un mat-dialog).
  */
  dialogNetting() {
    this.demand.netting = this.nettingName;
    this.demand.netting = this.demand.netting.replace('Aucun', '');
    const dialogRef = this.dialog.open(DialogFormNettingComponent, {
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: this.demand
    });
    dialogRef.afterClosed().subscribe({
      next: value => {
        this._ns.getNettingFromDemand(this.demand).subscribe({
          next: value => {
            if (value == null) this.nettingName = "Aucun";
            else this.nettingName = value.name + "";

          },
          error: err => {
            console.error(err);
          },
          complete: () => {
          }
        })
      },
      error: err => {
        console.error(err);
      },
      complete: () => {
      }
    })
  }

  /**
    * Edition de la demande: Changement manuel du numéro de bon de commande.
    */
  toggleOrderForm() {

    if (this.isEditing) {
      this.isEditing = false;
      this.isLoading = true;
      this.echeancesService.updateDemandRef(this.demand.demandNumber, this.demand.reference!).subscribe({
        next: demand => {
          this.echeancesService.getDemand(this.demand.demandNumber).subscribe({
            next: demandValue => {
              this.isLoading = false;
              this.demand.reference = demandValue.reference;
              this.isEditingManualData = this.isManualDataNull();
              this.refreshTable.emit(true);
            },
            error: err => {
              this.isLoading = false;
              throwError("Erreur lors de la mise à jour des données: " + err);
            },
            complete: () => {
            }
          });
        },
        error: err => {
          console.log(err);
        },
        complete: () => {
        }
      });

    } else this.isEditing = true;
  }

  /**
  * Edition de la demande: Event lorsque la valeur du bon de commande, le met à jour dans les données du front.
  */
  onRefChange(evt: any) {
    this.demand.reference = (evt.target as HTMLInputElement).value;
  }



  /**
   * Données Manuelles: Active l'onglet d'édition des données collectées.
   */
  toggleEditing() {
    this.isEditingManualData = true;
  }

  /**
   * Données Manuelles: Ferme l'onglet d'édition des données collectées, et recale les champs d'input sur les valeurs réelles sauvegardées.
   */
  cancelEditing() {
    if (this.demand?.demandNumber) this.echeancesService.getDemandManualData(this.demand).subscribe({
      next: data => {
        this.manualData = data;

        this.formGroup.setValue({
          realFirstDate: this.manualData.realFirstDate != null ? formatDate(this.manualData.realFirstDate + "", "yyyy-MM-dd", 'en') : null,
          realSecondDate: this.manualData.realSecondDate != null ? formatDate(this.manualData.realSecondDate + "", "yyyy-MM-dd", 'en') : null,
          realThirdDate: this.manualData.realThirdDate != null ? formatDate(this.manualData.realThirdDate + "", "yyyy-MM-dd", 'en') : null,

          estimateFirstDate: this.manualData.estimateFirstDate != null ? formatDate(this.manualData.estimateFirstDate + "", "yyyy-MM-dd", 'en') : null,
          estimateSecondDate: this.manualData.estimateSecondDate != null ? formatDate(this.manualData.estimateSecondDate + "", "yyyy-MM-dd", 'en') : null,
          estimateThirdDate: this.manualData.estimateThirdDate != null ? formatDate(this.manualData.estimateThirdDate + "", "yyyy-MM-dd", 'en') : null,

          mDComment: this.manualData.comment,
          invoiceType: this.manualData.invoiceType,

          reduction: this.manualData.reduction
        })
        this.isLoading = false;
      },
      error: err => {
        console.log(err);
      },
      complete: () => {
      }
    });
  }

  /**
   * Données Manuelles: Sauvegarde les données manuelles en input, et relance un calcul de l'échéancier avec les nouveaux paramètres.
   */
  confirmEditing() {

    this.isLoading = true;
    this.manualData.realFirstDate = this.formGroup.value.realFirstDate == null ? undefined : new Date(this.formGroup.value.realFirstDate)
    this.manualData.realSecondDate = this.formGroup.value.realSecondDate == null ? undefined : new Date(this.formGroup.value.realSecondDate)
    this.manualData.realThirdDate = this.formGroup.value.realThirdDate == null ? undefined : new Date(this.formGroup.value.realThirdDate)

    this.manualData.estimateFirstDate = this.formGroup.value.estimateFirstDate == null ? undefined : new Date(this.formGroup.value.estimateFirstDate)
    this.manualData.estimateSecondDate = this.formGroup.value.estimateSecondDate == null ? undefined : new Date(this.formGroup.value.estimateSecondDate)
    this.manualData.estimateThirdDate = this.formGroup.value.estimateThirdDate == null ? undefined : new Date(this.formGroup.value.estimateThirdDate)

    this.manualData.reduction = this.formGroup.value.reduction
    if (this.manualData.reduction == null) this.manualData.reduction = 0;

    this.manualData.invoiceType = this.formGroup.value.invoiceType
    this.manualData.comment = this.formGroup.value.mDComment

    if (this.manualData.invoiceType == "Par défaut") this.manualData.invoiceType = "";

    this.echeancesService.postDemandManualData(this.manualData).subscribe({
      next: data => {
        this.echeancesService.getCalculDue(this.demand.demandNumber).subscribe({
          next: value => {
            this.echeancesService.getDemand(this.demand.demandNumber).subscribe({
              next: demandValue => {
                this.isLoading = false;
                this.demand.echeances = demandValue.echeances;
                this.isEditingManualData = this.isManualDataNull();
                if (this.demand.echeances) this.demand.echeances.sort(this.compareDues);

                this.refreshTable.emit(true);
              },
              error: err => {
                this.isLoading = false;
                throwError("Erreur lors de la mise à jour des données: " + err);
              },
              complete: () => {

              }
            });
          },
          error: err => {
            this.isLoading = false;
            throwError("Erreur lors de la facturation: " + err);
          },
          complete: () => {
          }
        });
      },
      error: err => {
        this.isLoading = false;
        throwError("Erreur lors de l'export des données manuelles : " + err);
      },
      complete: () => {
      }
    });
  }

  /**
   * Echéancier: Extrait l'année d'une date donnée.
   */
  getYearOfDate(date: Date) {
    return ((date + '').substring(0, 4));
  }

  /**
   * Echéancier: Extrait le mois d'une date donnée en format Janvier/Décembre
   */
  getMonthOfDate(date: Date): string {
    const num: number = +(date + '').substring(5, 7);
    const str: string = this.MONTHS[num - 1] + "";
    return (str);
  }

  /**
   * Echéancier: Détermine si un jalon est considéré comme passé/présent/futur par rapport au mois de facturation.
   */
  getDateStatus(date: Date) {
    let ret = "";
    const year = parseInt((date + '').substring(0, 4));
    const month = parseInt((date + '').substring(5, 7));
    if (year > this.currPack.packYear) return "post";
    else if (year < this.currPack.packYear) return "past";
    else {
      if (month == this.currPack.packMonth) ret = "current";
      else if (month < this.currPack.packMonth) ret = "past";
      else ret = "post";
    }

    return ret;
  }


  /**
   * Commentaires: Ouvrir la popup des commentaires d'une demande.
   */
  public openDialog() {
    const matDialogRef = this.dialog.open(CommentBoxComponent, {
      id: 'comment-box'
    });

    matDialogRef.componentInstance.demand = this.demand;
    if (this.demand.demandNumber) {
      this.echeancesService.getComments(this.demand.demandNumber).subscribe({
        next: response => {
          if (response instanceof Array) {
            matDialogRef.componentInstance.demand.comments = response;
            matDialogRef.componentInstance.commentsDiv.nativeElement.scrollTop = matDialogRef.componentInstance.commentsDiv.nativeElement.scrollHeight;
          }
        },
        error: err => {
          console.error(err);
        },
        complete: () => {

        }
      });
    }

    matDialogRef.afterClosed().subscribe({
      next: value => {
        this.demand.echeances.sort(this.compareDues);
      },
      error: err => {
        console.error(err);
      },
      complete: () => {
      }
    });
  }



  /**
   * Alertes: Ouvre la fenêtre de la liste des alertes.
   */
  dialogAlert() {
    this.dialog.open(DialogFormAlertComponent, {
      data: this.alerts,
    });
  }



  /**
   * Initialisation: Formatte le nom/prénom du responsable
   */
  getResponsableName() {
    let res = "-";
    try {
      const splitted = (this.demand.responsable + "").split(", ");
      res = splitted[1].split("=")[1] + " " + splitted[2].split("=")[1];
    } catch (e) {
    }
    return res;
  }



  /**
   * Validation Individuelle: Valider les écheances de demande consultée
   */
  public validateDemand() {
    // affichage d'une alerte si la demande possede comme service A renseigner
    if (this.demand.serviceType == "A renseigner") {
      this.openErrorSnackBar("Veuillez renseigner le service de la demande avant de la valider.");
      return;
    }
    if (this.isAlreadyValidating || this.nettingName == '') return;
    this.isAlreadyValidating = true;
    if ((this.authenticationService.getUser().profile == UserProfile.CP_CDS
      || this.authenticationService.getUser().profile == UserProfile.MANAGER_CDS) && this.nettingName == "Aucun") {
      this.demand.netting = this.nettingName;
      this.demand.netting = this.demand.netting.replace('Aucun', '');
      const dialogRef = this.dialog.open(DialogFormNettingComponent, {
        panelClass: 'confirm-dialog-container',
        disableClose: true,
        data: this.demand
      });
      dialogRef.afterClosed().subscribe({
        next: value => {
          if (value) {
            this._ns.getNettingFromDemand(this.demand).subscribe({
              next: value => {
                if (value == null) this.nettingName = "Aucun";
                else this.nettingName = value.name + "";
              },
              error: err => {
                console.error(err);
              },
              complete: () => {
              }
            })

            this.echeancesService.validateDemands([this.demand.demandNumber]).subscribe({
              next: data => {
                if (data?.error) {
                  console.error(data.error);
                  this.isAlreadyValidating = false;
                  if (data.demandsNumbersInError && data.demandsNumbers.length) {
                    this.openErrorSnackBar(Constants.ErrorMessages.DEMAND_NOT_VALIDATED + data.demandsNumbersInError);
                  } else {
                    this.openErrorSnackBar(Constants.ErrorMessages.SERVER_TECH_ERROR);
                  }
                } else if (data.updatedDemands?.length) {
                  this.openSuccessSnackBar(Constants.Messages.DEMAND_VALIDATED);
                  this.demand.echeances = data.updatedDemands[0].echeances
                  this.demand.echeances.sort(this.compareDues);
                  this.echeancesService.checkIfDemandValidableARRejectable(this.demand);
                  this.isAlreadyValidating = false;
                }
                this.asyncLock = false;
              },
              error: err => {
                console.error(err);
                this.isAlreadyValidating = false;
              },
              complete: () => {
              }
            });
          }
        },
        error: err => {
          console.error(err);
        },
        complete: () => {
        }
      })
    } else {
      this.echeancesService.validateDemands([this.demand.demandNumber]).subscribe({
        next: data => {
          if (data?.error) {
            console.error(data.error);
            if (data.demandsNumbersInError && data.demandsNumbers.length) {
              this.openErrorSnackBar(Constants.ErrorMessages.DEMAND_NOT_VALIDATED + data.demandsNumbersInError);
            } else {
              this.openErrorSnackBar(Constants.ErrorMessages.SERVER_TECH_ERROR);
            }
          } else if (data.updatedDemands?.length) {
            this.openSuccessSnackBar(Constants.Messages.DEMAND_VALIDATED);
            this.demand.echeances = data.updatedDemands[0].echeances

            this.demand.echeances.sort(this.compareDues);

            this.echeancesService.checkIfDemandValidableARRejectable(this.demand);
          }
          this.asyncLock = false;
        },
        error: err => {
          console.error(err);
          this.asyncLock = false;
        },
        complete: () => {
        }
      });
    }
  }

  /**
   * Validation Individuelle: Rejetter les écheances de la demande consultée
   */
  public rejectDemand() {
    if (this.isAlreadyValidating) return;
    this.isAlreadyValidating = true;
    this.openCommentPrompt().afterClosed().subscribe({
      next: comment => {
        if (comment) {
          const reasonOfRejection = "Motif du rejet : " + comment;
          this.echeancesService.rejectDemands([this.demand.demandNumber], reasonOfRejection).subscribe({
            next: data => {
              if (data?.error) {
                console.error(data.error);
                this.isAlreadyValidating = false;
                if (data.demandsNumbersInError && data.demandsNumbers.length) {
                  this.openErrorSnackBar(Constants.ErrorMessages.DEMAND_NOT_REJECTED + data.demandsNumbersInError);
                } else {
                  this.openErrorSnackBar(Constants.ErrorMessages.SERVER_TECH_ERROR);
                }
              } else if (data.updatedDemands?.length) {
                this.openSuccessSnackBar(Constants.Messages.DEMAND_REJECTED);
                this.demand.echeances = data.updatedDemands[0].echeances;
                this.echeancesService.checkIfDemandValidableARRejectable(this.demand);
                this.isAlreadyValidating = false;
              }
            },
            error: err => {
              console.error(err);
              this.isAlreadyValidating = false;
            },
            complete: () => {

            }
          });
        }
        else {
          this.isAlreadyValidating = false;
        }
      },
      error: err => {
        console.error(err);
      },
      complete: () => {
      }
    });

  }

  /**
  *  Validation Individuelle: Ouvrir une popup d'entrée de notif de refus de demande.
  */
  openCommentPrompt(): MatDialogRef<CommentPromptComponent, any> {
    return this.dialog.open(CommentPromptComponent, {});
  }

  /**
   * Validation Individuelle: Changement manuel du statut (Admin seulement).
   */
  public changeDemandEcheanceStatus() {
    this.echeancesService.changeDemandEcheanceStatus(this.demand.demandNumber, this.targetAdminEcheanceStatus).subscribe({
      next: data => {

        if (data?.error) {
          console.error(data.error);
          this.openErrorSnackBar(Constants.ErrorMessages.ECHEANCE_STATUS_NOT_CHANGED);

        } else {
          this.echeancesService.getDemand(this.demand.demandNumber).subscribe({
            next: demandValue => {
              this.isLoading = false;
              this.demand.echeances = demandValue.echeances;
              this.isEditingManualData = this.isManualDataNull();
              if (this.demand.echeances) this.demand.echeances.sort(this.compareDues);

              this.refreshTable.emit(true);
            },
            error: err => {
              this.isLoading = false;
              throwError("Erreur lors de la mise à jour des données :" + err);
            },
            complete: () => {
            }
          });
        }
      },
      error: err => {
        console.error(err);
      },
      complete: () => {
      }
    });
  }



  /**
    * Critère d'Affichage: Valeur de référence d'une date ignorable (pour ignorer une date réelle).
    */
  nulldate(date: any) {
    return date == "0001-01-01";
  }

  /**
  * Critère d'Affichage: Détermine si une date est à un format acceptable.
  */
  isValid(date: Date) {
    // An invalid date object returns NaN for getTime() and NaN is the only
    // object not strictly equal to itself.
    return date === date && date != null && date.toString() != "";
  }

  /**
  * Critère d'Affichage: Détermine un ordre entre deux échéances, base du sorting d'échéances.
  */
  compareDues(ech1: Echeance, ech2: Echeance) {
    if (ech1.date! > ech2.date!) return 1;
    if (ech1.date! < ech2.date!) return -1;
    return 0;
  }

  /**
  * Critère d'Affichage: Détermine si la demande peut être supprimée selon le contexte et le rôle de l'utilisateur.
  */
  canBeDeleted() {
    if (!this.authenticationService.isUserAdmin()) return false;

    for (const element of this.demand.echeances) {
      if (!element.projected) return false;
      if (element.status != null && element.status != EcheanceStatus.DEFAULT) return false;
    }


    return true;
  }

  /**
  * Critère d'Affichage: Détermine si le bon de commande de la demande existe réellement (pass les montants en rouge sur l'échéancier).
  */
  hasUnknownCommand(d: IDemand) {
    if (d.reference == null
      || d.reference == ""
      || d.reference.toUpperCase().includes('DEROGATION')
      || d.reference.toUpperCase().includes('SAUVEGARDE')) return true;
    if (this.orderForms == null) return false;
    for (const element of this.orderForms) if (element.number == d.reference) return false;
    return true;
  }



  openErrorSnackBar(message: string) {
    this.snackbarService.openSnackBar(message, 'error-snack-bar');
  }

  openSuccessSnackBar(message: string) {
    this.snackbarService.openSnackBar(message);
  }


}
