import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Constants} from 'src/app/shared/constants/Constants';
import {EcheanceStatus} from 'src/app/shared/models/echeance-status';
import {IApplication} from 'src/app/shared/models/application';
import {registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {AuthenticationService} from 'src/app/shared/services/authentication.service';
import {ApplicationSettingsService} from '../../application-settings/application-settings.service';
import {UserProfile} from 'src/app/shared/models/user-profile';
import {ThemeService} from 'src/app/shared/services/theme.service';
import {DomainesService} from '../../domaines/domaines.service';
import {DsiDetails} from 'src/app/shared/models/dsiDetails';

registerLocaleData(localeFr);

@Component({
    selector: 'app-dsi-management-list',
    templateUrl: './dsi-management-list.component.html',
    styleUrls: ['./dsi-management-list.component.less']
})

export class DsiManagementListComponent implements OnInit {


    value = 66;

    dsi!: DsiDetails[];
    apps!: IApplication[];


    @Input()
    public userApplications: IApplication[] = [];

    spinner = true;

    selectedDsi = "";

    month: number;
    year: number;

    @Output()
    onDsiChange = new EventEmitter<string>();

    get Constants() {
        return Constants;
    }

    get EcheanceStatus() {
        return EcheanceStatus;
    }


    constructor(public authenticationService: AuthenticationService,
                public theme: ThemeService,
                public _as: ApplicationSettingsService,
                public _ds: DomainesService) {
        this.year = _as.getApplicationSettings().Pack_current_year;
        this.month = _as.getApplicationSettings().Pack_current_month;
    }

    ngOnInit(): void {
        this._ds.getAllDsisDetails().subscribe({
          next: value => {
            this.dsi = value;
        },
        error: err => {
            console.log(err);
            },
        complete: () => {
            this.spinner = false;
        }
        });
    }

    //Changes current DSI, and emits newest value to parent component
    toggleDsi(element: DsiDetails) {
        if (this.selectedDsi == element.name) this.selectedDsi = "";
        else this.selectedDsi = element.name;

        this.onDsiChange.emit(this.selectedDsi);
    }

    hasAlerts(element: DsiDetails): boolean {
        return (
            (element.prev_depassement != null && element.prev_depassement != 0)
            || (element.curr_depassement != null && element.curr_depassement != 0)
            || (element.prev_retard_facturation != null && element.prev_retard_facturation != 0)
            || (element.curr_retard_facturation != null && element.curr_retard_facturation != 0)
            || (element.prev_depassement_pr != null && element.prev_depassement_pr != 0)
            || (element.curr_depassement_pr != null && element.curr_depassement_pr != 0)
        );
    }

    enableCDSgraph() {
        return !(this.authenticationService.getUser().profile == UserProfile.CP_SNCF
            || this.authenticationService.getUser().profile == UserProfile.GUEST_SNCF
            || this.authenticationService.getUser().profile == UserProfile.SERVICE_MANAGER);
    }
}
