import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {throwError} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UtilsService {

    public handleError(err: HttpErrorResponse) {
        let errorMessage = "";
        if (err.error instanceof ErrorEvent) {
            errorMessage = `Erreur réseau : ${err.error.message}`;
        } else {
            errorMessage = `Code erreur : ${err.status}, erreur : ${err.message}`;
        }
        return throwError(errorMessage);
    }
}
