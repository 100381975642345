<button class="indigo addSpace" [style.background-color]="theme.getColor('Main Dark')" mat-raised-button
        (click)="view = 'Import'" [ngClass]="{'active' : view === 'Import'}">Import JIRA
</button>
<button class="indigo" [style.background-color]="theme.getColor('Main Dark')" mat-raised-button (click)="view = 'Alert'"
        [ngClass]="{'active' : view === 'Alert'}">Alertes
</button>

<mat-table [dataSource]="data" matSort class="light mat-elevation-z4 table">

  <ng-container matColumnDef="name">
    <th class="c0" mat-header-cell *matHeaderCellDef mat-header>Nom de fichier</th>
    <td class="c0" mat-cell *matCellDef="let d"><span>{{d[0]}}</span></td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th class="c1" mat-header-cell *matHeaderCellDef mat-header>Statut</th>
    <td class="c1" mat-cell *matCellDef="let d"><span class="state"
                                                      ngClass="{{d[2].toLowerCase()}}">{{statusText(d[2])}}</span></td>
  </ng-container>

  <ng-container matColumnDef="creation-date">
    <th class="c1" mat-header-cell *matHeaderCellDef mat-header>Date de dépot</th>
    <td class="c1" mat-cell *matCellDef="let d"><span>{{d[3] | date:'dd/MM/YYYY - HH:mm'}}</span></td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th class="c2" mat-header-cell *matHeaderCellDef mat-header>Actions</th>
    <td class="c2 centered" mat-cell *matCellDef="let d">
      <div><span><mat-icon (click)="downloadFile(d)">save_alt</mat-icon></span> <span><mat-icon (click)="removeFile(d)">delete</mat-icon></span>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="DISPLAYED_COLUMNS ; sticky: true;"></tr>
  <tr mat-row *matRowDef="let d; columns: DISPLAYED_COLUMNS;" [ngClass]="{'hidden' : view != d[1]}"></tr>

</mat-table>
<div class="input-footer">
  <div>
    <h2> Importer un fichier</h2>
    <div class="import">
      <app-import-file (uploadFileEvent)="uploadFile($event)" [canSubmit]="typeImport || typeAlert"></app-import-file>
      <mat-checkbox [checked]="typeImport" (change)="typeImport = $event.checked">Import JIRA</mat-checkbox>
      <mat-checkbox [checked]="typeAlert" (change)="typeAlert = $event.checked">Alertes</mat-checkbox>
    </div>
  </div>

  <div>
    <mat-card-title>
      <h2>
        <mat-slide-toggle [(ngModel)]="slider" (ngModelChange)="toggleSlider()"></mat-slide-toggle>
        Traitement automatique des fichiers JIRA
      </h2>
    </mat-card-title>
    <div class="import">
      <mat-form-field appearance="outline">
        <input matInput [readonly]="!isEditingH" [(ngModel)]="timer" type="time">
      </mat-form-field>
      <button *ngIf="!isEditingH" class="round" (click)="enableEditH()"><p class="material-icons">edit</p></button>
      <button *ngIf="isEditingH" class="round" (click)="onEditH()"><p class="material-icons">done</p></button>
    </div>
  </div>
</div>

