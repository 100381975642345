import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {INetting} from 'src/app/shared/models/netting-account';
import {DialogService} from 'src/app/shared/services/dialog.service';
import {NettingService} from '../netting.service';
import {NettingSchemaService} from '../../netting-schema/nettingschema.service';

@Component({
  selector: 'app-netting-form',
  templateUrl: './netting-form.component.html',
  styleUrls: ['./netting-form.component.less']
})
export class NettingFormComponent implements OnInit {

  formGroup: UntypedFormGroup;

  asEdited = false;
  btnStatus = false;

  @Input()
  netting!: INetting;
  empty_schema_name!: string;
  public schemas_names: string[] = [this.empty_schema_name];

  @Output()
  updateEvent = new EventEmitter<INetting>();

  @Output()
  clearEvent = new EventEmitter<any>();

  constructor(private _fb: UntypedFormBuilder, private _ns: NettingService, private _nss: NettingSchemaService, private snackbarService: SnackbarService,
              private dialogService: DialogService) {
    this.formGroup = this._fb.group({
      id: [''],
      name: ['', Validators.required],
      code: ['', Validators.required],
      intitule: ['', Validators.required],
      schema_name: [''],
      isEnabled: ['']
    })

  }


  ngOnInit(): void {
    this.getSchemas();

  }

  getSchemas(): void {
    this._nss.getSchemasNames().subscribe({
      next: data => {
        if (data) {
          this.schemas_names = this.schemas_names.concat(data);
        }
      },
      error: err => {
        console.log(err)
      },
      complete: () => {
      }
    });

  }

  updateFormGroup() {
    const pUpdate = {
      id: this.netting.id,
      name: this.netting.name,
      code: this.netting.code,
      intitule: this.netting.intitule,
      schema_name: this.netting.schema_name,
      isEnabled: this.netting.isEnabled
    }
    this.formGroup.setValue(pUpdate);

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.netting) return;

    this.asEdited = false;
    if (!this.netting || !this.netting.hasOwnProperty('annee_pivot') || this.netting.isEnabled && this.netting.name === '') {
      this.updateFormGroup();
      this.asEdited = false;
      this.btnStatus = false;
    } else if (this.netting.isEnabled && this.netting.name != '') {
      this.updateFormGroup();
      this.asEdited = true;
      this.btnStatus = false;

    } else if (!this.netting.isEnabled && this.netting.name != '') {
      this.updateFormGroup();
      this.asEdited = false;
      this.btnStatus = true;

    }
  }


  /*
   * Submit NettingForm
   */

  onSubmit(): void {

    if (!this.asEdited && !this.btnStatus) {
      this._ns.createNettingAccount(this.formGroup.value).subscribe({
        next: data => {
          this.updateEvent.emit(data);
          this.formGroup.reset();
          this.openSuccessSnackBar("Le compte de netting a été créé avec succès")
        },
        error: err => {
          console.log(err)
          this.openErrorSnackBar("Le compte de netting n'a pas été créé")
        },
        complete: () => {
        }
      });
    } else if (!this.asEdited && this.btnStatus) {
      this._ns.updateNettingAccount(this.netting.id ?? '', this.formGroup.value).subscribe({
        next: data => {
          this.updateEvent.emit(data);
          this.formGroup.reset();
          this.asEdited = false;
          this.btnStatus = false
          this.openSuccessSnackBar("Le compte de netting a été modifié avec succès")
        },
        error: err => {
          console.log(err)
          this.openErrorSnackBar("Le compte de netting n'a pas été modifié")
        },
        complete: () => {
        }
      })
    }
  }


  openErrorSnackBar(message: string) {
    this.snackbarService.openSnackBar(message, 'error-snack-bar');
  }

  openSuccessSnackBar(message: string) {
    this.snackbarService.openSnackBar(message);
  }
 


  /*
 * Initialize orderform component
 */
  clear(): void {
    this.formGroup.reset();
    this.asEdited = false;
    this.btnStatus = false
    this.clearEvent.emit();
  }

  is_edit(asEdited: boolean) {
    asEdited = !asEdited
    this.asEdited = asEdited
    this.btnStatus = true
  }

  /*
  * Delete project form
  */
  deleteProjectForm() {
    if (this.asEdited) {
      this._ns.deleteNettingAccount(this.netting).subscribe({
        next: data => {
          this.clear();
          this.updateEvent.emit(data);
        },
        error: err => {
          console.log(err)
        },
        complete: () => {
        }
      });
    }
  }

  /*
   * Pop Confirmation
   */
  confirmDelete() {
    this.dialogService.openConfirmDialog(this.netting.name)
      .afterClosed().subscribe({
      next: res => {
        this.deleteProjectForm();
        this.openSuccessSnackBar("Le compte de netting a été supprimé avec succès")
      },
      error: err => {
        console.log(err)
        this.openErrorSnackBar("Le compte de netting n'a pas été supprimé")
      },
      complete: () => {
      }
    });
  }

}
