import {Component, OnInit} from '@angular/core';
import * as fileSaver from 'file-saver';
import {UserProfile} from 'src/app/shared/models/user-profile';
import {AuthenticationService} from 'src/app/shared/services/authentication.service';
import {ThemeService} from 'src/app/shared/services/theme.service';
import {ApplicationSettingsService} from '../../application-settings/application-settings.service';
import {CalendrierService} from '../../calendrier-pack/calendrier.service';
import {OrderFormService} from '../../orders-forms/services/order-form.service';
import { SnackbarService } from '../../../shared/services/snackbar.service';

@Component({
  selector: 'app-dashboard-order',
  templateUrl: './dashboard-order.component.html',
  styleUrls: ['./dashboard-order.component.less'],
})
export class DashboardOrderComponent implements OnInit {
  headers = [
    'command',
    'label',
    'year',
    'dsi',
    'comAmount',
    'engAmount',
    'toEngAmount',
    'comEngRatio',
    'constAmount',
    'comConstRatio',
    'availAmount',
    'overAmount',
    'factured1',
    'm1',
    'm2',
    'm3',
    'm4',
    'm5',
    'm6',
    'm7',
    'm8',
    'm9',
    'm10',
    'm11',
    'm12',
    'total1',
    'm13',
    'm14',
    'm15',
    'm16',
    'm17',
    'm18',
    'm19',
    'm20',
    'm21',
    'm22',
    'm23',
    'm24',
    'total2',
    'cumul',
  ];

  months = [
    'janvier',
    'février',
    'mars',
    'avril',
    'mai',
    'juin',
    'juillet',
    'août',
    'septembre',
    'octobre',
    'novembre',
    'décembre',
  ];

  spinner = true;

  refValue = '';

  dataTable: any[][] = [];

  packDataTable1: any[][] = [];
  packDataTable2: any[][] = [];

  packTotalTable1: any[] = [];
  packTotalTable2: any[] = [];

  settingsYear = 0;
  selectedPack = 0;

  monthSelected = 2;

  monthLastClosed = 1;
  yearLastClosed = 1;

  monthCurrent = 1;
  yearCurrent = 1;

  selectedMode = 0;

  totalEngaged = 0;
  totalStated = 0;
  totalEngagedCommandless = 0;
  totalStatedCommandless = 0;

  constructor(
    public as: ApplicationSettingsService,
    public cs: CalendrierService,
    public os: OrderFormService,
    public theme: ThemeService,
    public auths: AuthenticationService,
    private snackbarService : SnackbarService,
  ) {
    this.monthCurrent = this.as.getApplicationSettings().Pack_current_month;
    this.yearCurrent = this.as.getApplicationSettings().Pack_current_year;

    cs.getCalendrier().subscribe({
      next: (value) => {
        value.forEach((element) => {
          if (element.cloture != null) {
            element.cloture = new Date(Date.parse(element.cloture + ''));
            if (
              element.cloture != null &&
              element.cloture.setHours(0, 0, 0, 0) <=
              new Date().setHours(0, 0, 0, 0)
            ) {
              if (
                (element.year != null &&
                  element.month != null &&
                  element.year > this.yearLastClosed) ||
                (element.year == this.yearLastClosed &&
                  element.month! >= this.monthLastClosed)
              ) {
                this.monthLastClosed = element.month!;
                this.yearLastClosed = element.year;
              }
            }
          }
        });
        this.loadPackTable(true);
      },
      error: (err) => {
        console.log(err);
      },
      complete: () => {

      }
    });
  }

  openErrorSnackBar(message: string) {
    this.snackbarService.openSnackBar(message, 'error-snack-bar');
  }

  openSuccessSnackBar(message: string) {
    this.snackbarService.openSnackBar(message);
  }
 

  ngOnInit(): void {
    this.settingsYear = this.as.getApplicationSettings().Pack_current_year;
    this.selectedPack = this.settingsYear;
  }

  swapMode(mode: number) {
    if (mode == this.selectedMode) this.selectedMode = 0;
    else this.selectedMode = mode;

    this.loadTotalAmounts();
  }

  isActive(row: any[]) {
    //DEBUG - A RETIRER !!!

    if (row[0] == '---') return true;

    if (
      (this.auths.getUser().profile == UserProfile.CP_SNCF ||
        this.auths.getUser().profile == UserProfile.CP_CDS) &&
      Number(row[4]) == 0 &&
      Number(row[5]) == 0
    )
      return false;

    if (this.selectedMode == 1) return Number(row[4]) > Number(row[3]);

    if (this.selectedMode == 2) return Number(row[7]) > Number(row[3]);

    return true;
  }

  exportData() {
    this.selectedMode = 0;
    this.loadTotalAmounts();

    let yr = this.yearCurrent;
    let mth = this.monthCurrent;
    if (this.selectedMode == 1) yr = this.yearLastClosed;
    if (this.selectedMode == 1) mth = this.monthLastClosed;
    this.os
      .exportDataDetails(
        this.dataTable,
        this.selectedPack + '',
        this.months[mth - 1] + ' ' + yr,
      )
      .subscribe({
        next: (data) => {
          const exportYear: number = this.selectedPack;
          if (data) {
            const blob: any = new Blob([data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            fileSaver.saveAs(
              blob,
              'Suivi de commande ' + exportYear + ' - ' +
              this.as.applicationSettings.CDS_name +
              ' - ' +
              new Date().toLocaleDateString().replace('_', '-') +
              ' ' +
              new Date().toLocaleTimeString().replace('_', '-'),
            );
          } else {
            this.openSuccessSnackBar('Aucunes données à exporter');
          }
        },
        error: (err) => {
          console.log(err);
          this.openErrorSnackBar("Erreur lors de l'export du dashboard");
        },
        complete: () => {
        },
      });
  }

  isRed(row: any[]): boolean {
    if (Number(row[6]) > 1.05) return true;
    if (Number(row[8]) > 1.05) return true;
    return false;
  }

  swapYear(year: number) {
    this.selectedPack = year;

    if (year == this.settingsYear) this.loadPackTable(true);
    else this.loadPackTable(false);
  }

  yearFromIndex(index: number) {
    if (index < 12) return this.selectedPack;
    return this.selectedPack + 1;
  }

  loadPackTable(current: boolean) {
    this.spinner = true;
    let table: any[][] = [];
    if (current) table = this.packDataTable2;
    else table = this.packDataTable1;

    //Skip request if already loaded
    if (table.length == 0) {
      const test = this.auths.getUser().applications;
      const tab: string[] = [];
      test.forEach((element) => {
        tab.push(element.name + '');
      });
      this.os
        .getOrderFormDetails(this.selectedPack + '', tab, this.refValue + '')
        .subscribe({
          next: (data) => {
            table = data;
            if (current) this.packDataTable2 = table;
            else table = this.packDataTable1 = table;

            this.dataTable = table;
            this.loadStatedAmounts();
            this.saveTotalTable(current);

          },
          error: (err) => {
            console.log(err);
            this.openErrorSnackBar("Erreur lors de la récuperation du dashboard");
          },
          complete: () => {
            this.spinner = false;
          },
        });
    } else {
      if (current) this.packDataTable2 = table;
      else this.packDataTable1 = table;

      this.dataTable = table;
      this.loadStatedAmounts();
      this.saveTotalTable(current);
      this.spinner = false;
    }
  }

  saveTotalTable(current: boolean) {
    let table: any[] = [];
    if (current) table = this.packTotalTable2;
    else table = this.packTotalTable1;

    if (table.length == 0) {
      table.push(this.totalEngaged);
      table.push(this.totalStated);
      table.push(this.totalEngagedCommandless);
      table.push(this.totalStatedCommandless);
    } else {
      this.totalEngaged = table[0];
      this.totalStated = table[1];
      this.totalEngagedCommandless = table[2];
      this.totalStatedCommandless = table[3];
    }
  }

  resetThenLoad() {
    this.packDataTable1 = [];
    this.packDataTable2 = [];

    if (this.selectedPack == this.settingsYear) this.loadPackTable(true);
    else this.loadPackTable(false);
  }

  loadStatedAmounts() {
    this.dataTable.forEach((row) => {
      row[7] = 0;

      for (let i = 15; i <= this.getTableIndexFromMonth(); i++) {
        row[7] += Number(row[i]);
      }

      //if (Number(row[3]) != 0) row[8] = 100 * Number(row[7]) / Number(row[3]);
    });
    this.loadTotalAmounts();
  }

  loadTotalAmounts() {
    this.totalEngaged = 0;
    this.totalStated = 0;
    this.totalEngagedCommandless = 0;
    this.totalStatedCommandless = 0;

    let firstTotal = true;
    const template = [
      '',
      '',
      '',
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      'TOTAL',
      '',
    ];

    let totalRow: any[] = [];
    for (let i = 0; i < template.length - 1; i++) totalRow.push(template[i]);

    this.dataTable.forEach((row) => {
      if (row[39] == 'TOTAL') {
        for (let i = 3; i < row.length - 1; i++)
          if (i != 6 && i != 8) row[i] = Number(totalRow[i]);
        row[40] = '';
        row[6] = row[3] == 0 ? 'n/a' : row[5] / row[3];
        row[8] = row[3] == 0 ? 'n/a' : row[7] / row[3];
        totalRow = [];
        for (let i = 0; i < template.length - 1; i++)
          totalRow.push(template[i]);

        if (firstTotal) {
          this.totalEngaged = Number(row[4]);
          this.totalStated = Number(row[7]);
          firstTotal = false;
        } else {
          this.totalEngagedCommandless = Number(row[4]);
          this.totalStatedCommandless = Number(row[7]);
        }
      } else if (this.isActive(row)) {
        for (let i = 3; i < row.length - 1; i++) totalRow[i] += Number(row[i]);
      }
    });
  }

  getTableIndexFromMonth() {
    if (this.monthSelected == 1)
      return (
        15 -
        1 +
        this.monthLastClosed +
        (this.yearLastClosed - this.selectedPack) * 12
      );
    else
      return (
        15 - 1 + this.monthCurrent + (this.yearCurrent - this.selectedPack) * 12
      );
  }
}
